import tw from "twin.macro"
import { EmptyFavoriteProps } from "./FavoriteEmpty.d"
import { LanguageContext } from "../../../contexts/Language"
import { useContext } from "react"
import Icon from "../../atoms/Icon"
import { css } from "@emotion/react"
const FavoriteEmpty: React.FC<EmptyFavoriteProps> = ({
  message,
  ctaLabel,
  ctaLink,
  currentTab,
}): JSX.Element => {
  const { language, _ } = useContext(LanguageContext)
  const subheadingHoverAnimation = css`
    @media (min-width: 1024px) {
      &:hover > .darkRed {
        ${tw`opacity-100 duration-300 translate-x-9`}
      }
      &:hover > .lighterRed {
        ${tw`opacity-100 delay-200 translate-x-7`}
      }
      &:hover > .lightestRed {
        ${tw`opacity-100 delay-100 translate-x-5`}
      }
    }
  `
  const subheadingFocusAnimation = css`
    @media (min-width: 1024px) {
      &:focus-visible > .darkRed {
        ${tw`opacity-100 duration-300 translate-x-9`}
      }
      &:focus-visible > .lighterRed {
        ${tw`opacity-100 delay-200 translate-x-7`}
      }
      &:focus-visible > .lightestRed {
        ${tw`opacity-100 delay-100 translate-x-5`}
      }
    }
  `
  return (
    <section
      aria-label={`Favorited ${currentTab} Section`}
      css={[
        tw`text-center w-full py-8`,
        `background-image: url(${process.env.GATSBY_ROOT_URL}favorite-outline.png)`,
        `background-repeat: no-repeat;`,
        `background-position: center;`,
      ]}
    >
      <div css={[tw`mx-auto mb-[40px] max-w-[38rem]`]}>
        <h2 css={[tw`text-xl font-book mt-8 mb-3 mx-12`]}>{message}</h2>
        <a
          css={[
            tw`text-gray-900 relative p-0 my-1 font-semibold whitespace-nowrap focus-visible:(outline-gray)`,
            subheadingHoverAnimation,
            subheadingFocusAnimation,
          ]}
          href={ctaLink}
        >
          {ctaLabel}
          <Icon.Chevron
            direction="right"
            color="red-200"
            className="lightestRed"
            css={[
              tw`absolute bottom-0 right-2 h-3 translate-x-5 -translate-y-0.5 opacity-0`,
            ]}
          />
          <Icon.Chevron
            direction="right"
            color="red-300"
            className="lighterRed"
            css={[
              tw`absolute bottom-0 right-2 h-3 translate-x-5 -translate-y-0.5 opacity-0`,
            ]}
          />
          <Icon.Chevron
            direction="right"
            color="red-400"
            className="darkRed"
            css={[
              tw`absolute bottom-0 right-2 h-3 translate-x-5 -translate-y-0.5`,
            ]}
          />
        </a>
      </div>
    </section>
  )
}
export default FavoriteEmpty
