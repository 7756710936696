import React, { useEffect, useRef, useState } from "react"

/**
 * Hook for implementing scroll-spy with the Intersection Observer API
 * @author Tyler
 * @param {Object[]} elements Array of node lists for specified DOM elements
 * @param {Object} options Intersection observer options
 * @returns {number} Index of active element
 */

const useScrollSpy = (elements: Element[], options: Object) => {
  const [activeElementIndex, setActiveElementIndex] = useState(-1)

  const observer = useRef<IntersectionObserver>()

  useEffect(() => {
    if (observer.current) {
      observer.current.disconnect()
    }

    observer.current = new IntersectionObserver(entries => {
      // Find index of currently active element
      const activeElement = entries.findIndex(entry => {
        return entry.intersectionRatio > 0
      })

      // Pass said index to local state
      setActiveElementIndex(activeElement)
    }, options)

    const { current: currentObserver } = observer

    // Attach an observer to each element passed in the hook
    elements?.forEach((element: Element) =>
      element ? currentObserver.observe(element) : null
    )

    return () => currentObserver.disconnect()
  }, [elements, options])

  return [activeElementIndex]
}

export default useScrollSpy
