import { useState } from "react"

type Coords = {
  x: number
  y: number
}

type DragInfoState = {
  isDragging: boolean
  origin: Coords
  translation: Coords
  lastTranslation: Coords
}

const useDrag = () => {
  const startingPosition: Coords = {
    x: 0,
    y: 0,
  }

  const [dragInfo, setDragInfo] = useState<DragInfoState>({
    isDragging: false,
    origin: { x: 0, y: 0 },
    translation: startingPosition,
    lastTranslation: startingPosition,
  })

  const { isDragging } = dragInfo
  const handleMouseDown = ({ clientX, clientY }: MouseEvent) => {
    if (!isDragging) {
      setDragInfo({
        ...dragInfo,
        isDragging: true,
        origin: { x: clientX, y: clientY },
      })
    }
  }

  const handleMouseMove = ({ clientX, clientY }: MouseEvent) => {
    if (isDragging) {
      const { origin, lastTranslation } = dragInfo
      setDragInfo({
        ...dragInfo,
        translation: {
          x: Math.abs(clientX - (origin.x + lastTranslation.x)),
          y: Math.abs(clientY - (origin.y + lastTranslation.y)),
        },
      })
    }
  }

  const handleMouseUp = () => {
    if (isDragging) {
      const { translation } = dragInfo
      setDragInfo({
        ...dragInfo,
        isDragging: false,
        lastTranslation: { x: translation.x, y: translation.y },
      })
    }
  }

  return {
    translation: dragInfo.translation,
    lastTranslation: dragInfo.lastTranslation,
    handleMouseUp,
    handleMouseDown,
    handleMouseMove,
  }
}

export default useDrag
