import React, { useContext } from "react"
import tw from "twin.macro"
import { LanguageContext } from "../../../../contexts/Language"
import { GalleryCarousel } from "../../../molecules/GalleryCarousel"
import { GalleryProps } from "./Gallery.d"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { parseDisclaimerBlocks } from "../../../../helpers"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"

const Gallery: React.FC<GalleryProps> = ({
  title,
  slides,
  _key,
  showSubNav,
  sectionSlug,
  showTitle = true,
  fromHybridSegment,
  margins,
  series,
}) => {
  const { _ } = useContext(LanguageContext)
  const [state, dispatch] = useContext(DisclaimersContext)
  return (
    <section
      css={[
        tw`pt-10 pb-10 relative bg-gray-900`,
        `margin: ${margins?.top}px 0 ${margins?.bottom}px !important;`,
      ]}
      key={_key}
      id={sectionSlug?.current}
      aria-label={_("Gallery Section")}
    >
      <div
        css={[
          tw`max-w-screen-xl mx-auto px-8 text-center text-white mb-10 relative`,
        ]}
      >
        {title && showTitle && (
          <h2 css={[tw`text-xs uppercase font-light tracking-widest mb-10`]}>
            {parseDisclaimerBlocks(title, selection =>
              dispatch(toggleDisclaimersModal(selection))
            )}
          </h2>
        )}
        {showSubNav && (
          <ul
            css={[
              tw`flex gap-x-8 overflow-x-scroll h-12`,
              tw`md:(justify-around gap-x-8 overflow-x-hidden)`,
            ]}
          >
            {["Models", "Inventory", "Offers", "Brochure", "Features"]?.map(
              item => (
                <span css={[tw`text-lg`]}>{item}</span>
              )
            )}
          </ul>
        )}
      </div>
      {slides && (
        <GalleryCarousel
          fromHybridSegment={fromHybridSegment}
          slides={slides}
          series={series}
        />
      )}
    </section>
  )
}

export default Gallery
