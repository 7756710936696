import React, { useContext, useEffect, useState } from "react"
import { XSeriesProps } from "./Xseries.d"
import tw from "twin.macro"
import { Image } from "../../../atoms/Image"
import { LanguageContext } from "../../../../contexts/Language"
import { Color, Model } from "../../../../global"
import { ModelImageViewerWithHotSpots } from "../../../molecules/ModelImageViewerWithHotSpots"
import { ColorSelector } from "../../../molecules/ColorSelector"
import { LocalInventoryLinks } from "../../../molecules/LocalInventoryLinks"
import { LocationContext } from "../../../../contexts/Location"
import { InventoryClient } from "../../../../clients/InventoryClient"
import { XseriesHotspots } from "../../../molecules/XseriesHotspots"
import { motion } from "framer-motion"
import { css } from "@emotion/react"
import {
  generateInventoryLink,
  parseDisclaimerBlocks,
} from "../../../../helpers"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { Hotspot } from "../../XseriesModel/XseriesModel.d"
import { useTealiumContext } from "../../../../contexts/Tealium"

/**
 * @author Cody
 *
 * @returns
 */

const Xseries: React.FC<XSeriesProps> = ({ sectionSlug, margins, models }) => {
  if (!models) return null
  const [_state, dispatch] = useContext(DisclaimersContext)

  const { language, _ } = useContext(LanguageContext)
  const [selectedColor, setSelectedColor] = useState<number>(0)
  const [activeSpot, setActiveSpot] = useState<Hotspot>()
  const [currentAngle, setCurrentAngle] = useState<1 | 2 | 3 | 4>(1)
  const [{ city, dealers }] = useContext(LocationContext)
  const [inventoryCount, setInventoryCount] = useState<number | "...">(0)
  const [activeModel, setActiveModel] = useState(models[0])
  const [exteriorColorList, setExteriorColorList] = useState(null)
  const [exteriorImages, setExteriorImages] = useState(null)
  const [hotspots, setHotspots] = useState<Hotspot[]>([])
  const [currentAngleHotspots, setCurrentAngleHotspots] = useState<Hotspot[]>(
    []
  )

  const { updateVehicleTealData, vehicleTealData } = useTealiumContext()

  const activeModelAccyCodes = activeModel.accessoryCodes?.split(",")

  const tabAnimation = css`
    &:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
      content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:hover:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const focusAnimation = css`
    &:focus-visible:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
      content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:focus-visible:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `

  const active = css`
    ${tw`font-semibold focus-visible:(border-transparent)`}
    &:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
    &:hover:after {
      ${tw`h-1 bg-red-400`}
    }
    &:focus-visible:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
  `

  useEffect(() => {
    if (dealers?.length > 0) {
      const getCount = async () => {
        const dealerIds = dealers?.map(dealer => dealer?.DealerCode).join(",")
        try {
          setInventoryCount("...")
          const { count } = await InventoryClient.getCount("", {
            dealer: dealerIds,
            accessoryModels: activeModelAccyCodes,
          })
          setInventoryCount(count)
        } catch (error) {
          setInventoryCount(error)
        }
      }
      getCount()
    }
  }, [dealers, activeModel])

  useEffect(() => {
    const updatedExteriorColorList = activeModel?.colors?.map((color: any) => {
      return {
        name: color?.exterior?.title,
        hex: color?.exterior?.colors?.map((color: any) => color?.hex),
      }
    })

    const updatedExteriorImages = activeModel?.colors?.map((color: any) => {
      return color?.exteriorImages
    })
    setExteriorColorList(updatedExteriorColorList)
    setExteriorImages(updatedExteriorImages)

    // Setting hotspots
    const angle1Hotspots = activeModel?.hotspotsAngle1?.map((hotspot: any) => {
      hotspot.angle = 1
      return hotspot
    })
    const angle2Hotspots = activeModel?.hotspotsAngle2?.map((hotspot: any) => {
      hotspot.angle = 2
      return hotspot
    })
    const angle3Hotspots = activeModel?.hotspotsAngle3?.map((hotspot: any) => {
      hotspot.angle = 3
      return hotspot
    })
    const angle4Hotspots = activeModel?.hotspotsAngle4?.map((hotspot: any) => {
      hotspot.angle = 4
      return hotspot
    })
    setHotspots([
      ...angle1Hotspots,
      ...angle2Hotspots,
      ...angle3Hotspots,
      ...angle4Hotspots,
    ])
  }, [activeModel])

  useEffect(() => {
    // Setting hotspots for current angle
    let newCurrentAngleHotspots = []
    switch (currentAngle) {
      case 1:
        newCurrentAngleHotspots = activeModel?.hotspotsAngle1?.map(
          (hotspot: any) => {
            hotspot.angle = 1
            return hotspot
          }
        )
        break
      case 2:
        newCurrentAngleHotspots = activeModel?.hotspotsAngle2?.map(
          (hotspot: any) => {
            hotspot.angle = 2
            return hotspot
          }
        )
        break
      case 3:
        newCurrentAngleHotspots = activeModel?.hotspotsAngle3?.map(
          (hotspot: any) => {
            hotspot.angle = 3
            return hotspot
          }
        )
        break
      case 4:
        newCurrentAngleHotspots = activeModel?.hotspotsAngle4?.map(
          (hotspot: any) => {
            hotspot.angle = 4
            return hotspot
          }
        )
        break
      default:
        break
    }
    setCurrentAngleHotspots(newCurrentAngleHotspots)
  }, [currentAngle, activeModel])

  // set Tealium values for X-Series trim
  if (vehicleTealData?.vehicle_trim !== activeModel.name) {
    // only update if the value for the trim has not been set
    updateVehicleTealData({
      ...vehicleTealData,
      vehicle_trim: activeModel.name || "", // For use in "X Series" section of Series page
      vehicle_model_trim:
        `${activeModel.series.name} ${activeModel.name}` || "",
    })
  }

  return (
    <section
      css={[
        tw`relative bg-gradient-to-br from-gray-200 via-gray-50 to-white`,
        `margin: ${margins?.top}px 0 ${margins?.bottom}px !important;`,
      ]}
      id={sectionSlug?.current}
      aria-label="X-Series Section"
    >
      <motion.div
        viewport={{ once: true, amount: 0.95 }}
        css={[
          tw`grid overflow-hidden grid-cols-12 pb-12 mx-auto grid-rows-1 grid-flow-row w-auto h-auto`,
          tw`lg:(max-w-full pb-32 )`,
        ]}
      >
        <motion.div
          viewport={{ once: true }}
          initial={{
            x: -300,
            opacity: 0,
          }}
          whileInView={{
            x: 0,
            opacity: 1,
          }}
          transition={{ delay: 0.5, duration: 0.25 }}
          css={[
            tw`col-span-12 order-1 p-8`,
            tw`lg:(col-span-4 order-1 bg-white mt-20 pb-16 rounded-tr-3xl shadow-5 mb-20)`,
          ]}
        >
          <div
            css={[
              tw`flex gap-6 overflow-x-scroll w-full px-4 mb-10 scrollbar-hide`,
              tw`lg:(justify-center)`,
            ]}
          >
            {models.length > 1 &&
              models.map((model: Model) => {
                return (
                  <button
                    onClick={() => {
                      setActiveModel(model)
                    }}
                    aria-selected={activeModel === model}
                    role="tab"
                    css={[
                      tabAnimation,
                      focusAnimation,
                      tw`cursor-pointer whitespace-nowrap inline-block relative py-2 mx-2 border border-transparent`,
                      tw`focus-visible:(border-gray-900 border-dashed border outline-none)`,
                      activeModel === model && active,
                    ]}
                  >
                    {model.name}
                  </button>
                )
              })}
          </div>

          <h3 css={[tw`uppercase text-2xl text-center font-semibold mt-8`]}>
            <p css={tw`tracking-wider font-book`}>{_("Explore the")}</p>{" "}
            {activeModel.series.name} {activeModel.name}
          </h3>
          <h3
            css={[
              tw`uppercase text-6xl italic text-center block font-semibold`,
            ]}
          >
            <Image imageData={activeModel.badgeImage.image} />
          </h3>
          <div css={[tw`text-center flex justify-center`]}>
            <LocalInventoryLinks
              city={city}
              dealerCount={dealers?.length}
              analyticsId="xseries features:"
              inventoryCount={inventoryCount}
              series={activeModel.series.name}
              css={[tw`mb-10`]}
              inventoryLink={generateInventoryLink({
                series: activeModel.series.name.toLowerCase(),
                accessoryModels: [activeModelAccyCodes?.join(",")],
              })}
            />
          </div>
          <p css={[tw`pt-0 pb-8 text-center max-w-xl mx-auto md:(py-8)`]}>
            {language === "es"
              ? parseDisclaimerBlocks(activeModel.taglineES, code =>
                  dispatch(toggleDisclaimersModal(code))
                )
              : parseDisclaimerBlocks(activeModel.tagline, code =>
                  dispatch(toggleDisclaimersModal(code))
                )}
          </p>
          {exteriorColorList && (
            <ColorSelector
              colorList={exteriorColorList}
              onChange={selectedSwatch => setSelectedColor(selectedSwatch)}
              analyticsId={`exterior color selector:xseries features:`}
              xpSeries={true}
            />
          )}
        </motion.div>
        <div
          css={[
            tw`col-start-1 col-span-12 order-2`,
            tw`md:(mx-8)`,
            tw`lg:(col-span-8 mx-10)`,
          ]}
        >
          {exteriorImages && (
            <ModelImageViewerWithHotSpots
              images={exteriorImages[selectedColor]}
              currentSlideHotspots={currentAngleHotspots}
              currentColor={exteriorColorList[selectedColor].name}
              activeSpot={activeSpot}
              setActiveSpot={setActiveSpot}
              setCurrentAngle={setCurrentAngle}
              currentAngle={currentAngle}
              hotspots={hotspots}
              xpSeries
            />
          )}

          <motion.div
            viewport={{ once: true }}
            initial={{
              x: 300,
              opacity: 0,
            }}
            whileInView={{
              x: 0,
              opacity: 1,
            }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            {hotspots && (
              <XseriesHotspots
                hotspots={hotspots}
                activeSpot={activeSpot}
                setActiveSpot={setActiveSpot}
                currentAngle={currentAngle}
                setCurrentAngle={setCurrentAngle}
              />
            )}
          </motion.div>
          {/*<p css={[tw`pb-0 mt-8 lg:(hidden)`]}>{activeModel.tagline}</p>*/}
        </div>
      </motion.div>
    </section>
  )
}
export default Xseries
