import { useContext, useEffect, useState } from "react"
import { LocationContext } from "../../contexts/Location"
import useDealers from "../useDealers"
import useTealiumEvent from "./useTealiumEvent"

/**
 * Hook provides a function that triggers a Tealium event for dealer searches or filter refinements when the `dealers` object is updated
 * @author Stu
 * @returns {() => DealerInfo} returns an function getDealerInfo, which itself returns an object that contains an array of dealer names, an array of dealer codes, and the number of search results
 */

//
const useDealerSearch = () => {
  const [{ dealers }] = useContext(LocationContext)
  const [inProgress, setInProgress] = useState(false)
  const [eventName, setEventName] = useState("")
  const [tealData, setTealData] = useState({})
  const { getDealerInfo } = useDealers()

  //   Function that extracts data from the current value of `dealers`
  const dealerInfo = getDealerInfo()

  const { trackTealEvent } = useTealiumEvent()

  //   This hook listens for changes to dealers and will fire off a trackTealEvent
  //   inProgress is used to make sure the trackTeal event is only triggered when 'dealerSearch' is manually called, and not every single time the 'dealers' object is updated
  useEffect(() => {
    if (inProgress) {
      trackTealEvent({
        tealium_event: eventName,
        ...tealData,
        ...(!tealData.dealer_name && {
          allocated_dealer_name: dealerInfo.dealerNames,
        }),
        ...(!tealData.dealer_code && {
          allocated_dealer_code: dealerInfo.dealerCodes,
        }),
        search_results: dealerInfo.searchResults,
      })
      //   Re-set inProgress to false to avoid tealium event calls for any update to `dealers`
      setInProgress(false)
    }
    return
  }, [dealers])

  /**
   * Function updates the state of data used in the tealium event call, AND the inProgress that tells the useEffect to fire the tealium call ONLY when it has been manually triggered.
   * @author Stu
   * @param  {string} nameOfEvent - the name of event used for the value of "tealium_event".
   * @param {object} dataForTealium - An optional object for custom data to send to Tealium.
   * @returns {() => void} returns a function that triggers the Tealium call on `dealers` object update
   */

  //
  const dealerSearch = (nameOfEvent = "", dataForTealium = {}) => {
    setEventName(nameOfEvent)
    setTealData(dataForTealium)
    setInProgress(true)
  }

  return { dealerSearch }
}

export default useDealerSearch
