import React, { useContext, useEffect, useState } from "react"
import { ChatBubbleMenuProps } from "./StickyFooter.d"
import tw from "twin.macro"
import { Link } from "../../atoms/Link"
import { ContactDealer } from "../ContactDealer"
import {
  openContactDealerModal,
  closeContactDealerModal,
} from "../../../contexts/Contact/actions"
import { ContactContext } from "../../../contexts/Contact"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { LanguageContext } from "../../../contexts/Language"
import FeedbackFormButton from "./FeedbackFormButton"
import { useTealiumContext } from "../../../contexts/Tealium"
import { getPageLoadTime } from "../../../helpers"

const ChatBubbleMenu: React.FC<ChatBubbleMenuProps> = ({
  toggle,
  setToggle,
}) => {
  const [{ isContactDealerModalOpen, vehicle }, dispatch] =
    useContext(ContactContext)
  const { _ } = useContext(LanguageContext)

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const {
    updateContactDealerData,
    setVehicleTealData,
    tealPageData,
    vehicleTealData,
  } = useTealiumContext()

  //additional styling targeting the qualtrics survey - bug reported on IOS safari where search bar overlays close button
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      const surveyEl = document?.getElementsByClassName(
        "QSIWebResponsiveDialog-Layout1-SI_8pSnkh6D7wgYMkK_content-medium"
      ) as HTMLCollectionOf<HTMLElement>
      if (surveyEl[0] !== undefined || null) {
        surveyEl[0].style.marginTop = "40px"
      }
    }, 4000)
  }, [feedbackOpen])

  return (
    <>
      <div
        css={[
          tw`flex opacity-0 justify-center items-center absolute -bottom-4 -right-20 bg-gray-100 border-gray-800 border rounded-full transition-all duration-300`,
          toggle.chatBubble
            ? tw`flex w-80 h-80 opacity-100`
            : tw`h-0 w-0 opacity-0`,
        ]}
      >
        <ul
          css={[
            tw`text-right w-[7.5rem] mb-16 mr-10 text-black text-base capitalize`,
          ]}
        >
          <li>
            <Link
              animated
              onClick={() => {
                window &&
                  window.ActivEngage.launchChat({
                    vehicleVin: "",
                    vehicleYear: "",
                    vehicleMake: "Toyota",
                    vehicleModel: "",
                    vehicleStatus: "",
                    launchSource: "SRP",
                  })

                window?.ActivEngage?.data?.setChatData({
                  geolocation_zipcode: tealPageData?.geolocation_zip || null,
                  page_name: tealPageData?.page_name || null,
                  domain: window?.location?.hostname || null,
                  url: window?.location?.href || null,
                  referrer: document?.referrer || null,
                  page_language: tealPageData?.page_language || null,
                  page_load_time: getPageLoadTime() || null,
                  page_type: tealPageData?.page_type || null,
                  offer_type: tealPageData?.offer_type || null,
                })

                console.info(
                  "Active Engage Chat Data",
                  window?.ActivEngage?.data?.getChatData()
                )
                trackTealEvent({
                  tealium_event: "chat_text_open",
                  click_text: "chat bubble:Chat Now",
                })
                setToggle({ ...toggle, chatBubble: !toggle.chatBubble })
              }}
              css={[tw`py-1 cursor-pointer`]}
              analytics-id="chat now:chat bubble:"
            >
              {_("Chat Now")}
            </Link>
            <Link
              animated
              onClick={() => {
                window &&
                  window.ActivEngage.launchSms({
                    vehicleVin: "",
                    vehicleYear: "",
                    vehicleMake: "Toyota",
                    vehicleModel: "",
                    vehicleStatus: "",
                    launchSource: "SRP",
                  })
                window?.ActivEngage?.data?.setChatData({
                  geolocation_zipcode: tealPageData?.geolocation_zip || null,
                  page_name: tealPageData?.page_name || null,
                  domain: window?.location?.hostname || null,
                  url: window?.location?.href || null,
                  referrer: document?.referrer || null,
                  page_language: tealPageData?.page_language || null,
                  page_load_time: getPageLoadTime() || null,
                  page_type: tealPageData?.page_type || null,
                  offer_type: tealPageData?.offer_type || null,
                })
                console.info(
                  "Active Engage Chat Data",
                  window?.ActivEngage?.data?.getChatData()
                )
                trackTealEvent({
                  tealium_event: "chat_text_open",
                  click_text: "chat bubble:Send Us A Text",
                })
                setToggle({ ...toggle, chatBubble: !toggle.chatBubble })
              }}
              css={[tw`py-1 cursor-pointer`]}
              analytics-id="send text:chat bubble:"
            >
              {_("Send Us a Text")}
            </Link>
          </li>
          <li>
            <Link
              animated
              css={[
                tw`border-gray-400 border-b py-1 pb-2 w-full text-right cursor-pointer`,
              ]}
              onClick={() => {
                dispatch(openContactDealerModal())
                trackTealEvent({
                  tealium_event: "chat_text_open",
                  click_text: "chat bubble:Contact Dealer",
                })
                setToggle({ ...toggle, chatBubble: !toggle.chatBubble })
                dispatch(openContactDealerModal(null, vehicle))
                if (tealPageData.page_type !== "inventory vdp") {
                  updateContactDealerData({
                    fromInventory: null,
                    fromSelection: null,
                    allocatedDealer: null,
                  })
                  setVehicleTealData(null)
                }
              }}
              analytics-id="contact:chat bubble:"
            >
              {_("Contact Dealer")}
            </Link>
          </li>
          <li
            css={[tw`py-0 text-xs text-gray-700`]}
            onClick={() => {
              trackTealEvent({
                tealium_event: "chat_text_open",
                click_text: "chat bubble:Feedback",
              })
              setToggle({ ...toggle, chatBubble: !toggle.chatBubble })
              setFeedbackOpen(true)
            }}
            analytics-id="feedback:chat bubble:"
          >
            <FeedbackFormButton />
          </li>
        </ul>
      </div>
      <ContactDealer
        modalIsOpen={isContactDealerModalOpen}
        setModalIsOpen={open => {
          if (open) {
            dispatch(openContactDealerModal())
          } else {
            dispatch(closeContactDealerModal())
          }
        }}
      />
    </>
  )
}

export default ChatBubbleMenu
