import { motion } from "framer-motion"
import { Link } from "../../../atoms/Link"
import React, { useState } from "react"
import tw from "twin.macro"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import { Image } from "../../../atoms/Image"
import { ImageBlockProps } from "./ImageBlocks.d"

const ImageBlock: React.FC<ImageBlockProps> = ({ block, count }) => {
  const [imageBlockOpacity, setImageBlockOpacity] = useState(false)
  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  return (
    <div
      css={[
        tw`relative`,
        count === 1 && tw`w-[80vw] flex-none`,
        tw`md:(w-full flex flex-grow)`,
        count === 2 && tw`w-[80vw] flex-none`,
        tw`md:(w-1/2 flex flex-grow)`,
        count === 3 && tw`w-[80vw] flex-none`,
        tw`md:(w-1/3 flex flex-grow)`,
        count === 4 && tw`w-[80vw] flex-none`,
        tw`md:(w-1/4 flex flex-grow)`,
      ]}
    >
      <Link
        onClick={() =>
          trackTealEvent({
            tealium_event: "footer_module",
            footer_module_click:
              block?.imageLink?.externalUrl ||
              `/${block?.imageLink?.internalLink?.slug?.current}/`,
            link_href:
              block?.imageLink?.externalUrl ||
              `/${block?.imageLink?.internalLink?.slug?.current}/`,
            coupon_module_text: `${block?.heading}`,
          })
        }
        to={
          block?.imageLink?.externalUrl ||
          `/${block?.imageLink?.internalLink?.slug?.current}/`
        }
        className="group"
        css={[
          tw`h-full w-full block opacity-100 transform duration-150 ease-out border border-black overflow-visible py-0`,
          tw`focus-visible:(outline-none border border-black border-dashed)`,
        ]}
        analytics-id={`image block::${block?.heading}`}
      >
        <div
          css={[
            tw`flex overflow-hidden justify-center items-center`,
            tw`md:order-1`,
          ]}
        >
          {block?.image?.image && (
            <>
              <Image
                imageData={block?.image.image}
                css={[
                  tw`h-56 w-full ease-out duration-300 opacity-100 object-cover`,
                  tw`md:(h-72)`,
                  tw`group-hover:(scale-110 ease-in-out duration-300)`,
                  tw`group-focus-visible:(scale-110 ease-in-out duration-300)`,
                ]}
              />
              <motion.div
                initial={{
                  width: "100%",
                }}
                whileInView={{
                  width: 0,
                }}
                viewport={{ once: true, amount: 0.45 }}
                transition={{ duration: 0.25 }}
                css={[tw`absolute left-0 h-full z-20 bg-white`]}
              />
              <motion.div
                initial={{
                  width: "100%",
                }}
                whileInView={{
                  width: 0,
                }}
                viewport={{ once: true, amount: 0.45 }}
                transition={{ delay: 0.5, duration: 0.25 }}
                css={[tw`absolute left-0 h-full z-10 bg-black`]}
              />
            </>
          )}
          {block?.bgImage?.image && (
            <>
              <Image
                imageData={block?.bgImage?.image}
                css={[
                  tw`h-56 w-full ease-out duration-300 opacity-100 object-cover`,
                  tw`md:(h-72)`,
                  tw`group-hover:(scale-110 ease-in-out duration-300)`,
                  tw`group-focus-visible:(scale-110 ease-in-out duration-300)`,
                ]}
              />
              <motion.div
                initial={{
                  width: "100%",
                }}
                whileInView={{
                  width: 0,
                }}
                viewport={{ once: true, amount: 0.45 }}
                transition={{ duration: 0.25 }}
                css={[tw`absolute left-0 h-full z-20 bg-white`]}
              />
              <motion.div
                initial={{
                  width: "100%",
                }}
                whileInView={{
                  width: 0,
                }}
                viewport={{ once: true, amount: 0.45 }}
                transition={{ delay: 0.5, duration: 0.25 }}
                css={[tw`absolute left-0 h-full z-10 bg-black`]}
              />
            </>
          )}
          <div
            css={[
              tw`z-10 absolute top-0 left-0 right-0 h-full w-full bg-gradient-to-t from-gray-900`,
            ]}
          ></div>
          {block?.heading && (
            <div
              css={[
                tw`z-20 absolute left-5 text-lg tracking-widest font-light bottom-5 text-white md:(text-2xl)`,
                tw`lg:(left-8 bottom-10)`,
              ]}
            >
              {block?.heading}
            </div>
          )}
        </div>
      </Link>
    </div>
  )
}

export default ImageBlock
