import React, { useContext, useEffect, useRef, useState } from "react"
import tw from "twin.macro"
import { SeriesSelectionProps } from "./SeriesSelection.d"
import Icon from "../../../atoms/Icon"
import { TabObject } from "../../../molecules/FamilySeriesTabs/FamilySeriesTabs.d"
import { CTA, Family, Model, Route } from "../../../../global"
import { Link } from "../../../atoms/Link"
import { Image } from "../../../atoms/Image"
import {
  getSeriesMpgDisclaimerCode,
  parseDisclaimerBlocks,
  toUsd,
} from "../../../../helpers"
import { css } from "@emotion/react"
import { NewCarousel } from "../../../NewCarousel"
import { motion } from "framer-motion"
import { wrap } from "popmotion"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import { LanguageContext } from "../../../../contexts/Language"
import { ThreeArrowAnimation } from "../../../molecules/ThreeArrowAnimation"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"
import { useElectricPluginSeriesNames } from "../../../../hooks/useElectricPluginSeriesNames"
import { ButtonLink } from "../../../atoms/Button"
import FamilySeries from "../../Inventory/FamilySeries"

/**
 * Carousel of series families on the homepage
 * @author Tyler
 * @returns <section></section>
 *
 * @todo Migrate to new carousel component when it's made
 * @todo Suss out the images causing document to shift
 */

const SeriesSelection = ({
  heading,
  familyRef,
  sectionTitle,
  sectionSlug,
  subheading,
  crownText,
  margins,
  tealIncludeLabel,
  analyticsIdArrows,
  fromHybridSegment,
  ...remainingProps
}: SeriesSelectionProps): JSX.Element => {
  const [_state, dispatch] = useContext(DisclaimersContext)
  const { language, _ } = useContext(LanguageContext)

  // Pull only the families that are activated
  const activeFamilies = familyRef?.filter((family: Family) => family?.isActive)

  // New data structure with only the data we need arranged how we need it
  const data = activeFamilies?.map((family: Family) => {
    return {
      family: family,
      data: family?.routes
        ?.filter((node: Route) => node?.route?.page?.vehicle?.models)
        ?.map((node: Route) => {
          let seriesData = null

          node.route.page.vehicle.models.map((model: Model) => {
            //TODO dont make this a map so we can break once the featured is found
            if (model._type === "vehicleModelGroup") {
              model.models.map((models: Model) => {
                if (models.featuredModel) {
                  if (seriesData === null) {
                    //if we already found the featured model, don't overwrite it
                    seriesData = models
                  }
                }
              })
            } else {
              if (seriesData === null) {
                //if we already found the featured model, don't overwrite it
                seriesData = node?.route?.page?.vehicle?.models?.find(
                  (model: Model) => model?.featuredModel === true
                )
              }
            }
          })

          // If there is no featured model, use the first model in the list
          // this is a failsafe that if for some reason the featured information was misconfigured we will still get something
          if (seriesData === null || seriesData === undefined) {
            for (let i = 0; i < node.route.page.vehicle.models.length; i++) {
              if (
                node.route.page.vehicle.models[i]._type === "vehicleModelGroup"
              ) {
                seriesData = node.route.page.vehicle.models[i].models[i]
                break
              } else {
                seriesData = node.route.page.vehicle.models[i]
                break
              }
            }
          }

          return {
            slug: `/${node?.route?.slug?.current}/`,
            series: seriesData,
            description:
              language === "es" ? node?.descriptionES : node?.description,
            seriesImage: node?.seriesImage?.image,
            ctas: language === "es" ? node?.ctasES : node?.ctas,
          }
        }),
    }
  })

  const families = data.map((node: TabObject) => node.family)

  const [carouselData, setCarouselData] = useState(0)

  const tabAnimation = css`
    &:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
      content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:hover:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const focusAnimation = css`
    &:focus-visible:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
      content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:focus-visible:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const active = css`
    ${tw`font-semibold focus-visible:(border-transparent)`}
    &:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
    &:hover:after {
      ${tw`h-1 bg-red-400`}
    }
    &:focus-visible:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
  `

  const newCarouselData = data[carouselData]?.data.map((node: any) => {
    const seriesData = node?.series?.model?.series

    return {
      seriesData: seriesData,
      seriesYear: node?.series?.model?.year,
      seriesName: seriesData?.name.split(" "),
      msrp: seriesData?.msrp,
      mpgCity: seriesData?.mpgCity,
      mpgHighway: seriesData?.mpgHighway,
      description: node?.description,
      asShown: node?.series?.model?.costs?.baseMSRP,
      slug: node?.slug,
      image: node?.seriesImage,
      ctas: node?.ctas,
      range: seriesData?.range,
    }
  })

  const [activeSlide, setActiveSlide] = useState(0)

  const slideIndex = wrap(0, newCarouselData.length, activeSlide)

  const seriesSubheadingHoverAnimation = css`
    @media (min-width: 1024px) {
      &:hover > .darkRed {
        ${tw`opacity-100 duration-300 translate-x-11`}
      }
      &:hover > .lighterRed {
        ${tw`opacity-100 delay-200 translate-x-8`}
      }
      &:hover > .lightestRed {
        ${tw`opacity-100 delay-100 translate-x-5`}
      }
    }
  `
  const seriesSubheadingFocusAnimation = css`
    @media (min-width: 1024px) {
      &:focus-visible > .darkRed {
        ${tw`opacity-100 duration-300 translate-x-11`}
      }
      &:focus-visible > .lighterRed {
        ${tw`opacity-100 delay-200 translate-x-8`}
      }
      &:focus-visible > .lightestRed {
        ${tw`opacity-100 delay-100 translate-x-5`}
      }
    }
  `

  const {
    seriesData,
    seriesYear,
    seriesName,
    msrp,
    mpgCity,
    mpgHighway,
    description,
    slug,
    image,
    asShown,
    ctas,
    range,
  } = newCarouselData[slideIndex]

  const carouselRef = useRef<HTMLDivElement>(null)
  const pluginSeriesNames = useElectricPluginSeriesNames()
  const isElectricPluginSeries = pluginSeriesNames.includes(
    seriesName?.join(" ")?.toLowerCase()
  )

  const [carouselHeight, setCarouselHeight] = useState(0)
  const mpgDisclaimerCode = getSeriesMpgDisclaimerCode(
    seriesName?.toString(),
    seriesYear
  )

  useEffect(() => {
    setCarouselHeight(carouselRef.current?.clientHeight)
  }, [newCarouselData])

  useEffect(() => {
    setActiveSlide(newCarouselData.length + 1)
  }, [carouselData])

  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  const handleTealiumEvent = (section: string, action: string) => {
    trackTealEvent({
      tealium_event: "carousel_click",
      carousel_click: "carousel_click",
      carousel_action: `${
        tealIncludeLabel ? `${section}|` : "vehicle|"
      }${action}`,
    })
  }
  const [state, modalDispatch] = useContext(DisclaimersContext)
  return (
    <section
      css={[
        tw`w-full py-28`,
        `margin: ${margins?.top}px 0 ${margins?.bottom}px !important;`,
      ]}
      id={sectionSlug?.current}
      aria-label="Toyota Family of Vehicles Section"
      {...remainingProps}
    >
      {/* Section headings */}
      <div css={tw`max-w-2xl mx-auto mb-6 text-center px-4 md:px-0`}>
        {crownText && (
          <p
            css={[
              tw`text-red-400 font-semibold uppercase mb-4`,
              fromHybridSegment &&
                tw`text-black font-bold text-lg tracking-[0.4em]`,
            ]}
          >
            {crownText}
          </p>
        )}
        {heading && (
          <h2
            css={[
              tw`text-2xl font-light`,
              tw`lg:(text-4xl)`,
              !subheading && "letter-spacing: 8.72px",
              subheading && tw`text-4xl mb-8 font-book md:(text-5xl)`,
              fromHybridSegment && tw`text-3xl lg:text-5xl`,
            ]}
          >
            {parseDisclaimerBlocks(heading, selection =>
              modalDispatch(toggleDisclaimersModal(selection))
            )}
          </h2>
        )}
        {subheading && <p css={tw`px-4`}>{subheading}</p>}
      </div>

      {/* Family tabs */}
      <nav
        css={[
          tw`flex gap-6 overflow-x-scroll w-full px-4 mb-10 scrollbar-hide py-1.5`,
          tw`md:(justify-center)`,
        ]}
      >
        {families.map((family: any, index: number) => {
          let FamilyIcon = null
          FamilyIcon = family.icon
            ? Icon[family.icon as keyof typeof Icon]
            : null
          return (
            <button
              onClick={() => {
                setCarouselData(index),
                  trackTealEvent({
                    tealium_event: "refinement",
                    refinement_value: `${window?.teal?.page_type}|${
                      window?.teal?.page_name
                    }|Vehicle Group|${family.name.replace("&", " ")}`,
                  })
              }}
              analytics-id={`vehicle group filter:vehicle:${index + 1}`}
              aria-selected={carouselData === index}
              role="tab"
              css={[
                tabAnimation,
                focusAnimation,
                tw`cursor-pointer whitespace-nowrap inline-block relative py-2 mx-2`,
                carouselData === index && active,
              ]}
            >
              {fromHybridSegment && FamilyIcon && (
                <FamilyIcon
                  color={"black"}
                  css={[tw`h-6 mb-4 mx-auto md:(h-8)`]}
                />
              )}
              {_(family.name)}
            </button>
          )
        })}
      </nav>
      <div
        css={[
          tw`flex  my-6 max-w-xl mx-auto px-4 md:px-0`,
          tw`md:(justify-center)`,
        ]}
      >
        {fromHybridSegment &&
        language == "en" &&
        families[carouselData].descriptionEN ? (
          <p>{families[carouselData].descriptionEN}</p>
        ) : fromHybridSegment &&
          language == "es" &&
          families[carouselData].descriptionES ? (
          <p>{families[carouselData].descriptionES}</p>
        ) : null}
      </div>

      {/* Carousel for series image */}
      {newCarouselData?.length > 1 ? (
        <NewCarousel
          slides={newCarouselData}
          activeSlide={activeSlide}
          setActiveSlide={setActiveSlide}
          continuous
          centered
          linkUrl={slug}
          section={"vehicles"}
          tealIncludeLabel={tealIncludeLabel}
        />
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          css={[
            tw`w-full h-[150px] flex justify-center py-2 `,
            tw`lg:(h-[300px])`,
            tw`desktop-hd:(h-[450px])`,
          ]}
        >
          <Link to={newCarouselData[0]?.slug} draggable={false}>
            <Image
              draggable={false}
              imageData={newCarouselData[0]?.image}
              css={[tw`w-full h-full mx-auto object-contain`]}
            />
          </Link>
        </motion.div>
      )}

      {/* Series specs */}
      <article
        css={[
          tw`relative flex flex-col justify-center gap-4 max-w-[540px] h-full w-full px-8 mx-auto`,
          tw`lg:(h-[270px] px-0)`,
        ]}
      >
        <motion.section
          css={[tw`flex flex-col items-center`, tw`lg:(flex-row items-start)`]}
          key={`${description}-specs`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <Link
            to={slug}
            css={[
              tw`relative p-2 pr-10`,
              seriesSubheadingHoverAnimation,
              seriesSubheadingFocusAnimation,
            ]}
          >
            {language === "en"
              ? seriesYear && (
                  <span css={[tw`block text-3xl font-light tracking-widest`]}>
                    {seriesYear}
                  </span>
                )
              : seriesName?.map((node: any) => (
                  <span
                    css={[
                      tw`block text-3xl leading-9 font-semibold uppercase tracking-widest min-w-[120px]`,
                      seriesName == "bZ4X" && tw`normal-case`,
                    ]}
                  >
                    {node}
                  </span>
                ))}
            {language === "es"
              ? seriesYear && (
                  <span css={[tw`block text-3xl font-light tracking-widest`]}>
                    {seriesYear}
                  </span>
                )
              : seriesName?.map((node: any) => (
                  <span
                    css={[
                      tw`block text-3xl leading-9 uppercase font-semibold tracking-widest`,
                      seriesName == "bZ4X" && tw`normal-case`,
                    ]}
                  >
                    {node}
                  </span>
                ))}

            <Icon.Chevron
              direction="right"
              color="red-200"
              className="lightestRed"
              css={[
                tw`absolute bottom-2.5 h-5 translate-x-5 -translate-y-0.5 opacity-0`,
                language === `en` ? tw`right-10` : tw`left-20`,
              ]}
            />
            <Icon.Chevron
              direction="right"
              color="red-300"
              className="lighterRed"
              css={[
                tw`absolute bottom-2.5 right-10 h-5 translate-x-5 -translate-y-0.5 opacity-0`,
                language === `en` ? tw`right-10` : tw`left-20`,
              ]}
            />
            <Icon.Chevron
              direction="right"
              color="red-400"
              className="darkRed"
              css={[
                tw`absolute bottom-2.5 right-10 h-5 translate-x-5 -translate-y-0.5`,
                language === `en` ? tw`right-10` : tw`left-20`,
              ]}
            />
          </Link>

          <div
            css={[
              tw`flex justify-center text-center gap-7 w-full`,
              tw`lg:(justify-end)`,
            ]}
          >
            {msrp && (
              <span>
                <div css={[tw`text-sm tracking-[2.79px] font-light`]}>
                  {_("Starting MSRP")}
                </div>
                <div css={[tw`text-3xl whitespace-nowrap`]}>
                  <span>$</span>
                  {parseDisclaimerBlocks(
                    `${toUsd(msrp)} [starting_msrp]`,
                    code => dispatch(toggleDisclaimersModal(code))
                  )}
                </div>
                <span css={[tw`text-sm whitespace-nowrap font-light italic`]}>
                  {_("as shown")}{" "}
                  <span>
                    <span>$</span>
                    {parseDisclaimerBlocks(
                      `${toUsd(asShown)} [starting_msrp]`,
                      code => dispatch(toggleDisclaimersModal(code))
                    )}
                  </span>
                </span>
              </span>
            )}
            {/* Showing est mi range if electric else showing mpg or mpge */}
            {!range ? (
              <>
                {/* mpg or mpge for gas or plugin series */}
                {mpgCity && mpgHighway && (
                  <span>
                    <div css={[tw`text-sm tracking-[2.79px] font-light`]}>
                      {_("Up to")}
                    </div>
                    <div css={[tw`text-3xl`]}>
                      <span>{mpgCity}</span>/<span>{mpgHighway}</span>
                    </div>
                    <div
                      css={[
                        tw`text-sm tracking-[2.79px] font-light italic whitespace-nowrap`,
                      ]}
                    >
                      {isElectricPluginSeries
                        ? _("est. mpg/mpge")
                        : _("est. mpg")}
                      <span
                        css={tw`whitespace-normal	tracking-normal not-italic`}
                      >
                        {parseDisclaimerBlocks(mpgDisclaimerCode, code =>
                          dispatch(toggleDisclaimersModal(code))
                        )}
                      </span>
                    </div>
                  </span>
                )}
              </>
            ) : (
              <span>
                {/* est. range for all electric series */}
                <div css={[tw`text-sm tracking-[2.79px] font-light`]}>
                  {_("Up to")}
                </div>
                <div css={[tw`text-3xl`]}>
                  <span>{range} mi</span>
                </div>
                <div
                  css={[
                    tw`text-sm tracking-[2.79px] font-light italic whitespace-nowrap`,
                  ]}
                >
                  {_("est. range")}
                  <span css={tw`whitespace-normal	tracking-normal not-italic`}>
                    {parseDisclaimerBlocks(mpgDisclaimerCode, code =>
                      dispatch(toggleDisclaimersModal(code))
                    )}
                  </span>
                </div>
              </span>
            )}
          </div>
        </motion.section>
        <motion.p
          css={[
            `@media (min-width: 1024px) {
              display: -webkit-box;
              -webkit-line-clamp: 6;
              -webkit-box-orient: vertical;
            }`,
          ]}
          key={`${description}-description`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          {description
            ? parseDisclaimerBlocks(description, code =>
                dispatch(toggleDisclaimersModal(code))
              )
            : ""}
        </motion.p>
        {newCarouselData?.length > 1 && (
          <>
            <button
              onClick={() => {
                setActiveSlide(activeSlide - 1),
                  handleTealiumEvent("vehicle", "left arrow")
              }}
              css={[
                tw`absolute left-4 top-[30px] z-50`,
                tw`lg:(-left-20 top-1/2 -translate-y-1/2)`,
                fromHybridSegment && tw`lg:(top-20 translate-y-0)`,
              ]}
              analytics-id={
                analyticsIdArrows ? `left arrow:${analyticsIdArrows}` : null
              }
            >
              <Icon.Chevron
                direction="left"
                color="gray-900"
                css={[tw`h-12`]}
              />
            </button>
            <button
              onClick={() => {
                setActiveSlide(activeSlide + 1),
                  handleTealiumEvent("vehicle", "right arrow")
              }}
              css={[
                tw`absolute right-4 top-[30px] z-50`,
                tw`lg:(-right-20 top-1/2 -translate-y-1/2)`,
                fromHybridSegment && tw`lg:(top-20 translate-y-0)`,
              ]}
              analytics-id={
                analyticsIdArrows ? `right arrow:${analyticsIdArrows}` : null
              }
            >
              <Icon.Chevron
                direction="right"
                color="gray-900"
                css={[tw`h-12`]}
              />
            </button>
          </>
        )}

        {ctas && (
          <div
            css={[
              tw`mt-8`,
              tw`md:(pt-8)`,
              tw`lg:(absolute -bottom-8)`,
              language === "es" && tw`lg:(-bottom-12)`,
              fromHybridSegment &&
                tw`mx-auto mt-4 lg:(relative pt-0 mt-0 bottom-0)`,
            ]}
          >
            {ctas?.map((cta: CTA) => {
              return (
                <ButtonLink
                  {...{ [cta?.buttonType]: true }}
                  to={
                    cta?.externalUrl || `/${cta?.internalLink?.slug?.current}`
                  }
                  css={[
                    tw`w-auto col-span-1 text-xs px-6 whitespace-nowrap`,
                    tw`md:w-auto mr-4`,
                  ]}
                  key={cta?.title}
                  onClick={() => {
                    trackTealEvent({
                      tealium_event:
                        cta?.title == "View Inventory"
                          ? "view_inventory_click"
                          : "cta_click",
                      link_href:
                        cta?.externalUrl ||
                        `/${cta?.internalLink?.slug?.current}`,
                      coupon_module_text: cta?.title,
                    })
                  }}
                  analytics-id={`${
                    cta?.title == "View Inventory"
                      ? "inventory"
                      : cta?.title.toLowerCase()
                  }:vehicle:${slideIndex + 1}`}
                >
                  {cta?.title}
                </ButtonLink>
              )
            })}
          </div>
        )}

        {families[carouselData].linkRoute && (
          <div
            css={[
              tw`flex font-book text-gray-700 mx-3 relative justify-center items-center pt-6`,
            ]}
          >
            <Link
              to={"/" + families[carouselData]?.linkRoute?.slug.current}
              className={"group"}
              css={tw`pt-[1px]`}
              target="_self"
            >
              {language === "es"
                ? families[carouselData].linkTextES
                : families[carouselData].linkText}
              <ThreeArrowAnimation />
            </Link>
          </div>
        )}
      </article>
    </section>
  )
}

export default SeriesSelection
