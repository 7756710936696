import React from "react"
import tw from "twin.macro"
import {
  Carousel,
  CarouselItem,
  CarouselNavigation,
} from "../../atoms/Carousel"
import { Image } from "../../atoms/Image"
import { GalleryCarouselProps } from "./GalleryCarousel.d"
import { useFirstView } from "../../../hooks/useFirstView"
import { parseDisclaimerBlocks } from "../../../helpers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { useContext } from "react"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import Icon from "../../atoms/Icon"
import { Button } from "../../atoms/Button"
import { useEffect, useRef, useState, KeyboardEvent } from "react"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"
import { updateSlideNumber } from "../../../helpers"
import { LanguageContext } from "../../../contexts/Language"
import { css } from "@emotion/react"
import { motion } from "framer-motion"

const GalleryCarousel: React.FC<GalleryCarouselProps> = ({
  slides,
  series,
  fromHybridSegment,
}) => {
  const [_state, dispatch] = useContext(DisclaimersContext)
  const { ref, inView } = useFirstView()
  const [modalOpen, setModalOpen] = useState(false)
  const [showExpand, setShowExpand] = useState(false)
  const [thumbnailOpacity, setThumbnailOpacity] = useState(false)
  const [expandedImage, setExpandedImage] = useState(null)
  const [expandedImageIndex, setExpandedImageIndex] = useState(null)
  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { _, language } = useContext(LanguageContext)
  const { vehicleTealData, tealPageData } = useTealiumContext()

  function handleClose() {
    setModalOpen(!modalOpen)
  }

  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [modalOpen])
  const modalRef = useRef<HTMLDivElement>(null)
  const focusableElements =
    'a[href], button, Button, [tabIndex]:not([tabindex="-2"])'
  let firstFocusElement: HTMLElement
  let lastFocusElement: HTMLElement

  const handleKeyDown: any = (
    e: KeyboardEvent<HTMLElement>,
    firstFocusElement: HTMLElement,
    lastFocusElement: HTMLElement
  ) => {
    if (e.key === "Tab" && document.activeElement === lastFocusElement) {
      firstFocusElement.focus()
      e.preventDefault()
    }
    if (
      e.shiftKey &&
      e.key === "Tab" &&
      document.activeElement === firstFocusElement
    ) {
      lastFocusElement.focus()
      e.preventDefault()
    }
  }

  useEffect(() => {
    // Trap focus within modal when modal is open
    if (modalOpen && modalRef.current) {
      const modalFocusElements =
        modalRef.current.querySelectorAll(focusableElements)
      firstFocusElement = modalFocusElements[0] as HTMLElement
      lastFocusElement = modalFocusElements[
        modalFocusElements.length - 1
      ] as HTMLElement
      modalRef.current.addEventListener("keydown", e =>
        handleKeyDown(e, firstFocusElement, lastFocusElement)
      )
    }

    // // Focus within modal when opened. TabIndex must be set to -1
    // // https://dev.to/westbrookc16/managing-focus-in-react-3n13
    if (typeof window !== "undefined" && modalOpen) {
      modalRef.current.focus()
    }

    return () =>
      modalRef.current?.removeEventListener("keydown", e =>
        handleKeyDown(e, firstFocusElement, lastFocusElement)
      )
  }, [modalOpen, modalRef])

  const variants = {
    enter: {
      opacity: 0,
    },
    center: {
      x: 0,
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  }
  /* START - LOGIC FOR TEALIUM */

  // Reusable fuction for multiple, similar calls
  const handleTealiumEvent = (
    eventName: string,
    action: string,
    imageDetails = ""
  ) => {
    // Conditionally include certain, optional properties.
    // Avoids empty string value in Tealium for values not provided in function call.
    trackTealEvent({
      ...(eventName && { tealium_event: eventName }),
      ...(action && { carousel_action: action }),
      ...(imageDetails && { gallery_image_details: imageDetails }),
      ...(vehicleTealData?.vehicle_model && {
        vehicle_model: vehicleTealData.vehicle_model,
      }),
      ...(vehicleTealData?.vehicle_model && {
        vehicle_model_truncated: vehicleTealData.vehicle_model,
      }),
    })
  }

  /* END - LOGIC FOR TEALIUM */

  const checkActiveSlide = (num: number) => {
    let adjustedNum = num
    if (num < 0) {
      adjustedNum = slides.length - 1
    }
    if (num > slides.length - 1) {
      adjustedNum = 0
    }
    return adjustedNum
  }

  return (
    <>
      <Carousel slides={slides}>
        {({ slides, changeSlide, activeSlide }) => (
          <div ref={ref} css={[tw`px-0 md:(px-10)`]}>
            {/* slides */}
            <div css={[tw`max-w-7xl mx-auto relative`]}>
              {slides.map(({ image, alt, captionHeading, captionBody }, i) => (
                <>
                  <div
                    css={[
                      i === activeSlide &&
                        tw`h-[300px] md:h-[480px] lg:h-[580px]`,
                    ]}
                  >
                    <CarouselItem active={i === activeSlide}>
                      <div css={[tw`h-[220px] md:h-[400px] lg:h-[500px]`]}>
                        <Image
                          imageData={image}
                          css={[
                            tw`max-w-5xl h-full w-full mb-6 px-8 flex items-center z-40 object-cover`,
                            tw`lg:(mx-auto mb-6 px-0)`,
                          ]}
                          onMouseEnter={() => setShowExpand(true)}
                          onMouseLeave={() => setShowExpand(false)}
                        />
                        <Button
                          onClick={() => {
                            setModalOpen(!modalOpen)
                            setExpandedImage(slides[activeSlide])
                            setExpandedImageIndex(activeSlide)
                            const imageDescr = !alt ? null : alt
                            handleTealiumEvent(
                              "vdp_zoom",
                              null,
                              `${vehicleTealData?.vehicle_model}|${
                                vehicleTealData?.vehicle_year
                              }|${captionHeading || "NOT SET"}|Gallery|${i + 1}`
                            )
                          }}
                          analytics-id="expand:gallery:gallery"
                          css={[
                            "z-index: 49;",
                            tw`absolute top-4 right-12 text-red-400 bg-gray-100 rounded-full shadow-2 p-2 px-2! opacity-100 hover:md:(opacity-100) focus:md:(opacity-100)`,
                            tw`focus-visible:(border-white outline-style[dashed] outline-offset[2px] outline-color[white] p-2 opacity-100)`,
                            tw`lg:(opacity-0 right-36 hover:opacity-100)`,
                            showExpand &&
                              tw`lg:(opacity-100 duration-150 transition-all)`,
                          ]}
                          disabled={i !== activeSlide}
                        >
                          <Icon.Expand color={"black"} css={[tw`h-6 w-6`]} />
                        </Button>
                        <div
                          css={[tw`w-full z-20 flex justify-center items-end`]}
                        >
                          <CarouselNavigation
                            direction="prev"
                            aria-label="previous"
                            css={[tw`mr-4 md:(mr-4)`]}
                            onClick={() => {
                              changeSlide(activeSlide - 1)
                              const adjustedSlide = checkActiveSlide(
                                activeSlide - 1
                              )
                              handleTealiumEvent(
                                "carousel_click",
                                "left arrow",
                                `${vehicleTealData?.vehicle_model}|${
                                  vehicleTealData?.vehicle_year
                                }|${
                                  slides[adjustedSlide]?.captionHeading
                                }|Gallery|${adjustedSlide + 1}`
                              )
                            }}
                            color="black"
                            analytics-id={`left arrow:gallery:gallery`}
                            disabled={i !== activeSlide}
                          />
                          <CarouselNavigation
                            direction="next"
                            aria-label="next"
                            css={[tw`ml-4 md:(ml-4)`]}
                            onClick={() => {
                              changeSlide(activeSlide + 1)
                              const adjustedSlide = checkActiveSlide(
                                activeSlide + 1
                              )
                              handleTealiumEvent(
                                "carousel_click",
                                "right arrow",
                                `${vehicleTealData?.vehicle_model}|${
                                  vehicleTealData?.vehicle_year
                                }|${
                                  slides[adjustedSlide]?.captionHeading
                                }|Gallery|${adjustedSlide + 1}`
                              )
                            }}
                            color="black"
                            analytics-id={`right arrow:gallery:gallery`}
                            disabled={i !== activeSlide}
                          />
                        </div>
                      </div>
                    </CarouselItem>
                  </div>
                  <div
                    css={[
                      tw`hidden`,
                      i === activeSlide &&
                        tw`pb-8 pt-4 px-8 flex relative z-50 md:(flex space-x-6 space-y-3 w-full px-0)`,
                    ]}
                  >
                    <div
                      css={[
                        i === activeSlide &&
                          tw`hidden text-white text-5xl font-light w-52 tracking-widest pt-6 xl:(block)`,
                        fromHybridSegment && tw`xl:(pt-0)`,
                      ]}
                    >
                      {_("Gallery")}
                    </div>

                    {/* Carousel Item Description */}
                    <div
                      css={[
                        tw`w-full invisible opacity-0 hidden`,
                        i === activeSlide &&
                          tw`transition-opacity block opacity-100 visible ease-in-out duration-500 mb-6`,
                      ]}
                    >
                      {captionHeading && (
                        <span
                          css={[
                            tw`block uppercase mb-3 font-semibold text-base text-white md:(text-lg mb-0 tracking-widest)`,
                            fromHybridSegment && tw`md:(pt-2)`,
                          ]}
                        >
                          {parseDisclaimerBlocks(captionHeading, code =>
                            dispatch(toggleDisclaimersModal(code))
                          )}
                        </span>
                      )}
                      {captionBody && (
                        <span
                          css={[
                            tw`block text-white tracking-wide`,
                            language === "en"
                              ? tw`text-xs leading-5 md:(text-sm leading-6) lg:(text-base)`
                              : tw`text-xs leading-5 md:(text-xs leading-5) lg:(text-base)`,
                          ]}
                        >
                          {parseDisclaimerBlocks(captionBody, code =>
                            dispatch(toggleDisclaimersModal(code))
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                </>
              ))}
            </div>

            {/* Thumbnails */}

            {inView && (
              <div
                css={[
                  tw`overflow-visible`,
                  fromHybridSegment && tw`pt-16`,
                  fromHybridSegment && tw`sm:(pt-20)`,
                  fromHybridSegment && tw`2xl:(pt-20)`,
                ]}
              >
                <section
                  css={[
                    tw`grid grid-flow-col grid-rows-2 gap-3 h-36 overflow-x-scroll w-full`,
                    tw`lg:(overflow-x-hidden w-full h-60 gap-4 p-9)`,
                    slides.length < 4
                      ? tw`lg:(w-[500px] mx-auto)`
                      : slides.length < 6
                      ? tw`lg:(w-[900px] mx-auto)`
                      : tw`lg:(w-full)`,
                  ]}
                  onMouseOver={() => setThumbnailOpacity(true)}
                  onMouseLeave={() => setThumbnailOpacity(false)}
                >
                  {slides.map(({ image, alt, captionHeading }, i) => (
                    <button
                      onClick={() => {
                        const imageDescr = !alt ? null : alt
                        handleTealiumEvent(
                          "gallery_click",
                          null,
                          `${vehicleTealData?.vehicle_model}|${
                            vehicleTealData?.vehicle_year
                          }|${captionHeading || "NOT SET"}|Gallery|${i + 1}`
                        )
                        changeSlide(i)
                      }}
                      className="group"
                      css={[
                        tw`w-24 opacity-100 outline-none overflow-hidden border border-transparent`,
                        tw`md:(w-auto)`,
                        thumbnailOpacity &&
                          tw`opacity-30 duration-150 ease-out focus-visible:(opacity-100)`,
                        i % 3 === 0 && tw`col-span-2 row-span-2 w-56`,
                        i === activeSlide &&
                          tw`opacity-100 transition content-center border border-gray-400`,
                        tw`transform duration-150 ease-out hover:(opacity-100) active:(border border-gray-400 opacity-100)`,
                        tw`focus:(border border-gray-400)`,
                        tw`focus-visible:(border-white border-dashed border outline-none rounded-none)`,
                      ]}
                      analytics-id={`thumbnail:gallery:${i + 1}`}
                    >
                      {image && (
                        <Image
                          imageData={image}
                          css={[
                            tw`h-full w-full opacity-100 ease-out duration-300 object-cover`,
                            tw`hover:(scale-110 ease-in-out duration-300)`,
                            tw`group-focus-visible:(scale-110 ease-in-out duration-300)`,
                          ]}
                          draggable={false}
                        />
                      )}
                    </button>
                  ))}
                </section>
              </div>
            )}
          </div>
        )}
      </Carousel>

      {modalOpen && (
        <div
          tabIndex={-1}
          ref={modalRef}
          css={[
            tw`fixed top-0 bottom-0 left-0 right-0 bg-[#000] w-full h-full text-white outline-none `,
            "z-index: 99999",
          ]}
        >
          <div css={[tw`p-12 h-full overflow-auto`]}>
            <button
              onClick={() => {
                handleClose()
              }}
              css={[
                tw`flex items-center text-xs text-white mb-4 border border-transparent p-1 uppercase`,
                tw`focus-visible:(border-white border-dashed border outline-none rounded-none p-1)`,
              ]}
            >
              <Icon.Chevron
                color="white"
                direction="left"
                css={[tw`max-h-4 mr-2 w-4`]}
              />
              {_("BACK")}
            </button>
            <div
              css={[tw`h-auto w-full relative`, tw`lg:(h-4/5) overflow-hidden`]}
            >
              {" "}
              <motion.div
                key={expandedImageIndex}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  opacity: { duration: 0.5 },
                }}
              >
                <Image
                  imageData={expandedImage.image}
                  css={[
                    tw`w-full flex items-center object-cover`,
                    tw`lg:(max-w-7xl h-full mx-auto)`,
                  ]}
                />
              </motion.div>
              <button
                onClick={() => {
                  if (expandedImageIndex - 1 > 0) {
                    setExpandedImage(slides[expandedImageIndex - 1])
                    setExpandedImageIndex(expandedImageIndex - 1)
                  } else {
                    setExpandedImage(slides[slides.length - 1])
                    setExpandedImageIndex(slides.length - 1)
                  }
                  handleTealiumEvent(
                    "carousel_click",
                    "left arrow",
                    `${vehicleTealData?.vehicle_model}|${
                      vehicleTealData?.vehicle_year
                    }|${expandedImage.captionHeading || "NOT SET"}|Gallery|${
                      expandedImageIndex + 1
                    }`
                  )
                }}
                css={[
                  tw`absolute h-16 w-6 top-1/3 left-3`,
                  tw`lg:(w-12 top-1/2 left-8 cursor-pointer border border-transparent p-2)`,
                  tw`lg:focus-visible:(border-white border-dashed border outline-none rounded-none p-2)`,
                ]}
                analytics-id={`left arrow:gallery:full screen`}
              >
                <Icon.Chevron
                  color="white"
                  direction="left"
                  css={[tw`max-h-12`]}
                />
              </button>
              <button
                onClick={() => {
                  if (expandedImageIndex + 1 < slides.length) {
                    setExpandedImage(slides[expandedImageIndex + 1])
                    setExpandedImageIndex(expandedImageIndex + 1)
                  } else {
                    setExpandedImage(slides[0])
                    setExpandedImageIndex(0)
                  }
                  handleTealiumEvent(
                    "carousel_click",
                    "right arrow",
                    `${vehicleTealData?.vehicle_model}|${
                      vehicleTealData?.vehicle_year
                    }|${expandedImage.captionHeading || "NOT SET"}|Gallery|${
                      expandedImageIndex + 1
                    }`
                  )
                }}
                css={[
                  tw`absolute h-16 w-6 top-1/3 right-3`,
                  tw`lg:(w-12 top-1/2 right-8 cursor-pointer border border-transparent p-2)`,
                  tw`lg:focus-visible:(border-white border-dashed border outline-none rounded-none p-2)`,
                ]}
                analytics-id={`right arrow:gallery:full screen`}
              >
                <Icon.Chevron
                  color="white"
                  direction="right"
                  css={[tw`max-h-12`]}
                />
              </button>
            </div>
            <div css={[tw`text-white max-w-desktop mx-auto pt-4`]}>
              <motion.div
                key={expandedImageIndex}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  opacity: { duration: 0.5 },
                }}
              >
                {expandedImage.captionHeading && (
                  <h2
                    css={[
                      tw`text-base font-semibold uppercase tracking-widest`,
                    ]}
                  >
                    {parseDisclaimerBlocks(expandedImage.captionHeading, code =>
                      dispatch(toggleDisclaimersModal(code))
                    )}
                  </h2>
                )}
                {expandedImage.captionBody && (
                  <span
                    css={[
                      tw`text-base font-light tracking-widest`,
                      tw`md:(text-xl)`,
                    ]}
                  >
                    {parseDisclaimerBlocks(expandedImage.captionBody, code =>
                      dispatch(toggleDisclaimersModal(code))
                    )}
                  </span>
                )}
              </motion.div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default GalleryCarousel
