import React, { useContext } from "react"
import tw from "twin.macro"
import { css } from "@emotion/react"
import { useTealiumContext } from "../../../contexts/Tealium"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { Carousel, CarouselNavigation } from "../../atoms/Carousel"
import { Image } from "../../atoms/Image"
import { ModelImageViewerProps } from "./ModelImageViewer.d"
import { LanguageContext } from "../../../contexts/Language"

const ModelImageViewer: React.FC<ModelImageViewerProps> = ({
  images,
  disclaimer,
}) => {
  // Tealium event and Context data
  const { trackTealEvent } = useTealiumEvent()
  const { vehicleTealData } = useTealiumContext()
  const { _ } = useContext(LanguageContext)
  return (
    <div>
      <Carousel slides={images}>
        {({ activeSlide, changeSlide, slides }) => {
          return (
            <>
              <li
                css={[
                  tw`flex justify-center mb-12 h-[14rem] md:(h-[16rem] mb-8) lg:(h-80) xl:(max-h-[26rem] mb-16)`,
                ]}
              >
                {slides?.map((image, i) => {
                  const active = i === activeSlide

                  return (
                    <>
                      <Image
                        imageData={image}
                        alt={
                          vehicleTealData?.vehicle_model_trim +
                          " " +
                          vehicleTealData?.exterior_color
                        }
                        css={[
                          tw`absolute my-auto h-auto opacity-0 object-contain max-h-[370px]`,
                          active && tw`opacity-100`,
                        ]}
                      />
                    </>
                  )
                })}
              </li>
              {disclaimer && (
                <div
                  css={[
                    tw`absolute bottom-20 left-0 right-0 px-4`,
                    tw`lg:(bottom-14)`,
                  ]}
                >
                  <span
                    css={[
                      tw`text-xs block font-semibold tracking-widest text-gray-700 text-center`,
                    ]}
                  >
                    {_(`${disclaimer}`)}
                  </span>
                </div>
              )}
              <div css={[tw`flex justify-center items-center w-full mb-3`]}>
                <CarouselNavigation
                  direction="prev"
                  color="white"
                  onClick={() => {
                    changeSlide(activeSlide - 1)
                    trackTealEvent({
                      features_series_prop: `360|${vehicleTealData.exterior_color}|${vehicleTealData.vehicle_config}`,
                      vehicle_model: vehicleTealData.vehicle_model,
                    })
                  }}
                  css={[
                    tw`bg-black mr-1 hover:(bg-gray-600)`,
                    tw`focus-visible:(outline-color[#000])`,
                  ]}
                  analytics-id={`left arrow:model list:exterior 360`}
                />
                <CarouselNavigation
                  direction="next"
                  color="white"
                  onClick={() => {
                    changeSlide(activeSlide + 1)
                    trackTealEvent({
                      features_series_prop: `360|${vehicleTealData.exterior_color}|${vehicleTealData.vehicle_config}`,
                      vehicle_model: vehicleTealData.vehicle_model,
                    })
                  }}
                  css={[
                    tw`bg-black ml-1 hover:(bg-gray-600)`,
                    tw`focus-visible:(outline-color[#000])`,
                  ]}
                  analytics-id={`right arrow:model list:exterior 360`}
                />
              </div>
            </>
          )
        }}
      </Carousel>
    </div>
  )
}

export default ModelImageViewer
