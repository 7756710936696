import React from "react"
import { PhotographProps } from "./Photograph.d"
import tw from "twin.macro"
import { Image } from "../../atoms/Image"
import { ToyotaLogo } from "../../atoms/Logo"
import { motion, MotionConfig } from "framer-motion"

const Photograph: React.FC<PhotographProps> = ({
  src,
  alt,
  caption,
  logoImage = null,
  ...remainingProps
}) => {
  return (
    <article
      {...remainingProps}
      css={[
        tw`flex flex-col max-w-xs p-2 bg-white mx-auto mb-20 shadow-3 overflow-hidden`,
      ]}
    >
      <motion.div
        viewport={{ once: true }}
        initial={{
          opacity: 0,
        }}
        whileInView={{
          opacity: 1,
        }}
        transition={{ duration: 2.5, delay: 0.5 }}
      >
        <Image imageData={src} />
      </motion.div>
      <section css={[tw`flex justify-between items-center p-4`]}>
        {logoImage ? (
          <Image imageData={logoImage?.image} />
        ) : (
          <ToyotaLogo fill={"#000"} css={[tw`w-7`]} />
        )}
        <span css={[tw`text-xs text-gray-600`]}>{caption}</span>
      </section>
    </article>
  )
}

export default Photograph
