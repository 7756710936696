import React, { useContext, useEffect, useState } from "react"
import { FeatureBadgesProps } from "./FeatureBadges.d"
import tw from "twin.macro"
import { Image } from "../../../atoms/Image"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { parseDisclaimerBlocks } from "../../../../helpers"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"
import { useRef } from "react"

/**
 * @author Cody
 *
 * @param badges - the badges to display in the row
 */

const FeatureBadges: React.FC<FeatureBadgesProps> = ({
  badges,
  _key,
  sectionSlug,
  margins,
}) => {
  const controls = useAnimation()
  const [{}, dispatch] = useContext(DisclaimersContext)
  const featuredBadgeSection = useRef(null)
  const [parentContainerRect, setParentContainerRect] = useState(null)

  const badgeVariants = {
    visible: (i: number) => ({
      opacity: 1,
      transition: {
        delay: i * 0.275,
      },
    }),
    hidden: { opacity: 0 },
  }

  const getDisclaimerParentPosition = (): { left: number; right: number } => {
    if (window?.innerWidth < 1024) return { left: null, right: null }

    const parentContainerRect =
      featuredBadgeSection?.current?.getBoundingClientRect()
    return {
      left: parentContainerRect?.left + 32,
      right: parentContainerRect?.right - 32,
    }
  }

  useEffect(() => {
    const parentPosition = getDisclaimerParentPosition()
    setParentContainerRect(parentPosition)
  }, [])

  return (
    <section
      css={[
        tw`relative flex flex-wrap justify-evenly items-center w-auto mt-0 px-6 pb-[580px] mb-[-500px]`,
        tw`sm:(px-10)`,
        tw`md:(max-w-7xl)`,
        tw`lg:(px-10 flex gap-4 overflow-x-scroll scrollbar-hide)`,
        tw`xl:(mx-auto px-8 max-w-[1344px])`,
      ]}
      id={sectionSlug?.current}
      key={_key}
      aria-label="Featured Badges Section"
      ref={featuredBadgeSection}
    >
      {badges?.map((badge: any, i: number) => {
        let disclaimers = badge.title?.match(/\[(.*?)\]/g)
        disclaimers = disclaimers?.toString()?.replace(",", " ")
        return (
          <motion.div
            css={[
              tw`w-32 flex items-center flex-shrink-0 md:(my-4) scrollbar-hide`,
              tw`md:(w-64)`,
              tw`lg:(w-44)`,
              `margin: ${margins?.top}px 0 ${margins?.bottom}px !important;`,
            ]}
            custom={i}
            variants={badgeVariants}
            animate={controls}
            whileInView="visible"
            initial={{ opacity: 0 }}
            viewport={{ once: true }}
          >
            <Image
              imageData={badge?.badgeImage?.image}
              css={[tw`h-24 object-contain`]}
            />
            {disclaimers &&
              parseDisclaimerBlocks(
                disclaimers,
                selection => dispatch(toggleDisclaimersModal(selection)),
                parentContainerRect?.left,
                parentContainerRect?.right
              )}
          </motion.div>
        )
      })}
    </section>
  )
}
export default FeatureBadges
