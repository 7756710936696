import React, { useContext } from "react"
import { AccessoriesProps } from "./Accessories.d"
import { Image } from "../../../atoms/Image"
import tw from "twin.macro"
import { ButtonLink } from "../../../atoms/Button"
import { SeriesPageContext } from "../../../../templates/series"
import {
  normalizeSeriesNameForLink,
  parseDisclaimerBlocks,
} from "../../../../helpers"
import { motion } from "framer-motion"
import { LanguageContext } from "../../../../contexts/Language"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"

const Accessories: React.FC<AccessoriesProps> = ({
  heading,
  subheading,
  body,
  ctaAcc,
  backgroundImage,
  featuredImage,
  sectionSlug,
  _key,
  margins,
}) => {
  const { series } = useContext(SeriesPageContext)
  const { _, language } = useContext(LanguageContext)
  const [state, dispatch] = useContext(DisclaimersContext)

  return (
    <section
      css={[
        tw`grid grid-cols-1`,
        `margin: ${margins?.top}px 0 ${margins?.bottom}px !important;`,
      ]}
      key={_key}
      aria-label={heading}
    >
      {/* Background image */}
      {backgroundImage && (
        <Image
          imageData={backgroundImage?.image}
          css={[tw`col-start-1 row-start-1 z-0 object-cover h-full`]}
        />
      )}
      {/* Content */}
      <section
        css={[
          tw`relative col-start-1 row-start-1 grid grid-cols-1 gap-y-6 max-w-7xl w-full mx-auto px-6 pt-14 text-white z-50`,
          tw`lg:(grid-cols-2 pt-32)`,
        ]}
        id={sectionSlug?.current}
      >
        {/* Heading/subheading */}
        <div css={[tw`lg:(col-span-1 order-1)`]}>
          {subheading && (
            <div css={[tw`font-semibold mb-5`]}>
              {parseDisclaimerBlocks(subheading, selection =>
                dispatch(toggleDisclaimersModal(selection))
              )}
            </div>
          )}
          {heading && (
            <h2
              css={[
                tw`text-3xl font-light tracking-widest w-1/2`,
                tw`lg:(w-auto text-5xl max-w-md)`,
              ]}
            >
              {parseDisclaimerBlocks(heading, selection =>
                dispatch(toggleDisclaimersModal(selection))
              )}
            </h2>
          )}
        </div>

        {/* Body */}
        {body && (
          <div css={[tw`lg:(col-span-1 order-2 mt-12)`]}>
            {parseDisclaimerBlocks(body, selection =>
              dispatch(toggleDisclaimersModal(selection))
            )}
            <div>
              {ctaAcc ? (
                <ButtonLink
                  {...{ [ctaAcc?.buttonType]: true }}
                  to={
                    ctaAcc?.externalUrl ||
                    `/${ctaAcc?.internalLink?.slug?.current}`
                  }
                  css={[tw`mt-8`, tw`lg:w-auto`]}
                  aria-label={ctaAcc?.title}
                  key={ctaAcc?.title}
                >
                  {ctaAcc?.title}
                </ButtonLink>
              ) : (
                <ButtonLink
                  to={`/accessories/${normalizeSeriesNameForLink(series.name)}`}
                  secondaryLight
                  css={[tw`mt-8 text-white`]}
                >
                  {language === "en" ? (
                    <>
                      {_("View All")} {series.name} {_("Accessories")}
                    </>
                  ) : (
                    <>
                      {_("View All")} {_("Accessories")} {_("de")} {series.name}
                    </>
                  )}
                </ButtonLink>
              )}
            </div>
          </div>
        )}

        {/* Image */}
        {featuredImage && (
          <motion.div
            css={[tw`lg:(col-span-2 order-4 mx-auto w-full top-12)`]}
            initial={{ opacity: 0, scale: 0.85 }}
            whileInView={{
              opacity: 1,
              scale: 1,
            }}
            transition={{ duration: 0.25 }}
            viewport={{ once: true, amount: 0.85 }}
          >
            <Image
              imageData={featuredImage?.image}
              css={[tw`w-full max-h-96 object-contain lg:(h-[400px])`]}
            />
          </motion.div>
        )}
      </section>
    </section>
  )
}

export default Accessories
