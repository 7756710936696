import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { PixleeProps } from "./Pixlee.d"
import { PixleeClient } from "../../../clients/PixleeClient"
import { Image } from "../../atoms/Image"
import { Link } from "../../atoms/Link"
import { PixleeResponse, PixleePhoto } from "../../../clients/PixleeClient.d"
import Icon, { Instagram, Twitter } from "../../atoms/Icon"
import { ConditionalWrapper } from "../../atoms/ConditionalWrapper"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { parseDisclaimerBlocks } from "../../../helpers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import TikTok from "../../atoms/Icon/TikTok"

const Pixlee: React.FC<PixleeProps> = ({
  albumId,
  pixleeHeading,
  pixleeIntroText,
}) => {
  const [_state, dispatch] = useContext(DisclaimersContext)
  const [content, setContent] = useState<PixleePhoto[]>([])

  const icons = { instagram: Instagram, twitter: Twitter, tiktok: TikTok }

  // all pixlee filters: https://developers.pixlee.com/reference/consuming-content
  const filters = {
    content_type: ["image"],
    filter_by_language: ["english"],
  }

  useEffect(() => {
    const fetchContent = async () => {
      const res: PixleeResponse = await PixleeClient.getForAlbum(albumId)
      setContent(res?.data)
    }
    fetchContent()
  }, [])

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  return (
    <>
      {pixleeHeading && (
        <div css={[tw`text-center`]}>
          <h2
            css={[
              tw`mx-auto font-light text-2xl py-6`,
              tw`lg:(max-w-[350px])`,
              "letter-spacing: 7.26px;",
            ]}
          >
            {parseDisclaimerBlocks(pixleeHeading, code =>
              dispatch(toggleDisclaimersModal(code))
            )}
          </h2>
          <p css={[tw`mx-auto px-6 pb-6`, tw`lg:(max-w-[720px] px-0)`]}>
            {parseDisclaimerBlocks(pixleeIntroText, code =>
              dispatch(toggleDisclaimersModal(code))
            )}
          </p>
        </div>
      )}
      <section
        css={[tw`overflow-hidden bg-black scrollbar-hide`]}
        aria-label="Pixlee Section"
      >
        {/* <pre>{JSON.stringify(photos.data, null, 2)}</pre> */}
        <div
          css={[
            tw`flex flex-wrap justify-start w-full`,
            tw`md:(flex flex-nowrap justify-center overflow-x-auto scrollbar-hide)`,
          ]}
        >
          {content?.map((content, index) => {
            const SourceIcon: typeof Icon = icons[content.source]
            let link: string | null = null
            let linkText: string = ""

            /* Determine if image is static, a CTA, or a social/Instagram link */
            /* For internal links, remove part of URL before the local reference */
            if (content.action_link) {
              link = decodeURIComponent(content?.action_link)
                .replace("https://qa.web.exploretoyota.com", "")
                .replace("https://web.exploretoyota.com", "")
                .replace("https://explore.setbuyatoyota.com/", "")
                .replace("https://exploretoyota.com/", "")
                .replace(`${process.env.GATSBY_ROOT_URL}`, "")

              linkText = decodeURIComponent(content?.action_link_text)
            } else if (content.platform_link) {
              link = content.platform_link
              linkText = content.user_name
            }
            return (
              <ConditionalWrapper
                condition={!!link}
                wrapper={children => (
                  <Link
                    to={link}
                    target="_blank"
                    css={[tw`p-0 w-1/2 flex md:w-auto`]}
                    className="group"
                    onClick={() => {
                      trackTealEvent({
                        tealium_event: "pixlee_link_click",
                        link_href: `${link}`,
                      })
                    }}
                    analytics-id={`pixlee:footer:${index + 1}`}
                  >
                    {children}
                  </Link>
                )}
                falseWrapper={children => (
                  <div css={[tw`p-0 w-1/2 flex`]} className="group">
                    {children}
                  </div>
                )}
              >
                {content.content_type === "image" && (
                  <Image
                    src={content.pixlee_cdn_photos.square_medium_url}
                    alt={content.alt_text}
                    css={[
                      tw`h-auto min-w-[160px]`,
                      tw`md:(h-[180px] w-auto object-cover transform opacity-75 transition ease-in-out group-hover:(scale-110 opacity-100))`,
                    ]}
                  />
                )}
                {content.content_type === "video" && (
                  <video
                    src={content.platform_link}
                    css={[
                      tw`h-auto min-w-[160px] w-auto object-cover`,
                      tw`md:(h-[180px] w-auto object-cover transform opacity-75 transition ease-in-out group-hover:(scale-110 opacity-100))`,
                    ]}
                    poster={content.big_url}
                  ></video>
                )}
                <span
                  css={[
                    tw`p-2 flex items-center absolute bottom-0 left-0 text-white transition duration-300 ease-out opacity-0 group-hover:(opacity-100)`,
                  ]}
                >
                  {content.source === "instagram" ||
                  content.source === "tiktok" ? (
                    <SourceIcon color="white" css={[tw`w-3 mr-2`]} />
                  ) : null}
                  <div css={[tw`overflow-hidden`]}>
                    <div
                      css={[
                        tw`text-xs transform -translate-x-8 transition duration-300 group-hover:(translate-x-0) text-black font-bold`,
                      ]}
                    >
                      {linkText}
                    </div>
                  </div>
                </span>
              </ConditionalWrapper>
            )
          })}
        </div>
      </section>
    </>
  )
}

export default Pixlee
