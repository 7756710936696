import React from "react"
import { PartAndServiceBackground } from "../../molecules/PartAndServiceBackground"
import { PartAndServiceHeadline } from "../../molecules/PartAndServiceHeadline"
import { PartAndServiceHeroProps } from "./PartAndServiceHero.d"
import tw from "twin.macro"
import PartsAndServiceCta from "../../molecules/PartAndServiceStickyFooter/PartsAndServiceCta"

const PartAndServiceHero: React.FC<PartAndServiceHeroProps> = ({
  background,
  headline,
}) => {
  return (
    <>
      <PartAndServiceBackground {...background}>
        <div
          css={[
            tw`lg:(w-full max-w-desktop mt-14 mx-auto pl-8)`,
            background?.backgroundType === "fullWidthImage"
              ? "grid-area: 1 / 1 / 6 / 6;"
              : "grid-area: 1 / 1 / 3 / 6;",
          ]}
        >
          <PartAndServiceHeadline
            {...headline}
            css={
              background?.backgroundType === "fullWidthImage"
                ? tw`xl:(max-w-xl)`
                : ""
            }
          />
        </div>
      </PartAndServiceBackground>
      <PartsAndServiceCta {...headline} />
    </>
  )
}

export default PartAndServiceHero
