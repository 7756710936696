import React from "react"
import { PartAndServiceBackgroundProps } from "./PartAndServiceBackground.d"
import tw from "twin.macro"
import { Image } from "../../atoms/Image"
import { motion } from "framer-motion"

/**
 * Background image component that displays an optional vehicle image.
 *
 * @component
 * @param {string} backgroundType - determines the style of background returned. Current cms options are imageWithVehicle OR fullWidthImage.
 * @param {SanityImageSource} backgroundImage
 * @param {SanityImageSource} backgroundImageTablet
 * @param {SanityImageSource} backgroundImageMobile
 * @param {SanityImageSource} vehicleImage - optional vehicle image that will be displayed if backgroundType === imageWithVehicle.
 * @param {React.ReactNode} children
 *
 * @returns <div></div>
 */

const PartAndServiceBackground: React.FC<PartAndServiceBackgroundProps> = ({
  backgroundType,
  backgroundImage,
  backgroundImageTablet,
  backgroundImageMobile,
  vehicleImage,
  children,
  ...remainingProps
}) => {
  return (
    <section
      css={[
        tw`relative`,
        tw`lg:(grid grid-cols-5 grid-rows-5 min-h-[440px])`,
        backgroundType === "imageWithVehicle" &&
          tw`lg:(mb-0 max-h-[calc(100vh - 182px - 56px)])`,
        backgroundType === "fullWidthImage" && tw`lg:(min-h-[680px])`,
      ]}
      {...remainingProps}
    >
      {/* Desktop Background Image */}
      <div
        css={[
          tw`relative w-full h-full overflow-hidden`,
          "grid-area: 1 / 1 / 6 / 6;",
        ]}
      >
        <Image
          imageData={backgroundImage}
          css={[
            tw`hidden`,
            tw`lg:(block absolute bottom-0 w-screen h-full object-cover)`,
            tw`2xl:(h-[calc(100vh - 182px)])`,
            tw`desktop-hd:(h-[calc(100vh - 382px)])`,
            "z-index: -1;",
          ]}
        />
      </div>
      <div
        css={[
          tw`relative`,
          tw`lg:(flex flex-col-reverse content-end)`,
          backgroundType === "imageWithVehicle" && "grid-area: 3 / 1 / 6 / 6;",
        ]}
      >
        {/* Mobile Background Image */}
        <Image
          imageData={
            backgroundImageMobile ? backgroundImageMobile : backgroundImage
          }
          css={[
            tw`w-full h-full z-0 object-cover min-h-[275px]`,
            tw`sm:(hidden)`,
          ]}
        />
        {/* Tablet Background Image */}
        <Image
          imageData={
            backgroundImageTablet ? backgroundImageTablet : backgroundImage
          }
          css={[
            tw`hidden`,
            tw`sm:(flex w-full h-full z-0 object-cover min-h-[475px])`,
            tw`lg:(hidden)`,
          ]}
        />
        {/* Mobile Gradient */}
        <div
          css={[
            tw`h-28 w-full absolute bottom-0`,
            tw`lg:(hidden)`,
            "background: linear-gradient(180deg, rgba(30, 38, 46, 0.00) 0%, #1E262E 100%);",
          ]}
        />
        {/* Optional Vehicle Image */}
        {backgroundType === "imageWithVehicle" && vehicleImage && (
          <div
            css={[
              tw`absolute w-full bottom-0 left-1/2 transform -translate-x-1/2`,
              tw`lg:(relative mb-0)`,
            ]}
          >
            <motion.div
              animate={{ opacity: 1, x: 0, scale: 1 }}
              initial={{ opacity: 0, x: 30, scale: 0.95 }}
              transition={{ duration: 0.5, delay: 1 }}
            >
              <Image
                transparentPreview
                imageData={vehicleImage}
                css={[
                  tw`w-auto max-w-[330px] mx-auto h-auto`,
                  tw`sm:(max-w-lg)`,
                  tw`lg:(max-w-2xl)`,
                ]}
              />
            </motion.div>
          </div>
        )}
      </div>
      {/* Children */}
      {children}
    </section>
  )
}

export default PartAndServiceBackground
