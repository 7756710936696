import { useState, useEffect, useContext } from "react"
import tw from "twin.macro"
import { Radio } from "../../atoms/Radio"
import BackAndNextButtons from "./FormPage/BackAndNextButtons"
import { ViewProps } from "./ContactDealer.d"
import FormPage from "./FormPage"
import InputField from "./InputField"
import { ContactContext } from "../../../contexts/Contact"
import { setActiveView, updateField } from "../../../contexts/Contact/actions"
import { LanguageContext } from "../../../contexts/Language"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"
import { tealiumNavClick } from "./TealiumHelpers"

const MethodOfContactView: React.FC<ViewProps> = ({
  active,
  contactInputs,
  showFormHeading,
}) => {
  const [{ previousView, contactType, contact }, dispatch] =
    useContext(ContactContext)
  const { _ } = useContext(LanguageContext)

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { contactDealerData } = useTealiumContext()

  const [activeContactInput, setActiveContactInput] = useState(contactInputs[0])
  const [showVal, setShowVal] = useState(false)

  const handleNextClick = () => {
    const isValid = activeContactInput.regEx.test(contact?.toString())
    tealiumNavClick(
      "How should we contact you?",
      "Next",
      trackTealEvent,
      contactDealerData
    )

    if (isValid) {
      dispatch(setActiveView("OptionalCommentsView"))
    } else {
      setShowVal(true)
      const contactEl = document.getElementById("contact")
      contactEl.focus()
    }
  }

  useEffect(() => {
    setActiveContactInput(
      contactInputs.find(input => input.value === contactType)
    )
    setShowVal(false)
  }, [contactType])

  useEffect(() => {
    if (showVal) {
      setShowVal(false)
    }
  }, [contact])

  if (!active) return null

  return (
    <FormPage
      active={active}
      prevOnClick={() => {
        tealiumNavClick(
          "How should we contact you?",
          "Back",
          trackTealEvent,
          contactDealerData
        )
        previousView === "NameValidationView"
          ? dispatch(setActiveView("NameValidationView"))
          : dispatch(setActiveView("NameView"))
      }}
      nextOnClick={handleNextClick}
      heading={_("How should we contact you?")}
      showFormHeading={showFormHeading}
    >
      <section css={[tw`flex flex-wrap justify-center gap-5 mb-6 mt-3`]}>
        {contactInputs?.length > 1 &&
          active && // prevent setting defaultChecked before view is active
          contactInputs.map((node: any) => (
            <Radio
              label={node.label}
              name="group"
              value={node.value}
              onChange={e => {
                dispatch(updateField("contactType", e.target?.value))
                dispatch(updateField("contact", ""))
                tealiumNavClick(
                  "How should we contact you?",
                  e.target.value,
                  trackTealEvent,
                  contactDealerData
                )
              }}
              defaultChecked={contactType === node.value}
              analytics-id={`${node.label}:contact form:How should we contact you?`}
            />
          ))}
      </section>
      <InputField
        active={active}
        value={contact}
        type={activeContactInput.input}
        fieldName="contact"
        label={activeContactInput.label}
        validationMessage={activeContactInput.validationMessage}
        customOnChange={value => dispatch(updateField("contact", value))}
        showVal={showVal}
        css={[tw`max-w-[20rem]`]}
        heading="How should we contact you?"
      />
    </FormPage>
  )
}

export default MethodOfContactView
