import React, {
  Children,
  cloneElement,
  useRef,
  ReactNode,
  useState,
  useEffect,
} from "react"
import { ContentSliderProps } from "./ContentSlider.d"
import tw, { theme } from "twin.macro"
import { css } from "@emotion/react"
import Icon from "../../../atoms/Icon"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../../contexts/Tealium"

/**
 *  Interates over each child element and wraps a div with a ref
 *  Used to allow horizontal scrolling and onscreen scroll controls
 *
 * @param {ContentSliderProps} props React Props
 * @param {React.ReactChildren} props.children used has headline
 * @param {Object<any> | null} remainingProps
 * @return {JSX.Element}
 */

const ContentSlider: React.FC<ContentSliderProps> = ({
  children,
  sectionTitle,
  sectionSlug,
  margins,
  tealCarouselLabel,
  analyticsId,
  ...remainingProps
}) => {
  // make children iterable
  const items: ReactNode[] = Children.toArray(children)

  const sliderRef = useRef(null)

  const itemRefs = useRef<HTMLDivElement[]>([])

  const [activeItem, setActiveItem] = useState<number>(0)

  const [init, setInit] = useState<boolean>(false)

  const changeItem = (itemId: number): boolean => {
    if (itemId >= items.length) {
      setActiveItem(items.length - 1)
      return true
    }

    if (itemId < 0) {
      setActiveItem(0)
      return true
    }

    setActiveItem(itemId)
    return true
  }

  useEffect(() => {
    if (!init) return setInit(true)
    if (typeof activeItem === "number" && itemRefs.current[activeItem]) {
      const activeRef = itemRefs.current[activeItem]
      const xScroll = activeRef.getBoundingClientRect().x
      activeRef.scrollIntoView({ behavior: "smooth", block: "nearest" })
    }
  }, [activeItem])

  const screenSizes = theme("screens")
  const scrollSnapPoints = [
    ["md", "33.33vw"],
    ["desktop-hd", "25vw"],
  ]
  const gridColumnHandler: [string, number][] = [
    ["md", 3],
    ["desktop-hd", 4],
  ]

  const buttonColumnHandler = gridColumnHandler.map(([breakpoint, columns]) => {
    const cssBreakpoint = screenSizes[breakpoint]

    const breakpointString =
      typeof cssBreakpoint == "string" ? cssBreakpoint : cssBreakpoint.min

    const breakpointInPixels = parseInt(breakpointString.replace(/\D/g, ""))

    return [breakpointInPixels, columns]
  })

  const getColumnChange = () => {
    if (typeof window === "undefined") return 1

    let slideAdvance = 1

    for (let [breakpoint, columns] of buttonColumnHandler) {
      if (breakpoint < window.innerWidth) {
        slideAdvance = columns
        break
      }
    }
    return slideAdvance
  }

  const advanceSlides = () => {
    const rightmostItem = activeItem + (getColumnChange() - 1)
    handleTealiumEvent("right arrow")
    changeItem(rightmostItem + getColumnChange())
  }
  const regressSlides = () => {
    const leftmostItem = activeItem - (getColumnChange() - 1)
    handleTealiumEvent("left arrow")
    changeItem(leftmostItem - getColumnChange())
  }

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData } = useTealiumContext()

  const handleTealiumEvent = (action: string) => {
    trackTealEvent({
      tealium_event: "carousel_click",
      carousel_action: `${
        tealCarouselLabel ? `${tealCarouselLabel}|` : ""
      }${action}`,
    })
  }

  useEffect(() => {
    if (document && sliderRef && tealPageData?.page_name) {
      let xDown: number = null

      const getTouches = (evt: any) => {
        return (
          evt.touches || // browser API
          evt.originalEvent.touches
        ) // jQuery
      }

      const handleTouchStart = (evt: any) => {
        const firstTouch = getTouches(evt)[0]
        xDown = firstTouch.clientX
      }

      const handleTouchMove = (evt: any) => {
        if (!xDown) {
          return
        }

        const xUp = evt.touches[0].clientX
        const xDiff = xDown - xUp

        if (xDiff > 0) {
          trackTealEvent({
            ...tealPageData,
            tealium_event: "carousel_click",
            carousel_action: `${
              tealCarouselLabel ? `${tealCarouselLabel}|` : ""
            } drag right`,
          })
        } else {
          trackTealEvent({
            ...tealPageData,
            tealium_event: "carousel_click",
            carousel_action: `${
              tealCarouselLabel ? `${tealCarouselLabel}|` : ""
            } drag left`,
          })
        }
        xDown = null
      }
      sliderRef.current.addEventListener("touchstart", handleTouchStart)
      sliderRef.current.addEventListener("touchmove", handleTouchMove)
    }
  }, [tealPageData])

  return (
    <section
      css={[
        tw`w-full relative`,
        `margin: ${margins?.top}px 0 ${margins?.bottom}px !important;`,
      ]}
      id={sectionSlug?.current}
      aria-label="Content Slider Section"
    >
      <button
        onClick={regressSlides}
        css={[
          tw` bg-transparent absolute top-[23%] left-2 transform -translate-y-1/2 z-20 xl:(left-8) hover:(bg-gray-800 bg-opacity-75) focus:(bg-gray-800 bg-opacity-75) focus-visible:(outline-white) disabled:(opacity-0)`,
        ]}
        disabled={activeItem == 0}
        aria-label={"previous slide"}
        analytics-id={analyticsId ? `left arrow:${analyticsId}` : null}
      >
        <Icon.Chevron
          css={[tw`m-2 h-8 inline lg:(m-4 h-16)`]}
          color="white"
          direction="left"
        />
      </button>
      {/* Anchor for Jump To menu */}
      <div
        css={[
          tw` overflow-x-scroll scrollbar-hide`,
          css`
            padding-bottom: 500px;
            margin-bottom: -500px;
          `,
        ]}
      >
        <div
          ref={sliderRef}
          css={[
            tw`grid w-full grid-cols-1 relative overflow-y-visible`,
            css`
              // height: 32rem;
              grid-template-columns: repeat(
                ${items.length >= 2 ? items.length : 1},
                1fr
              );
              scroll-snap-points-x: repeat(100vw);
              ${gridColumnHandler.map(
                ([breakpoint, columns]) => css`
                  @media (min-width: ${screenSizes[breakpoint]}) {
                    grid-template-columns: repeat(
                      ${items.length >= columns ? items.length : columns},
                      1fr
                    );
                  }
                `
              )}
            `,
            css`
              @supports (scroll-snap-type: x mandatory) {
                scroll-snap-type: x mandatory;
                ::-webkit-scrollbar {
                  display: none;
                }
                ${scrollSnapPoints.map(
                  ([breakpoint, scrollSnapPointsX]) => `
                              @media (min-width: ${screenSizes[breakpoint]}){
                                  scroll-snap-points-x: repeat(${scrollSnapPointsX})
                              }
                          `
                )}
              }
            `,
          ]}
          {...remainingProps}
        >
          {Children.map(items, (item, index) => (
            <div
              ref={itemRef => (itemRefs.current[index] = itemRef)}
              css={[
                tw`relative flex flex-col justify-end w-[80vw]`,
                items?.length >= 2 && tw`md:(w-1/2vw)`,
                items?.length >= 3 && tw`lg:(w-1/3vw)`,
                items?.length >= 4 && tw`desktop-hd:(w-1/4vw)`,
                css`
                  scroll-snap-align: start;
                `,
              ]}
            >
              {cloneElement(item)}
            </div>
          ))}
        </div>
      </div>

      {/* Slider Controls */}
      <button
        onClick={advanceSlides}
        css={[
          tw`block bg-transparent absolute top-[23%] right-2 transform -translate-y-1/2 z-20`,
          tw`xl:(right-8)`,
          tw`hover:(bg-gray-800 bg-opacity-75)`,
          tw`focus:(bg-gray-800 bg-opacity-75)`,
          tw`focus-visible:(outline-white) disabled:(opacity-0)`,
        ]}
        disabled={activeItem == items.length - 1}
        aria-label={"next slide"}
        analytics-id={analyticsId ? `right arrow:${analyticsId}` : null}
      >
        <Icon.Chevron
          css={[tw`m-2 h-8 inline lg:(m-4 h-16)`]}
          color="white"
          direction="right"
        />
      </button>
    </section>
  )
}

export default ContentSlider
