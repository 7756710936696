import { useState, useEffect, useRef, RefObject } from "react"
import {
  observe,
  IntersectionOptions,
  ObserverInstanceCallback,
  IntersectionObserverEntry,
} from "react-intersection-observer"

/**
 * @hook
 * @author Matt Carstensen
 * @summary wires up an intersection observer to a ref.  Sets inView to `true`, then destroys the observer
 *  See https://github.com/thebuilder/react-intersection-observer#readme for info on the library
 *
 * @param {IntersectionOptions} options - allows for observer configuration
 * @param {Function} onEnterViewport - function to fire off when the referenced component enters the viewport
 * @returns {FirstViewHookObject} - ref and inView boolean
 */

export type FirstViewHookObject = {
  ref: RefObject<any>
  inView: boolean
}

export type EnterViewportArgs = {
  inView: boolean
  entry: IntersectionObserverEntry
  ref: RefObject<any>
}

export type EnterViewportCallback = (args: EnterViewportArgs) => void

export const useFirstView = (
  options: IntersectionOptions = {},
  onEnterViewport: EnterViewportCallback = () => {}
): FirstViewHookObject => {
  const [init, setInit] = useState<boolean>(false)

  const ref = useRef<HTMLElement>(null)
  const unobserve = useRef<Function>(() => {})

  const observerCallback: ObserverInstanceCallback = (inView, entry) => {
    if (inView && unobserve.current) {
      setInit(true)
      onEnterViewport({
        inView,
        entry,
        ref,
      })
      // destroy the observer
      unobserve.current()
    }
  }

  useEffect(() => {
    if (!init && ref.current) {
      unobserve.current = observe(ref.current, observerCallback, options)
    }
    // destroy the intersection observer
    return () => unobserve?.current()
  }, [init, ref.current, unobserve.current])

  return {
    ref,
    inView: init,
  }
}
