import React, { useContext, useState } from "react"
import Icon from "../../atoms/Icon"
import { LanguageContext } from "../../../contexts/Language"
import { LocationContext } from "../../../contexts/Location"
import tw from "twin.macro"
import { css } from "@emotion/react"
import { Link } from "../../atoms/Link"
import { navigate } from "gatsby"
import { Button } from "../../atoms/Button"
import { Popover, POPOVER_ORIGINS } from "../../atoms/Popover"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { LocationSelector } from "../../molecules/LocationSelector"
import { useTealiumContext } from "../../../contexts/Tealium"

enum OPEN_POPOVER {
  DEALERS,
  LANGUAGE,
}

/**
 *
 * @author Tyler
 * @summary - Section with My Profile, Dealer, and Location
 *
 * @component
 * @todo - Implement Matt's popover comp after rebase
 *
 * @returns <UserSettingsMenu />
 *
 */
const UserSettingsMenu: React.FC = () => {
  const [{ zip, dealers }] = useContext(LocationContext)
  const { language, _ } = useContext(LanguageContext)

  const toggleRoute = () => {
    if (typeof window === "undefined") return
    const url = new URL(window.location.href)
    const path = url.pathname.split("/").filter(String)
    const newLanguage = language == "es" ? "" : "es"

    navigate(
      `/${newLanguage && newLanguage + "/"}${path
        .splice(path.indexOf(language) + 1)
        .join("/")}`
    )
  }

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { setZipEvent } = useTealiumContext()

  // Popover State Control
  const [openPopover, setOpenPopover] = useState<OPEN_POPOVER>(null)
  const closePopover = () => {
    setOpenPopover(null)
    setShowZipEntry(false)
  }

  const openLanguagePopover = () => setOpenPopover(OPEN_POPOVER.LANGUAGE)
  const openDealersPopover = () => setOpenPopover(OPEN_POPOVER.DEALERS)

  const toggleOpenLanguage = () =>
    openPopover === OPEN_POPOVER.LANGUAGE
      ? closePopover()
      : openLanguagePopover()

  const toggleOpenDealers = () =>
    openPopover === OPEN_POPOVER.DEALERS ? closePopover() : openDealersPopover()

  const [confirmedZip, setConfirmedZip] = useState(false) //TODO set this in local storage maybe in the location context
  const [showZipEntry, setShowZipEntry] = useState(false)

  return (
    <div
      css={[
        tw`hidden`,
        tw`lg:(col-span-1 flex flex-wrap gap-7 w-full justify-end)`,
      ]}
    >
      <nav
        css={[
          tw`flex flex-row mt-1.5 w-full list-none items-center justify-start my-6`,
          tw`md:(justify-end)`,
        ]}
      >
        {/* Dealer State */}
        <ul
          css={[
            tw`flex justify-start w-full items-center text-sm text-white`,
            tw`md:(justify-end)`,
          ]}
        >
          <li css={[tw`inline-flex relative lg:(relative)`]}>
            <Link
              animated
              animatedThin
              tabIndex={0}
              css={[
                tw`mx-4 flex justify-items-center items-center cursor-pointer`,
                tw`md:(py-1 my-1 ml-0 pl-0 after:(left-8 right-0 w-auto))`,
                tw`focus-visible:(border-white border-dashed border outline-none p-2 pb-2 mb-[-1px] mt-[-1px] mr-[7px])`,
              ]}
              onClick={() => {
                confirmedZip ? navigate("/dealers") : toggleOpenDealers()
              }}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  confirmedZip ? navigate("/dealers") : toggleOpenDealers()
                }
              }}
            >
              {dealers && dealers.length > 0 && (
                <span css={[tw`hidden lg:(inline-flex text-sm)`]}>
                  <Icon.Marker
                    color="red-400"
                    css={[tw`hidden`, tw`lg:(inline-flex w-4 mx-2)`]}
                  />

                  <span css={[tw`pt-0.5`]}>
                    {dealers?.length} {_("Dealers Near")} {zip}
                  </span>
                </span>
              )}
              <span
                css={[
                  tw`hidden border rounded-lg border-gray-400 py-3 w-16 h-16 relative`,
                  tw`lg:( inline-flex border-0 w-auto py-0 h-auto)`,
                ]}
              >
                <span
                  css={[
                    tw`absolute -right-1 -top-1 bg-gray-900 rounded-full w-4 h-4 flex items-center justify-center`,
                    tw`lg:(hidden)`,
                  ]}
                >
                  <span
                    css={[tw`text-gray-50 text-xs font-light leading-none`]}
                  >
                    {dealers?.length}
                  </span>
                </span>
                <Icon.Marker
                  color="red-400"
                  css={[tw`w-5 mx-auto pt-1 lg:(hidden)`]}
                />
                <span css={[tw`text-xs w-full block text-center lg:(hidden)`]}>
                  {zip}
                </span>
              </span>
            </Link>
            <Popover
              open={openPopover === OPEN_POPOVER.DEALERS}
              origin={POPOVER_ORIGINS.BOTTOM_RIGHT}
              onClose={closePopover}
              css={[
                tw`bg-white !w-[26em] p-8 pt-4`,
                language === "es" &&
                  tw`w-[26rem] max-w-[100vw] pt-8 lg:!w-[30.75rem]`,
                `@media (max-width: 850px) {
                  width: 20em; left: -20px; position: absolute; top: -150px;
                }`,
              ]}
            >
              <div
                css={[tw`text-lg text-black`]}
                aria-describedby="ZipCodeDesc"
                aria-label="Confirm Your Zip Code"
                aria-labelledby="ZipCodeTitle"
                role="dialog"
              >
                {_("Current Zip")}:{" "}
                <span css={[tw`font-semibold`]} id="ZipCodeTitle">
                  {zip}
                </span>
              </div>
              {!showZipEntry && (
                <div css={[tw`bg-white`, `z-index: 99999;`]}>
                  <div
                    css={[
                      tw`flex flex-row gap-2 justify-center mt-6 flex-wrap`,
                    ]}
                  >
                    <Button
                      primary
                      css={[tw`whitespace-nowrap !px-6`]}
                      onClick={() => {
                        // Tealium confirm zip event
                        setZipEvent({
                          inProgress: true,
                          prevZip: zip,
                          searchInput: zip,
                          isGlobal: true,
                        })
                        setConfirmedZip(true)
                        navigate(language === "es" ? "/es/dealers" : "/dealers")
                        closePopover()
                      }}
                    >
                      {_("Confirm")}
                    </Button>
                    <Button
                      secondary
                      css={[tw`whitespace-nowrap !px-6`]}
                      onClick={() => {
                        setShowZipEntry(true)
                      }}
                    >
                      {_("Change Zip")}
                    </Button>
                  </div>
                </div>
              )}

              {showZipEntry && (
                <LocationSelector
                  textColor="black"
                  focusColor={"gray"}
                  fromBanner={true}
                  analyticsId="footer:nearby dealers"
                  location="global"
                />
              )}
            </Popover>
          </li>
          {/* Language */}
          <li
            css={[
              tw`hidden lg:(inline-flex relative pt-0.5 hover:cursor-pointer)`,
            ]}
          >
            <Link
              animated
              animatedThin
              tabIndex={0}
              onClick={() => {
                toggleOpenLanguage()
                trackTealEvent({ tealium_event: "language_selector" })
              }}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  toggleOpenLanguage()
                  trackTealEvent({ tealium_event: "language_selector" })
                }
              }}
              css={[
                tw`mx-4 flex justify-items-center items-center`,
                tw`md:(py-1 my-1 ml-0 pl-0 after:(left-8 right-0 w-auto))`,
                tw`focus-visible:(border-white border-dashed border outline-none p-2 pb-2 mb-[-1px] mt-[-1px] mr-[7px] ml-[-9px])`,
              ]}
              target="_self"
            >
              <Icon.Language
                color="red-400"
                css={[tw`hidden`, tw`lg:(inline-flex w-4 mx-2)`]}
              />
              <span
                css={[
                  tw`text-sm`,
                  css`
                    &:after {
                      content: "";
                      transform: scaleX(0);
                      ${tw`absolute w-full rounded-full h-0.5 bottom-0 left-0 bg-red-400 origin-bottom-left transition-transform ease-out duration-300`}
                    }
                    button:hover &:after a:hover &:after {
                      transform: scaleX(1);
                      ${tw`origin-bottom-left`}
                    }
                  `,
                ]}
              >
                {language === "en" ? "Español" : "English"}
              </span>
            </Link>
            <Popover
              open={openPopover === OPEN_POPOVER.LANGUAGE}
              origin={POPOVER_ORIGINS.BOTTOM_RIGHT}
              onClose={closePopover}
              css={[tw`bg-white w-[24em] p-8 pt-6`]}
            >
              <div css={[tw`bg-white`, `z-index: 99999;`]}>
                <div css={[tw`text-lg text-black`]}>
                  {_("Set Your Language")}
                </div>
                <div css={[tw`flex flex-row justify-center mt-4`]}>
                  <Button primary onClick={() => toggleRoute()}>
                    {language === "en"
                      ? "Cambiar a Español"
                      : "Change to English"}
                  </Button>
                </div>
              </div>
            </Popover>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default UserSettingsMenu
