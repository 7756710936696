import React, { useCallback, useEffect, useRef, useState } from "react"
import tw from "twin.macro"
import { useTealiumContext } from "../../../contexts/Tealium"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import Icon from "../Icon"
import { AccordionProps } from "./Accordion.d"

const Accordion: React.FC<AccordionProps> = ({
  expanded = false,
  label,
  children,
  icon,
  analyticsId = null,
  ...remainingProps
}) => {
  const content = useRef(null)
  const [isExpanded, setisExpanded] = useState(expanded)

  let HeadingIcon = null
  if (icon) {
    HeadingIcon = Icon[icon]
  }

  useEffect(() => {
    setisExpanded(expanded)
  }, [expanded])
  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { vehicleTealData } = useTealiumContext()
  return (
    <div>
      <button
        type="button"
        css={[
          tw`flex justify-between items-center mt-4 w-full font-semibold  !pr-0 md:(!pr-0 !pt-4 !pb-4)`,
          tw`focus-visible:(outline-gray)`,
          "-webkit-tap-highlight-color: transparent;",
        ]}
        // isExpanded attribute set to true when element is activated
        aria-expanded={isExpanded}
        onClick={() => {
          setisExpanded(!isExpanded),
            trackTealEvent({
              tealium_event: "accordion_open",
              accordion_question: label,
              ...(vehicleTealData && { ...vehicleTealData }),
            })
        }}
        analytics-id={`accordion:${analyticsId}`}
        {...remainingProps}
      >
        <h5 css={[tw`text-left mt-0.5 pr-4 flex items-center  w-auto`]}>
          {HeadingIcon && (
            <HeadingIcon color={"black"} css={[tw`h-8 mr-4 md:(h-10)`]} />
          )}
          {label}
        </h5>
        <div css={[tw`w-5 justify-center h-7 flex mt-1 mr-4`]}>
          <Icon.Chevron
            direction="down"
            color="red-400"
            css={[
              tw`w-5 transition-transform duration-500`,
              isExpanded && tw`transform -rotate-180`,
            ]}
          />
        </div>
      </button>
      <div
        ref={content}
        css={[
          tw`transition-all ease-out duration-300 opacity-100 delay-300`,
          isExpanded
            ? `height: ${content.current}px`
            : tw`h-0 opacity-0 overflow-hidden delay-75`,
        ]}
      >
        {children}
      </div>
    </div>
  )
}

export default Accordion
