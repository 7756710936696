import { useState, useEffect, useContext } from "react"
import tw from "twin.macro"
import { ContactContext } from "../../../contexts/Contact"
import {
  setActiveView,
  updateField,
  setPreviousView,
} from "../../../contexts/Contact/actions"
import { LanguageContext } from "../../../contexts/Language"
import { useTealiumContext } from "../../../contexts/Tealium"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { ViewProps } from "./ContactDealer.d"
import FormPage from "./FormPage"
import InputField from "./InputField"
import { tealiumNavClick } from "./TealiumHelpers"

const TactNameView: React.FC<ViewProps> = ({
  active,
  prevView,
  showFormHeading,
  nextPageOverride,
}) => {
  const [showNameValidation, setShowNameValidation] = useState(false)
  const [showEmailValidation, setShowEmailValidation] = useState(false)
  const [showPhoneValidation, setShowPhoneValidation] = useState(false)
  const [
    {
      fullName,
      phone,
      email,
      contactButtonType,
      activeView,
      leadType,
      contact,
    },
    dispatch,
  ] = useContext(ContactContext)
  const { _ } = useContext(LanguageContext)

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { contactDealerData } = useTealiumContext()

  const contactInputDetails = {
    email: {
      label: _("Email"),
      value: "email",
      type: "Email Address",
      input: "email",
      regEx: new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ),
      validationMessage: _("Please enter a valid email address."),
    },
    phone: {
      label: _("Phone"),
      value: "phone",
      type: "Phone Number",
      input: "tel",
      regEx: new RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/),
      validationMessage: _("Please enter a valid phone number."),
    },
  }

  useEffect(() => {
    if (
      (contactButtonType === "CheckAvailability" || leadType === "tact") &&
      activeView == "NameView"
    ) {
      trackTealEvent({
        tealium_event: "contact_dealer",
        contact_question: "What's your name?",
        ...contactDealerData?.fromInventory,
        ...contactDealerData?.dealerData,
        ...contactDealerData?.allocatedDealer,
      })
    }
  }, [contactButtonType, contactDealerData, active])

  const handleClickNext = () => {
    tealiumNavClick(
      "What's your name?",
      "Next",
      trackTealEvent,
      contactDealerData
    )

    const names = fullName?.trim()?.split(" ")
    const emailValid = contactInputDetails.email.regEx.test(email)
    const phoneValid = contactInputDetails.phone.regEx.test(phone)

    if (phone === "" && email === "") {
      setShowPhoneValidation(true)
      setShowEmailValidation(true)
      const phoneInputEl = document.getElementById("phone")
      phoneInputEl?.focus()
      return
    }

    if (!phoneValid && email === "") {
      setShowPhoneValidation(true)
      const phoneInputEl = document.getElementById("phone")
      phoneInputEl?.focus()
      return
    }

    if (!emailValid && phone === "") {
      setShowEmailValidation(true)
      const emailInputEl = document.getElementById("email")
      emailInputEl?.focus()
      return
    }

    if (names?.length === 2) {
      dispatch(updateField("firstName", names[0]))
      dispatch(updateField("lastName", names[1]))
      dispatch(setPreviousView("NameView"))
      dispatch(setActiveView(nextPageOverride ?? "SummaryView"))
    } else if (names?.length > 2) {
      const names = fullName?.split(" ")
      const initialFirstName = names?.shift()
      dispatch(updateField("firstName", initialFirstName))
      const initialLastNameValue = names?.join(" ")
      dispatch(updateField("lastName", initialLastNameValue))
      dispatch(setActiveView("NameValidationView"))
    } else {
      setShowNameValidation(true)
      const nameInputEl = document.getElementById("fullName")
      nameInputEl?.focus()
    }
  }

  useEffect(() => {
    if (showNameValidation) {
      setShowNameValidation(false)
    }
  }, [fullName])

  useEffect(() => {
    if (showEmailValidation || showPhoneValidation) {
      setShowEmailValidation(false)
      setShowPhoneValidation(false)
    }
  }, [email, phone])

  if (!active) return null

  return (
    <FormPage
      active={active}
      prevOnClick={() => {
        // dispatch(setActiveView(prevView ? prevView : "SelectVehicleView")),
        //   tealiumNavClick(
        //     "What's your name?",
        //     "Back",
        //     trackTealEvent,
        //     contactDealerData
        //   )
      }}
      nextOnClick={handleClickNext}
      heading={_("What's your name?")}
      showFormHeading={showFormHeading}
    >
      <InputField
        active={active}
        showVal={showNameValidation}
        fieldName="fullName"
        label={_("First & Last Name")}
        validationMessage={_("We need your first and last name.")}
        value={fullName}
        customOnChange={(value: string) =>
          dispatch(updateField("fullName", value))
        }
        type="text"
        css={[tw`max-w-[15rem]`]}
        heading="What's your name?"
      />
      <section css={[tw`flex flex-wrap justify-center gap-5 mt-4 mb-1`]}>
        <h3
          css={[
            tw`text-center text-2xl font-semibold normal-case mt-6 block w-full`,
          ]}
        >
          {_("How should we contact you?")}
        </h3>
      </section>
      <InputField
        active={active}
        value={phone}
        type="text"
        fieldName={contactInputDetails.phone.value}
        label={contactInputDetails.phone.label}
        validationMessage={contactInputDetails.phone.validationMessage}
        customOnChange={value => dispatch(updateField("phone", value))}
        showVal={showPhoneValidation}
        css={[tw`max-w-[20rem]`]}
        heading="How should we contact you?"
      />
      <InputField
        active={active}
        value={email}
        type="email"
        fieldName={contactInputDetails.email.value}
        label={contactInputDetails.email.label}
        validationMessage={contactInputDetails.email.validationMessage}
        customOnChange={value => dispatch(updateField("email", value))}
        showVal={showEmailValidation}
        css={[tw`max-w-[20rem]`]}
        heading="How should we contact you?"
      />
    </FormPage>
  )
}

export default TactNameView
