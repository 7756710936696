import React, { useContext } from "react"
import tw from "twin.macro"
import { Image } from "../../atoms/Image"
import { Carousel } from "../../atoms/Carousel"
import { AccessoryCardCarouselProps } from "."
import { Chevron } from "../../atoms/Icon"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { AccessoriesPageContext } from "../../../templates/seriesAccessories"
import { stripDisclaimerCodes } from "../../../helpers"
import ExternalVideo from "../VideoSection/ExternalVideo"
import { TealiumContext } from "../../../contexts/Tealium"

const AccessoryCardCarousel: React.FC<AccessoryCardCarouselProps> = ({
  slides,
  title,
  analyticsId,
}) => {
  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { page } = useContext(AccessoriesPageContext)
  const { vehicleTealData } = useContext(TealiumContext)

  return (
    <div
      css={[
        tw`h-60 rounded-t-lg overflow-hidden`,
        tw`md:(h-52)`,
        tw`lg:(h-48)`,
        tw`2xl:(h-64)`,
      ]}
    >
      <Carousel slides={slides}>
        {({ activeSlide, changeSlide, slides }) => {
          return (
            <div className="group">
              {slides?.map((item, i) => {
                const active = i === activeSlide
                return (
                  <div key={i}>
                    {item?.src ? (
                      <Image
                        className="group"
                        imageData={item?.image?.asset && item.image}
                        src={item?.src && item?.src}
                        css={[
                          tw`absolute opacity-0 left-0 right-0 bottom-0 top-0 w-full h-full object-cover`,
                          active && tw`opacity-100`,
                        ]}
                      />
                    ) : item?.image?._type === "image" ? (
                      <Image
                        className="group"
                        imageData={item?.image?.asset && item.image}
                        src={item?.src && item?.src}
                        css={[
                          tw`absolute opacity-0 left-0 right-0 bottom-0 top-0 w-full h-full object-cover`,
                          active && tw`opacity-100`,
                        ]}
                      />
                    ) : (
                      item?.image?._type === "externalMedia" && (
                        <div
                          css={[
                            tw`absolute opacity-0 left-0 right-0 bottom-0 top-0 w-full h-full object-cover`,
                            active && tw`opacity-100 z-[49]`,
                          ]}
                        >
                          <ExternalVideo
                            videoSectionVideo={item?.image}
                            videoSectionHeading={""}
                            videoCss={[tw`h-full w-full`]} // Custom CSS for video
                            extraTealiumValues={{
                              ...(vehicleTealData?.vehicle_model && {
                                vehicle_model: vehicleTealData?.vehicle_model,
                              }),
                              ...(vehicleTealData?.vehicle_model_truncated && {
                                vehicle_model_truncated:
                                  vehicleTealData?.vehicle_model_truncated,
                              }),
                              ...(vehicleTealData?.vehicle_year && {
                                vehicle_year: vehicleTealData?.vehicle_year,
                              }),
                              ...(vehicleTealData?.vehicle_segment_vehicle_page && {
                                vehicle_segment_vehicle_page:
                                  vehicleTealData?.vehicle_segment_vehicle_page,
                              }),
                            }} // Custom Tealium values
                          />
                        </div>
                      )
                    )}
                  </div>
                )
              })}
              <button
                aria-label="Previous"
                onClick={() => {
                  changeSlide(activeSlide - 1)
                  trackTealEvent({
                    tealium_event: "carousel_click",
                    carousel_action: "left arrow",
                    gallery_image_details:
                      `${page?.vehicles[0]?.series?.name}|${page?.vehicles[0]?.year}|` +
                      stripDisclaimerCodes(title) +
                      `|Gallery|${activeSlide - 1}`,
                  })
                }}
                css={[
                  tw`opacity-0 absolute flex justify-center items-center cursor-pointer w-16 h-10 rounded-full transition-all duration-500  bg-white left-3 top-[45%] -translate-y-1/2 z-50 group-hover:(opacity-100) hover:(bg-gray-400) focus-visible:(outline-white)`,
                ]}
                analytics-id={`left arrow:accessories:${analyticsId + 1}`}
              >
                <Chevron direction={"left"} color="black" css={[tw`h-5`]} />
              </button>
              <button
                aria-label="Next"
                onClick={() => {
                  changeSlide(activeSlide + 1)
                  trackTealEvent({
                    tealium_event: "carousel_click",
                    carousel_action: "right arrow",
                    gallery_image_details:
                      `${page?.vehicles[0]?.series?.name}|${page?.vehicles[0]?.year}|` +
                      stripDisclaimerCodes(title) +
                      `|Gallery|${activeSlide + 1}`,
                  })
                }}
                className="group"
                css={[
                  tw`opacity-0 absolute flex justify-center items-center bg-white cursor-pointer w-16 h-10 rounded-full transition-all duration-500 right-3 top-[45%] -translate-y-1/2 z-50 hover:(bg-gray-400) group-hover:(opacity-100) focus-visible:(outline-white)`,
                ]}
                analytics-id={`right arrow:accessories:${analyticsId + 1}`}
              >
                <Chevron direction={"right"} color="black" css={[tw`h-5`]} />
              </button>
            </div>
          )
        }}
      </Carousel>
    </div>
  )
}

export default AccessoryCardCarousel
