import React, { useContext } from "react"
import Icon from "../../atoms/Icon"
import Logo, { Toyota } from "../../atoms/Logo"
import tw from "twin.macro"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { parseDisclaimerBlocks } from "../../../helpers"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { HeadlineProps } from "./PartAndServiceHeadline.d"

/**
 * Heading
 *
 * @component
 * @param {string} headlineType - determines the style of heading returned. Current cms options are textHeadline, iconWithTextHeadline, OR logoHeadline.
 * @param {string} textHeadline - text heading that's displayed when headlineType === textHeadline OR headlineType === iconWithTextHeadline
 * @param {string} iconHeadline - name of the icon that will be displayed when headlineType === iconWithTextHeadline
 * @param {string} logoHeadline - name of the logo that will be displayed when the headlineType === logoHeadline
 * @param {string} logoText - Optional text to be displayed when the ToyotaSymbol logo is selected. Text will be displayed to the right of the ToyotaSymbol.
 *
 * @returns <h1></h1>
 */

const Headline: React.FC<HeadlineProps> = ({
  headlineType,
  textHeadline,
  iconHeadline,
  logoHeadline,
  logoText,
  color,
  contentTextColor,
}) => {
  const [{}, dispatch] = useContext(DisclaimersContext)
  const formattedLogoHeadline =
    logoHeadline == "Toyoguard Platinum Logo (White)"
      ? "ToyoguardPlatinumWhite"
      : logoHeadline == "Toyoguard Platinum Logo (Black)"
      ? "ToyoguardPlatinumBlack"
      : logoHeadline
  const HeadingIcon = iconHeadline && Icon[iconHeadline]
  const HeadingLogo = logoHeadline && Logo[formattedLogoHeadline]
  const textColor =
    contentTextColor &&
    `
        @media(min-width: 1023px) {
            color: rgba(${contentTextColor.rgb?.r}, ${contentTextColor.rgb?.g}, ${contentTextColor.rgb?.b}, ${contentTextColor.rgb?.a})
        }
    `
  const iconColor =
    color &&
    `
        @media(max-width: 1023px) {
            stroke: #FFF;
        }
    `
  let toyotaLogoText = ""

  // Setting optional text for Toyota Symbol logo
  if (logoHeadline === "Toyota" && logoText) {
    toyotaLogoText = logoText
  }

  // Logo Headline
  if (headlineType === "logoHeadline") {
    return (
      <div css={tw`text-4xl font-semibold max-w-[20rem]`}>
        <HeadingLogo
          showText={toyotaLogoText ? true : false}
          text={toyotaLogoText}
          css={[tw`h-8 md:(h-12) object-contain`]}
          aria-hidden={true}
          alt=""
        />
      </div>
    )
  }

  // Icon + Text Headline
  if (headlineType === "iconWithTextHeadline" && HeadingIcon) {
    return (
      <h1
        css={[
          tw`text-4xl font-semibold text-white flex items-center gap-2`,
          textColor,
        ]}
      >
        <HeadingIcon
          color={color || "#FFF"}
          css={[tw`h-8 inline-block md:(h-12)`, iconColor]}
          aria-hidden={true}
          alt=""
        />
        {textHeadline && (
          <span>
            {parseDisclaimerBlocks(textHeadline, selection =>
              dispatch(toggleDisclaimersModal(selection))
            )}
          </span>
        )}
      </h1>
    )
  }

  // Text Only Headline
  if (headlineType === "textHeadline" && textHeadline) {
    return (
      <h1 css={tw`text-4xl font-semibold`}>
        {parseDisclaimerBlocks(textHeadline, selection =>
          dispatch(toggleDisclaimersModal(selection))
        )}
      </h1>
    )
  }

  // Return null if required fields aren't passed in
  return null
}

export default Headline
