import React, { useContext } from "react"
import { HeroProps } from "./Hero.d"
import tw from "twin.macro"
import { SeriesPageContext } from "../../../../templates/series"
import { motion, useAnimation } from "framer-motion"
import hybridLogo from "../../../../images/SET_Hybrid_Logo.svg"
import ToyotaCareLogo from "./ToyotCareLogo"
import {
  getSeriesMpgDisclaimerCode,
  parseDisclaimerBlocks,
  toUsd,
} from "../../../../helpers"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"
import { Disclaimer } from "../../../../contexts/Disclaimers/disclaimers.d"
import { LanguageContext } from "../../../../contexts/Language"
import {
  useElectricPluginSeriesNames,
  electricSeriesNames,
} from "../../../../hooks/useElectricPluginSeriesNames"
import Icon from "../../../atoms/Icon"
import { Tooltip } from "../../../atoms/Tooltip"
import { Link } from "../../../atoms/Link"

/**
 *
 * @author Cody & Scott
 * @summary
 *
 */

const HeroFooter: React.FC<HeroProps> = ({ model }) => {
  const { vehicle } = useContext(SeriesPageContext)
  const [state, dispatch] = useContext(DisclaimersContext)
  const mpgDisclaimerCode = getSeriesMpgDisclaimerCode(
    model?.series?.name,
    vehicle?.year
  )
  const { _ } = useContext(LanguageContext)
  const pluginSeriesNames = useElectricPluginSeriesNames()
  const isElectricPluginSeries = pluginSeriesNames.includes(
    model?.series?.name?.toLocaleLowerCase()
  )
  const electricName = electricSeriesNames()
  const isAllElectric = electricName.includes(
    model?.series?.name?.toLocaleLowerCase().toLocaleLowerCase()
  )

  let hasHybridModels = false
  let hasCustomModel = false
  let hasNonHybridModels = false
  let hasElectricModels = false
  vehicle.models.map((model: { models: any[] }) => {
    if (model.models) {
      model.models.map(mod => {
        if (mod.model?.isHybridModel) {
          hasHybridModels = true
        } else {
          hasNonHybridModels = true
        }
        if (mod.model?.isElectricModel) {
          hasElectricModels = true
        }
        if (mod?.model?._type === "customModel") {
          hasCustomModel = true
        }
      })
    } else {
      model?.model?.isHybridModel ? (hasHybridModels = true) : null
      model?.model?.isElectricModel ? (hasElectricModels = true) : null
      model?.model?._type === "customModel" ? (hasCustomModel = true) : null
      console.log("model?.model?._type", model?.model?._type)
    }
  })

  const allModelsAreHybrid = hasHybridModels && !hasNonHybridModels

  const controls = useAnimation()

  const cardVariants = {
    visible: (i: number) => ({
      opacity: 1,
      transition: {
        delay: i * 0.275,
      },
    }),
    hidden: { opacity: 0 },
  }

  return (
    <section
      css={[
        tw`my-4 mt-10 pb-4 w-full grid grid-cols-2 gap-6 px-6`,
        tw`lg:(flex mt-6 gap-x-4 mx-auto justify-center)`,
        tw`xl:(gap-x-10)`,
      ]}
      aria-label="Hero Footer Section"
    >
      <motion.div
        css={[
          tw`text-gray-700 text-center lg:(w-56 my-4 text-center overflow-visible )`,
        ]}
        custom={0}
        variants={cardVariants}
        animate={controls}
        whileInView="visible"
        initial={{ opacity: 0 }}
        viewport={{ once: true }}
      >
        <div
          css={[
            tw`text-4xl inline-flex justify-self-center font-light h-12`,
            tw`lg:(text-5xl h-16)`,
          ]}
        >
          {parseDisclaimerBlocks(
            `$${toUsd(model?.series?.msrp)} [starting_msrp]`,
            code => dispatch(toggleDisclaimersModal(code))
          )}
        </div>
        <div css={[tw`font-light text-center text-sm lg:(text-base)`]}>
          {_("Starting MSRP")}
        </div>
        <div css={[tw`font-light text-center text-sm lg:(text-base)`]}>
          {_("as shown")}{" "}
          {parseDisclaimerBlocks(
            `$${toUsd(model?.costs?.baseMSRP)} [starting_msrp]`,
            code => dispatch(toggleDisclaimersModal(code))
          )}
        </div>
      </motion.div>
      <motion.div
        css={[
          tw`hidden`,
          tw`lg:(w-1 flex items-start flex-shrink-0 md:(my-4) text-gray-700)`,
        ]}
        custom={0}
        variants={cardVariants}
        animate={controls}
        whileInView="visible"
        initial={{ opacity: 0 }}
        viewport={{ once: true }}
      >
        <div css={[tw`w-[1px] block h-full z-10 bg-gray-500`]}>&nbsp;</div>
      </motion.div>
      <motion.div
        css={[tw`text-gray-700 text-center lg:(w-56 my-4 text-center)`]}
        custom={1}
        variants={cardVariants}
        animate={controls}
        whileInView="visible"
        initial={{ opacity: 0 }}
        viewport={{ once: true }}
      >
        <div
          css={[
            tw`text-4xl h-12`,
            tw`lg:(text-5xl text-center font-light h-16)`,
          ]}
        >
          {isAllElectric ? (
            <span>{vehicle.series.range} mi</span>
          ) : (
            <>
              {model?.epa?.mpgCity}/{model?.epa?.mpgHighway}
            </>
          )}

          {parseDisclaimerBlocks(mpgDisclaimerCode, code =>
            dispatch(toggleDisclaimersModal(code))
          )}
        </div>
        <div css={[tw`font-light text-center text-sm lg:(text-base)`]}>
          {_("Estimated")}
          <br />
          {!isElectricPluginSeries && !isAllElectric && _("MPG")}
          {isElectricPluginSeries && _("MPG/MPGe")}
          {isAllElectric && _("Range")}
        </div>
      </motion.div>
      <motion.div
        css={[
          tw`hidden`,
          tw`lg:(w-1 flex items-start flex-shrink-0 md:(my-4) text-gray-700)`,
        ]}
        custom={0}
        variants={cardVariants}
        animate={controls}
        whileInView="visible"
        initial={{ opacity: 0 }}
        viewport={{ once: true }}
      >
        <div css={[tw`w-[1px] block h-full z-10 bg-gray-500`]}>&nbsp;</div>
      </motion.div>
      <motion.div
        css={[tw`text-gray-700 text-center lg:(w-56 my-4 text-center)`]}
        custom={2}
        variants={cardVariants}
        animate={controls}
        whileInView="visible"
        initial={{ opacity: 0 }}
        viewport={{ once: true }}
      >
        <div css={[tw`h-16 flex justify-center items-center`]}>
          <Link to="/toyotacare">
            <ToyotaCareLogo css={[tw`mr-1`]} />
          </Link>
          {parseDisclaimerBlocks(`[toyota_care]`, selection =>
            dispatch(toggleDisclaimersModal(selection))
          )}
        </div>
        <div css={[tw`font-light text-center text-sm lg:(text-base)`]}>
          <Link to="/toyotacare" css={[tw`hover:underline`]}>
            {_("included with every new Toyota lease or purchase")}
          </Link>
        </div>
      </motion.div>
      {hasHybridModels && (
        <>
          <motion.div
            css={[
              tw`hidden`,
              tw`lg:(w-1 flex items-start flex-shrink-0 md:(my-4) text-gray-700)`,
            ]}
            custom={0}
            variants={cardVariants}
            animate={controls}
            whileInView="visible"
            initial={{ opacity: 0 }}
            viewport={{ once: true }}
          >
            <div css={[tw`w-[1px] block h-full z-10 bg-gray-500`]}>&nbsp;</div>
          </motion.div>
          <motion.div
            css={[tw`text-gray-700 text-center lg:(w-56 my-4 text-center)`]}
            custom={2}
            variants={cardVariants}
            animate={controls}
            whileInView="visible"
            initial={{ opacity: 0 }}
            viewport={{ once: true }}
          >
            {hasHybridModels && !hasNonHybridModels ? (
              <div css={[tw`h-16 pt-4 lg:(h-16 p-6)`]}>
                <Link to="/hybrids">
                  <img
                    src={hybridLogo}
                    css={[tw`mx-auto w-[150px]`]}
                    alt={"Hybrid Logo"}
                  />
                </Link>
                <div
                  css={[
                    tw`font-light text-center pt-2.5 text-sm lg:(text-base)`,
                  ]}
                >
                  <Link to="/hybrids" css={[tw`hover:underline`]}>
                    {_("Hybrid Powertrain")}
                  </Link>
                </div>
              </div>
            ) : (
              <div css={[tw`h-16 pt-4 lg:(h-16 p-6)`]}>
                <Link to="/hybrids">
                  <img
                    src={hybridLogo}
                    css={[tw`mx-auto w-[150px]`]}
                    alt={"Hybrid Logo"}
                  />
                </Link>
                <div
                  css={[
                    tw`font-light text-center pt-2.5 text-sm lg:(text-base)`,
                  ]}
                >
                  <Link to="/hybrids" css={[tw`hover:underline`]}>
                    {_("Hybrid Powertrain Available")}
                  </Link>
                </div>
              </div>
            )}
          </motion.div>
        </>
      )}

      {/* Waiting on a design logo and text for what the last tile should be for an EV */}
      {hasElectricModels && (
        <>
          <motion.div
            css={[
              tw`hidden`,
              tw`lg:(w-1 flex items-start flex-shrink-0 md:(my-4) text-gray-700)`,
            ]}
            custom={0}
            variants={cardVariants}
            animate={controls}
            whileInView="visible"
            initial={{ opacity: 0 }}
            viewport={{ once: true }}
          >
            <div css={[tw`w-[1px] block h-full z-10 bg-gray-500`]}>&nbsp;</div>
          </motion.div>
          <motion.div
            css={[tw`text-gray-700 text-center lg:(w-56 my-4 text-center)`]}
            custom={2}
            variants={cardVariants}
            animate={controls}
            whileInView="visible"
            initial={{ opacity: 0 }}
            viewport={{ once: true }}
          >
            <div css={[tw`h-auto pt-2 lg:(h-[5.5rem] pt-2)`]}>
              <Icon.Electric css={[tw`mx-auto max-h-14 lg:(max-h-16)`]} />
            </div>
            <div
              css={[
                tw`font-light text-center text-sm lg:(text-base whitespace-pre-line)`,
              ]}
            >
              {_("Electric Vehicle")}
            </div>
          </motion.div>
        </>
      )}
      {hasCustomModel && (
        <>
          <motion.div
            css={[
              tw`hidden`,
              tw`lg:(w-1 flex items-start flex-shrink-0 md:(my-4) text-gray-700)`,
            ]}
            custom={0}
            variants={cardVariants}
            animate={controls}
            whileInView="visible"
            initial={{ opacity: 0 }}
            viewport={{ once: true }}
          >
            <div css={[tw`w-[1px] block h-full z-10 bg-gray-500`]}>&nbsp;</div>
          </motion.div>
          <motion.div
            css={[tw`text-gray-700 text-center lg:(w-56 my-4 text-center)`]}
            custom={2}
            variants={cardVariants}
            animate={controls}
            whileInView="visible"
            initial={{ opacity: 0 }}
            viewport={{ once: true }}
            className="group"
          >
            <div css={tw`relative`}>
              <div css={[tw`h-auto pt-2 mb-2`]}>
                <Icon.XSeries css={[tw`mx-auto max-h-12`]} />
              </div>
              <div
                css={[
                  tw`font-light text-center text-sm pb-2 lg:(text-base whitespace-pre-line)`,
                ]}
              >
                {_("XSeries Available")}
              </div>
              <Tooltip
                color="black"
                css={tw`hidden mt-2 group-hover:(block) hover:(block)`}
              >
                <span>
                  {_(
                    "The Toyota XSeries™ models are upgraded to provide more rugged adventure"
                  )}{" "}
                </span>
                <span css={tw`underline`}>{_("in the day-to-day.")}</span>
                <Link to="/xseries" css={tw`block underline`}>
                  {_("Read More")}
                </Link>
              </Tooltip>
            </div>
          </motion.div>
        </>
      )}
    </section>
  )
}

export default HeroFooter
