import React, { useContext, useEffect, useRef, useState } from "react"
import tw from "twin.macro"
import { FamilySeriesContext } from "../../../contexts/FamilySeries"
import { Family, Model, Route } from "../../../global"
import { Link } from "../../atoms/Link"
import { SeriesObject } from "../../molecules/FamilySeriesTabs/FamilySeriesTabs.d"
import { Image } from "../../atoms/Image"
import { reformatCategory } from "../../../helpers"
import _ from "lodash"
import Icon from "../../atoms/Icon"
import { css } from "@emotion/react"
import { useTealiumContext } from "../../../contexts/Tealium"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { LanguageContext } from "../../../contexts/Language"
import { LocalStorageClient } from "../../../clients/LocalStorageClient"
import CheckMarkInCircle from "../../atoms/Icon/CheckMarkInCircle"
import { Tooltip } from "../../atoms/Tooltip"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useLocation } from "@reach/router"
import { Buffer } from "buffer"
import { seriesNameMap, normalizeSeriesNameForLink } from "../../../helpers"

const FamilySeries: React.FC<{
  series: string
  isOpened?: boolean
}> = ({ series, isOpened = true }) => {
  if (series == "86") series = "gr86"
  let families = useContext(FamilySeriesContext)
  if (Array.isArray(families) && families?.length > 0) {
    families = families[0]
  }
  const [selectedSeriesSlug, setSelectedSeriesSlug] = useState(series)
  const [selectedSeriesFamily, setSelectedSeriesFamily] = useState("")
  const [familyHasBeenSelected, setFamilyHasBeenSelected] = useState(
    series ? true : false
  )
  const [{}, disclaimersDispatch] = useContext(DisclaimersContext)
  const languageContextValue = useContext(LanguageContext)
  const { _ } = useContext(LanguageContext)
  const [selectedFamilyForAccessories, setSelectedFamilyForAccessories] =
    useState()
  const content = useRef(null)
  const location = useLocation()

  // Tealium
  const { updateVehicleTealData } = useTealiumContext()
  const { trackTealEvent } = useTealiumEvent()

  const familiesToDisplay = [
    "Cars & Minivans",
    "Crossovers & SUVs",
    "Trucks",
    "Hybrid & Electric",
  ]
  families = families?.filter((family: Family) =>
    familiesToDisplay.includes(family.name)
  )

  // order the families by the order of the familiesToDisplay array
  families = families?.sort((a: Family, b: Family) => {
    return familiesToDisplay.indexOf(a.name) - familiesToDisplay.indexOf(b.name)
  })

  const [isExpanded, setisExpanded] = useState(isOpened)
  useEffect(() => {
    // Check the screen width and set isExpanded to false for mobile devices
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setisExpanded(false)
      } else if (isOpened) {
        setisExpanded(true)
      }
    }

    handleResize()

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    setSelectedSeriesSlug(series)
    let isHybridFamilyParams = false
    try {
      const queryParams = location?.search

      isHybridFamilyParams =
        queryParams && queryParams.includes("isHybrid=true")
    } catch (err) {
      console.log("Error while parsing url params", err.message)
    }

    // the code below is responsible for setting the selectedFamily from the series selected
    let family = null
    if (isHybridFamilyParams && series) {
      family = familyData?.find(
        (family: any) => family?.family === "Hybrid & Electric"
      )
    } else if (series) {
      family = familyData.find((family: any) =>
        family.data.some(
          e => e?.slug?.replaceAll("/", "") === series.toLowerCase()
        )
      )
    }

    if (family && series) {
      setSelectedFamilyForAccessories(family)
      setSelectedSeriesFamily(family.family)
      // Data needed for Tealium view call on Series Inventory Pages:
      updateVehicleTealData({ family: family })
    }
  }, [series])

  // Setting initial selectedFamily for the generic inventory page
  useEffect(() => {
    if (series) return

    const storedFamily = LocalStorageClient.read("selectedFamilyForAccessories")
    if (!familyHasBeenSelected && storedFamily) {
      setFamilyHasBeenSelected(true)
    }

    // Using previously selected family stored in local storage if a series isn't selected.
    if (storedFamily) {
      const userPreSelectedFamily = familyData?.find(
        (family: any) => family?.family === storedFamily
      )
      setSelectedFamilyForAccessories(userPreSelectedFamily)
    } else {
      setSelectedFamilyForAccessories(familyData[0])
    }
  }, [])

  const familyData = families?.map((family: Family) => {
    if (!family) return
    return {
      family: family.name,
      data: family.routes?.map(({ route, seriesImage }: Route) => {
        const vehicle = route?.page?.vehicle
        const slug = route?.slug?.current?.replaceAll("-", "")

        let modelData = vehicle?.models?.find(
          (model: Model) =>
            model?.hasOwnProperty("featuredModel") &&
            model?.featuredModel === true
        )?.model

        if (modelData === undefined) {
          if (vehicle?.models && vehicle?.models[0]?.models) {
            // this means we are dealing with a series that exclusively has models in a group
            modelData = vehicle?.models[0]?.models[0]?.model // TODO clean this up a bit but will work for current use case
          } else {
            return
          }
        }

        // Getting Hybrid Models for inventory link
        let hybridModelNumbers: string[] = []
        vehicle.models.forEach(model => {
          if (model?.model?.isHybridModel) {
            hybridModelNumbers.push(model?.model?.code)
            return
          }

          if (model?.models) {
            model.models?.forEach(model => {
              if (model?.model?.isHybridModel) {
                hybridModelNumbers.push(model?.model?.code)
              }
            })
            return
          }
        })

        return {
          slug: `${slug}`,
          seriesData: modelData?.series,
          imageData: seriesImage,
          isHybrid: family.name == "Hybrid & Electric",
          hybridModelNumbers: hybridModelNumbers,
          family: family.name,
        }
      }),
    }
  })

  const handleUpdateFamily = (family: any) => {
    LocalStorageClient.write("selectedFamilyForAccessories", family.family)
    setSelectedFamilyForAccessories(family)
    !familyHasBeenSelected && setFamilyHasBeenSelected(true)
    // Currently only triggering event on general /inventory page
    if (family) {
      trackTealEvent({
        tealium_event: "refinement",
        page_name: reformatCategory(family.family) + `_${selectedSeriesSlug}`,
        refinement_value:
          `accessories` +
          `|` +
          reformatCategory(family.family) +
          `_${selectedSeriesSlug}` +
          `|Segment|${family.family}`,
      })
    }
  }

  const tabAnimation = css`
    &:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
      content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:hover:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const focusAnimation = css`
    &:focus-visible:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
    content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:focus-visible:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const activeStyles = css`
    ${tw`font-semibold focus-visible:(border-transparent)`}
    &:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
    &:hover:after {
      ${tw`h-1 bg-red-400`}
    }
    &:focus-visible:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
  `
  const getSeriesAccessories = (series: string) => {
    let link = `/accessories/${normalizeSeriesNameForLink(series)}`

    if (selectedFamilyForAccessories?.family === "Hybrid & Electric") {
      link += `?isHybrid=true`
    }

    return link
  }
  return (
    <section
      aria-label="Family Series Selector Section"
      css={[tw`relative bg-gradient-to-b from-gray-100`]}
    >
      <div css={[tw`bg-gunmetal flex relative min-h-[64px]`]}>
        <button
          type="button"
          css={[
            tw`absolute right-8 top-4 z-20 flex justify-center items-center h-[24px]`,
            tw`md:(px-0)`,
            tw`focus-visible:(outline-gray)`,
            "-webkit-tap-highlight-color: transparent;",
          ]}
          aria-expanded={isExpanded}
          onClick={() => setisExpanded(!isExpanded)}
        >
          <span
            css={[
              tw`hidden text-white transition-all duration-300 xl:(inline-flex opacity-100 pr-3 transform)`,
              isExpanded && tw`xl:(opacity-0 hidden)`,
            ]}
          >
            {selectedSeriesSlug
              ? seriesNameMap.get(selectedSeriesSlug)
              : _("Select a Series")}
          </span>
          <Icon.Chevron
            color="red-400"
            direction="down"
            css={[
              tw`h-2.5 transition-all duration-300 inline-flex mt-1`,
              isExpanded && tw`transform -rotate-180`,
            ]}
          />
        </button>
        <div
          css={[
            tw`flex flex-nowrap justify-between overflow-x-auto w-4/5 py-3 scrollbar-hide`,
            tw`md:(flex justify-center pb-3 pt-1.5 w-full items-center)`,
            // Padding and negative margin styles added in order to view tooltip on the generic /accessories page
            !familyHasBeenSelected &&
              isExpanded &&
              tw`pb-[500px] mb-[-500px] md:(pb-[500px] mb-[-500px])`,
          ]}
        >
          {familyData &&
            familyData.map((familyData: { family: string }, i: any) => {
              const isActive =
                selectedFamilyForAccessories?.family === familyData.family &&
                familyHasBeenSelected
              return (
                <div css={tw`md:relative`}>
                  <button
                    css={[
                      tabAnimation,
                      focusAnimation,
                      tw`relative flex-nowrap whitespace-nowrap px-0 mx-6 py-2 cursor-pointer flex text-white`,
                      tw`focus-visible:(outline-gray)`,
                      tw`md:(cursor-pointer flex whitespace-pre-wrap border border-transparent)`,
                      isActive && activeStyles,
                    ]}
                    onClick={() => {
                      handleUpdateFamily(familyData)
                      setisExpanded(true)
                    }}
                    analytics-id={`segment filter:vehicle selector:${i + 1}`}
                  >
                    {_(familyData.family)}
                  </button>
                  {i === 0 && !familyHasBeenSelected && isExpanded && (
                    <Tooltip
                      fromAccessoriesNav
                      color="black"
                      css={[
                        tw`hidden w-80 max-w-full top-14 left-1/2 transform -translate-x-1/2`,
                        tw`md:(left-0 top-8 -translate-x-16 max-w-none)`,
                        tw`lg:(-translate-x-20)`,
                        isExpanded && tw`block`,
                      ]}
                    >
                      <div css={[tw`inline-block px-6 py-2`]}>
                        {languageContextValue?._(
                          "To get started select a segment like"
                        )}{" "}
                        <span css={tw`font-bold`}>
                          {languageContextValue?._("Cars & Minivans")}
                        </span>
                        {"."}
                      </div>
                    </Tooltip>
                  )}
                </div>
              )
            })}
        </div>
      </div>
      <div
        ref={content}
        css={[
          tw`flex justify-center items-end flex-wrap pb-4 pt-2 scrollbar-hide relative items-baseline`,
          //tw`md:(flex justify-between items-end transition-all duration-500)`,
          isExpanded
            ? tw`h-auto flex`
            : tw`h-0 opacity-0 hidden pt-0 transition-none`,
        ]}
      >
        {selectedFamilyForAccessories &&
          selectedFamilyForAccessories.data?.map(
            (node: SeriesObject, i: number) => {
              if (!node || !node.seriesData || !node.imageData) return
              const seriesData = node?.seriesData
              const imageData = node?.imageData
              const selected =
                selectedSeriesSlug &&
                selectedSeriesSlug.toLowerCase() ===
                  node.slug.replaceAll("/", "") &&
                selectedSeriesFamily === node.family

              return (
                <div
                  css={[
                    tw``,
                    tw`md:(flex flex-col items-center pb-2 focus-visible:(outline-red))`,
                  ]}
                  analytics-id={`series filter:model selector:${i + 1}`}
                >
                  <div
                    css={[
                      tw`flex items-center justify-between py-3`,
                      tw`md:(flex flex-col items-center focus-visible:(outline-red))`,
                      tw`lg:(mx-0)`,
                    ]}
                    className="group"
                  >
                    <Link
                      to={getSeriesAccessories(node?.seriesData?.name)}
                      className="group"
                      css={[
                        tw`mx-4 py-0 inline-block`,
                        tw`md:(flex flex-col items-center focus-visible:(outline-red))`,
                        tw`lg:(mx-0)`,
                      ]}
                      onClick={() => {
                        selectedSeriesFamily &&
                          trackTealEvent({
                            tealium_event: "refinement",
                            page_name:
                              reformatCategory(
                                selectedFamilyForAccessories.family
                              ) + `_${seriesData?.name?.toLowerCase()}`,
                            vehicle_model_truncated: seriesData?.name,
                            refinement_value:
                              `accessories|` +
                              reformatCategory(
                                selectedFamilyForAccessories.family
                              ) +
                              `_${seriesData?.name?.toLowerCase()}` +
                              `|Series|` +
                              `${seriesData?.name}`,
                          })
                      }}
                      analytics-id={`series filter:vehicle selector:${i + 1}`}
                    >
                      {imageData && (
                        <>
                          <Image
                            imageData={imageData.image}
                            css={[tw`w-36 max-w-none`, tw`md:(w-52)`]}
                          />
                        </>
                      )}
                    </Link>
                    <div css={[tw`text-center`]}>
                      <Link
                        to={getSeriesAccessories(node?.seriesData?.name)}
                        className="group"
                        css={[
                          tw`flex items-center justify-center mx-0 py-0 w-[165px]`,
                          tw`md:(flex flex-col items-center focus-visible:(outline-red))`,
                          tw`lg:(mx-0)`,
                        ]}
                        onClick={() => {
                          selectedSeriesFamily &&
                            trackTealEvent({
                              tealium_event: "refinement",
                              page_name:
                                reformatCategory(
                                  selectedFamilyForAccessories.family
                                ) + `_${seriesData?.name?.toLowerCase()}`,
                              vehicle_model_truncated: seriesData?.name,
                              refinement_value:
                                `accessories|` +
                                reformatCategory(
                                  selectedFamilyForAccessories.family
                                ) +
                                `_${seriesData?.name?.toLowerCase()}` +
                                `|Series|` +
                                `${seriesData?.name}`,
                            })
                        }}
                        analytics-id={`series filter:vehicle selector:${i + 1}`}
                      >
                        {seriesData?.name && (
                          <div
                            css={[
                              tw`font-semibold relative mb-2`,
                              "line-height: 1.125rem",
                              selected && tw`text-red-400`,
                            ]}
                          >
                            {seriesData?.name}{" "}
                            <span css={[tw`block`]}>{_("Accessories")}</span>
                          </div>
                        )}
                      </Link>

                      {selectedSeriesSlug ===
                        node?.slug.replaceAll("/", "").replaceAll("-", "") &&
                        selectedSeriesFamily === node.family && (
                          <CheckMarkInCircle
                            color="green"
                            css={tw`w-6 mx-auto mt-2`}
                          />
                        )}
                    </div>
                  </div>
                </div>
              )
            }
          )}
        {/* Overlay filter shown on /accessories page when family hasn't been selected */}
        <div
          css={[
            tw`bg-mystic/70 blur-sm absolute w-full h-full top-1.5 z-10 hidden`,
            !familyHasBeenSelected && isExpanded && "display: block;",
          ]}
        />
        {/* Tooltip shown on /accessories page when family has been selected but series has not */}
        <Tooltip
          color="black"
          css={[
            tw`hidden -mt-3 w-72`,
            familyHasBeenSelected && !series && "display: block;",
          ]}
        >
          <span css={tw`inline-block px-4 py-2`}>
            {languageContextValue?._(
              "Next, select a series to see accessories for that series."
            )}
          </span>
        </Tooltip>
      </div>
    </section>
  )
}

export default FamilySeries
