import React, { useContext } from "react"
import FormHeading from "./FormPage/FormHeading"
import tw from "twin.macro"
// import { ContactDealerFormContext } from "./ContactDealerFormContext"
import { ContactContext } from "../../../contexts/Contact"
import { LocationContext } from "../../../contexts/Location"
import { Image } from "../../atoms/Image"
import defaultImage from "../../../images/favorite-vehicle-empty-state.png"
import { LanguageContext } from "../../../contexts/Language"
import {
  setActiveView,
  setPreviousView,
} from "../../../contexts/Contact/actions"
import { reformattedContactView } from "../../../helpers"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"

export default function LeftBar({ images }: { images: Record<string, any> }) {
  //   const ctx = useContext(ContactDealerFormContext)
  const [
    {
      contactDealerSelection, // selected dealer passed from clicking Contact Dealer from a dealer
      dealer,
      activeView,
      seriesName,
      trim,
      vehicle,
      gRecaptchaResponse,
      contactType,
      comments,
      leadType,
      contact,
      firstName,
      lastName,
      contactButtonType,
      confirmContactDealerSelection,
    },
    dispatch,
  ] = useContext(ContactContext)
  const [{ zip, dealers, radius, dealersLoading }] = useContext(LocationContext)
  const { _ } = useContext(LanguageContext)

  const handleClick = e => {
    e.preventDefault()
    // trackTealEvent({
    //   tealium_event: "contact_form_nav",
    //   contact_question: activeView,
    //   dropdown_selected: `Change:${reformattedContactView(activeView)}`,
    // })
    if (activeView != "SelectVehicleView") {
      dispatch(setPreviousView(activeView))
    }
    dispatch(setActiveView("SelectVehicleView"))
  }

  const formHeading = () => {
    switch (true) {
      case activeView === "DealerLocationView":
        return ""
      case activeView === "SelectDealerView":
        return zip?.toString()
      default:
        return ""
    }
  }

  const showHeading = ![].includes(activeView)

  const seriesNameLower =
    typeof seriesName === "string"
      ? seriesName
          .toLowerCase()
          .replace("crown signia", "crown")
          .replace("gr supra", "supra")
          .replace(/\s/g, "-")
      : undefined

  const seriesImage =
    images[seriesNameLower]?.asset?.gatsbyImageData?.images?.fallback?.src

  const { trackTealEvent } = useTealiumEvent()

  return (
    <div>
      {showHeading ? (
        <FormHeading
          showFormHeading={true}
          formHeading={formHeading()}
          // css={!active && tw`opacity-0 z-0`}
        />
      ) : null}
      {/* {seriesName ? (
        <div css={[tw`py-4 rounded-lg bg-gray-200 mt-8 items-end flex`]}>
          <Image
            imageData={ImageData || defaultImage}
            src={seriesImage || defaultImage}
            alt={seriesName}
            css={[tw`object-cover w-[228px] max-h-[96px]`, tw`lg:(w-full)`]}
          />
        </div>
      ) : null}
      <p css={tw`text-lg font-semibold uppercase`}>
        {seriesName === "I'm not sure"
          ? `No vehicle selected`
          : `${seriesName}`}
      </p>
      <p
        css={[
          // tw`hidden`,
          tw`text-base font-book normal-case block`,
        ]}
      >
        {trim}
      </p>
      {seriesName && (
        <button
          css={[
            // tw`hidden`,
            tw`text-red-400 text-xs py-1.5 z-10 relative inline-block`,
          ]}
          type="button"
          onClick={e => {
            handleClick(e)
            // trackTealEvent({
            //   dropdown_selected: "Change:vehicle",
            // })
          }}
          // analytics-id={`Change:contact form:${reformattedContactView(
          //   activeView
          // )}`}
        >
          {_("Change")}
        </button>
      )} */}
      {seriesName && (
        <div
          css={[
            tw`flex justify-between bg-gray-200 rounded-lg py-4 border border-gray-300`,
            tw`lg:(block w-full mt-4)`,
          ]}
        >
          <div css={[tw`pl-4 font-semibold text-base`, tw`lg:(hidden)`]}>
            {seriesName === "I'm not sure"
              ? `No vehicle selected`
              : `${seriesName}`}
            <br />
            <span
              css={[tw`text-base font-book normal-case block`, tw`lg:(hidden)`]}
            >
              {trim?.length > 30 ? `${trim?.substring(0, 30)}...` : trim}
            </span>
            {seriesName && activeView !== "SelectVehicleView" && (
              <button
                css={[
                  tw`text-red-400 text-xs py-1.5 z-10 relative inline-block`,
                  tw`lg:(hidden)`,
                ]}
                onClick={e => {
                  handleClick(e)
                  trackTealEvent({
                    dropdown_selected: "Change:vehicle",
                  })
                }}
                analytics-id={`Change:contact form:${reformattedContactView(
                  activeView
                )}`}
              >
                {_("Change")}
              </button>
            )}
          </div>
          <Image
            imageData={ImageData || defaultImage}
            src={seriesImage || defaultImage}
            alt={seriesName}
            css={[
              tw`object-cover w-[180px] max-h-[70px]`,
              tw`lg:(w-full max-h-[89px])`,
            ]}
          />
        </div>
      )}
      <span css={[tw`hidden`, tw`lg:(block text-base font-semibold)`]}>
        {seriesName === "I'm not sure"
          ? `No vehicle selected`
          : `${seriesName}`}
      </span>
      <span css={[tw`hidden`, tw`lg:(text-base font-book normal-case block)`]}>
        {trim}
      </span>
      {seriesName && (
        <button
          css={[
            tw`hidden`,
            tw`lg:(text-red-400 text-xs py-1.5 z-10 relative inline-block)`,
          ]}
          onClick={e => {
            handleClick(e)
            trackTealEvent({
              dropdown_selected: "Change:vehicle",
            })
          }}
          analytics-id={`Change:contact form:${reformattedContactView(
            activeView
          )}`}
        >
          {_("Change")}
        </button>
      )}
    </div>
  )
}
