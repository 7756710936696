import React, { useState, useRef, useEffect, useContext } from "react"
import tw from "twin.macro"
import { Card } from "../../../atoms/Card"
import {
  ActiveFeature,
  DesktopDescriptionProps,
  FeatureCategory,
  FeaturesProps,
} from "./Features.d"
import { motion } from "framer-motion"
import Icon from "../../../atoms/Icon"
import useScrollSpy from "../../../../hooks/useScrollSpy"
import { Image } from "../../../atoms/Image"
import { SeriesPageContext } from "../../../../templates/series"
import { LanguageContext } from "../../../../contexts/Language"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { parseDisclaimerBlocks } from "../../../../helpers"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"

/**
 * Features section on the series page
 * @author Tyler
 * @returns <section></section>
 *
 * @todo Set height to viewport height
 */

const Features: React.FC<FeaturesProps> = ({
  // sectionSlug,
  ariaLabel,
  featureCategories,
  margins,
}) => {
  const { series } = useContext(SeriesPageContext)

  // const { current: slug } = sectionSlug

  // Add featureSlugs field to featureCategories object
  featureCategories = featureCategories.map((node: any) => {
    return {
      ...node,
      featureSlugs: node.features.map((feature: any) =>
        feature?.title?.replace(/\s+/g, "-").toLowerCase()
      ),
    }
  })

  /**
   * ~~~~~~~~~~~~~~~ STATE & REFS ~~~~~~~~~~~~~~~
   */
  // State for currently active featureCategory
  const [activeTab, setActiveTab] = useState(0)

  // State for currently active feature
  const [activeFeature, setActiveFeature] = useState(0)

  // State for description container height
  const [descriptionIsExpanded, setDescriptionIsExpanded] = useState(false)

  // State for swapping out fullDescription with excerptDescription
  const [ellipsisIsActive, setEllipsisIsActive] = useState(true)

  // State to control how featured images are rendered based on screen width in DOM
  // Rendered based on scroll position in desktop, based on selected feature in mobile
  const [mobileView, setMobileView] = useState(false)

  // State to store currently active feature elements (childNodes pulled from featuresContainerRef)
  const [currentlyDisplayedFeatures, setCurrentlyDisplayedFeatures] =
    useState(null)

  // Ref on overflow-y-scroll container
  const containerRef = useRef<HTMLElement>(null)

  // Ref on features container to pull features elements for useScrollSpy
  const featuresContainerRef = useRef<HTMLElement>(null)

  // Pass feature elements to features state each time activeTab changes (i.e. new featureCategory is selected by user)
  useEffect(() => {
    // Convert HTMLCollection to an array and pass it to features state
    setCurrentlyDisplayedFeatures(
      Array.from(featuresContainerRef?.current?.childNodes)
    )
    // Scroll features container to top when tab changes
    containerRef.current.scrollTop = 0
  }, [activeTab])

  // Detect screen width in DOM
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1024px)")
    setMobileView(mediaQuery.matches || false)
    mediaQuery.addEventListener("change", e => {
      const mobileViewOn = e.matches
      setMobileView(mobileViewOn)
    })
  }, [])

  /**
   * ~~~~~~~~~~~~~~~ VARIABLES ~~~~~~~~~~~~~~~
   */
  // Currently active features
  const activeFeatures = featureCategories[activeTab]?.features

  // Full feature description (for desktop view and mobile expanded state)
  const fullDescription =
    featureCategories[activeTab]?.features[activeFeature]?.description

  // Trunctuated description for mobile view
  const excerptDescription =
    fullDescription?.length > 100
      ? `${fullDescription?.substring(0, 100).trimEnd()}...`
      : fullDescription

  // Hook returns the index of the currently in-view feature
  const [inViewSection] = useScrollSpy(currentlyDisplayedFeatures, {
    root: containerRef.current, // Set root as the parent container
    rootMargin: "-49% 0px -50% 0px",
  })

  // Render image associated with the scrolled/in view feature
  const imageData = mobileView
    ? featureCategories[activeTab]?.features[activeFeature]?.image?.image
    : featureCategories[activeTab]?.features[inViewSection]?.image?.image

  // Currently active image alt
  const imageAlt =
    featureCategories[activeTab]?.features[inViewSection]?.image?.alt

  return (
    <section
      ref={containerRef}
      id={"features"}
      aria-label={ariaLabel}
      css={[
        tw`relative grid grid-cols-2 auto-rows-min min-h-[600px] h-[calc(100vh - 126px)]`,
        tw`lg:(min-h-[900px] max-h-[calc(100vh - 100px)] h-full overflow-hidden bg-gray-50)`,
        // Empty filler div
        tw`after:(col-start-1 col-end-3 row-start-4 row-end-5 h-28)`,
        tw`after:(lg:(hidden))`,
        `margin: ${margins?.top}px 0 ${margins?.bottom}px !important;`,
      ]}
    >
      {/* CATEGORY TABS */}
      <nav
        css={[
          tw`flex col-start-1 col-end-3 row-start-1 row-end-2 overflow-x-scroll scrollbar-hide h-12 bg-black text-white z-30`,
          tw`lg:(justify-center)`,
        ]}
        aria-label={`${series?.name} features`}
      >
        {featureCategories?.map((node: FeatureCategory, index: number) => (
          <button
            key={index}
            css={[
              tw`relative py-3 px-7 text-sm font-semibold`,
              tw`focus-visible:(border-white border-dashed border outline-none)`,
            ]}
            onClick={() => setActiveTab(index)}
          >
            {activeTab === index && (
              <motion.div
                layoutId="underline"
                css={[tw`w-full h-1 absolute top-0 left-0 bg-red-400`]}
              />
            )}
            {node?.title}
          </button>
        ))}
      </nav>
      {/* FEATURE CARDS */}
      <section
        css={[
          tw`flex gap-4 col-start-1 col-end-3 row-start-2 row-end-3 px-4 pb-6 mt-3 overflow-x-scroll h-[fit-content] z-10 justify-start`,
          tw`lg:(row-start-3 row-end-4 justify-center)`,
        ]}
      >
        {activeFeatures?.map((node: FeatureCategory, index: number) => (
          <motion.a
            onClick={() => {
              setActiveFeature(index)
              currentlyDisplayedFeatures[index].scrollIntoView({
                block: "nearest",
              })
            }}
            key={`${activeTab}-${index}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            css={[tw`cursor-pointer`]}
          >
            <Card
              shadowLevel={3}
              css={[
                tw`relative w-44 h-full text-left shadow-lg leading-5 font-semibold text-[#7f7f7f] bg-white transition-all duration-300`,
                tw`lg:(text-lg w-60)`,
                tw`hover:shadow-5`,
                activeFeature === index && tw`text-black lg:text-[#7f7f7f]`,
                inViewSection === index && tw`lg:text-black`,
              ]}
            >
              {node?.title}
              <Icon.Chevron
                direction="right"
                color="red-400"
                css={[tw`absolute bottom-5 right-5 h-4`]}
              />
            </Card>
          </motion.a>
        ))}
      </section>

      {/* BACKGROUND IMAGE */}
      {imageData && (
        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          css={[
            tw`col-start-1 col-end-3 row-start-2 row-end-4 z-0 min-h-[calc(100vh - 292px)]`,
            tw`lg:(col-end-2 row-start-1 row-end-3)`,
          ]}
        >
          <Image imageData={imageData} css={[tw`object-cover h-full`]} />
        </motion.section>
      )}

      {/* DESCRIPTION CARD */}
      <motion.article
        css={[
          tw`block visible relative col-start-1 col-end-3 row-start-3 row-end-5 self-end bg-white h-28 w-full leading-[1.375rem] rounded-tl-xl rounded-tr-xl z-50`,
          tw`lg:( hidden invisible row-end-4 )`,
        ]}
        variants={{
          expandHeight: {
            height: "auto",
          },
          shrinkHeight: {
            height: "7rem",
          },
        }}
        animate={descriptionIsExpanded ? "expandHeight" : "shrinkHeight"}
        transition={{ duration: 0.5, type: "spring" }}
        // Add ellipsis when description shrinks. This is done onAnimationComplete
        // as we're animating height and want the animation to finish before changing
        // the size of the container.
        onAnimationComplete={() =>
          !descriptionIsExpanded && setEllipsisIsActive(true)
        }
      >
        <div css={[tw`px-8 py-6 max-h-[400px] h-full overflow-y-scroll`]}>
          {ellipsisIsActive ? (
            <motion.div
              key={excerptDescription}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              {excerptDescription}
            </motion.div>
          ) : (
            fullDescription
          )}
        </div>

        {/* CHEVRON BUTTON */}
        <motion.button
          onClick={() => {
            setDescriptionIsExpanded(prev => !prev)
            // Remove ellipsis when description expands. This is done on the onClick as we're
            // animating height and want the container to expand before initializing the animation.
            !descriptionIsExpanded && setEllipsisIsActive(false)
          }}
          variants={{
            down: {
              rotate: 180,
            },
            up: {
              rotate: 0,
            },
          }}
          animate={descriptionIsExpanded ? "down" : "up"}
          css={[
            tw`absolute top-[-16px] right-[calc(50% - 16px)] bg-red-400 rounded-full h-8 w-8 shadow-3 z-30`,
          ]}
        >
          <Icon.Chevron
            direction="up"
            color="white"
            css={[tw`h-[10px] mx-auto`]}
          />
        </motion.button>
      </motion.article>
      <DesktopDescription
        activeFeatures={activeFeatures}
        featuresContainerRef={featuresContainerRef}
      />

      {/* SCROLL INDICATORS */}
      <nav
        css={[
          tw`invisible absolute top-[calc(50% - 128px)] right-10`,
          tw`lg:(visible)`,
        ]}
        aria-label="Nav features"
      >
        {activeFeatures?.map((node: ActiveFeature, index: number) => (
          <button
            css={[
              tw`block`,
              tw`focus-visible:(border-black border-dashed border outline-none)`,
            ]}
            onClick={() => {
              currentlyDisplayedFeatures[index].scrollIntoView({
                block: "nearest",
              })
            }}
          >
            <div
              css={[
                tw`rounded-full border w-3 h-3 m-2 transition-colors duration-500 z-50`,
                inViewSection === index
                  ? tw`border-red-400`
                  : tw`border-gray-500`,
              ]}
            ></div>
          </button>
        ))}
      </nav>
    </section>
  )
}

const DesktopDescription: React.FC<DesktopDescriptionProps> = ({
  activeFeatures,
  featuresContainerRef,
}) => {
  const { _ } = useContext(LanguageContext)
  const [state, modalDispatch] = useContext(DisclaimersContext)
  return (
    <article
      css={[
        tw`col-start-2 col-end-3 row-start-1 row-end-3 pl-9 pr-24 mt-28 max-h-[calc(100vh - 412px)] h-full overflow-y-scroll scrollbar-hide hidden`,
        tw`lg:(block)`,
        "scroll-behavior: smooth;",
      ]}
    >
      <h2 css={[tw`text-4xl tracking-[6px] font-light text-center mb-12`]}>
        {_("Features")}
      </h2>
      <div ref={featuresContainerRef} css={[tw`pb-40`]}>
        {activeFeatures?.map((node: ActiveFeature) => {
          return (
            <motion.section
              key={node?.description}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              css={[
                tw`pt-8 text-lg leading-6`,
                // Offset position the anchor links scroll to, compensates for the nav bar running across top of element
                // Flagged important because this property is set in global stylesheet to every section with an id value
                // "scroll-margin-top: 150px !important;",
              ]}
            >
              <h3 css={[tw`mb-5`]}>
                {parseDisclaimerBlocks(node?.title, selection =>
                  modalDispatch(toggleDisclaimersModal(selection))
                )}
              </h3>
              <p>
                {parseDisclaimerBlocks(node?.description, selection =>
                  modalDispatch(toggleDisclaimersModal(selection))
                )}
              </p>
            </motion.section>
          )
        })}
      </div>
    </article>
  )
}

export default Features
