import React, { useContext, useEffect, useMemo, useState } from "react"
import { ContactDealerProps, ContactFormUserFlow } from "./ContactDealer.d"
import { Modal } from "../../atoms/Modal"
import tw from "twin.macro"
import { graphql, useStaticQuery } from "gatsby"
import IntroView from "./IntroView"
import ProgressIndicators from "./ProgressIndicators"
import SummaryView from "./SummaryView"
import MethodOfContactView from "./MethodOfContactView"
import SelectVehicleView from "./SelectVehicleView"
import ConfirmZip from "./ConfirmZip"
import DealerLocationView from "./DealerLocationView"
import SelectDealerView from "./SelectDealerView"
import { FavoritesContext } from "../../../contexts/Favorites/context"
import { ContactContext } from "../../../contexts/Contact"
import {
  clearState,
  setActiveView,
  updateField,
} from "../../../contexts/Contact/actions"
import ScheduleAServiceApptView from "./ScheduleAServiceApptView"
import { LocationContext } from "../../../contexts/Location"
import NameView from "./NameView"
import NameValidationView from "./NameValidationView"
import { LanguageContext } from "../../../contexts/Language"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"
import { tealiumNavClick } from "./TealiumHelpers"
import { reformattedContactView } from "../../../helpers"
import { LocalStorageClient } from "../../../clients/LocalStorageClient"
import CheckAvailabilityMethod from "./CheckAvailabilityMethod"
import OptionalCommentsView from "./OptionalCommentsView"
import useVehicleData from "../../../hooks/Tealium/useVehicleData"
import { LeadsClient } from "../../../clients/LeadsClient"
import { Lead } from "../../../clients/LeadsClient.d"
import Sidebar from "./Sidebar"
import CancelConfirmation from "./CancelConfirmation"
import { FormView } from "../../../contexts/Contact/contact.d"
import ContactDealerSummaryView from "./ContactDealerSummaryView"

const ContactDealer: React.FC<ContactDealerProps> = ({
  modalIsOpen,
  setModalIsOpen,
}) => {
  const [{ zip }] = useContext(LocationContext)
  const { language, _ } = useContext(LanguageContext)
  const [submitting, setSubmitting] = useState(false)
  const [sentSuccess, setSentSuccess] = useState(null)
  const [isIosSafari, setIsIosSafari] = useState(false)
  const [{ favoritedDealer }] = useContext(FavoritesContext)
  const [
    {
      contactDealerSelection, // selected dealer passed from clicking Contact Dealer from a dealer
      dealer,
      activeView,
      seriesName,
      trim,
      vehicle,
      gRecaptchaResponse,
      contactType,
      comments,
      leadType,
      contact,
      firstName,
      lastName,
      contactButtonType,
      confirmContactDealerSelection,
    },
    dispatch,
  ] = useContext(ContactContext)

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { contactDealerData, updateContactDealerData, tealPageData } =
    useTealiumContext()
  const [vehicleData] = useVehicleData()
  const [lastActiveView, setLastActiveView] = useState<FormView>("IntroView")

  // Check to see if user answer was "I'm not sure" or translation of.
  const seriesNotKnown = seriesName === _("I'm not sure")
  // POST to leads API
  const onSubmit = async () => {
    setSubmitting(true)

    // set series to null if user selected "I'm not sure"
    const lead: Lead = {
      LeadType: leadType,
      Comments: comments,
      FirstName: firstName,
      LastName: lastName,
      LanguageCode: language,
      DealerName: dealer.Name,
      DealerCode: dealer.DealerCode,
      Model: seriesName ? (seriesNotKnown ? null : seriesName) : "",
      Trim: trim || "",
      ExteriorColor: vehicle?.color?.exterior?.name || "",
      InteriorColor: vehicle?.color?.interior?.name || "",
      Price: vehicle?.msrp || "",
      Year: vehicle?.year || "",
      contact_options: contactType,
      Email: contactType === "email" ? contact : "",
      Phone: contactType === "phone" ? contact : "",
      "g-recaptcha-response": gRecaptchaResponse,
    }
    const sentSuccesfully = await LeadsClient.createLead(lead)
    if (sentSuccesfully) {
      setSubmitting(false)
      setSentSuccess(true)
    } else {
      setSubmitting(false)
      setSentSuccess(false)
    }
  }

  const getUserFlow = (): ContactFormUserFlow => {
    const zipIsConfirmed = LocalStorageClient.read("confirmedZip")
    const dealerIsPreselected = contactDealerSelection || favoritedDealer

    if (
      dealerIsPreselected &&
      contactButtonType === "CheckAvailability" &&
      !confirmContactDealerSelection
    ) {
      return "CheckAvailabilityFlow"
    } else if (leadType === "Coming Soon") {
      return "SelectDealerFlow"
    } else if (dealerIsPreselected && !confirmContactDealerSelection) {
      // Dealer is pre-selected - Contact Dealer button was clicked from a dealer OR a dealer is favorited
      return "DealerPreSelectedFlow"
    } else if (zip && zipIsConfirmed) {
      // Zip is already confirmed - select a Dealer from list of dealers
      return "SelectDealerFlow"
    } else if (zip) {
      // Confirm zip code
      return "ConfirmZipFlow"
    } else {
      // Zip code is null or undefined - get zip code from user
      return "Default"
    }
  }

  // Setting first form view based on user flow and set dealer if preselected.
  useEffect(() => {
    const userFlow = getUserFlow()
    const seriesIsPreSelected =
      leadType === "New Inventory Availability" ||
      leadType === "Model & Trim Availability"
    const preSelectedDealer = contactDealerSelection
      ? contactDealerSelection
      : favoritedDealer
    if (preSelectedDealer) {
      dispatch(updateField("dealer", preSelectedDealer))
    } else {
      dispatch(updateField("dealer", null))
    }

    switch (userFlow) {
      case "CheckAvailabilityFlow":
        dispatch(setActiveView("CheckAvailabilityMethod"))
        return
      case "DealerPreSelectedFlow":
        // Skipping SelectVehicleView if vehicle is preselected
        seriesIsPreSelected
          ? dispatch(setActiveView("NameView"))
          : dispatch(setActiveView("IntroView"))
        return
      case "SelectDealerFlow":
        dispatch(setActiveView("SelectDealerView"))
        return
      case "ConfirmZipFlow":
        dispatch(setActiveView("ConfirmZip"))
        return
      default:
        dispatch(setActiveView("DealerLocationView"))
        return
    }
  }, [modalIsOpen])

  // Tealium initial load view that accounts for different initial views
  useEffect(() => {
    const preSelectedDealer = contactDealerSelection
      ? contactDealerSelection
      : favoritedDealer
    if (
      leadType === "New Inventory Availability" &&
      contactButtonType != "CheckAvailability"
    ) {
      trackTealEvent({
        tealium_event: "contact_dealer",
        contact_question: "Were you interested in a specific vehicle?",
        ...contactDealerData.fromInventory,
      })
    } else if (modalIsOpen && contactButtonType != "CheckAvailability") {
      trackTealEvent({
        tealium_event: "contact_dealer",
        contact_question: "What can we do for you?",
        dealer_code:
          preSelectedDealer?.DealerCode ||
          contactDealerData?.dealerData?.dealer_code ||
          "NOT SET",
        dealer_name:
          preSelectedDealer?.Name ||
          contactDealerData?.dealerData?.dealer_name ||
          "NOT SET",
        ...(tealPageData?.page_type === "inventory vdp" && { ...vehicleData }),
      })
    }
  }, [modalIsOpen])

  // Steps for Progress Indicator
  enum Steps {
    IntroView = 1,
    ConfirmZip = null,
    DealerLocationView = null,
    SelectDealerView = null,
    SelectVehicleView = 2,
    NameView = 3,
    NameValidationView = 3,
    MethodOfContactView = 3,
    // OptionalCommentsView = 5,
    SummaryView = 4,
  }

  // Data from GraphQL query (bottom of page)
  const data = useStaticQuery(Query)

  // Series and model data for VehicleSelectionView
  const seriesData = data?.series?.nodes
  const modelsData = data?.models?.group

  const images = useMemo(() => {
    return data.images?.menuItems
      ?.find((item: any) => item.title === "Vehicles")
      .sections[0]?.familyRef?.reduce((acc: any, item: any) => {
        const family = {}
        item.routes.forEach((route: any) => {
          family[route.route.slug.current] = route.seriesImage?.image
        })
        return { ...acc, ...family }
      }, {})
  }, [data])

  const resetForm = () => {
    setSentSuccess(null)
    dispatch(clearState())
    setModalIsOpen(false)
    setSubmitting(false)
    updateContactDealerData({
      fromInventory: null,
      fromSelection: null,
      dealerData: null,
    })
  }

  const contactInputDetails = [
    {
      label: _("Email"),
      value: "email",
      type: "Email Address",
      input: "email",
      regEx: new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ),
      validationMessage: _("Please enter a valid email address."),
    },
    {
      label: _("Phone"),
      value: "phone",
      type: "Phone Number",
      input: "tel",
      regEx: new RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/),
      validationMessage: _("Please enter a valid phone number."),
    },
  ]

  useEffect(() => {
    if (window === undefined) return
    const originalStyle = window.getComputedStyle(document.body).overflow

    if (modalIsOpen) {
      document.body.style.overflow = "hidden"
      document.body.style.position = "fixed"
      document.body.style.width = "100%"
    } else {
      document.body.style.overflow = originalStyle
      document.body.style.position = ""
      document.body.style.width = ""
    }

    return () => {
      document.body.style.overflow = originalStyle
      document.body.style.position = ""
      document.body.style.width = ""
    }
  }, [modalIsOpen])

  useEffect(() => {
    if (window === undefined) return
    const ua = window.navigator.userAgent
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
    const webkit = !!ua.match(/WebKit/i)
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i)
    setIsIosSafari(iOSSafari)
  }, [])

  return (
    <Modal
      css={[
        tw`w-full overflow-y-scroll h-full max-h-full bg-white`,
        tw`lg:(max-w-[65rem] max-h-[475px] bg-gray-50 overflow-y-visible)`,
        "z-index: 9999;",
        isIosSafari && tw`h-[100dvh] max-h-[100dvh] translate-y-[-42px]`,
      ]}
      preventDefaultClose={true}
      open={modalIsOpen}
      onClose={() => {
        setLastActiveView(activeView)
        dispatch(setActiveView("ConfirmOnCloseView"))
        // resetForm()
        // tealiumNavClick(
        //   activeView === "SelectDealerView"
        //     ? "Select a Dealership to Continue"
        //     : "SelectVehicleView"
        //     ? "Were you interested in a specific vehicle?"
        //     : "What can we do for you?",
        //   "Close",
        //   trackTealEvent,
        //   contactDealerData,
        //   vehicleData
        // )
      }}
      analyticsId={`X:contact form:${reformattedContactView(activeView)}`}
    >
      <form
        css={[
          tw`h-full rounded-lg w-full bg-white lg:(grid grid-cols-12 auto-rows-auto relative px-0 bg-gray-200)`,
        ]}
        id="contactDealer"
      >
        {activeView === "ConfirmOnCloseView" ? (
          <CancelConfirmation
            resetForm={resetForm}
            lastActiveView={lastActiveView}
          />
        ) : null}
        <div css={[tw`rounded-l p-4 bg-white`, tw`lg:(col-span-3)`]}>
          <Sidebar images={images} />
        </div>
        <section
          css={[tw`justify-center mt-14 items-center lg:(relative col-span-9)`]}
        >
          <IntroView
            active={activeView === "IntroView"}
            showFormHeading={false}
            fromContactDealer="true"
          />
          <ScheduleAServiceApptView
            active={activeView === "ScheduleAServiceApptView"}
            resetForm={resetForm}
          />
          <ConfirmZip active={activeView === "ConfirmZip"} />
          <DealerLocationView active={activeView === "DealerLocationView"} />
          <SelectDealerView
            active={activeView === "SelectDealerView"}
            showFormHeading={false}
          />
          <CheckAvailabilityMethod
            showFormHeading={false}
            active={activeView === "CheckAvailabilityMethod"}
            closeModal={() => {
              resetForm()
              setModalIsOpen(false)
            }}
          />
          <SelectVehicleView
            active={activeView === "SelectVehicleView"}
            seriesData={seriesData}
            modelsData={modelsData}
            showFormHeading={false}
          />
          <NameView
            active={activeView === "NameView"}
            contactInputs={contactInputDetails}
            showFormHeading={false}
          />
          <NameValidationView
            showFormHeading={false}
            active={activeView === "NameValidationView"}
            nextPageOverride="SummaryView"
          />
          <MethodOfContactView
            active={activeView === "MethodOfContactView"}
            contactInputs={contactInputDetails}
            showFormHeading={false}
          />
          <OptionalCommentsView
            active={activeView === "OptionalCommentsView"}
            showFormHeading={false}
          />
          <ContactDealerSummaryView
            active={activeView === "SummaryView"}
            submitting={submitting}
            sentSuccess={sentSuccess}
            setSentSuccess={setSentSuccess}
            setModalIsOpen={setModalIsOpen}
            reset={resetForm}
            onSubmit={onSubmit}
            showFormHeading={false}
          />
          {/* Progress indicators and buttons */}
          {Steps[activeView] && (
            <ProgressIndicators view={Steps[activeView]} totalSteps={4} />
          )}
        </section>
      </form>
    </Modal>
  )
}

const Query = graphql`
  query {
    series: allSanitySeries {
      nodes {
        name
        currentYear
      }
    }
    models: allSanityModel {
      group(field: series___name) {
        fieldValue
        nodes {
          name
          year
        }
      }
    }
    images: sanityNavigationHeader(language: { eq: "en" }) {
      menuItems {
        sections {
          ... on SanityVehicles {
            _key
            _type
            familyRef {
              routes {
                route {
                  slug {
                    current
                  }
                }
                seriesImage {
                  alt
                  image {
                    asset {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
        title
      }
    }
  }
`

export default ContactDealer
