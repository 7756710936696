import React, { useContext } from "react"
import tw from "twin.macro"
import { DealerDetailsPageContext } from "../../../templates/dealerDetails"
import SocialMediaIcons from "../../molecules/SocialMediaIcons/SocialMediaIcons"

const DealerSocialNetworks: React.FC = () => {
  const { dealer } = useContext(DealerDetailsPageContext)

  let items = []

  if (dealer.TwitterUrl) {
    items.push({ network: "twitter", url: dealer.TwitterUrl })
  }

  if (dealer.FacebookUrl) {
    items.push({ network: "facebook", url: dealer.FacebookUrl })
  }

  if (dealer.InstagramUrl) {
    items.push({ network: "instagram", url: dealer.InstagramUrl })
  }

  return (
    <SocialMediaIcons
      items={items}
      css={[tw`justify-end sm:justify-end !gap-2`]}
    />
  )
}

export default DealerSocialNetworks
