import React, { useContext, useEffect, useState } from "react"
import { ModelListItemProps } from "./ModelList.d"
import { Image } from "../../../atoms/Image"
import tw from "twin.macro"
import { useInView } from "react-intersection-observer"
import { AnimatePresence, motion, MotionConfig } from "framer-motion"
import { LanguageContext } from "../../../../contexts/Language"
import CheckMarkInCircle from "../../../atoms/Icon/CheckMarkInCircle"

const ModelListItem: React.FC<ModelListItemProps> = ({
  onSelect,
  active,
  modelGroup,
  key,
  analyticsId,
  showConfigurations,
  ...remainingProps
}): JSX.Element => {
  const { _ } = useContext(LanguageContext)
  const { ref, inView } = useInView()
  const model = modelGroup[0]?.model
  const featuredColor = modelGroup[0]?.featuredColor

  // Model images of the configured featured color set in sanity
  const [featuredColorImages, setFeaturedColorImages] = useState(
    model?.colors?.find((color: { exterior: { code: string } }) => {
      if (featuredColor && featuredColor.code) {
        return color.exterior.code === featuredColor.code
      } else {
        return model.colors[0]
      }
    }).exteriorImages
  )

  useEffect(() => {
    setFeaturedColorImages(
      model?.colors?.find((color: { exterior: { code: string } }) => {
        if (featuredColor && featuredColor.code) {
          return color.exterior.code === featuredColor.code
        } else {
          return model.colors[0]
        }
      }).exteriorImages
    )
  }, [modelGroup])

  // set the active image to display
  const [angle, setAngle] = useState(2)

  const vehicleAnimation = {
    hidden: { opacity: 0, position: "absolute", top: 0, left: 0, duration: 1 },
    visible: { opacity: 1, position: "absolute", top: 0, left: 0, duration: 1 },
    exiting: { opacity: 0, position: "absolute", top: 0, left: 0, duration: 1 },
  }

  if (!model) return null

  return (
    <button
      ref={ref}
      onClick={() => onSelect()}
      aria-label={
        active
          ? `${_("Current Model")}: ${model.name}`
          : `${_("Select")} ${model.name}`
      }
      aria-selected={active}
      className="ModelConfig"
      css={[
        tw`rounded-t-xl pt-4 px-3 md:(rounded-t-3xl text-center p-4 pb-0 border border-transparent)`,
        tw`focus-visible:(border-gray-700 border-dashed border outline-none)`,
        active && tw`bg-gray-50 shadow-4`,
      ]}
      key={`tab-button-${key}`}
      analytics-id={analyticsId || null}
      {...remainingProps}
    >
      <>
        <div css={[tw`relative`]}>
          <CheckMarkInCircle
            color="green"
            css={[tw`w-6 opacity-0 absolute top-0`, active && tw`opacity-100`]}
          />
          <div css={[tw`min-h-[85px] max-h-[85px] min-w-[192px]`]}>
            {angle && featuredColorImages && featuredColorImages[angle] && (
              <MotionConfig transition={{ duration: 0.3 }}>
                <AnimatePresence initial={false}>
                  <motion.div
                    key={`${model.name}-${angle}`}
                    variants={vehicleAnimation}
                    animate="visible"
                    initial="visible"
                    exit="exiting"
                  >
                    <Image
                      imageData={
                        active
                          ? featuredColorImages[1]
                          : featuredColorImages[angle]
                      }
                      css={[tw`mx-auto w-48 min-h-[85px] object-contain`]}
                      draggable={false}
                      onMouseEnter={() => {
                        setAngle(1)
                      }}
                      onMouseLeave={() => {
                        setAngle(2)
                      }}
                    />
                  </motion.div>
                </AnimatePresence>
              </MotionConfig>
            )}
          </div>
          <div
            css={[
              tw`-mt-1 mb-4 text-gray-500`,
              active && tw`text-gray-900 scale-100 hover:(scale-100)`,
            ]}
          >
            <span css={[tw`font-semibold h-[23px]`]}>
              {modelGroup.title ? modelGroup.title : model.name}
            </span>
            {showConfigurations ? (
              <span css={[tw`block text-xs h-[16px]`]}>
                {modelGroup.length > 1 &&
                  `${modelGroup.length} ${_("Configurations")}`}
              </span>
            ) : (
              <span css={[tw`block text-xs h-[16px]`]}></span>
            )}
          </div>
        </div>
      </>
    </button>
  )
}

export default ModelListItem
