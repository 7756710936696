import _ from "lodash"
import React, { useContext } from "react"
import { LanguageContext } from "../../../contexts/Language"
import { CheckAvailabilityViewProps } from "./ContactDealer.d"
import FormPage from "./FormPage"
import Icon from "../../atoms/Icon"
import tw from "twin.macro"
import { Link } from "../../atoms/Link"
import { ContactContext } from "../../../contexts/Contact"
import { setActiveView } from "../../../contexts/Contact/actions"
import { LocationContext } from "../../../contexts/Location"
import { tealiumNavClick } from "./TealiumHelpers"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"
import { getPageLoadTime } from "../../../helpers"

const CheckAvailabilityMethod: React.FC<CheckAvailabilityViewProps> = ({
  active,
  closeModal,
  showFormHeading,
}) => {
  const { language, _ } = useContext(LanguageContext)
  const [{ dealer }, dispatch] = useContext(ContactContext)

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { contactDealerData, tealPageData, vehicleTealData } =
    useTealiumContext()

  const options = [
    {
      Icon: Icon.Mail,
      label: _("Message"),
      type: "button",
      onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        dispatch(setActiveView("NameView"))
        tealiumNavClick(
          "How would you like to check the availability of this vehicle?",
          "Message",
          trackTealEvent,
          contactDealerData,
          {},
          null,
          contactDealerData?.dealerData
        )
        trackTealEvent({
          tealium_event: "contact_dealer_rest",
          ...contactDealerData.fromInventory,
          ...contactDealerData.fromSelection,
          ...contactDealerData.dealerData,
          ...contactDealerData.allocatedDealer,
        })
      },
    },
    {
      Icon: Icon.ChatBubble,
      label: _("Chat Now"),
      type: "button",
      onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        window.ActivEngage.launchChat({
          vehicleVin: "",
          vehicleYear: "",
          vehicleMake: "Toyota",
          vehicleModel: "",
          vehicleStatus: "",
          launchSource: "SRP",
        })
        window?.ActivEngage?.data?.setChatData({
          geolocation_zipcode: tealPageData?.geolocation_zip || null,
          dealer_name: dealer?.Name || null,
          dealer_code: dealer?.DealerCode || null,
          vehicle_name:
            contactDealerData?.fromInventory?.vehicle_model_truncated || null,
          vehicle_year: contactDealerData?.fromInventory?.vehicle_year || null,
          vehicle_model: contactDealerData?.fromInventory?.vehicle_trim || null,
          vehicle_trim_id:
            contactDealerData?.fromInventory?.vehicle_trim_id || null,
          page_name: tealPageData?.page_name || null,
          domain: window?.location?.hostname || null,
          url: window?.location?.href || null,
          referrer: document?.referrer || null,
          page_language: tealPageData?.page_language || null,
          vehicle_sale_price:
            contactDealerData?.fromInventory?.vehicle_sale_price || null,
          page_load_time: getPageLoadTime() || null,
          page_type: tealPageData?.page_type || null,
        })
        console.info(
          "Active Engage Chat Data",
          window?.ActivEngage?.data?.getChatData()
        )
        tealiumNavClick(
          "How would you like to check the availability of this vehicle?",
          "Chat Now",
          trackTealEvent,
          contactDealerData,
          {},
          null,
          contactDealerData?.dealerData
        )
        trackTealEvent({
          tealium_event: "chat_srp_vdp",
          ...contactDealerData.fromInventory,
          ...contactDealerData.fromSelection,
          ...contactDealerData?.allocatedDealer,
          ...contactDealerData?.dealerData,
        })
        closeModal()
      },
    },
    {
      Icon: Icon.Phone,
      label: _("Call"),
      type: "link",
      to: `tel:${dealer?.Phone}`,
      onClick: () => {
        tealiumNavClick(
          "How would you like to check the availability of this vehicle?",
          "Call",
          trackTealEvent,
          contactDealerData,
          {},
          null,
          contactDealerData?.dealerData
        )
        trackTealEvent({
          tealium_event: "click_to_call",
          ...contactDealerData.fromInventory,
          ...contactDealerData.fromSelection,
          ...contactDealerData?.allocatedDealer,
          ...contactDealerData?.dealerData,
        })
      },
    },
    {
      Icon: Icon.MapPinWithCircle,
      label: _("Visit"),
      type: "link",
      to: `https://www.google.com/maps/search/?api=1&query=${dealer?.Name} ${dealer?.Address1} ${dealer?.City}, ${dealer?.State} ${dealer?.Zip}`,
      onClick: () => {
        closeModal(),
          tealiumNavClick(
            "How would you like to check the availability of this vehicle?",
            "Visit",
            trackTealEvent,
            contactDealerData,
            {},
            null,
            contactDealerData?.dealerData
          )
        trackTealEvent({
          tealium_event: "get_directions",
          dealer_code: dealer?.DealerCode,
          dealer_name: dealer?.Name,
          ...contactDealerData.fromInventory,
          ...contactDealerData.fromSelection,
          ...contactDealerData.allocatedDealer,
        })
      },
    },
  ]

  if (!active) return null

  return (
    <FormPage
      showFormHeading={showFormHeading}
      active={active}
      showButtons={false}
    >
      <h3
        css={tw`text-center text-2xl font-semibold normal-case max-w-[24rem]`}
      >
        {_("How would you like to check the availability of this vehicle?")}
      </h3>
      <div
        css={[
          tw`grid grid-cols-2 gap-4 w-full items-center justify-center my-6 mx-auto max-w-[20rem]`,
        ]}
      >
        {options.map(({ Icon, onClick, label, type, to }) => (
          <div css={tw`flex items-center justify-center`}>
            {type === "button" ? (
              <button
                onClick={onClick}
                css={tw`text-center`}
                className="group"
                analytics-id={`${label}:contact form:How would you like to check the availability of this vehicle? `}
              >
                <Icon
                  color={null}
                  css={tw`w-14 h-14 mx-auto mb-1.5 stroke-[#000] group-hover:stroke-[#e10a1d] group-focus:stroke-[#e10a1d]`}
                />
                <span css={tw`text-base`}>{label}</span>
              </button>
            ) : (
              <Link
                to={to}
                css={tw`text-center normal-case`}
                onClick={onClick}
                className="group"
                analytics-id={`${label}:contact form:How would you like to check the availability of this vehicle? `}
              >
                <Icon
                  color={null}
                  css={tw`w-14 h-14 mx-auto mb-1.5 stroke-[#000] group-hover:stroke-[#e10a1d] group-focus:stroke-[#e10a1d]`}
                />
                <span css={tw`text-base`}>{label}</span>
              </Link>
            )}
          </div>
        ))}
      </div>
    </FormPage>
  )
}

export default CheckAvailabilityMethod
