import React, { useEffect, useState } from "react"
import tw from "twin.macro"
import { useTealiumContext } from "../../../contexts/Tealium"
import { CTA } from "../../../global"
import { getCTALinkValue } from "../../../helpers"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { ButtonLink } from "../../atoms/Button"
import { PartAndServiceCtaProps } from "../../molecules/Heros/Heros.d"
import { m } from "framer-motion"
import { css } from "@emotion/react"
import useViewportSize from "../../../hooks/useViewportSize"

const PartsAndServiceCta: React.FC<PartAndServiceCtaProps> = ({
  ctas,
  ctasMobile,
  footerBackgroundColorMobile,
  footerBackgroundColorDesktop,
}) => {
  // If ctas is not defined or is an empty array, don't render the component
  if (!ctas || ctas.length === 0) {
    return null
  }

  const { width } = useViewportSize()
  const isMobile = width < 1024
  const isDesktop = width >= 1024
  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData } = useTealiumContext()
  const [showFooterDiv, setShowFooterDiv] = useState(false)
  const [fadeFooterDiv, setFadeFooterDiv] = useState(true)

  const footerMobileBGColor =
    footerBackgroundColorMobile &&
    `
        @media(max-width: 1023px) {
            background-color: rgba(${footerBackgroundColorMobile.rgb?.r}, ${footerBackgroundColorMobile.rgb?.g}, ${footerBackgroundColorMobile.rgb?.b}, ${footerBackgroundColorMobile.rgb?.a})
        }
    `
  const footerDesktopBGColor =
    footerBackgroundColorDesktop &&
    `
        @media(min-width: 1023px) {
            background-color: rgba(${footerBackgroundColorDesktop.rgb?.r}, ${footerBackgroundColorDesktop.rgb?.g}, ${footerBackgroundColorDesktop.rgb?.b}, ${footerBackgroundColorDesktop.rgb?.a})
        }
    `
  // BOTTOM CTA BOX
  useEffect(() => {
    const handleScroll = () => {
      const y = window.pageYOffset
      setShowFooterDiv(y > 600)
      setFadeFooterDiv(y > 650)
    }

    window.addEventListener("scroll", handleScroll)

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <>
      {showFooterDiv && (
        <div
          css={[
            tw`fixed bottom-0 z-50 border-0 bg-transparent right-0 left-0 transition-all duration-500`,
            tw`lg:(fixed bottom-0 z-50 border-0 bg-transparent right-0)`,
            fadeFooterDiv
              ? tw`transition-all duration-500 opacity-100`
              : tw`transition-all duration-500 opacity-0`,
          ]}
        >
          <article css={[tw`justify-start w-full`]}>
            <div
              css={[
                tw`bg-black px-2 py-4 mt-6 flex`,
                footerMobileBGColor,
                tw`lg:(w-full shadow-4 px-8)`,
                footerDesktopBGColor,
              ]}
            >
              {/* CTAs */}
              {ctas && (
                <div css={[tw`flex items-center gap-4`]}>
                  {isMobile &&
                    ctasMobile?.map((cta: CTA) => {
                      const link = getCTALinkValue(cta)
                      return (
                        <ButtonLink
                          to={link}
                          target={
                            cta?.linkType === "external" ? "_blank" : "_self"
                          }
                          key={cta?.title}
                          {...{ [cta?.buttonType]: true }}
                          analytics-id={
                            link === "/dealers"
                              ? "dealers:hero"
                              : `${cta?.title}:opps box:`
                          }
                          //Cta button type = secondary change background color to white 90% opacity  and text color to black
                          css={[
                            cta?.buttonType === "secondary" &&
                              tw`bg-white bg-opacity-90 text-black`,
                            css`
              @media (min-width: 300px) {
                ${tw`text-xs`}

              @media (min-width: 400px) {
                ${tw`text-sm`}
              }
            `,
                          ]}
                          onClick={() => {
                            trackTealEvent({
                              tealium_event: "cta_click",
                              coupon_module_text: cta?.title,
                              link_href: `${link}`,
                            })
                          }}
                        >
                          {cta?.title}
                        </ButtonLink>
                      )
                    })}
                  {isDesktop &&
                    ctas?.map((cta: CTA) => {
                      const link = getCTALinkValue(cta)
                      return (
                        <ButtonLink
                          to={link}
                          target={
                            cta?.linkType === "external" ? "_blank" : "_self"
                          }
                          key={cta?.title}
                          {...{ [cta?.buttonType]: true }}
                          analytics-id={
                            link === "/dealers"
                              ? "dealers:hero"
                              : `${cta?.title}:opps box:`
                          }
                          //Cta button type = secondary change background color to white 90% opacity  and text color to black
                          css={[
                            cta?.buttonType === "secondary" &&
                              tw`bg-white bg-opacity-90 text-black`,
                            css`
              @media (min-width: 300px) {
                ${tw`text-xs`}

              @media (min-width: 400px) {
                ${tw`text-sm`}
              }
            `,
                          ]}
                          onClick={() => {
                            trackTealEvent({
                              tealium_event: "cta_click",
                              coupon_module_text: cta?.title,
                              link_href: `${link}`,
                            })
                          }}
                        >
                          {cta?.title}
                        </ButtonLink>
                      )
                    })}
                </div>
              )}
            </div>
          </article>
        </div>
      )}
    </>
  )
}

export default PartsAndServiceCta
