import React, { useContext, useEffect, useState } from "react"
import { IndicatorProps, NewCarouselProps } from "./NewCarousel.d"
import { motion, AnimatePresence } from "framer-motion"
import { wrap } from "popmotion"
import useInterval from "../../hooks/useInterval"
import tw from "twin.macro"
import { css } from "@emotion/react"
import Icon from "../atoms/Icon"
import useTealiumEvent from "../../hooks/Tealium/useTealiumEvent"
import { HeaderContext } from "../../contexts/Header"

/**
 * Carousel component with variants for each carousel style across the codebase. New behavior can be passed inside the variants prop.
 *
 * @author Tyler
 * @param {Object[]} slides - An array of objects containing the content of each slide
 * @param {boolean} continuous - Whether the carousel should loop back to the first slide when the last slide is reached
 * @param {boolean} hero - Whether the carousel should be a hero carousel
 * @param {boolean} centered - Whether the carousel should be centered
 * @param {number} activeSlide - The index of the slide that is currently active
 * @param {string} linkUrl - Optional link url. When provided, slides will be a link to this url.
 * @param {Dispatch<React.SetStateAction<number>>} setActiveSlide - A function that sets the active slide index
 * @returns {JSX.Element} - A carousel of slides
 */

const NewHeroCarousel = ({
  id,
  slides,
  continuous = false,
  hero = false,
  inventory = false,
  inventoryData,
  centered,
  mobileButtons,
  activeSlide,
  linkUrl,
  setActiveSlide,
  children,
  section,
  analyticsId,
  chevronPosition = "inside",
  tealIncludeLabel,
  ...remainingProps
}: NewCarouselProps): JSX.Element => {
  const [[slide, direction], setSlide] = useState([0, 0])

  const [isHovered, setIsHovered] = useState(false)
  const { toggleMenu } = useContext(HeaderContext)

  const timeout = 8000
  //adding this to prevent the content from shifting when the menu is open and the carousel changes slides
  useEffect(() => {
    if (toggleMenu) {
      setIsHovered(true)
    } else {
      setIsHovered(false)
    }
  }, [toggleMenu])

  const variants = {
    enter: (direction: number) => {
      return {
        x: inventory ? 0 : direction > 0 ? 1000 : -1000,
        opacity: 0,
      }
    },
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: inventory ? 0 : direction < 0 ? 1000 : -1000,
        opacity: 0,
      }
    },
  }

  const slideIndex =
    continuous && slides?.length > 1 ? wrap(0, slides.length, slide) : slide

  const changeSlide = (direction: number) => {
    setSlide([slide + direction, direction])
  }

  const swipeConfidenceThreshold = 10000

  const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity
  }

  useInterval(
    () => {
      if (hero && slides.length > 1) changeSlide(1)
    },
    timeout,
    isHovered
  )

  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  const handleTealiumEvent = (section: string, action: string) => {
    trackTealEvent({
      tealium_event: "carousel_click",
      carousel_action: `${tealIncludeLabel ? `${section}|` : ""}${action}`,
    })
  }

  // Hero type
  if (hero)
    return (
      <>
        <section
          id={id}
          aria-label="Hero Carousel Section"
          css={[
            tw`relative scrollbar-hide overflow-y-hidden`,
            `&:hover {
            .button {
              opacity: 1;
            }
          }`,
          ]}
          {...remainingProps}
        >
          {slides?.length > 1 ? (
            <AnimatePresence initial={false} custom={direction}>
              <motion.div
                key={slide}
                custom={direction}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  x: { type: "spring", stiffness: 300, damping: 30 },
                  opacity: { duration: 0.2 },
                }}
                drag="x"
                dragConstraints={{ left: 0, right: 0 }}
                dragElastic={1}
                onDragEnd={(e, { offset, velocity }) => {
                  const swipe = swipePower(offset.x, velocity.x)

                  if (swipe < -swipeConfidenceThreshold) {
                    changeSlide(1), handleTealiumEvent(section, "right drag")
                  } else if (swipe > swipeConfidenceThreshold) {
                    changeSlide(-1), handleTealiumEvent(section, "left drag")
                  }
                }}
                onMouseOver={() => setIsHovered(true)}
                onMouseOut={() => setIsHovered(false)}
                css={[tw`absolute top-0 left-0 w-full h-full`]}
              >
                {children({ slideIndex })}
              </motion.div>
            </AnimatePresence>
          ) : (
            <>{children({ slideIndex })}</>
          )}
          {slides.length > 1 && (
            <div css={[tw`absolute bottom-4 left-1/2 -translate-x-1/2 flex`]}>
              {slides.map((node, i) => (
                <Indicator
                  key={node.id}
                  active={slideIndex === i}
                  timeout={timeout}
                  isHovered={isHovered}
                />
              ))}
            </div>
          )}
          {slides.length > 1 && <Buttons changeSlide={changeSlide} />}
        </section>

        {mobileButtons && (
          <section
            css={[
              tw`flex justify-center items-center gap-2 mt-8`,
              tw`lg:(hidden)`,
            ]}
          >
            <button
              css={[
                tw`flex justify-center items-center cursor-pointer w-16 h-10 bg-black rounded-full`,
              ]}
              className="button"
              onClick={() => {
                changeSlide(-1), handleTealiumEvent("hero", "left arrow")
              }}
              aria-label="Previous slide"
            >
              <Icon.Chevron direction="left" color="white" css={[tw`h-5`]} />
            </button>

            <button
              css={[
                tw`flex justify-center items-center cursor-pointer w-16 h-10 bg-black rounded-full`,
              ]}
              className="button"
              onClick={() => {
                changeSlide(1), handleTealiumEvent("hero", "right arrow")
              }}
              aria-label="Next slide"
            >
              <Icon.Chevron direction="right" color="white" css={[tw`h-5`]} />
            </button>
          </section>
        )}
      </>
    )
}

const Buttons = ({ changeSlide }: any) => {
  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  const handleTealiumEvent = (section: string, action: string) => {
    trackTealEvent({
      tealium_event: "carousel_click",
      carousel_action: `${section}|${action}`,
    })
  }

  return (
    <>
      <button
        css={[
          tw`hidden absolute left-4 top-1/2 -translate-y-1/2 z-50 justify-center items-center cursor-pointer w-16 h-10 bg-white rounded-full opacity-0 transition-all hover:bg-gray-400 focus-visible:(bg-gray-400 opacity-100)`,
          tw`lg:(flex)`,
        ]}
        className="button"
        onClick={() => {
          changeSlide(-1), handleTealiumEvent("hero", "left arrow")
        }}
        aria-label="Previous slide"
      >
        <Icon.Chevron
          direction="left"
          color="white"
          css={[tw`h-5 lg:(hidden)`]}
        />
        <Icon.Chevron
          direction="left"
          color="black"
          css={[tw`h-5 hidden lg:(block)`]}
        />
      </button>

      <button
        css={[
          tw`hidden`,
          tw`lg:(absolute right-4 top-1/2 -translate-y-1/2 z-50 flex justify-center items-center cursor-pointer w-16 h-10 bg-white rounded-full opacity-0 transition-all hover:bg-gray-400) focus-visible:(bg-gray-400 opacity-100)`,
        ]}
        className="button"
        onClick={() => {
          changeSlide(1), handleTealiumEvent("hero", "right arrow")
        }}
        aria-label="Next slide"
      >
        <Icon.Chevron
          direction="right"
          color="white"
          css={[tw`h-5 lg:(hidden)`]}
        />
        <Icon.Chevron
          direction="right"
          color="black"
          css={[tw`h-5 hidden lg:(block)`]}
        />
      </button>
    </>
  )
}

const Indicator = ({
  key,
  active,
  timeout = 0,
  isHovered,
}: IndicatorProps): JSX.Element => {
  return (
    <div
      key={key}
      css={[tw`py-2 relative mx-1 transition-colors bg-transparent`]}
    >
      <div
        css={[
          tw`bg-gray-200 w-8 h-1 border-gray-200 relative overflow-hidden`,
          `border-width: 0.025rem;`,
          active && tw`bg-transparent border-toyotaRed`,
        ]}
      >
        {active ? (
          <span
            css={[
              tw`bg-toyotaRed border-r-2 border-toyotaRed h-full absolute left-0 top-0 bottom-0 w-full`,
              css`
                animation: progressBar ${timeout}ms linear forwards;
                @keyframes progressBar {
                  /* Note:  Animating Width leaves a stutering effect.  Use use Traslate X */
                  from {
                    transform: translateX(-100%);
                  }
                  to {
                    transform: translateX(0%);
                  }
                }
              `,
              isHovered && `animation-play-state: paused;`,
            ]}
            role="presentation"
            className="progress-completed"
          />
        ) : null}
      </div>
    </div>
  )
}

export default NewHeroCarousel
