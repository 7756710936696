import React, { useContext } from "react"
import {
  PartAndServiceHeadlineProps,
  Breadcrumb,
} from "./PartAndServiceHeadline.d"
import { Breadcrumbs } from "../../atoms/Breadcrumbs"
import tw from "twin.macro"
import { CTA, Color } from "../../../global"
import { getCTALinkValue, parseDisclaimerBlocks } from "../../../helpers"
import { ButtonLink } from "../../atoms/Button"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import Headline from "./Headline"
import GenericBreadcrumbs from "../../atoms/Breadcrumbs/GenericBreadcrumbs"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"

/**
 * Content block
 *
 * @component
 * @param {Breadcrumb[]} breadcrumbs
 * @param {string} headlineType - determines the style of heading returned. Current cms options are textHeadline, iconWithTextHeadline, OR logoHeadline.
 * @param {string} textHeadline - text heading that's displayed when headlineType === textHeadline OR headlineType === iconWithTextHeadline
 * @param {string} iconHeadline - name of the icon that will be displayed when headlineType === iconWithTextHeadline
 * @param {string} logoHeadline - name of the logo that will be displayed when the headlineType === logoHeadline
 * @param {string} logoText - Optional text to be displayed when the ToyotaSymbol logo is selected. Text will be displayed to the right of the ToyotaSymbol.
 * @param {string} description
 * @param {CTA[]} ctas
 * @param {string} finePrint
 * @param {RGBAColor} contentBackgroundColor
 * @param {RGBAColor} contentTextColor
 *
 * @returns <div></div>
 */

const PartAndServiceHeadline: React.FC<PartAndServiceHeadlineProps> = ({
  breadcrumbs,
  headlineType,
  textHeadline,
  iconHeadline,
  logoHeadline,
  logoText,
  description,
  ctas,
  finePrint,
  contentBackgroundColor,
  contentTextColor,
  ...remainingProps
}) => {
  const [{}, dispatch] = useContext(DisclaimersContext)
  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  const breadcrumbItems = breadcrumbs?.map(breadcrumb => {
    return {
      label: breadcrumb?.label,
      to: `/${breadcrumb?.link?.slug?.current}`,
      isCurrentPage: breadcrumb?.isCurrentPage,
    }
  })
  const backgroundColor =
    contentBackgroundColor &&
    `
        @media(min-width: 1023px) {
            background-color: rgba(${contentBackgroundColor.rgb?.r}, ${contentBackgroundColor.rgb?.g}, ${contentBackgroundColor.rgb?.b}, ${contentBackgroundColor.rgb?.a})
        }
    `
  const textColor =
    contentTextColor &&
    `
        @media(min-width: 1023px) {
            color: rgba(${contentTextColor.rgb?.r}, ${contentTextColor.rgb?.g}, ${contentTextColor.rgb?.b}, ${contentTextColor.rgb?.a})
        }
    `

  return (
    <div
      css={[
        tw`bg-gunmetal text-white py-8 px-6`,
        tw`sm:(bg-gunmetal text-white py-8 px-6)`,
        tw`lg:(max-w-[23rem] bg-transparent rounded-lg p-6)`,
        tw`xl:(max-w-md)`,
        backgroundColor && backgroundColor,
        textColor && textColor,
      ]}
      {...remainingProps}
    >
      {/* Breadcrumbs */}
      {breadcrumbItems?.length > 0 && (
        <GenericBreadcrumbs items={breadcrumbItems} css={tw`mb-2`} />
      )}
      <div css={tw`flex flex-col gap-4`}>
        {/* Headline */}
        <Headline
          headlineType={headlineType}
          textHeadline={textHeadline}
          iconHeadline={iconHeadline}
          logoHeadline={logoHeadline}
          logoText={logoText}
          color={contentTextColor?.hex}
          contentTextColor={contentTextColor}
        />
        {/* Description */}
        {description && (
          <p>
            {parseDisclaimerBlocks(description, selection =>
              dispatch(toggleDisclaimersModal(selection))
            )}
          </p>
        )}
        {/* CTAs */}
        {ctas && (
          <div css={[tw`flex items-center gap-4`]}>
            {ctas?.map((cta: CTA) => {
              const link = getCTALinkValue(cta)
              return (
                <ButtonLink
                  to={link}
                  target={cta?.linkType === "external" ? "_blank" : "_self"}
                  key={cta?.title}
                  {...{ [cta?.buttonType]: true }}
                  analytics-id={
                    link === "/dealers"
                      ? "dealers:hero"
                      : `${cta?.title}:opps box:`
                  }
                  //Cta button type = secondary change background color to white 90% opacity  and text color to black
                  css={[
                    cta?.buttonType === "secondary" &&
                      tw`bg-white bg-opacity-90 text-black`,
                  ]}
                  onClick={() => {
                    trackTealEvent({
                      tealium_event: "cta_click",
                      coupon_module_text: cta?.title,
                      link_href: `${link}`,
                    })
                  }}
                >
                  {cta?.title}
                </ButtonLink>
              )
            })}
          </div>
        )}
        {/* Fine Print */}
        {finePrint && (
          <p css={tw`text-xs`}>
            {parseDisclaimerBlocks(finePrint, selection =>
              dispatch(toggleDisclaimersModal(selection))
            )}
          </p>
        )}
      </div>
    </div>
  )
}

export default PartAndServiceHeadline
