import React, { useEffect } from "react"
import { SlideOverProps } from "./SlideOver.d"
import tw from "twin.macro"
import { X } from "../Icon"
import { Button } from "../Button"

/**
 * SlideOver/Drawer comp
 *
 * @component
 * @author Tyler
 * @summary - Slides over from the right.
 * s
 * @param {boolean} active - Controls the open/closed state of the comp
 * @param {Function} setActive - Allows active state to be controlled within comp (i.e. the close button)
 * @param {boolean} noCloseButton - Flag to remove default close button
 * @param {boolean} menuType - Flag to change positioning to absolute, suited for use case in mobile menu or when absolute positioning is required
 * @param children
 * @param remainingProps
 * @returns <section></section>
 */

const SlideOver: React.FC<SlideOverProps> = ({
  active,
  setActive,
  noCloseButton,
  menuType = false,
  children,
  ...remainingProps
}) => {
  useEffect(() => {
    if (active) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [active])

  return (
    <section
      css={[
        tw`h-full max-w-xl p-4 top-0 right-0 transition-transform ease-out duration-500 bg-white pb-6 z-[99999]`,
        active ? tw`translate-x-0` : tw`translate-x-full`,
        menuType ? tw`absolute` : tw`fixed`,
      ]}
      {...remainingProps}
    >
      {!noCloseButton && (
        <Button
          onClick={() => setActive(!active)}
          css={[
            tw`bg-red-400 ml-auto mb-5 mt-16 cursor-pointer rounded-lg p-2 flex z-50`,
          ]}
        >
          <X color="white" css={[tw`w-4`]} />
        </Button>
      )}
      <div css={[tw`h-full scrollbar-hide`]}>{children}</div>
    </section>
  )
}

export default SlideOver
