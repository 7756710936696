import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { TabbedImageModuleProps, TabImageModule } from "./TabbedImageModule.d"
import { Image } from "../../atoms/Image"
import Icon from "../../atoms/Icon"
import { ButtonLink } from "../../atoms/Button"
import { CTA } from "../../../global"
import { parseDisclaimerBlocks, getCTALinkValue } from "../../../helpers"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import {
  setActiveDisclaimer,
  toggleDisclaimersModal,
} from "../../../contexts/Disclaimers/actions"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"
import { SeriesPageContext } from "../../../templates/series"
// import { Features } from "../Features"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const TabbedImageModule: React.FC<TabbedImageModuleProps> = ({
  features,
  sectionTitle,
  ctas,
  index = 0,
  model,
}) => {
  const [selectedFeature, setSelectedFeature] = useState<TabImageModule>()
  const [state, dispatch] = useContext(DisclaimersContext)
  const { vehicle, series } = useContext(SeriesPageContext)
  const { vehicleTealData } = useTealiumContext()

  // Vehicle data
  const seriesName = series?.name
  const vehicleYear = vehicle?.year

  useEffect(() => {
    setSelectedFeature(features[0])
  }, [])

  const { trackTealEvent } = useTealiumEvent()

  return (
    <section
      css={[
        tw`col-span-2 px-6 w-full bg-gradient-to-b bg-gray-100 to-white py-12`,
        tw`md:(px-8)`,
        tw`lg:(mx-auto px-4)`,
      ]}
      aria-label="Tabbed Image Module Section"
    >
      {sectionTitle && (
        <div
          css={[
            tw`justify-self-center text-3xl font-light tracking-widest mb-10 text-center`,
            tw`md:(text-5xl)`,
          ]}
        >
          {parseDisclaimerBlocks(sectionTitle, selection =>
            dispatch(toggleDisclaimersModal(selection))
          )}
        </div>
      )}
      <div
        css={[
          tw`overflow-auto overflow-x-scroll z-30 max-w-7xl mx-auto scrollbar-hide`,
          tw`md:(z-10)`,
        ]}
      >
        <div css={[tw`flex flex-grow gap-4 justify-between`]}>
          {features.map((feature: TabImageModule) => {
            return (
              <div css={[tw`flex flex-col items-center`]}>
                {" "}
                {/* Added a container div with flex column direction */}
                {feature?.showFeaturedImageInTab &&
                feature?.featureImage?.image ? (
                  <span
                    css={[
                      selectedFeature && selectedFeature.name === feature?.name
                        ? tw`bg-transparent` // Conditionally apply bg-gray-900
                        : tw`bg-gray-900`,
                      tw`rounded-2xl mb-4`,
                      tw`lg:(mb-2)`,
                    ]}
                  >
                    <Image
                      imageData={feature?.featureImage.image}
                      css={[
                        tw`rounded-2xl min-w-[200px] h-[130px] object-cover cursor-pointer opacity-40`,
                        selectedFeature &&
                          selectedFeature.name === feature?.name &&
                          tw`opacity-100`,
                      ]}
                      onClick={() => setSelectedFeature(feature)}
                    />
                  </span>
                ) : (
                  <span
                    css={[
                      tw`min-w-[200px] h-[130px]`,
                      tw`rounded-2xl mb-4 flex items-center justify-center`,
                      tw`lg:(mb-2)`,
                    ]}
                  ></span>
                )}
                <button
                  css={[
                    tw`flex flex-col justify-end items-center text-xs font-semibold uppercase px-8 pt-6 pb-6 text-center z-30 transform duration-300 ease-in-out w-[200px] min-h-[98px]`,
                    tw`md:(text-sm w-[200px] min-h-[98px] pb-6 z-10 mt-2)`,
                    selectedFeature &&
                      selectedFeature.name === feature?.name &&
                      tw`flex-grow-0 px-8 rounded-t-xl bg-white z-20 shadow-4 md:shadow-5`,
                    tw`md:(rounded-t-xl text-center pt-6 pb-6 px-8 )`,
                  ]}
                  onClick={() => setSelectedFeature(feature)}
                >
                  <span
                    css={[
                      tw`inline-block border-b-4 pb-2`,
                      selectedFeature && selectedFeature.name === feature?.name
                        ? tw`border-red-400`
                        : tw`border-transparent`,
                    ]}
                  >
                    {feature?.name}
                  </span>
                </button>
              </div>
            )
          })}
        </div>
      </div>

      {selectedFeature && (
        <>
          <div
            css={[
              tw`relative flex flex-wrap z-20 h-auto p-6 pb-12 shadow-4 rounded-b-xl bg-white`,
              tw`md:(-top-2 gap-14 p-14 shadow-5 max-w-7xl mx-auto rounded-xl)`,
              tw`lg:(gap-24 grid grid-cols-2 grid-rows-1 grid-flow-row w-auto mb-10)`,
            ]}
          >
            <div
              css={[
                tw`text-xl leading-normal col-span-2 order-2 relative min-h-[245px]`,
                tw`lg:( pt-4 col-span-1 order-1 min-h-[400px])`,
                tw`xl:(min-h-[275px])`,
              ]}
            >
              <h3 css={[tw`font-semibold pb-2 mt-6`, tw`lg:(mt-0)`]}>
                {selectedFeature.name}
              </h3>
              {parseDisclaimerBlocks(
                selectedFeature.description,
                (code: string) => {
                  dispatch(setActiveDisclaimer(code))
                  dispatch(toggleDisclaimersModal())
                }
              )}

              {/* CTAs */}
              {ctas && (
                <div
                  css={[tw`mt-6`, tw`md:(pt-5)`, tw`lg:(absolute bottom-1)`]}
                >
                  {ctas?.map((cta: CTA) => {
                    return (
                      <ButtonLink
                        {...{ [cta?.buttonType]: true }}
                        to={getCTALinkValue(cta)}
                        target={
                          cta?.linkType === "external" ? "_blank" : "_self"
                        }
                        css={[
                          tw`w-auto col-span-1 text-xs px-6 whitespace-nowrap`,
                          tw`md:w-auto mr-4`,
                        ]}
                        key={cta?.title}
                        onClick={() => {
                          cta?.title == "View Inventory" ||
                          cta?.title == "Ver Inventario"
                            ? trackTealEvent({
                                tealium_event: "view_inventory_click",
                                vehicle_model_truncated: seriesName,
                                coupon_module_text: cta?.title,
                                vehicle_year: vehicleYear,
                                vehicle_model: seriesName,
                                vehicle_segment_vehicle_page:
                                  vehicleTealData?.vehicle_segment_vehicle_page,
                                link_href: getCTALinkValue(cta),
                              })
                            : null
                        }}
                        analytics-id={
                          cta?.title == "View Inventory" ||
                          cta?.title == "Ver Inventario"
                            ? `inventory:hybrid advantage:${
                                features.indexOf(selectedFeature) + 1
                              }`
                            : null
                        }
                      >
                        {cta?.title}
                      </ButtonLink>
                    )
                  })}
                </div>
              )}
            </div>

            <div
              css={[
                tw`text-center items-center col-span-2 order-1 mt-2`,
                tw`lg:(col-span-1 p-4 mt-0 order-2)`,
              ]}
            >
              {selectedFeature?.featureImage?.image && (
                <Image
                  imageData={selectedFeature?.featureImage?.image}
                  css={[tw`rounded-2xl max-w-full`]}
                />
              )}
            </div>
          </div>
        </>
      )}
    </section>
  )
}
export default TabbedImageModule
