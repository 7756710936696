import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import {
  InteriorGalleryProps,
  InteriorColorAggergate,
  ModelColorInterior,
} from "./InteriorGallery.d"
import { Checkmark } from "../../../atoms/Icon"
import {
  Carousel,
  CarouselItem,
  CarouselNavigation,
} from "../../../atoms/Carousel"
import Image from "../../../atoms/Image/Image"
import {
  parseDisclaimerBlocks,
  stripDisclaimerCodes,
} from "../../../../helpers"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"

import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import { TealiumContext } from "../../../../contexts/Tealium"
import { LanguageContext } from "../../../../contexts/Language"

/**
 * @component
 * @author Scott Daniels
 * @author Matt Carstensen
 * @summary A Gallery that allows the user to view different inteior angles with
 *  all available color swatches
 *
 * @param   {InteriorGalleryProps} props React Props
 * @param   {{colors: ModelColor[]}} props.model indicates whether this component is active
 * @return  {JSX.Element}
 */

const InteriorGallery: React.FC<InteriorGalleryProps> = ({
  model,
}: InteriorGalleryProps) => {
  // An array of the unique interior colors for this vehicle model
  const [interiorColors, setInteriorColors] = useState<
    InteriorColorAggergate[]
  >([])
  // index of selected swatch
  const [selectedInteriorSwatch, setSelectedInteriorSwatch] =
    useState<number>(0)
  const [state, modalDispatch] = useContext(DisclaimersContext)
  const { _ } = useContext(LanguageContext)

  useEffect(() => {
    // Whenever the model or interior fabric selection changes, update the global context for interior_color
    let swatchNumber = selectedInteriorSwatch
    const numberOfSwatches = model?.colors[0]?.interior?.length

    // If trim selection has too few swatches, reset to first swatch
    if (numberOfSwatches <= swatchNumber) {
      swatchNumber = 0
    }
    const interiorSwatch =
      model?.colors[0]?.interior[swatchNumber]?.interior?.title

    updateVehicleTealData({ interior_color: interiorSwatch })
  }, [selectedInteriorSwatch, model])

  useEffect(() => {
    // loop over the available colors and get all unique interiors.
    const uniqueInteriorColors: InteriorColorAggergate[] = Object.values(
      model.colors.reduce(
        (
          // Accumulator, with Aggregate values for each color
          interiorColors: Record<string, InteriorColorAggergate>,
          // current Index
          currentInteriors: { interior: Array<ModelColorInterior> }
        ) => {
          currentInteriors.interior.map((color: ModelColorInterior) => {
            // check if color has already been added to the accumulator
            if (interiorColors[`${color?.interior?.title}`]) return

            // if color has not been added, get the necessary properties
            const unappliedColor: InteriorColorAggergate = {
              name: color?.interior?.title,
              galleryImages: color?.interiorImages,
              thumbnail: color?.interior?.interiorSwatch,
              primaryColor:
                typeof color?.interior?.interiorSwatch?.asset
                  .gatsbyImageData !== "string" &&
                color?.interior?.interiorSwatch?.asset.gatsbyImageData
                  ?.backgroundColor,
            }

            // add the Interior Color to the Accumulator
            interiorColors[`${color?.interior?.title}`] = unappliedColor
          })
          // return the Accumulator
          return interiorColors
        },
        {} as Record<string, InteriorColorAggergate>
      )
    )

    // Store the Aggregated colors in state
    setInteriorColors(uniqueInteriorColors)

    // Check if the last selected index is greater than the last index.
    if (selectedInteriorSwatch > uniqueInteriorColors.length - 1) {
      setSelectedInteriorSwatch(0)
    }
  }, [model, setInteriorColors, setSelectedInteriorSwatch])

  const { trackTealEvent } = useTealiumEvent()
  const { vehicleTealData, updateVehicleTealData } = useContext(TealiumContext)

  const handleTrackEvent = (trackData = {}, vehicleInfo = {}) => {
    trackTealEvent({
      ...trackData,
    })
    updateVehicleTealData({ ...vehicleInfo })
  }

  return (
    <>
      {interiorColors.length && (
        <Carousel
          slides={interiorColors[selectedInteriorSwatch].galleryImages}
          css={[tw`h-56 lg:(h-96 mb-8)`]}
        >
          {({ activeSlide, changeSlide, slides }) => (
            <>
              {/* Gallery Images */}
              {slides?.length > 0 &&
                slides?.map(
                  (slide, i) =>
                    slide && (
                      <CarouselItem active={i === activeSlide}>
                        <Image
                          imageData={slide}
                          css={[
                            tw`h-[12rem] rounded-lg`,
                            tw`md:(h-[354px] w-full)`,
                          ]}
                          objectFit="contain"
                        />
                      </CarouselItem>
                    )
                )}
              {slides?.length === 0 && (
                <div
                  css={[
                    tw`bg-gray-50 flex items-center justify-center rounded-lg mb-4 h-[12rem] md:(h-[354px])`,
                  ]}
                >
                  <p css={[tw`text-gray-400 font-bold text-center`]}>
                    {_("No Images Available")}
                  </p>
                </div>
              )}
              <CarouselNavigation
                direction="prev"
                color="white"
                onClick={() => {
                  changeSlide(activeSlide - 1)
                  handleTrackEvent({
                    features_series_prop: `interior 360|${interiorColors[selectedInteriorSwatch].name}|${vehicleTealData.trim}`,
                    interior_color: `${interiorColors[selectedInteriorSwatch].name}`,
                    vehicle_model: vehicleTealData.vehicle_model,
                  })
                }}
                analytics-id={`left arrow:model list:interior 360`}
                css={[
                  tw`absolute left-1/2 bottom-32 ml-[-4.25em] -mt-8 bg-black z-[51]`,
                  tw`md:(-bottom-14)`,
                ]}
              />
              <CarouselNavigation
                direction="next"
                color="white"
                onClick={() => {
                  changeSlide(activeSlide + 1)
                  handleTrackEvent({
                    features_series_prop: `interior 360|${interiorColors[selectedInteriorSwatch].name}|${vehicleTealData.trim}`,
                    interior_color: `${interiorColors[selectedInteriorSwatch].name}`,
                    vehicle_model: vehicleTealData.vehicle_model,
                  })
                }}
                analytics-id={`right arrow:model list:interior 360`}
                css={[
                  tw`absolute right-1/2 bottom-32 mr-[-4.25em] -mt-8 bg-black z-[51]`,
                  tw`md:(-bottom-14)`,
                ]}
              />

              {/* Swatch Buttons */}
              {interiorColors?.length > 0 && (
                <div
                  css={[
                    tw`flex justify-center w-full absolute bottom-20`,
                    tw`(md:bottom-[-7.5em])`,
                  ]}
                >
                  {interiorColors.map((swatch, i) => {
                    const { name, thumbnail, primaryColor } = swatch
                    const selected: boolean = selectedInteriorSwatch === i

                    return (
                      <button
                        css={[
                          tw`bg-transparent ml-2 transform transition-all hover:(shadow-2 scale-110)`,
                        ]}
                        title={stripDisclaimerCodes(name)}
                        onClick={() => {
                          setSelectedInteriorSwatch(i)
                          handleTrackEvent({
                            features_series_prop: `interior color selector|${name}|${vehicleTealData.trim} `,
                            vehicle_model: vehicleTealData.vehicle_model,
                          })
                        }}
                        analytics-id={`interior color selector:model list:${
                          i + 1
                        }`}
                      >
                        <div
                          css={[
                            tw`border w-8 h-8 relative border-white`,
                            tw`md:(w-12 h-12)`,
                            `background-color: ${primaryColor};`,
                          ]}
                        >
                          <Image
                            imageData={thumbnail}
                            css={[tw`object-cover w-full h-full`]}
                          />
                          {selected && (
                            <Checkmark
                              color="#fff"
                              css={[
                                tw`absolute w-3 z-10`,
                                // absolute center
                                tw`transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2`,
                              ]}
                            />
                          )}
                        </div>
                      </button>
                    )
                  })}
                </div>
              )}
              {/* Swatch Name */}
              <h2
                css={[
                  tw`text-xs font-semibold uppercase tracking-widest text-gray-700 text-center`,
                  tw`absolute bottom-[3em] w-full`,
                  tw`md:(bottom-[-12em])`,
                ]}
              >
                {parseDisclaimerBlocks(
                  interiorColors[selectedInteriorSwatch].name,
                  selection => modalDispatch(toggleDisclaimersModal(selection))
                )}
              </h2>
            </>
          )}
        </Carousel>
      )}
    </>
  )
}

export default InteriorGallery
