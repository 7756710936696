import React, { useContext } from "react"
import tw from "twin.macro"
import { LanguageContext } from "../../../contexts/Language"
import { DealerDetailsPageContext } from "../../../templates/dealerDetails"
import { Link } from "../../atoms/Link"

const DealerDepartments: React.FC = () => {
  const { dealer } = useContext(DealerDetailsPageContext)
  const { _ } = useContext(LanguageContext)
  const servicesToShow = [
    "CollisionReplacement",
    "ServiceReplacement",
    "ExpressMaintenance",
    "TTC",
    "SpanishSpeaking",
    "PartsCenter",
    "CourtesyShuttleService",
    "AutoDetailing",
  ]
  return (
    <div
      css={[
        tw`col-start-1 col-span-12`,
        tw`md:(col-span-6 p-4)`,
        tw`lg:(col-span-6)`,
        tw`xl:(col-span-7)`,
        // tw`2xl:(col-span-8)`,
      ]}
    >
      {dealer.Departments?.filter(d => d.name !== "Inventory Manager").map(
        department => {
          if (department.descriptions && department.descriptions.length > 0) {
            return (
              <div css={[tw`mt-4 mb-6`]}>
                <h2 css={[tw`tracking-widest text-3xl text-gray-700 mb-1`]}>
                  {department?.name === "Main Dealer"
                    ? "About Us"
                    : department?.name}
                </h2>
                <p>{department.descriptions[0].description}</p>
                {department.name === "Service" && (
                  <span css={tw`font-semibold block mt-4`}>
                    {_("Services Offered")}
                  </span>
                )}
                {department.name === "Service" &&
                  dealer.Services?.map(service => {
                    if (
                      servicesToShow.includes(service.code) &&
                      service.active
                    ) {
                      if (service.code === "TTC" && dealer.DealerTireUrl) {
                        return (
                          <li>
                            <Link
                              animated
                              animatedThin
                              to={dealer.DealerTireUrl}
                              css={tw`py-0 text-red-500`}
                            >
                              {service.name}
                            </Link>
                          </li>
                        )
                      } else {
                        return <li>{service.name}</li>
                      }
                    }
                  })}
              </div>
            )
          }
        }
      )}
    </div>
  )
}

export default DealerDepartments
