import React, { FC, useEffect, useState } from "react"
import tw from "twin.macro"
import { getBrightness, hexToRgb } from "../../../helpers"
import { Checkmark } from "../Icon"
import { ColorSwatchProps } from "./ColorSwatch.d"
import { css } from "@emotion/react"
import { Tooltip } from "../Tooltip"

/**
 * Color swatch component
 *
 * @component
 * @param {ColorSwatchProps} props
 * @param {TWColor|string} props.color - 6-digit hex color of swatch
 * @param {boolean} props.selected - When true, adds an appropriately-contrasted checkmark in the center of the swatch
 * @param {function} props.onClick - Function to run when swatch is clicked
 *
 * @returns <button></button>
 *
 */
const ColorSwatch: FC<ColorSwatchProps> = ({
  color,
  selected = false,
  name,
  onClick,
  onTouchStart,
  fromModal = false,
  backgroundImageUrl,
  paramName = null,
  ...remainingProps
}) => {
  const [imageExists, setImageExists] = useState(false)
  //check to see if image is returned and style accordingly
  useEffect(() => {
    if (paramName == "interiorColor" && backgroundImageUrl) {
      const img = new Image()
      img.onload = () => setImageExists(true)
      img.onerror = () => setImageExists(false)
      img.src = backgroundImageUrl
    }
  }, [backgroundImageUrl])

  // This allows us to use string or array, even if array length is only 1
  const primaryColor = Array.isArray(color) ? color[0] : color
  const secondaryColor =
    Array.isArray(color) && color.length > 1 ? color[1] : null

  // Sets the selected checkmark color to either black or white based on swatch color brightness
  let checkmarkColor: string

  // If there's a secondary color, we need to compare against both
  if (secondaryColor !== null) {
    checkmarkColor =
      getBrightness(hexToRgb(primaryColor)) < 128 ||
      getBrightness(hexToRgb(secondaryColor)) < 128
        ? "#ffffff"
        : "#000000"
  } else {
    if (!imageExists && paramName == "interiorColor") {
      checkmarkColor = "#000000"
    } else {
      checkmarkColor =
        getBrightness(hexToRgb(primaryColor)) < 128 ? "#ffffff" : "#000000"
    }
  }
  return (
    <button
      css={[
        tw`inline-flex relative m-1 items-center justify-center w-8 h-8 border-gray-200 border cursor-pointer lg:(w-12 h-12)`,
        tw`focus-visible:(outline-none)`,
        `background-color: ${primaryColor || null}`,
        backgroundImageUrl &&
          css`background: center / cover no-repeat url(${backgroundImageUrl})}`,
        // Hover pseudo element
        css`
          &:after {
            content: "";
            ${tw`block w-full h-full absolute top-0 left-0 border border-gray-600 transform transition ease-out duration-300 opacity-0`}
          }

          &:hover:after {
            ${tw`md:(scale-[120%] opacity-100)`}
          }
        `,
      ]}
      onClick={onClick}
      onTouchStart={onTouchStart}
      title={fromModal ? null : name}
      aria-label={name}
      {...remainingProps}
    >
      {secondaryColor && (
        <div
          css={[
            tw`w-full h-full absolute rounded-full`,
            `background: linear-gradient(135deg, transparent 0, transparent 50%, ${secondaryColor} 50%)`,
          ]}
        />
      )}
      {selected && (
        <Checkmark color={checkmarkColor} css={[tw`w-3 relative z-10`]} />
      )}
    </button>
  )
}

export default ColorSwatch
