import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { LanguageContext } from "../contexts/Language"
import { SalesEvent } from "../global"
import moment from "moment"
import { monthsMap } from "../helpers"

const useSalesEventsData = (): SalesEvent[] => {
  const salesEvent = useStaticQuery(SalesEventsQuery)
  const { language } = useContext(LanguageContext)
  const salesEventData =
    language === "es"
      ? salesEvent?.salesEventsES?.nodes
      : salesEvent.salesEvents?.nodes

  // Get formatted Sales Event dates
  for (let i = 0; i <= salesEventData.length - 1; i++) {
    let startDateMonth = moment(salesEventData[i]?.startDate).format("MMMM")
    let startDateDay = moment(salesEventData[i]?.startDate).format("D")

    let endDateMonth = moment(salesEventData[i]?.endDate).format("MMMM")
    let endDateDay = moment(salesEventData[i]?.endDate).format("D")

    if (language === "es") {
      startDateMonth = monthsMap.get(startDateMonth)
      endDateMonth = monthsMap.get(endDateMonth)
    }

    const formattedStartDate = `${startDateMonth ? startDateMonth : ""} ${
      startDateDay ? startDateDay : ""
    }`
    const formattedEndDate = `${endDateMonth ? endDateMonth : ""} ${
      endDateDay ? endDateDay : ""
    }`

    salesEventData[i].formattedStartDate = formattedStartDate
    salesEventData[i].formattedEndDate = formattedEndDate
  }

  return salesEventData ? salesEventData : []
}

export default useSalesEventsData

export const SalesEventsQuery = graphql`
  query SalesEventQuery {
    salesEvents: allSanitySalesEvent(
      filter: { salesEventActive: { eq: true }, language: { eq: "en" } }
    ) {
      nodes {
        ...SalesEvent
      }
    }
    salesEventsES: allSanitySalesEvent(
      filter: { salesEventActive: { eq: true }, language: { eq: "es" } }
    ) {
      nodes {
        ...SalesEvent
      }
    }
  }
`
