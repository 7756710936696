import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { NewCarouselProps } from "./NewCarousel.d"
import {
  motion,
  useMotionValue,
  animate,
  AnimationOptions,
  PanInfo,
} from "framer-motion"
import tw from "twin.macro"
import { Image } from "../atoms/Image"
import { Link } from "../atoms/Link"
import useTealiumEvent from "../../hooks/Tealium/useTealiumEvent"

/**
 * Carousel component with variants for each carousel style across the codebase. New behavior can be passed inside the variants prop.
 *
 * @author Tyler
 * @param {Object[]} slides - An array of objects containing the content of each slide
 * @param {boolean} continuous - Whether the carousel should loop back to the first slide when the last slide is reached
 * @param {boolean} centered - Whether the carousel should be centered
 * @param {number} activeSlide - The index of the slide that is currently active
 * @param {string} linkUrl - Optional link url. When provided, slides will be a link to this url.
 * @param {Dispatch<React.SetStateAction<number>>} setActiveSlide - A function that sets the active slide index
 * @returns {JSX.Element} - A carousel of slides
 */

const NewCarousel = ({
  id,
  slides,
  continuous = false,
  hero = false,
  inventory = false,
  inventoryData,
  centered,
  mobileButtons,
  activeSlide,
  linkUrl,
  setActiveSlide,
  children,
  section,
  analyticsId,
  chevronPosition = "inside",
  tealIncludeLabel,
  ...remainingProps
}: NewCarouselProps): JSX.Element => {
  return (
    <>
      <section
        id={id}
        css={[
          tw`relative flex justify-center items-center w-full overflow-hidden`,
        ]}
        {...remainingProps}
      >
        <div
          css={[
            tw`w-full h-[150px] flex justify-center py-2 `,
            tw`lg:(h-[300px])`,
            tw`desktop-hd:(h-[450px])`,
          ]}
        >
          <CarouselContainer
            index={activeSlide}
            setIndex={setActiveSlide}
            centered={centered}
            section={section}
            tealIncludeLabel={tealIncludeLabel}
          >
            {({ index }) => {
              const modulo = index % slides.length
              const imageIndex = modulo < 0 ? slides.length + modulo : modulo
              if (linkUrl) {
                return (
                  <Link
                    to={slides[imageIndex]?.slug}
                    css={tw`w-full h-full`}
                    draggable={false}
                  >
                    <Image
                      draggable={false}
                      imageData={slides[imageIndex]?.image}
                      css={[tw`w-auto h-full mx-auto object-contain`]}
                    />
                  </Link>
                )
              }
              return (
                <Image
                  draggable={false}
                  imageData={slides[imageIndex]?.image}
                  css={[
                    tw`w-full h-full transition-opacity duration-75 opacity-40 ease-in-out mx-auto object-contain`,
                  ]}
                />
              )
            }}
          </CarouselContainer>
        </div>
      </section>
    </>
  )
}

const CarouselContainer = ({
  index,
  setIndex,
  centered,
  children,
  section,
  tealIncludeLabel,
}: any): JSX.Element => {
  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  const handleTealiumEvent = (section: string, action: string) => {
    trackTealEvent({
      tealium_event: "carousel_click",
      carousel_action: `${tealIncludeLabel ? `${section}|` : ""}${action}`,
    })
  }

  // If carousel is set to centered, immediate left and right syblings will be visible. Adding
  // an additional item on either side will ensure no elements are disappearing as the carousel
  // is toggled left or right.
  const range = centered ? [-2, -1, 0, 1, 2] : [-1, 0, 1]

  const transition: AnimationOptions<any> = {
    type: "spring",
    bounce: 0,
  }

  const x = useMotionValue(0)

  const containerRef = useRef<HTMLDivElement>(null)

  //added event listener to recalculate carousel positions if the browser is resized

  function useWindowSize() {
    const [size, setSize] = useState([0, 0])
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight])
      }
      window.addEventListener("resize", updateSize)
      updateSize()
      return () => window.removeEventListener("resize", updateSize)
    }, [])
    return size
  }
  const windowSize = useWindowSize()[0]

  const calculateNewX = () => -index * (containerRef.current?.clientWidth || 0)

  const handleEndDrag = (e: Event, dragProps: PanInfo) => {
    const clientWidth = containerRef.current?.clientWidth || 0

    const { offset, velocity } = dragProps

    if (Math.abs(velocity.y) > Math.abs(velocity.x)) {
      animate(x, calculateNewX(), transition)
      return
    }

    if (offset.x > clientWidth / 4) {
      setIndex(index - 1), handleTealiumEvent(section, "vehicle|left drag")
    } else if (offset.x < -clientWidth / 4) {
      setIndex(index + 1), handleTealiumEvent(section, "vehicle|right drag")
    } else {
      animate(x, calculateNewX(), transition)
    }
  }

  useEffect(() => {
    const controls = animate(x, calculateNewX(), transition)
    return controls.stop
  }, [index, windowSize])
  return (
    <motion.div
      ref={containerRef}
      css={[
        tw`relative h-full overflow-x-visible`,
        centered ? tw`w-3/4 md:w-1/2` : tw`w-full`,
      ]}
    >
      {range.map(rangeValue => {
        return (
          <Item
            key={rangeValue + index}
            x={x}
            onDragEnd={handleEndDrag}
            index={rangeValue + index}
            renderPage={children}
            rangeValue={rangeValue}
            containerWidth={windowSize}
          />
        )
      })}
    </motion.div>
  )
}

export const Item = ({
  index,
  renderPage,
  x,
  onDragEnd,
  rangeValue,
  containerWidth,
}: any): JSX.Element => {
  const child = useMemo(
    () => renderPage({ index }),
    [index, renderPage, containerWidth]
  )
  const variants = {
    focus: { opacity: 1 },
    outOfFocus: { opacity: 0.4 },
  }
  return (
    <motion.div
      style={{
        x,
      }}
      css={[
        tw`absolute w-full h-full flex items-center`,
        `left: ${index * 100}%; right: ${index * 100}%;`,
      ]}
      draggable
      drag="x"
      dragElastic={1}
      onDragEnd={onDragEnd}
      initial={{ opacity: 0 }}
      animate={rangeValue == 0 ? "focus" : "outOfFocus"}
      transition={{ duration: 1 }}
      variants={variants}
    >
      {child}
    </motion.div>
  )
}

export default NewCarousel
