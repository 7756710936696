import React, { useContext, useState } from "react"
import tw from "twin.macro"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { LanguageContext } from "../../../contexts/Language"
import { parseDisclaimerBlocks, stripDisclaimerCodes } from "../../../helpers"
import { Button } from "../../atoms/Button"
import Icon from "../../atoms/Icon"
import { XseriesHotspotProps } from "./XseriesHotspots.d"
import { Image } from "../../atoms/Image"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"

const XseriesHotspot: React.FC<XseriesHotspotProps> = ({
  hotspot,
  activeSpot,
  setActiveSpot,
  modalOpen,
  setModalOpen,
  parentLeft,
  parentRight,
  currentAngle,
  setCurrentAngle,
  imageIndex,
}) => {
  const { language } = useContext(LanguageContext)
  const [state, dispatch] = useContext(DisclaimersContext)

  /* START - LOGIC FOR TEALIUM */

  const { trackTealEvent } = useTealiumEvent()
  const { vehicleTealData } = useTealiumContext()

  const handleTealClick = (vars: {}) =>
    trackTealEvent({
      ...(vars && vars),
      vehicle_model: vehicleTealData?.vehicle_model,
      vehicle_model_truncated: vehicleTealData?.vehicle_model,
      vehicle_segment_vehicle_page:
        vehicleTealData?.vehicle_segment_vehicle_page,
      vehicle_year: vehicleTealData?.vehicle_year,
    })

  return (
    <div
      className="group"
      key={`hotspot-${hotspot?._key}`}
      id={`hotspot-${hotspot?._key}`}
      css={[
        tw`w-full px-20 opacity-60 pb-5 md:(px-6) lg:(text-2xl) hover:(transition duration-150 ease-in-out opacity-100)`,
        hotspot?._key === activeSpot?._key && tw`opacity-100`,
      ]}
      onClick={() => {
        setActiveSpot(hotspot)
        currentAngle !== hotspot?.angle && setCurrentAngle(hotspot?.angle)
        handleTealClick({
          features_series_prop: `xp feature|${stripDisclaimerCodes(
            hotspot?.title
          )}|${vehicleTealData?.vehicle_model_trim}`,
        })
      }}
      analytics-id={`feature card:xseries features:${imageIndex + 1}`}
    >
      <div
        css={[
          tw`shadow-2 rounded-lg bg-white relative h-full min-h-[230px] max-w-[350px]`,
          hotspot?._key === activeSpot?._key && tw`p-0 opacity-100 shadow-4 `,
        ]}
      >
        <Button
          onClick={e => {
            //prevent parent teal event from firing
            if (e && e.stopPropagation) e.stopPropagation()

            setModalOpen(true)
            // tealium event
            handleTealClick({
              features_series_prop: `xp zoom|${stripDisclaimerCodes(
                hotspot?.title
              )}|${vehicleTealData?.vehicle_model_trim}`,
            })
          }}
          css={[
            tw`p-2! absolute z-10 bg-white right-1.5 top-1.5 w-8 h-8 opacity-0 group-hover:opacity-100 focus-visible:(outline-gray rounded-none)`,
          ]}
          analytics-id={`zoom:xseries features:${imageIndex + 1}`}
        >
          <Icon.Expand color="gray-800" css={[tw`max-h-6`]} />
        </Button>
        <Image imageData={hotspot?.image} role="presentation" />
        <p css={tw`font-semibold py-2 px-4`}>
          {language === "es"
            ? parseDisclaimerBlocks(
                hotspot?.titleES,
                selection => dispatch(toggleDisclaimersModal(selection)),
                parentLeft,
                parentRight
              )
            : parseDisclaimerBlocks(
                hotspot?.title,
                selection => dispatch(toggleDisclaimersModal(selection)),
                parentLeft,
                parentRight
              )}
        </p>
        <p css={[tw`text-sm px-4 pb-4`]}>
          {language === "es"
            ? parseDisclaimerBlocks(
                hotspot?.descriptionES,
                selection => dispatch(toggleDisclaimersModal(selection)),
                parentLeft,
                parentRight
              )
            : parseDisclaimerBlocks(
                hotspot?.description,
                selection => dispatch(toggleDisclaimersModal(selection)),
                parentLeft,
                parentRight
              )}
        </p>
      </div>
    </div>
  )
}

export default XseriesHotspot
