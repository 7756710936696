import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { LanguageContext } from "../../../contexts/Language"
import { DealerDetailsPageContext } from "../../../templates/dealerDetails"
import Icon from "../../atoms/Icon"
import { Link } from "../../atoms/Link"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { ContactContext } from "../../../contexts/Contact"
import { openTactContactDealerModal } from "../../../contexts/Contact/actions"
import { Button, ButtonLink } from "../../atoms/Button"

const AdditionalLinks: React.FC = () => {
  const { dealer } = useContext(DealerDetailsPageContext)
  const { _, language } = useContext(LanguageContext)
  const [{}, contactDispatch] = useContext(ContactContext)

  const serviceDept = dealer.Departments?.filter(
    (department: { name: string }) => department.name === "Service"
  )[0]

  const shopTiresLink = dealer.DealerTireUrl
  const tactUrl = dealer.TactUrl

  const englishTrackingCode = "?siteid=explore_tpco_exploresite_shopnow_GM"
  const spanishTrackingCode = "?siteid=explore_tpco_exploresite_shopnow_HM"

  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  return (
    <div css={[tw`bg-gray-100 rounded-lg m-0 mt-4 p-8`, tw`md:(m-4)`]}>
      <span css={tw`text-2xl tracking-widest font-light block mb-2`}>
        {_("Additional Links")}
      </span>
      <ul>
        {shopTiresLink && (
          <li>
            <Link
              animated
              animatedThin
              to={shopTiresLink}
              css={tw`py-0 text-red-500 inline-flex items-center text-sm`}
              onClick={() => {
                trackTealEvent({
                  tealium_event: "shop_tires",
                  dealer_name: dealer?.Name,
                  dealer_code: dealer?.DealerCode,
                })
              }}
              analytics-id="shop tires:dealer info:"
            >
              <span css={[tw`inline-block whitespace-nowrap`]}>
                {_("Shop Tires")}
              </span>
              <Icon.ExternalLink color="red-500" css={[tw`h-3 ml-2`]} />
            </Link>
          </li>
        )}
        <li>
          <Link
            animated
            animatedThin
            to={
              dealer?.PartsUrl
                ? dealer?.PartsUrl.includes("autoparts")
                  ? language === "en"
                    ? dealer?.PartsUrl + englishTrackingCode
                    : dealer?.PartsUrl + spanishTrackingCode
                  : dealer?.PartsUrl
                : "/parts/parts-center-online/"
            }
            css={tw`py-0 text-red-500 inline-flex items-center text-sm`}
            onClick={() => {
              trackTealEvent({
                tealium_event: "parts_ext_link",
                dealer_name: dealer?.Name,
                dealer_code: dealer?.DealerCode,
              })
            }}
            analytics-id="shop parts:dealer info:"
          >
            <span css={[tw`inline-block whitespace-nowrap`]}>
              {_("Order Parts Online")}
            </span>
            <Icon.ExternalLink color="red-500" css={[tw`h-3 ml-2`]} />
          </Link>
        </li>

        {/* {tactUrl && (
          <li>
            <ButtonLink
              secondary
              css={[tw`text-xs mt-4`]}
              onClick={() => {
                trackTealEvent({
                  tealium_event: "tact_apply",
                  dealer_name: dealer?.Name,
                  dealer_code: dealer?.DealerCode,
                  coupon_module_text:
                    "Apply to the Technician Training Program",
                })
              }}
              analytics-id="tact apply:dealer info:"
              to={tactUrl}
            >
              {_("Apply to the Technician Training Program")}
            </ButtonLink>
          </li>
        )} */}
        {tactUrl && (
          <li>
            <Button
              secondary
              css={[tw`text-xs mt-4`]}
              onClick={() => {
                trackTealEvent({
                  tealium_event: "contact_dealer_rest",
                  dealer_name: dealer?.Name,
                  dealer_code: dealer?.DealerCode,
                  coupon_module_text:
                    "Apply to the Technician Training Program",
                })
                contactDispatch(openTactContactDealerModal(dealer))
              }}
              analytics-id="tact apply:dealer info:"
            >
              {_("Apply to the Technician Training Program")}
            </Button>
          </li>
        )}
      </ul>
    </div>
  )
}

export default AdditionalLinks
