import React, { useContext, useMemo } from "react"
import { PortableText } from "@portabletext/react"
import urlBuilder from "@sanity/image-url"
import { getImageDimensions } from "@sanity/asset-utils"
import tw from "twin.macro"
import type { BlockContentProps } from "./BlockContent.d"
import { Link } from "../Link"
import { parseDisclaimerBlocks } from "../../../helpers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { Image } from "../../atoms/Image"

const ImageComponent = ({ value }) => {
  return <Image imageData={value} />
}

const BlockContent: React.FC<BlockContentProps> = ({ data }) => {
  let blocks = data
  const [state, dispatch] = useContext(DisclaimersContext)

  if (blocks && !Array.isArray(blocks)) {
    blocks = [blocks]
  }

  const newBlocks = blocks?.map((i: { markDefs: any[] }) => {
    i.markDefs = i.markDefs || []
    return i
  })

  const portableTextComponents = useMemo(() => {
    return {
      types: {
        image: ImageComponent,
      },
      listItem: ({ children }: { children: any }) => (
        <li css={[tw`list-disc list-outside ml-5 py-1 text-lg`]}>{children}</li>
      ),
      // add line-height for paragraph breaks
      //block: ({ children }: { children: any }) => (
      //  <p css={[tw`mb-3`, tw`last:mb-0`]}>{children}</p>
      //),
      block: {
        h1: ({ children }: { children: any }) => (
          <h1 css={[tw`text-5xl mb-6`]}>{children}</h1>
        ),
        h2: ({ children }: { children: any }) => (
          <h2 css={[tw`text-3xl mb-5`]}>{children}</h2>
        ),
        h3: ({ children }: { children: any }) => (
          <h3 css={[tw`text-2xl mb-4`]}>{children}</h3>
        ),
        h4: ({ children }: { children: any }) => (
          <h4 css={[tw`text-xl mb-3`]}>{children}</h4>
        ),
        normal: ({ children }: { children: any }) => (
          <p css={[tw`mb-3 last:mb-0`]}>{children}</p>
        ),
      },
      marks: {
        disclaimer: ({ children }: { children: any }) => (
          <>
            {parseDisclaimerBlocks(children, selection =>
              dispatch(toggleDisclaimersModal(selection))
            )}
          </>
        ),
        link: ({ children, mark }: { children: any; mark: any }) =>
          mark?.href ? (
            <Link css={tw`text-red-500 !p-0`} to={mark?.href}>
              {children}
            </Link>
          ) : null,
        internalLink: ({ children, value }: { children: any; value: any }) => (
          <Link
            css={tw`text-red-500 !p-0`}
            to={`/${value?.internal?.slug?.current}/`}
          >
            {children}
          </Link>
        ),
        strong: ({ children }: { children: any }) => (
          <strong css={tw`font-bold`}>{children}</strong>
        ),
        alignCenter: ({ children }: { children: any }) => (
          <div css={tw`content-center`}>{children}</div>
        ),
        alignRight: ({ children }: { children: any }) => (
          <div css={tw`content-end`}>{children}</div>
        ),
      },
    }
  }, [])

  if (blocks) {
    return (
      <PortableText value={newBlocks} components={portableTextComponents} />
    )
  }
  return null
}

export default BlockContent
