import React, { useContext } from "react"
import { Image } from "../../../atoms/Image"
import { HeroCarouselItemProps } from "./HeroCarousel.d"
import { IGatsbyImageData } from "gatsby-plugin-image"
import tw from "twin.macro"
import { ButtonLink } from "../../../atoms/Button"
import { LanguageContext } from "../../../../contexts/Language"
import { getCTALinkValue, parseDisclaimerBlocks } from "../../../../helpers"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"

const HeroCarouselItem: React.FC<HeroCarouselItemProps> = ({
  slide,
  index,
}) => {
  const { _ } = useContext(LanguageContext)

  const {
    desktopImage,
    mainImage,
    heading,
    subheading,
    boxColor,
    textColor,
    ctaArray,
    _type,
  } = slide

  const background: IGatsbyImageData = desktopImage?.image
  const backgroundAlt: string = desktopImage?.alt
  const image = mainImage?.image
  const imageAlt = mainImage?.alt

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const [state, modalDispatch] = useContext(DisclaimersContext)
  return (
    <article
      css={[
        // `background:${`#fffff`}`,
        tw`h-full w-full grid grid-cols-1 grid-rows-2 bg-gray-300 lg:(bg-transparent)`,
      ]}
    >
      {/* BACKGROUND */}
      {background && (
        <div
          css={[
            tw`col-start-1 col-span-1 row-start-1 row-end-2 w-full`,
            tw`lg:(col-start-1 col-end-2 col-span-2 row-start-1 row-end-3 grid grid-cols-1 grid-rows-1)`,
          ]}
        >
          {/* Background image */}
          <Image
            imageData={background}
            css={[tw`h-full w-full object-cover`]}
            hideAlt
            transparentPreview
          />
        </div>
      )}

      <section
        css={[
          tw`col-end-2 row-start-1 row-end-3 grid w-full h-full mx-auto`,
          tw`md:(-mt-1)`,
          tw`lg:(bg-transparent px-10 col-end-2 row-start-1 grid max-w-desktop w-full mx-auto)`,
        ]}
      >
        {/* Heading */}
        {heading && (
          <div
            css={[
              tw`pt-12 pb-20 px-12 col-start-1 col-end-3 row-start-3 text-white text-2xl text-left w-full justify-self-center -mt-16`,
              tw`lg:(p-10 px-12 rounded-lg row-start-1 col-end-2 max-w-[32rem] text-left text-[2.2rem] leading-[3rem] font-light self-center mt-20)`,
              tw`2xl:(max-w-[40rem])`,
              `backdrop-filter: blur(1px);`,
              `background:${boxColor ? `${boxColor.hex}D9` : `#1E262ED9`}`,
              `@media (max-width: 1024px) {
                background:${boxColor ? `${boxColor.hex}` : `#1E262ED9`}`,
            ]}
          >
            <h1
              css={[
                tw`text-4xl text-left font-book`,
                tw`lg:(text-[4rem] leading-[4.5rem] text-left mt-0)`,
                `color:${textColor ? `${textColor.hex}FF` : `#FFFFFF`}`,
              ]}
            >
              {parseDisclaimerBlocks(heading, selection =>
                modalDispatch(toggleDisclaimersModal(selection))
              )}
            </h1>

            {/* Subheading */}
            <div
              css={[
                tw`text-2xl text-left justify-self-center`,
                tw`lg:(text-left text-[1.9rem] leading-[3rem] font-light pt-2)`,
                `color:${textColor ? `${textColor.hex}FF` : `#FFFFFF`}`,
              ]}
            >
              {subheading && (
                <h2>
                  {parseDisclaimerBlocks(subheading, selection =>
                    modalDispatch(toggleDisclaimersModal(selection))
                  )}
                </h2>
              )}
            </div>

            {/* CTAs */}
            {ctaArray && (
              <div
                css={[
                  tw`mt-6 flex grid-cols-2 gap-2`,
                  //tw`md:(max-w-[500px])`,
                ]}
              >
                {ctaArray?.map((cta: any) => (
                  <ButtonLink
                    {...{ [cta?.buttonType]: true }}
                    to={getCTALinkValue(cta)}
                    target={cta?.linkType === "external" ? "_blank" : "_self"}
                    css={[tw`whitespace-nowrap`, tw`lg:w-auto`]}
                    aria-label={cta?.title}
                    key={cta?.title}
                    analytics-id={`${cta?.title}:homepage carousel:${
                      index + 1
                    }`}
                    onClick={() => {
                      trackTealEvent({
                        coupon_module_text: cta?.title,
                      })
                    }}
                  >
                    {cta?.title}
                  </ButtonLink>
                ))}
              </div>
            )}
          </div>
        )}

        {/* Main image */}
        {image && (
          <div
            css={[
              tw`relative z-0 row-span-2 col-start-1 col-span-2 flex items-center justify-center min-h-full`,
              tw`md:(mt-0)`,
              tw`lg:(row-span-1 col-start-2 row-start-1 row-end-3 col-span-1 flex items-center justify-end mt-10)`,
            ]}
          >
            <Image
              imageData={image}
              css={[
                tw`object-contain`,
                tw`h-auto w-3/4 sm:(h-[220px] w-auto) md:(h-[230px]) lg:(h-[280px]) xl:(h-[300px]) 2xl:(h-[350px])`,
              ]}
              hideAlt
              // transparentPreview
            />
          </div>
        )}
      </section>
    </article>
  )
}

export default HeroCarouselItem
