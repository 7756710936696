import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { AccessoriesPageContext } from "../../../templates/seriesAccessories"
import { Model } from "../../../global"
import ModelListItem from "../Series/ModelList/ModelListItem"
import AliceCarousel from "react-alice-carousel"
import useDrag from "../../../hooks/useDrag"
import Icon from "../../atoms/Icon"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"
import { LanguageContext } from "../../../contexts/Language"

const Filter: React.FC<{ selected: boolean; onClick: () => void }> = ({
  selected = false,
  children,
  ...remainingProps
}) => {
  return (
    <div css={[tw`inline-block relative`]}>
      <button
        css={[
          tw`inline-block py-3 px-5 rounded-lg`,
          selected && tw`font-semibold shadow-2 bg-white`,
        ]}
        {...remainingProps}
      >
        {children}
      </button>
    </div>
  )
}

const AccyModels: React.FC = () => {
  const {
    selectedModelGroup,
    setSelectedModelGroup,
    models,
    hybridsOnly,
    selectedCategories,
    setSelectedCategories,
  } = useContext(AccessoriesPageContext)

  const [selectedFilter, setSelectedFilter] = useState(hybridsOnly ? 1 : 0)

  const languageObj = useContext(LanguageContext)
  const { handleMouseUp } = useDrag()

  const onlyHybrids = models.filter((model: Model[]) =>
    model.some(model => model?.model?.isHybridModel)
  )

  const hasGasAndHybridModels = (slug: string) => {
    switch (slug?.toLowerCase()) {
      case "camry":
        return true
      case "corolla":
        return true
      case "avalon":
        return true
      case "rav4":
        return true
      case "highlander":
        return true
      case "corollacross":
        return true
      case "grandhighlander":
        return true
      case "tundra":
        return true
      default:
        return false
    }
  }

  const filterButtons = hasGasAndHybridModels(
    models && models.length > 0 ? models[0][0]?.model?.series?.slug : null
  )
    ? [languageObj._("All"), languageObj._("Hybrid")]
    : null

  // State to store the value of selectedFamilyForAccessories from local storage
  const [selectedFamily, setSelectedFamily] = useState(
    typeof window !== "undefined"
      ? localStorage.getItem("selectedFamilyForAccessories")
      : null
  )

  // Effect to update selectedFamily state when local storage changes

  useEffect(() => {
    const handleStorageChange = () => {
      setSelectedFamily(
        typeof window !== "undefined"
          ? localStorage.getItem("selectedFamilyForAccessories")
          : null
      )
    }

    // Check if window is not undefined
    if (typeof window !== "undefined") {
      window.addEventListener("storage", handleStorageChange)

      // Cleanup function to remove the event listener
      return () => {
        window.removeEventListener("storage", handleStorageChange)
      }
    }
  }, [])

  // Effect to update selectedFilter state when selectedFamily state changes
  useEffect(() => {
    if (selectedFamily === "Hybrid & Electric") {
      setSelectedFilter(1)
    } else {
      setSelectedFilter(0)
    }
  }, [selectedFamily])

  const responsive = {
    0: { items: 1 },
    600: { items: 2 },
    859: { items: 3 },
    1023: { items: 3 },
    1100: { items: 4 },
    1440: { items: 5 },
    1600: { items: 6 },
    1919: { items: 7 },
  }

  const [items, setItems] = useState([])
  const [activeIndex, setActiveIndex] = useState()

  let clickCount = 0

  function handleClick() {
    clickCount++

    if (clickCount === 1) {
      setActiveIndex(i)
    } else if (clickCount === 2) {
      setActiveIndex([])
      clickCount = 0 // Reset click count after the second click
    }
  }

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData } = useTealiumContext()

  useEffect(() => {
    let filteredModels = []
    if (selectedFilter === 0) {
      // All
      filteredModels = models
    } else if (selectedFilter === 1) {
      // Hybrid
      filteredModels = onlyHybrids
    }
    setItems(
      filteredModels?.map((modelGroup: Model[], i: number) => (
        //models?.map((modelGroup: Model[], i: number) => (
        <ModelListItem
          onClick={() => {
            // If X-Series Filter is selected only because the trim was set to XP - remove X-Series filter
            if (selectedModelGroup.title == "XP" && modelGroup.title !== "XP") {
              setSelectedCategories(
                selectedCategories.filter(
                  (filter: any) => filter !== "X-Series"
                )
              )
            }
            modelGroup.title && modelGroup.title === selectedModelGroup.title
              ? setSelectedModelGroup([])
              : modelGroup[0]?.model.name === selectedModelGroup[0]?.model.name
              ? setSelectedModelGroup([])
              : setSelectedModelGroup(modelGroup)
            setActiveIndex(i)
            trackTealEvent({
              tealium_event: "refinement",
              refinement_value:
                tealPageData.page_type +
                `|` +
                tealPageData.page_name +
                `|Model|${modelGroup.title}`,
            })
          }}
          active={modelGroup[0]?.model.id === selectedModelGroup[0]?.model.id}
          modelGroup={modelGroup}
          isElectricVehicle={modelGroup[0]?.model?.isElectricModel}
          isHybridVehicle={modelGroup[0]?.model?.isHybridModel}
          key={i}
          showConfigurations={false}
          className="ModelConfig"
          analytics-id={`model filter:refinement:${i + 1}`}
        />
      ))
    )
  }, [models, selectedModelGroup, selectedFilter])

  const renderPrevButton = ({ isDisabled }) => {
    return (
      <button
        css={[
          tw`absolute -left-6 top-[45%] -translate-y-1/2 z-50 flex justify-center items-center cursor-pointer w-16 h-10 bg-white rounded-full transition-all hover:bg-gray-400 focus-visible:(bg-gray-400)`,
          tw`lg:(-left-10)`,
          isDisabled ? tw`hidden` : tw`flex`,
        ]}
      >
        <Icon.Chevron direction="left" color="black" css={[tw`h-5`]} />
      </button>
    )
  }

  const renderNextButton = ({ isDisabled }) => {
    return (
      <button
        css={[
          tw`absolute -right-6 top-[45%] -translate-y-1/2 z-50 flex justify-center items-center cursor-pointer w-16 h-10 bg-white rounded-full transition-all hover:(bg-gray-400) focus-visible:(bg-gray-400)`,
          tw`lg:(-right-10)`,
          isDisabled ? tw`hidden` : tw`flex`,
        ]}
      >
        <Icon.Chevron direction="right" color="black" css={[tw`h-5`]} />
      </button>
    )
  }

  return (
    <section aria-label="Accessories By Model Selector" css={[tw`bg-gray-200`]}>
      <div>
        <h2
          css={[
            tw`text-center text-2xl p-4  py-14 md:(py-8) xl:(py-14 text-4xl)`,
          ]}
        >
          {languageObj._("Select a Trim Below to View Available Accessories")}
        </h2>
        <div css={[tw`flex justify-center space-x-4 mb-4 mt-4 lg:(mt-0)`]}>
          {filterButtons &&
            filterButtons.map((filter, i) => {
              const isSelected = i === selectedFilter
              const selected = i === selectedFilter
              return (
                <Filter
                  selected={selected}
                  onClick={() => {
                    setSelectedFilter(i)
                  }}
                  key={`filter-${i}`}
                  css={tw`capitalize`}
                >
                  {filter}
                  <div
                    css={[
                      tw`hidden absolute -right-1.5 -top-1.5 px-2 py-0.5 rounded-full bg-toyotaRed text-gray-50 text-sm`,
                      selected && tw`block`,
                    ]}
                  >
                    {i === 0 ? models.length : onlyHybrids.length}
                  </div>
                </Filter>
              )
            })}
        </div>
        <div onMouseUp={handleMouseUp}>
          <div
            css={[tw`overflow-hidden h-[152px] px-8 md:(px-16) bg-gray-200`]}
          >
            <div
              css={[tw`flex flex-nowrap w-auto text-center justify-center`]}
              className="modelListItems"
            >
              <AliceCarousel
                items={items}
                disableDotsControls
                responsive={responsive}
                paddingLeft={18}
                paddingRight={18}
                controlsStrategy="default"
                renderPrevButton={renderPrevButton}
                renderNextButton={renderNextButton}
                activeIndex={activeIndex}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AccyModels
