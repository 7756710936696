import React, { useContext, useState } from "react"
import tw from "twin.macro"
import { Link } from "../../atoms/Link"
import { Image } from "../../atoms/Image"
import Icon from "../../atoms/Icon"
import { ContentSlider } from "../Homepage/ContentSlider"
import { LanguageContext } from "../../../contexts/Language"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { parseDisclaimerBlocks } from "../../../helpers"
import { DisclaimersContext } from "../../../contexts/Disclaimers"

export type AccyBlockProps = {
  accyBlocks: []
}

const AccyBlocks: React.FC<AccyBlockProps> = ({ accyBlocks }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedBlock, setSelectedBlock] = useState()
  const [selectedBlockIndex, setSelectedBlockIndex] = useState(0)
  const { _ } = useContext(LanguageContext)
  const [state, modalDispatch] = useContext(DisclaimersContext)
  function handleClose() {
    setModalOpen(!modalOpen)
  }

  function openModal(block: any, index: number): () => void {
    return () => {
      if (block.type === "externalMedia") {
        setSelectedBlock(block)
        setSelectedBlockIndex(index)
        setModalOpen(!modalOpen)
      }
    }
  }

  function hasNextVideo(): boolean {
    for (
      let i = selectedBlockIndex + 1;
      i < accyBlocks.imageBlocks.length;
      i++
    ) {
      if (accyBlocks.imageBlocks[i].type === "externalMedia") {
        return true
      }
    }
    return false
  }

  function hasPrevVideo(): boolean {
    for (let i = selectedBlockIndex - 1; i > 0; i--) {
      if (accyBlocks.imageBlocks[i].type === "externalMedia") {
        return true
      }
    }
    return false
  }

  function nextVideo(): () => void {
    //find next block that has externalMedia from the current selectedBlockIndex
    for (
      let i = selectedBlockIndex + 1;
      i < accyBlocks.imageBlocks.length;
      i++
    ) {
      if (accyBlocks.imageBlocks[i].type === "externalMedia") {
        setSelectedBlock(accyBlocks.imageBlocks[i])
        setSelectedBlockIndex(i)
        break
      }
    }
    return
  }

  function prevVideo(): () => void {
    //find previous block that has externalMedia from the current selectedBlockIndex
    for (
      let i = selectedBlockIndex - 1;
      i < accyBlocks.imageBlocks.length;
      i--
    ) {
      if (accyBlocks.imageBlocks[i].type === "externalMedia") {
        setSelectedBlock(accyBlocks.imageBlocks[i])
        setSelectedBlockIndex(i)
        break
      }
    }
    return
  }

  if (!accyBlocks || !accyBlocks.imageBlocks) {
    return <></>
  } else {
    return (
      <section css={[tw`flex flex-col`, tw`md:(flex flex-row)`]}>
        <ContentSlider>
          {accyBlocks?.imageBlocks.map((block: any, index: number) => {
            return (
              <>
                <Link
                  to={`/${block.imageLink?.internalLink?.slug.current}`}
                  css={tw`bg-white relative z-10 flex flex-col justify-end overflow-hidden h-full w-screen md:(w-1/2vw) 2xl:(w-1/3vw) desktop-hd:(w-1/4vw)`}
                >
                  <div
                    css={[
                      tw`absolute z-0 transform top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-items-center`,
                    ]}
                  >
                    <Image
                      imageData={block?.image?.image}
                      objectFit="cover"
                      css={[tw`h-[600px] w-full`]}
                    />
                    <div
                      css={[
                        tw`bg-black bg-opacity-30 h-2/5 w-2/3 absolute md:(w-4/5) lg:(w-3/5)`,
                      ]}
                    >
                      {block.type === "externalMedia" && (
                        <div
                          css={[
                            tw`absolute top-6 text-base left-8 font-semibold text-white`,
                            tw`lg:(bottom-10)`,
                          ]}
                        >
                          <span css={[tw`text-sm uppercase font-book`]}>
                            {_("Watch Video")}
                          </span>
                        </div>
                      )}
                      <div
                        css={[
                          tw`absolute top-14 text-base left-8 font-semibold text-white`,
                          tw`lg:(bottom-10)`,
                        ]}
                      >
                        {block.heading}
                      </div>
                      <div
                        css={[
                          tw`absolute top-20 tracking-widest flex text-xl w-auto pr-10 left-8 font-light text-white md:(w-auto)`,
                          tw`lg:(bottom-10 w-auto text-2xl)`,
                        ]}
                      >
                        {block.body}
                      </div>
                    </div>
                  </div>
                  {block.type === "externalMedia" && (
                    <div
                      onClick={openModal(block, index)}
                      css={[tw`w-14 h-14 absolute bottom-4 right-4`]}
                    >
                      <Icon.YouTube
                        color="red-400"
                        css={[
                          tw`mt-1 max-h-12 cursor-pointer transform transition-all duration-500 ease-out
                          hover:(scale-75)`,
                        ]}
                      />
                    </div>
                  )}
                </Link>
              </>
            )
          })}
        </ContentSlider>

        {selectedBlock && modalOpen && (
          <div
            css={[
              tw`fixed top-0 bottom-0 w-full bg-[#000] h-full text-white p-12 pt-24`,
              "z-index: 60",
            ]}
          >
            <button
              onClick={() => handleClose()}
              css={[tw`flex items-center text-xs`]}
            >
              <Icon.Chevron
                color="white"
                direction="left"
                css={[tw`max-h-4 mr-2 w-4`]}
              />
              {_("BACK")}
            </button>
            <iframe
              allow="autoplay; encrypted-media"
              frameBorder="0"
              allowFullScreen
              title="video"
              src={`${selectedBlock.externalMedia.embedUrl}?rel=0&showinfo=0&controls=1&fs=0;&autoplay=1`}
              css={tw`h-1/3 w-full md:h-5/6 p-12`}
            ></iframe>
            <div css={tw`text-white`}>
              <h2 css={[tw`text-base font-semibold`]}>
                {parseDisclaimerBlocks(selectedBlock.heading, selection =>
                  modalDispatch(toggleDisclaimersModal(selection))
                )}
              </h2>
              <span css={[tw`text-3xl font-light tracking-widest`]}>
                {parseDisclaimerBlocks(selectedBlock.body, selection =>
                  modalDispatch(toggleDisclaimersModal(selection))
                )}
              </span>
            </div>
            {hasPrevVideo() && (
              <Icon.Chevron
                color="white"
                direction="left"
                css={[tw`max-h-12 absolute top-56 left-8 md:top-96`]}
                onClick={() => prevVideo()}
              />
            )}
            {hasNextVideo() && (
              <Icon.Chevron
                color="white"
                direction="right"
                css={[tw`max-h-12 absolute top-56 right-8 md:top-96`]}
                onClick={() => nextVideo()}
              />
            )}
          </div>
        )}
      </section>
    )
  }
}

export default AccyBlocks
