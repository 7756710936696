import React, { useContext } from "react"
import { BrochureProps } from "./Brochure.d"
import tw from "twin.macro"
import { ButtonA } from "../../../atoms/Button"
import { Image } from "../../../atoms/Image"
import { Photograph } from "../../../molecules/Photograph"
import { motion } from "framer-motion"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../../contexts/Tealium"
import { LanguageContext } from "../../../../contexts/Language"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { parseDisclaimerBlocks } from "../../../../helpers"

const Brochure: React.FC<BrochureProps> = ({
  heading,
  body,
  bulletPoints,
  brochure,
  mainImage,
  photograph,
  sectionSlug,
  margins,
  ctaText,
  logoWithImage,
}) => {
  const { _ } = useContext(LanguageContext)
  const { trackTealEvent } = useTealiumEvent()
  const { vehicleTealData } = useTealiumContext()
  const [state, dispatch] = useContext(DisclaimersContext)
  return (
    <section
      css={[
        tw`relative grid grid-cols-1 bg-gray-50`,
        tw`md:grid-cols-2`,
        `margin: ${margins?.top}px 0 ${margins?.bottom}px !important;`,
      ]}
      id={sectionSlug?.current}
      aria-label={_("Brochure Section")}
    >
      {/* Content */}
      <motion.article
        initial={{
          y: 200,
          opacity: 0,
        }}
        whileInView={{
          y: 0,
          opacity: 1,
        }}
        viewport={{ once: true, amount: 0.05 }}
        transition={{ duration: 0.5, delay: 0 }}
        css={[
          tw`bg-gray-50 col-start-1 row-start-1 flex flex-col justify-center gap-6 p-8 z-40`,
          tw`md:(max-w-xl ml-10)`,
          tw`xl:max-w-md`,
          tw`2xl:max-w-xl`,
        ]}
      >
        {heading && (
          <h2 css={[tw`text-3xl font-light tracking-[6px]`, tw`md:text-4xl`]}>
            {parseDisclaimerBlocks(heading, selection =>
              dispatch(toggleDisclaimersModal(selection))
            )}
          </h2>
        )}
        {body && (
          <p css={[tw`text-lg md:(text-xl)`]}>
            {parseDisclaimerBlocks(body, selection =>
              dispatch(toggleDisclaimersModal(selection))
            )}
          </p>
        )}

        {bulletPoints && (
          <ul
            css={[
              tw`grid overflow-visible grid-cols-2 grid-rows-1 gap-2 gap-x-12 grid-flow-row w-auto h-auto mb-0 ml-5`,
            ]}
          >
            {bulletPoints?.map(node => (
              <li css={[tw`list-disc list-outside text-sm font-semibold`]}>
                {parseDisclaimerBlocks(node, selection =>
                  dispatch(toggleDisclaimersModal(selection))
                )}
              </li>
            ))}
          </ul>
        )}
        {/* Brochure */}
        {brochure && (
          <ButtonA
            href={`https://cdn.sanity.io/${brochure.asset.path}`}
            rel="noopener noreferrer"
            onClick={() => {
              return trackTealEvent({
                url: `https://cdn.sanity.io/${brochure.asset.path}`,
                tealium_event: "download_brochure",
                vehicle_model: vehicleTealData?.vehicle_model,
                vehicle_model_truncated:
                  vehicleTealData?.vehicle_model_truncated,
              })
            }}
            secondary
            css={[tw`max-w-max`]}
            target="_blank"
            analytics-id="view brochure:brochure:"
          >
            {ctaText || "View Brochure"}
          </ButtonA>
        )}
      </motion.article>
      {/* Main image */}
      {mainImage && (
        <div
          css={[
            tw`relative row-start-2 grid w-full h-full`,
            tw`md:(mt-0 col-start-2 row-start-1)`,
          ]}
        >
          <Image
            imageData={mainImage?.image}
            css={[tw`row-start-1 col-start-1 w-full h-full z-0`]}
          />
          <motion.div
            initial={{
              width: "100%",
            }}
            whileInView={{
              width: 0,
            }}
            viewport={{ once: true, amount: 0.45 }}
            transition={{ duration: 0.25, delay: 0.25 }}
            css={[tw`absolute bottom-0 h-full z-20 bg-white`]}
          />

          <motion.div
            initial={{
              width: "100%",
            }}
            whileInView={{
              width: 0,
            }}
            viewport={{ once: true, amount: 0.45 }}
            transition={{ delay: 0.5, duration: 0.25 }}
            css={[tw`absolute bottom-0 h-full z-10 bg-black`]}
          />
          {/* Image overlay */}
          <div
            css={[
              tw`row-start-1 col-start-1 w-full h-full z-10`,
              `background: linear-gradient(
              to top,
              rgba(245, 245, 245, 1),
              rgba(0, 0, 0, 0) 15%
            );
            @media (min-width: 768px) {
              background: linear-gradient(
                to top,
                rgba(245, 245, 245, 1),
                rgba(0, 0, 0, 0) 10%
              );
            }
            `,
            ]}
          ></div>
        </div>
      )}
      {/* Photograph */}
      {photograph && (
        <motion.div
          viewport={{ once: true, amount: 0.05 }}
          css={[
            tw`col-start-1 col-end-3 row-start-1 row-end-2 justify-center ml-5 my-auto z-30`,
          ]}
        >
          <Photograph
            src={photograph?.image}
            caption={photograph.caption}
            css={[
              tw`opacity-0 invisible transition duration-700 ease-in`,
              tw`xl:(opacity-100 visible)`,
            ]}
            logoImage={logoWithImage || null}
          />
        </motion.div>
      )}
    </section>
  )
}

export default Brochure
