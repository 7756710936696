import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { TealiumContext } from "../../../contexts/Tealium"
import { parseDisclaimerBlocks, stripDisclaimerCodes } from "../../../helpers"
import { ColorSwatch } from "../../atoms/ColorSwatch"
import { ColorSelectorProps, tealClickProps } from "./ColorSelector.d"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../contexts/Disclaimers"

const ColorSelector: React.FC<ColorSelectorProps> = ({
  colorList,
  analyticsId,
  xpSeries = false,
  onChange,
}) => {
  const [selectedSwatch, setSelectedSwatch] = useState<number>(null)
  const [state, modalDispatch] = useContext(DisclaimersContext)

  // If vehicle model or trim does not have enough color options, swatch is reset to 1
  useEffect(() => {
    if (colorList.length <= selectedSwatch) {
      setSelectedSwatch(0)
    }
  }, [colorList])

  useEffect(() => {
    if (selectedSwatch === null) {
      setSelectedSwatch(0)
      return
    }
    onChange(selectedSwatch)
  }, [selectedSwatch])

  const { trackTealEvent } = useTealiumEvent()
  const { vehicleTealData, updateVehicleTealData } = useContext(TealiumContext)

  // Passes data from tagged element to the Tealium link event
  const tealClickEvent = (data: tealClickProps): void => {
    trackTealEvent({
      ...data,
    })
  }
  return (
    <div>
      <div css={[tw`flex justify-center mb-3`]}>
        {colorList.map(({ name, hex }, i) => (
          <ColorSwatch
            name={name}
            color={hex}
            selected={selectedSwatch === i}
            onClick={() => {
              const colorSansDisclaimer = stripDisclaimerCodes(name)
              !{ features_series_prop: xpSeries } &&
                updateVehicleTealData({ exterior_color: colorSansDisclaimer })

              setSelectedSwatch(i)
              tealClickEvent({
                features_series_prop: xpSeries
                  ? `xp color selector|${colorSansDisclaimer}|${vehicleTealData.vehicle_model_trim}`
                  : `color selector|${colorSansDisclaimer}|${vehicleTealData.vehicle_config}`,
                vehicle_model: vehicleTealData.vehicle_model,
                vehicle_model_truncated:
                  vehicleTealData.vehicle_model_truncated,
                vehicle_segment_vehicle_page:
                  vehicleTealData.vehicle_segment_vehicle_page,
                vehicle_year: vehicleTealData.vehicle_year,
              })
            }}
            analytics-id={analyticsId ? `${analyticsId}${i + 1}` : null}
          />
        ))}
      </div>
      <div
        css={[
          tw`text-xs font-semibold uppercase tracking-widest text-gray-700 text-center`,
        ]}
      >
        {parseDisclaimerBlocks(colorList[selectedSwatch | 0]?.name, selection =>
          modalDispatch(toggleDisclaimersModal(selection))
        )}
      </div>
    </div>
  )
}

export default ColorSelector
