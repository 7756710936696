import React from "react"
import tw from "twin.macro"
import { PriceAndMPGProps } from "./PriceAndMPG.d"
import {
  getSeriesMpgDisclaimerCode,
  parseDisclaimerBlocks,
  toUsd,
} from "../../../helpers"
import { useContext } from "react"
import { LanguageContext } from "../../../contexts/Language"
import { Odometer } from "../../atoms/Odometer"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import {
  useElectricPluginSeriesNames,
  electricSeriesNames,
} from "../../../hooks/useElectricPluginSeriesNames"

/**
 * @author Tyler
 * @todo Write variant for Offers Hero item
 */

const PriceAndMPG: React.FC<PriceAndMPGProps> = ({
  msrp,
  asShownMsrp = null,
  mpgCity,
  mpgHighway,
  range,
  light = false,
  inline = false,
  small = false,
  seriesName,
  seriesYear,
  ...remainingProps
}) => {
  const { _ } = useContext(LanguageContext)
  const formattedMsrp = toUsd(msrp)
  const formattedAsShown = toUsd(asShownMsrp)
  const [state, dispatch] = useContext(DisclaimersContext)
  const mpgDisclaimerCode = getSeriesMpgDisclaimerCode(seriesName, seriesYear)
  const pluginSeriesNames = useElectricPluginSeriesNames()
  const electricName = electricSeriesNames()
  const isElectricPluginSeries = pluginSeriesNames.includes(
    seriesName?.toLocaleLowerCase()
  )

  const isAllElectric = electricName.includes(seriesName?.toLocaleLowerCase())

  return (
    <section
      css={[
        tw`flex w-full lg:(max-w-xs)`,
        light && tw`text-gray-50`,
        inline && tw`flex-row`,
        small && tw`gap-7 md:(gap-7 )`,
      ]}
      {...remainingProps}
    >
      <div css={[tw`flex-1 flex flex-col items-center`]}>
        <div
          css={[
            tw`text-4xl text-center mb-3`,
            small && tw`text-xl lg:text-2xl`,
          ]}
        >
          ${formattedMsrp}
        </div>
        <p
          css={[
            tw`text-xs pb-4 italic text-center max-w-[125px]`,
            small &&
              `
                & {
                  font-size: 0.75rem;
                  color: #b2b2b2;
                }
                @media (max-width: 1024px) {
                  font-size: 0.5rem;
                }`,
          ]}
        >
          {asShownMsrp ? (
            <>
              {_("Starting MSRP")}
              <br />
              {_("as shown")} <span>$</span>
              {formattedAsShown}{" "}
              {parseDisclaimerBlocks(`[starting_msrp]`, code =>
                dispatch(toggleDisclaimersModal(code))
              )}
            </>
          ) : (
            <>
              {_("Base MSRP")}{" "}
              {parseDisclaimerBlocks(`[base_msrp]`, code =>
                dispatch(toggleDisclaimersModal(code))
              )}
            </>
          )}
        </p>
      </div>
      <div css={[tw`flex-1 flex flex-col items-center`]}>
        <div
          css={[
            tw`text-4xl text-center pb-3`,
            small && tw`text-xl lg:text-2xl`,
          ]}
        >
          {isAllElectric ? (
            <span>{range} mi</span>
          ) : (
            <>
              {mpgCity}/{mpgHighway}
            </>
          )}
        </div>
        <p
          css={[
            tw`text-xs italic text-center max-w-[125px]`,
            small &&
              `
                & {
                  font-size: 0.75rem;
                  color: #b2b2b2;
                }
                @media (max-width: 1024px) {
                  font-size: 0.5rem;
                }`,
          ]}
        >
          {isElectricPluginSeries && _("estimated MPG/MPGe")}
          {!isElectricPluginSeries && !isAllElectric && _("estimated MPG")}
          {isAllElectric && "Range"}
          {parseDisclaimerBlocks(mpgDisclaimerCode, code =>
            dispatch(toggleDisclaimersModal(code))
          )}
        </p>
      </div>
    </section>
  )
}

export default PriceAndMPG
