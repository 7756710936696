import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import tw from "twin.macro"
import { Image } from "../../atoms/Image"
import Icon from "../../atoms/Icon"
import { Modal } from "../../atoms/Modal"
import { Button } from "../../atoms/Button"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"
import { XseriesHotspotsProps } from "./XseriesHotspots.d"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { scrollIntoView } from "seamless-scroll-polyfill"
import { LanguageContext } from "../../../contexts/Language"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { parseDisclaimerBlocks } from "../../../helpers"
import XseriesHotspot from "./XseriesHotspot"
import { Hotspot } from "../../organisms/XseriesModel/XseriesModel.d"

const XseriesHotspots: React.FC<XseriesHotspotsProps> = ({
  hotspots,
  setActiveSpot,
  activeSpot,
  currentAngle,
  setCurrentAngle,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const isMounted = useRef(false)
  const { language } = useContext(LanguageContext)
  const [state, dispatch] = useContext(DisclaimersContext)
  const parentXseriesHotspotContainer = useRef(null)

  const getDisclaimerParentPosition = (): { left: number; right: number } => {
    if (window?.innerWidth < 768) return { left: null, right: null }

    const parentContainerRect =
      parentXseriesHotspotContainer?.current?.getBoundingClientRect()

    const parentContainerStyle = getComputedStyle(
      parentXseriesHotspotContainer?.current
    )
    const paddingLeft =
      Number(parentContainerStyle?.paddingLeft?.replace("px", "")) + 10
    const paddingRight =
      Number(parentContainerStyle?.paddingRight?.replace("px", "")) + 10

    return {
      left: parentContainerRect?.left + paddingLeft,
      right: parentContainerRect?.right - paddingRight,
    }
  }

  useEffect(() => {
    if (activeSpot !== null) {
      if (isMounted.current) {
        //only run this after the component has mounted -- fixes bug where page would scroll here on initial load
        const element = document.getElementById(`hotspot-${activeSpot?._key}`)
        if (element) {
          // Built in .scrollIntoView HTML DOM  Method (with smooth behavior) is not supported in Safari - using polyfill plugin as workaround
          scrollIntoView(element, {
            behavior: "smooth",
            inline: "start",
            block: "nearest",
          })
        }
      } else {
        isMounted.current = true
      }
    }
  }, [activeSpot])

  const responsive = {
    0: { items: 1 },
    760: { items: 2 },
    1500: { items: 3 },
  }

  const [items, setItems] = useState([])

  useEffect(() => {
    const disclaimerParentPosition = getDisclaimerParentPosition()
    setItems(
      hotspots?.map((hotspot, i) => (
        <div css={[tw`pb-28`]}>
          <XseriesHotspot
            hotspot={hotspot}
            activeSpot={activeSpot}
            setActiveSpot={setActiveSpot}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            parentLeft={disclaimerParentPosition.left}
            parentRight={disclaimerParentPosition.right}
            currentAngle={currentAngle}
            setCurrentAngle={setCurrentAngle}
            imageIndex={i}
          />
        </div>
      ))
    )
  }, [hotspots, activeSpot, currentAngle])

  const updateSliderPosition = (step: number) => {
    const currentHotspotIndex = hotspots?.findIndex(
      (spot: Hotspot) => spot?._key === activeSpot?._key
    )
    let newHotspotIndex = currentHotspotIndex + step

    if (newHotspotIndex < 0) {
      newHotspotIndex = hotspots?.length - 1
    } else if (newHotspotIndex > hotspots?.length - 1) {
      newHotspotIndex = 0
    }

    const newActiveSpot: Hotspot = hotspots[newHotspotIndex]
    if (newActiveSpot?.angle && newActiveSpot.angle !== currentAngle) {
      setCurrentAngle(newActiveSpot.angle)
    }
    if (newActiveSpot) setActiveSpot(newActiveSpot)
  }

  const renderPrevButton = ({}) => {
    return (
      <button
        css={[tw`absolute left-4 top-[18%]`, tw`md:(top-1/3 -left-8)`]}
        onClick={() => updateSliderPosition(-1)}
      >
        <Icon.Chevron direction="left" color="red-400" css={[tw`w-10`]} />
      </button>
    )
  }

  const renderNextButton = ({}) => {
    return (
      <button
        css={[tw`absolute right-4 top-[18%]`, tw`md:(top-1/3 -right-8)`]}
        onClick={() => updateSliderPosition(1)}
      >
        <Icon.Chevron direction="right" color="red-400" css={[tw`w-10`]} />
      </button>
    )
  }

  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  return (
    <div css={[tw`mx-auto max-w-7xl`]}>
      <div
        css={[
          tw`mt-20 overflow-y-visible`,
          tw`md:(px-16 mt-36)`,
          tw`lg:(mt-0 px-4)`,
          tw`xl:(px-8)`,
          tw`2xl:(px-12 mt-20)`,
          tw`desktop-hd:(mt-96)`,
        ]}
        ref={parentXseriesHotspotContainer}
      >
        <AliceCarousel
          mouseTracking
          items={items}
          disableDotsControls
          responsive={responsive}
          paddingLeft={0}
          paddingRight={0}
          controlsStrategy="responsive"
          renderPrevButton={renderPrevButton}
          renderNextButton={renderNextButton}
        />
        <Modal
          open={modalOpen}
          closeStyled={false}
          onClose={() => {
            setModalOpen(false)
          }}
          css={[tw`w-full h-screen md:(w-1/2 h-auto)`]}
        >
          {hotspots && (
            <>
              <Image
                imageData={activeSpot?.image}
                alt={"title"}
                css={[tw`mt-14`]}
              />

              <div css={[tw`p-8 text-center`]}>
                <h2 css={[tw`mb-4`]}>
                  <p css={tw`font-semibold`}>
                    {language === "es"
                      ? parseDisclaimerBlocks(activeSpot?.titleES, selection =>
                          dispatch(toggleDisclaimersModal(selection))
                        )
                      : parseDisclaimerBlocks(activeSpot?.title, selection =>
                          dispatch(toggleDisclaimersModal(selection))
                        )}
                  </p>
                </h2>
                <p css={[tw`text-sm pb-4`]}>
                  {language === "es"
                    ? parseDisclaimerBlocks(
                        activeSpot?.descriptionES,
                        selection => dispatch(toggleDisclaimersModal(selection))
                      )
                    : parseDisclaimerBlocks(
                        activeSpot?.description,
                        selection => dispatch(toggleDisclaimersModal(selection))
                      )}
                </p>
              </div>
            </>
          )}
        </Modal>
      </div>
    </div>
  )
}

export default XseriesHotspots
