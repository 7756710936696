import { graphql, PageProps } from "gatsby"
import React, { createContext, useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { Layout } from "../components/atoms/Layout"
import { Dealer } from "../global"
import { DealerMap } from "../components/molecules/DealerMap"
import DealerHours from "../components/organisms/DealerDetail/Hours"
import DealerHeader from "../components/organisms/DealerDetail/Header"
import DealerDepartments from "../components/organisms/DealerDetail/Departments"
import DealerContact from "../components/organisms/DealerDetail/Contact"
import DealerAwards from "../components/organisms/DealerDetail/Awards"
import { Pixlee } from "../components/molecules/Pixlee"
import FeaturedOffersSection from "../components/organisms/DealerDetail/FeaturedOffersSection"
import { SEO } from "../components/atoms/SEO"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import AdditionalLinks from "../components/organisms/DealerDetail/AdditionalLinks"
import { useTealiumContext } from "../contexts/Tealium"
import TactContactDealer from "../components/molecules/ContactDealer/TactContactDealer"
import { ContactContext } from "../contexts/Contact"
import {
  openTactContactDealerModal,
  closeTactContactDealerModal,
} from "../contexts/Contact/actions"
import useOffersData from "../hooks/useOffersData"
import { css } from "@emotion/react"
import useTealiumEvent from "../hooks/Tealium/useTealiumEvent"
import { LanguageContext } from "../contexts/Language"
import DealerFavorites from "../components/organisms/DealerDetail/FavoritesSection"
import DealerRecentlyViewed from "../components/organisms/DealerDetail/RecentlyViewedSection"
import useViewportSize from "../hooks/useViewportSize"
import { detectMobileView, windowExists } from "../helpers"

type DealerDetailsProps = {
  data: {
    dealer: Dealer
    pixleeAlbum: {
      album_id: number
    }
    offers: {
      nodes: any
    }
  }
  favoriteMessage: string
  pageContext: {
    canonicalPath: string
  }
}

export const DealerDetailsPageContext = createContext({
  dealer: null,
})

const DealerDetails: React.FC<PageProps & DealerDetailsProps> = ({
  data,
  location,
  favoriteMessage,
  pageContext,
}) => {
  const { dealer, pixleeAlbum, offers } = data
  const allOffers = useOffersData()
  const albumId = pixleeAlbum?.album_id
  const [{ isTactContactDealerModalOpen }, dispatch] =
    useContext(ContactContext)
  const [mobileView, setMobileView] = useState(false)
  const { width } = useViewportSize()

  useEffect(() => {
    if (windowExists) {
      const isMobileView = detectMobileView()
      setMobileView(isMobileView)
    }
  }, [width])

  const { language, _ } = useContext(LanguageContext)
  const categories = ["Favorites", "Recently Viewed"]
  const [dealerTab, setDealerTab] = useState("favorites")

  const customDealerSchema = `
  {
    "@context": "[http://schema.org](http://schema.org/)",

    "@type": "AutoDealer",

    "name": "${dealer.Name}",

    "image": "https://cdn.sanity.io/images/90qxor39/production/9bf3dd539c9eac39c1a4b2465e19d7191702222e-500x415.jpg",

    "description": "Visit your local Southeast Toyota Dealer today for new vehicles, used vehicles, Toyota Parts, and Toyota Service",

    "url": "https://exploresetoyota.com${location.pathname}",
  }
`
  const tabAnimation = css`
    &:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
    content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:hover:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const focusAnimation = css`
    &:focus-visible:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
    content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:focus-visible:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const active = css`
    ${tw`font-semibold focus-visible:(border-transparent)`}
    &:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
    &:hover:after {
      ${tw`h-1 bg-red-400`}
    }
    &:focus-visible:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
  `

  const redUnderlineAnimation = css`
    &:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-red-400 origin-bottom-left transition-transform ease-out duration-300`}
      content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:hover:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left`}
      }
    }
    &:focus-visible:after {
      transform: scaleX(1);
      ${tw`origin-bottom-left`}
    }
  `

  //Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { handleTealView } = useTealiumView()
  const { updateContactDealerData } = useTealiumContext()

  useEffect(() => {
    handleTealView({
      page_name: `fyd details`,
      tealium_event: "dealer_details",
      page_type: "find your dealer",
      dealer_name: dealer?.Name,
      dealer_code: dealer?.DealerCode,
    })
    updateContactDealerData({
      dealerData: {
        dealer_name: dealer?.Name,
        dealer_code: dealer?.DealerCode,
      },
    })
  }, [])

  return (
    <DealerDetailsPageContext.Provider
      value={{
        dealer: dealer,
      }}
    >
      <Layout>
        <SEO
          url={location.href}
          canonicalPath={pageContext.canonicalPath}
          title={dealer.Name}
          schema={customDealerSchema}
        />
        <DealerHeader />
        <div
          css={[
            tw`col-span-12`,
            tw`md:(block col-start-5 col-span-8 bg-gray-300)`,
          ]}
        >
          <DealerMap
            center={{ lat: dealer.Latitude, lng: dealer.Longitude }}
            dealers={[dealer]}
            allowPan={false}
            mapContainerStyle={{
              height: `${mobileView ? "120" : "200"}px`,
              width: "100%",
            }}
          />
        </div>
        <div
          css={[tw`hidden md:(px-8 pt-6 bg-gradient-to-b from-gray-100 block)`]}
        ></div>
        <div
          css={[
            tw`grid grid-cols-12 grid-rows-1 gap-0 grid-flow-row max-w-desktop mx-auto h-auto px-6 mb-6 mt-4`,
            tw`md:(grid-rows-1 gap-10 px-6 mb-6 mt-0)`,
            tw`xl:(gap-20)`,
          ]}
        >
          <DealerDepartments />
          <div
            css={[
              tw`col-start-1 col-span-12 row-start-1`,
              tw`md:(col-start-7 col-span-6)`,
              tw`lg:(col-start-7 col-span-6)`,
              tw`xl:(col-start-8 col-span-5)`,
              // tw`2xl:(col-start-9 col-span-4)`,
            ]}
          >
            <DealerContact />
            <AdditionalLinks />
            <DealerHours />
            <DealerAwards />
          </div>
        </div>

        <div css={[tw`w-full pt-10 text-center`]}>
          {categories?.map(cat => (
            <button
              onClick={() => {
                trackTealEvent({
                  tealium_event: "nav_click",
                  click_text: `top nav:Favorites:${cat}`,
                })
                setDealerTab(cat.toLowerCase())
              }}
              role="tab"
              css={[
                tabAnimation,
                focusAnimation,
                tw`cursor-pointer whitespace-nowrap inline-block relative py-2 mx-2 capitalize`,
                dealerTab === cat.toLocaleLowerCase() && active,
              ]}
              analytics-id={`${cat}:top nav:favorites|`}
            >
              {_(cat)}
            </button>
          ))}
        </div>
        {dealerTab === "favorites" && categories && (
          <>
            <div
              css={[
                //tw`grid grid-cols-1 grid-rows-2 mb-10 mx-0`,
                //tw`md:(grid grid-cols-2 grid-rows-1 gap-6 max-w-7xl mb-10 !mx-8)`,
                tw`lg:(!mx-auto flex max-w-7xl)`,
              ]}
            >
              <DealerFavorites />
            </div>
          </>
        )}
        {dealerTab === "recently viewed" && categories && (
          <>
            <div
              css={[
                //tw`grid grid-cols-1 grid-rows-2 mb-10 mx-0`,
                //tw`md:(grid grid-cols-2 grid-rows-1 gap-6 max-w-7xl mb-10 !mx-8)`,
                tw`lg:(!mx-auto flex max-w-7xl)`,
              ]}
            >
              <DealerRecentlyViewed />
            </div>
          </>
        )}

        {/* Featured offers section */}
        <FeaturedOffersSection dealer={dealer} offers={allOffers} />
        {albumId && <Pixlee albumId={albumId} />}
        <TactContactDealer
          modalIsOpen={isTactContactDealerModalOpen}
          setModalIsOpen={open => {
            if (open) {
              dispatch(openTactContactDealerModal())
            } else {
              dispatch(closeTactContactDealerModal())
            }
          }}
        />
      </Layout>
    </DealerDetailsPageContext.Provider>
  )
}

export const query = graphql`
  query ($id: String, $dealerCode: String) {
    dealer(id: { eq: $id }) {
      ...dealer
    }
    pixleeAlbum(description: { eq: $dealerCode }) {
      album_id
    }
  }
`

export default DealerDetails
