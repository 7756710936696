import React from "react"
import tw from "twin.macro"
import { LogoProps } from "../../../atoms/Logo/Logo.d"
import { processIconColor } from "../../../../helpers"

/**
 * Card Background
 *
 * @param {string} width Can specify width in px
 * @param {string} height Can specify height in px
 * @param {any} ..remainingProps Additional props specified in component declaration
 * @returns <div />
 */

const CardBg: React.FC<LogoProps> = ({
  color,
  wideTitle,
  ...remainingProps
}) => {
  if (wideTitle) {
    return (
      <svg
        viewBox="0 0 382 183"
        height={"auto"}
        width={"100%"}
        fill="none"
        preserveAspectRatio="none"
        {...remainingProps}
      >
        <path
          d="M31 0H20C8.9543 0 0 8.95431 0 20V163C0 174.046 8.9543 183 20 183H362C373.046 183 382 174.046 382 163V20C382 8.9543 373.046 0 362 0H351V5C351 16.0457 342.046 25 331 25H51C39.9543 25 31 16.0457 31 5V0Z"
          fill={color || "#ffffff"}
        />
      </svg>
    )
  }

  return (
    <svg
      width="100%"
      height="180px"
      viewBox="0 0 502 210.99966"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      {...remainingProps}
    >
      <path
        d="M79.2022,0c2.6879,0,4.8376,2.15222,5.4959,4.75828,2.5145,9.95569,11.5304,17.32369,22.2669,17.32369H394.035c10.73599,0,19.75198-7.368,22.267-17.32369,.65799-2.60606,2.80798-4.75828,5.496-4.75828h50.202c16.569,0,30,13.43147,30,29.99997V180.99966c0,16.569-13.431,30-30,30H30c-16.5685,0-30-13.431-30-30V29.99997C0,13.43147,13.4315,0,30,0h49.2022Z"
        fill={color || "#ffffff"}
        fill-rule="evenodd"
      />
    </svg>
  )
}

export default CardBg
