import { css } from "@emotion/react"
import { motion } from "framer-motion"
import React, { useContext, useEffect, useRef, useState } from "react"
import tw from "twin.macro"
import { useTealiumContext } from "../../../contexts/Tealium"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { Carousel, CarouselNavigation } from "../../atoms/Carousel"
import { Image } from "../../atoms/Image"
import { Hotspot } from "../../organisms/XseriesModel/XseriesModel.d"
import { ModelImageViewerWithHotSpotsProps } from "./ModelImageViewerWithHotSpots.d"
import { LanguageContext } from "../../../contexts/Language"
import { stripDisclaimerCodes } from "../../../helpers"

const ModelImageViewerWithHotSpots: React.FC<
  ModelImageViewerWithHotSpotsProps
> = ({
  images,
  currentSlideHotspots,
  activeSpot,
  setActiveSpot,
  setCurrentAngle,
  currentAngle,
  currentColor = null,
  xpSeries = false,
  hotspots = null,
}) => {
  // Setting active hotspot if it is not currently set or if it is not included in the current slide/angle's hotspots.
  useEffect(() => {
    const isActiveSpotOnCurrentSlide = currentSlideHotspots?.find(
      (hotspot: Hotspot) => hotspot?._key === activeSpot?._key
    )
    if (!isActiveSpotOnCurrentSlide && currentSlideHotspots?.length > 0) {
      setActiveSpot(currentSlideHotspots[0])
    }
  }, [currentSlideHotspots])

  const { language } = useContext(LanguageContext)

  /* START - LOGIC FOR TEALIUM */

  const { trackTealEvent } = useTealiumEvent()
  const { vehicleTealData } = useTealiumContext()

  const handleTealClick = (vars: {}) =>
    trackTealEvent({
      ...(vars && vars),
      vehicle_model: vehicleTealData?.vehicle_model,
      vehicle_model_truncated: vehicleTealData?.vehicle_model,
      vehicle_segment_vehicle_page:
        vehicleTealData?.vehicle_segment_vehicle_page,
      vehicle_year: vehicleTealData?.vehicle_year,
    })

  return (
    <div>
      <Carousel slides={images}>
        {({ activeSlide, changeSlide, slides }) => {
          const updateAngle = (slideId: number) => {
            if (slideId >= slides?.length) {
              setCurrentAngle(1)
            } else if (slideId < 0) {
              setCurrentAngle(4)
            } else {
              setCurrentAngle(slideId + 1)
            }
          }

          return (
            <div
              css={[
                tw`relative mb-12 h-48 mx-5 md:(h-60 mb-0 mx-10 my-10) lg:(h-52) xl:(h-96)`,
              ]}
            >
              {slides?.map((image, i) => {
                const active = i + 1 === currentAngle
                return (
                  <motion.div
                    viewport={{ once: true }}
                    initial={{
                      opacity: 0,
                      scale: 0.7,
                    }}
                    whileInView={{
                      opacity: 1,
                      scale: 1,
                    }}
                    transition={{ delay: 0.5, duration: 0.25 }}
                    css={[tw`absolute  h-full`]}
                  >
                    <div css={[tw`relative mb-12  mx-5 lg:mx-16`]}>
                      <Image
                        imageData={image}
                        css={[
                          tw`opacity-0 relative`,
                          active && tw`opacity-100 `,
                        ]}
                      />

                      {currentSlideHotspots?.map((hotspot: Hotspot) => (
                        <motion.div
                          initial={{
                            opacity: 0,
                          }}
                          whileInView={{
                            opacity: 1,
                          }}
                          transition={{ delay: 0.5, duration: 0.5 }}
                        >
                          <div
                            css={[
                              tw`absolute flex h-10 w-10 -ml-3 -mt-3 lg:(-mt-2 -ml-2) desktop-hd:(-mt-0 -ml-0)`,
                              css`
                                left: ${hotspot?.x}%;
                                top: ${hotspot?.y}%;
                              `,
                            ]}
                            onClick={() => {
                              const title =
                                language === "en"
                                  ? hotspot.title
                                  : hotspot.titleES

                              const featureTitle = hotspot.title
                                .replace(/\[.*?\]/g, "") //remove square brackets and contents
                                .replace(/\s*$/, "") //remove trailing space
                              handleTealClick({
                                features_series_prop: `xp feature|${featureTitle}|${vehicleTealData?.vehicle_model_trim}`,
                              })
                              setActiveSpot(hotspot)
                            }}
                            analytics-id={`hotspot:xseries features:${
                              hotspots?.indexOf(hotspot) + 1
                            }`}
                          >
                            {active && (
                              <>
                                <span
                                  css={[
                                    tw``,
                                    activeSpot?._key === hotspot?._key &&
                                      tw`animate-pingAnimation absolute inline-flex w-full h-full rounded-full bg-gray-50 opacity-75`,
                                  ]}
                                ></span>
                                <span
                                  css={[
                                    active &&
                                      tw`relative inline-flex rounded-full h-10 w-10 bg-gray-50 border-2 border-red-400 opacity-50 shadow-1`,
                                    activeSpot?._key === hotspot?._key &&
                                      tw`bg-red-500 opacity-50`,
                                  ]}
                                ></span>
                              </>
                            )}
                          </div>
                        </motion.div>
                      ))}
                    </div>
                  </motion.div>
                )
              })}
              <motion.div
                viewport={{ once: true }}
                initial={{
                  opacity: 0,
                }}
                whileInView={{
                  opacity: 1,
                }}
                transition={{ delay: 1, duration: 0.25 }}
              >
                <CarouselNavigation
                  direction="prev"
                  color="white"
                  onClick={() => {
                    changeSlide(activeSlide - 1)
                    updateAngle(activeSlide - 1)
                    handleTealClick({
                      features_series_prop: xpSeries
                        ? `xp 360|${stripDisclaimerCodes(currentColor)}|${
                            vehicleTealData?.vehicle_model_trim
                          }`
                        : `360|${stripDisclaimerCodes(
                            vehicleTealData?.exterior_color
                          )}|${vehicleTealData?.vehicle_model_trim}`,
                    })
                  }}
                  css={[
                    tw`absolute left-[31%] -bottom-12 bg-black`,
                    tw`md:(left-0 top-1/2 -ml-8 -mt-8 hover:(bg-gray-600))`,
                    tw`focus-visible:(outline-color[#000])`,
                  ]}
                  analytics-id={`left arrow:xseries features:exterior 360`}
                />
                <CarouselNavigation
                  direction="next"
                  color="white"
                  onClick={() => {
                    changeSlide(activeSlide + 1)
                    updateAngle(activeSlide + 1)
                    handleTealClick({
                      features_series_prop: xpSeries
                        ? `xp 360|${stripDisclaimerCodes(currentColor)}|${
                            vehicleTealData?.vehicle_model_trim
                          }`
                        : `360|${stripDisclaimerCodes(
                            vehicleTealData?.exterior_color
                          )}|${vehicleTealData?.vehicle_model_trim}`,
                    })
                  }}
                  css={[
                    tw`absolute right-[31%] -bottom-12 bg-black`,
                    tw`md:(right-0 top-1/2 -mr-8 -mt-8 hover:(bg-gray-600))`,
                    tw`focus-visible:(outline-color[#000])`,
                  ]}
                  analytics-id={`right arrow:xseries features:exterior 360`}
                />
              </motion.div>
            </div>
          )
        }}
      </Carousel>
    </div>
  )
}

export default ModelImageViewerWithHotSpots
