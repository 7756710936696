import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { TemplateProps, SeriesAccessoriesTemplatePageData } from "./templates"
import { graphql } from "gatsby"
import { Layout } from "../components/atoms/Layout"
import tw from "twin.macro"
import AccyBlocks from "../components/organisms/Accessories/Blocks"
import AccyModels from "../components/organisms/Accessories/Models"
import AccysFiltered from "../components/organisms/Accessories/Filtered"
import AccyFilters from "../components/organisms/Accessories/Filters"
import { SEO } from "../components/atoms/SEO"
import { Model } from "../global"
import { Accessory } from "../components/organisms/Accessories"
import { css } from "@emotion/react"
import { LanguageContext } from "../contexts/Language"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import { sanitizeSeries, vehicleCategory } from "../helpers"
import { DisclaimersContext } from "../contexts/Disclaimers"
import { setDisclaimers } from "../contexts/Disclaimers/actions"
import { Disclaimer } from "../contexts/Disclaimers/disclaimers.d"
import _ from "lodash"
import FamilySeries from "../components/organisms/Accessories/FamilySeries"
import { scrollIntoView } from "seamless-scroll-polyfill"
import { HeaderContext } from "../contexts/Header"
import PartAndServiceHero from "../components/organisms/PartAndServiceHero/PartAndServiceHero"
import CtaBox from "../components/molecules/Heros/CtaBox"
import { SeriesPageContext } from "./series"
import PartInfo from "../components/organisms/Part/Info"
import PartHighlights from "../components/organisms/Part/Highlights"
import TabbedImageModule from "../components/molecules/TabbedImageModule/TabbedImageModule"

export const AccessoriesPageContext = createContext({
  page: null,
  accessories: null,
  filteredAccessories: null,
  setFilteredAccessories: null,
  selectedCategories: null,
  setSelectedCategories: null,
  selectedYears: null,
  setSelectedYears: null,
  selectedModelGroup: null,
  setSelectedModelGroup: null,
  models: null,
  hybridsOnly: null,
  selectedAccyType: null,
  selectedFilter: null,
  setSelectedFilter: null,
  seriesSlug: "",
})

const AccessoriesTemplate = ({
  data,
  location,
}: TemplateProps<SeriesAccessoriesTemplatePageData>) => {
  const { page, accessories } = data
  const seriesSlug =
    page?.vehicles?.length > 0 ? page?.vehicles[0]?.series?.slug : ""

  const maxYear = page.vehicles.reduce(
    (acc: number, curr: { year: number }) => {
      if (curr.year > acc) {
        return curr.year
      }
      return acc
    },
    0
  )

  const [selectedYears, setSelectedYears] = useState([maxYear])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedModelGroup, setSelectedModelGroup] = useState([])
  const [selectedAccyType, setSelectedAccyType] = useState("port")
  const [models, setModels] = useState([])
  const language = useContext(LanguageContext)
  const [disclaimers, dispatch] = useContext(DisclaimersContext)
  const { viewedAcc, setViewedAcc } = useContext(HeaderContext)

  const portAccyNote = language._(
    " Browse accessories that are available for applicable trims, many of which are exclusive to Local Toyota Dealers in Florida, Georgia, North Carolina, South Carolina and Alabama."
  )

  const factoryAccyNote = language._(
    "Browse options that are included with applicable trims at production. Contact your Local Toyota Dealer to learn more."
  )

  //default to showing the accys for the most recent year
  const yearFilteredAccessories = accessories.nodes.filter(
    (accessory: { year: number }) => accessory.year === maxYear
  )

  const [filteredAccessories, setFilteredAccessories] = useState(
    yearFilteredAccessories
  )

  const { series } = useContext(SeriesPageContext)

  const vehicles = page.vehicles
  let allModels: any = []
  vehicles?.forEach(vehicle => {
    const vehicleModels = vehicle?.models?.map(
      (model: Model | Model[]): Model[] => {
        if (model.hasOwnProperty("models")) {
          let values = model.models?.map((model: { model: Model }) => model)
          values.title = model?.title
          values.modelYear = model.models[0]?.model?.year
          return values
        } else {
          let value = [model]
          value.title = model?.model?.name
          value.modelYear = model?.model?.year
          return value
        }
      }
    )
    allModels = [...allModels, ...vehicleModels]
  })

  const factoryAccessories = accessories.nodes.filter(
    (accessory: Accessory) => accessory.type === "factory"
  )
  const portAccessories = accessories.nodes.filter(
    (accessory: Accessory) => accessory.type === "port"
  )

  const filters = [
    { label: language._("Exclusive Accessories"), type: "port" },
    { label: language._("Factory Options"), type: "factory" },
  ]

  const getModelGroupsByYears = (years: number[]) => {
    const modelGroupsByYear = allModels?.filter(modelGroup => {
      return years?.includes(modelGroup.modelYear)
    })
    return modelGroupsByYear
  }

  useEffect(() => {
    if (selectedAccyType === "port") {
      setFilteredAccessories(portAccessories)
    } else {
      setFilteredAccessories(factoryAccessories)
    }
  }, [selectedAccyType])

  useEffect(() => {
    let modelGroupsByYear = []
    if (selectedYears?.length === 0) {
      modelGroupsByYear = allModels
    } else {
      modelGroupsByYear = getModelGroupsByYears(selectedYears)
    }
    setModels(modelGroupsByYear)

    if (
      selectedModelGroup &&
      !selectedYears?.includes(selectedModelGroup?.modelYear)
    ) {
      setSelectedModelGroup([])
    }
  }, [selectedYears])

  useEffect(() => {
    const initialModels = getModelGroupsByYears([maxYear])
    setModels(initialModels)
  }, [])

  // Adding accessories' disclaimers to disclaimers context
  useEffect(() => {
    let seriesAccessoriesDisclaimers: Array<any> = []
    accessories?.nodes?.forEach(accessory => {
      if (accessory?.disclaimers?.length > 0) {
        seriesAccessoriesDisclaimers = [
          ...seriesAccessoriesDisclaimers,
          ...accessory.disclaimers,
        ]
      }
    })

    if (seriesAccessoriesDisclaimers.length < 1) return

    const containsAllAccessoryDisclaimers = seriesAccessoriesDisclaimers?.every(
      (disclaimer: Disclaimer) =>
        disclaimers.disclaimers.some(
          (existingDisclaimer: Disclaimer) =>
            existingDisclaimer.code === disclaimer.code
        )
    )

    if (!containsAllAccessoryDisclaimers) {
      const newDisclaimers = _.unionBy(
        seriesAccessoriesDisclaimers,
        disclaimers.disclaimers,
        "code"
      )
      dispatch(setDisclaimers(newDisclaimers))
    }
  }, [disclaimers])

  // scroll to accessory if coming from fav panel "view details" button
  useEffect(() => {
    if (typeof document === "undefined") return
    if (filteredAccessories && viewedAcc?.fromPanel && viewedAcc?.code) {
      viewedAcc?.type && setSelectedAccyType(viewedAcc?.type)
      setTimeout(() => {
        const acc = document?.getElementById(`acc-${viewedAcc?.code}`)
        if (acc) {
          scrollIntoView(acc, {
            behavior: "smooth",
            inline: "center",
            block: "center",
          })
          setViewedAcc(null)
        }
      }, 500)
    }
  }, [viewedAcc])

  // Tealium
  const { handleTealView } = useTealiumView()

  useEffect(() => {
    const series = vehicles[0].series.name
    const serieslc = sanitizeSeries(series)
    const category = vehicleCategory(series)
    handleTealView({
      tealium_event: "accessories_series",
      page_type: "accessories",
      page_name: `${category}_${serieslc}`,
      vehicle_model: series,
    })
  }, [])
  let seriesName: Model[]
  console.log(series?.name)
  return (
    <AccessoriesPageContext.Provider
      value={{
        page: page,
        accessories: accessories.nodes,
        filteredAccessories,
        setFilteredAccessories,
        selectedCategories,
        setSelectedCategories,
        selectedYears,
        setSelectedYears,
        selectedModelGroup,
        setSelectedModelGroup,
        models: models,
        selectedAccyType: selectedAccyType,
        seriesSlug: seriesSlug,
      }}
    >
      <Layout>
        <SEO
          url={location.href}
          title={page.title}
          keywords={page.seo?.keywords}
          schema={page.seo?.schema}
          description={page.seo?.description}
          canonicalPath={page.seo?.canonicalPath}
        />
        <FamilySeries series={seriesSlug} isOpened={false} />
        <SanitySections data={page} />
        <section
          aria-label="Accessories Hero Section"
          css={[tw`grid grid-cols-1`]}
        >
          <div
            css={[
              tw`col-span-full`,
              tw`md:(col-start-auto col-span-9 relative w-full)`,
            ]}
            id="accSection"
          >
            <AccyModels />
            <section
              aria-label="Accessories By Model Section"
              css={[
                tw`grid grid-cols-12 grid-rows-1`,
                tw`lg:(w-auto h-auto mt-4)`,
              ]}
            >
              <article
                aria-label="Accessories Filter"
                css={[
                  tw`col-start-1 col-end-13 lg:(col-end-4) xl:(col-span-3 col-end-4 pl-8)`,
                ]}
              >
                <AccyFilters />
              </article>
              <article
                aria-label="Filtered Accessories"
                css={[
                  tw`p-5 col-start-1 col-end-13 mx-2 lg:(col-start-4 col-span-9 mx-6) xl:(col-start-4 col-span-9)`,
                ]}
              >
                <div css={[tw`flex justify-center`]}>
                  {filters &&
                    filters.map((filter, i) => {
                      const tabAnimation = css`
                        &:after {
                          ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
                          ${tw`h-px`}
                          content: "";
                          transform: scaleX(0);
                        }
                        @media (min-width: 1024px) {
                          &:hover:after {
                            transform: scaleX(1);
                            ${tw`origin-bottom-left bg-gray-900 h-px`}
                          }
                        }
                      `
                      const focusAnimation = css`
                        &:focus-visible:after {
                          ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
                          ${tw`h-px`}
                          content: "";
                          transform: scaleX(0);
                        }
                        @media (min-width: 1024px) {
                          &:focus-visible:after {
                            transform: scaleX(1);
                            ${tw`origin-bottom-left bg-gray-900 h-px`}
                          }
                        }
                      `
                      const active = css`
                        ${tw`font-semibold focus-visible:(border-gray-900 border-dashed border outline-none)`}
                        &:after {
                          transform: scaleX(1);
                          ${tw`h-1 bg-red-400`}
                        }
                        &:hover:after {
                          ${tw`h-1 bg-red-400`}
                        }
                        &:focus-visible:after {
                          transform: scaleX(1);
                          ${tw`h-1 bg-red-400`}
                        }
                      `
                      return (
                        <div css={[tw`flex px-4`, tw`lg:(flex gap-6 px-4)`]}>
                          <button
                            css={[
                              tabAnimation,
                              focusAnimation,
                              tw`cursor-pointer whitespace-nowrap inline-block relative py-2 border border-transparent`,
                              tw`lg:(m-4)`,
                              tw`focus-visible:(border-gray-900 border-dashed border outline-none)`,
                              selectedAccyType === filter.type && active,
                            ]}
                            onClick={() => setSelectedAccyType(filter.type)}
                            aria-selected={
                              selectedAccyType === filter.type ? true : false
                            }
                          >
                            {filter.label}
                          </button>
                        </div>
                      )
                    })}
                </div>
                <p
                  css={[
                    tw`text-xs text-left mt-4 mx-2`,
                    tw`lg:(text-center mt-3 mx-20)`,
                    tw`xl:(mx-48)`,
                  ]}
                >
                  {selectedAccyType === "port" ? (
                    <span>{portAccyNote}</span>
                  ) : (
                    <span>{factoryAccyNote}</span>
                  )}
                </p>
                <AccysFiltered />
              </article>
            </section>
          </div>
        </section>
        <AccyBlocks accyBlocks={page.imageBlocks} />
      </Layout>
    </AccessoriesPageContext.Provider>
  )
}

const SanitySections = ({ data }: any): JSX.Element => {
  return (
    <>
      {data?.content?.map((section: any) => {
        switch (section?._type) {
          case "partsAndServiceHero":
            return <PartAndServiceHero {...section} />
          case "tabbedImageModule":
            return <TabbedImageModule {...section} />
          case "partInfoSection":
            return (
              <>
                <PartInfo {...section} />
                <PartHighlights {...section} />
              </>
            )
        }
      })}
    </>
  )
}

export const query = graphql`
  query SeriesAccessoriesPageQuery($id: String, $seriesId: String) {
    page: sanityPageSeriesAccessories(id: { eq: $id }) {
      id
      _type
      title
      seo {
        keywords
        schema
        description
        canonicalPath
      }
      imageBlocks {
        _type
        imageBlocks {
          _key
          body
          heading
          type
          imageLink {
            _type
            linkType
          }
          externalMedia {
            _type
            embedUrl
            meta
            originalLink
            url
          }
          image {
            _type
            image {
              _type
              asset {
                altText
                _id
                gatsbyImageData
              }
            }
          }
        }
      }
      vehicles {
        id
        year
        series {
          name
          msrp
          _id
          slug
        }
        models {
          ... on SanityVehicleModelGroup {
            title
            models {
              featuredColor {
                code
              }
              ...model
            }
          }
          ... on SanityVehicleModel {
            featuredColor {
              code
            }
            ...model
          }
        }
        featuredAccessories {
          _id
          title
          subCategory
          code
          media {
            ... on SanityImage {
              _key
              _type
              asset {
                altText
                _id
                gatsbyImageData
              }
            }
          }
        }
      }
      content {
        ...PartsAndServiceHeroData
        ...PartInfoData
        ...CallToActionBoxData
        ...TabbedImageModuleData
      }
    }
    accessories: allSanityAccessory(
      filter: { series: { id: { eq: $seriesId } } }
    ) {
      nodes {
        ...Accessory
      }
    }
  }
`

export default AccessoriesTemplate
