import React, { useContext } from "react"
import tw from "twin.macro"
import { ContactContext } from "../../../../contexts/Contact"
import {
  setActiveView,
  setPreviousView,
} from "../../../../contexts/Contact/actions"
import { LanguageContext } from "../../../../contexts/Language"
import { reformattedContactView } from "../../../../helpers"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import { FormHeadingProps } from "./FormPage.d"

const FormHeading: React.FC<FormHeadingProps> = ({
  showFormHeading = true,
  formHeading,
  ...remainingProps
}) => {
  const [{ dealer, activeView }, dispatch] = useContext(ContactContext)
  const { _ } = useContext(LanguageContext)
  const handleClick = e => {
    e.preventDefault()
    trackTealEvent({
      tealium_event: "contact_form_nav",
      contact_question: activeView,
      dropdown_selected: "Change",
    })
    if (activeView != "SelectDealerView") {
      dispatch(setPreviousView(activeView))
    }
    if (activeView === "SelectDealerView") {
      dispatch(setActiveView("DealerLocationView"))
    } else {
      dispatch(setActiveView("ConfirmZip"))
    }
  }
  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  return (
    <div css={tw`lg:(h-auto)`} {...remainingProps}>
      {showFormHeading && (
        <>
          <p
            css={[
              tw`text-2xl font-semibold normal-case pr-28 leading-6 lg:pr-0`,
            ]}
          >
            {formHeading || dealer?.Name}
          </p>
          <button
            css={tw`text-red-400 text-xs py-1.5 z-10 relative inline-block`}
            onClick={e => handleClick(e)}
            analytics-id={`Change:contact form:${reformattedContactView(
              activeView
            )}`}
          >
            {_("Change")}
          </button>
        </>
      )}
    </div>
  )
}

export default FormHeading
