import { PixleePhoto, PixleeResponse } from "./PixleeClient.d"

export class PixleeClient {
  private static key: string = process.env.GATSBY_PIXLEE_API_KEY //todo move to env

  /**
   * Makes an arbitrary call to the Dealers API
   *
   * @param {string} endpoint - The endpoint to call beyond the base URL
   * @param {"GET"|"POST"|"DELETE"|"UPDATE"} method - Which HTTP method to use
   * @param {any} params - Key/value object that will be converted to URL query params in the call
   *
   * @returns {Promise<T>} - JSON response from the API
   */
  private static fetch = async <T>(
    endpoint: string,
    params: any
  ): Promise<T> => {
    const baseUrl: string = `https://distillery.pixlee.co/api/v2/albums${endpoint}`
    const queryParams = {
      ...params,
      api_key: this.key,
    }
    // console.log("queryParams", queryParams)
    const url = `${baseUrl}?${new URLSearchParams(queryParams).toString()}`

    try {
      const res = await fetch(url)
      const json: T = await res.json()
      return json
    } catch (error) {
      console.error(`Couldn't fetch from Pixlee.`, error)
    }
  }

  /**
   * Get n number of photos in album
   *
   * @param {number} albumId - The album ID to pull photos from
   * @param {number} page - Which page to return
   * @param {number} per_page - How many photos to return per page
   *
   * @returns {PixleePhoto[]} - Array of photos
   */
  public static getForAlbum = async (
    albumId: number,
    per_page: number = 15,
    page: number = 1,
    filters: any = {}
  ): Promise<PixleeResponse> => {
    let params: any = {
      albumId,
      page,
      per_page,
      filters,
    }

    return await this.fetch(`/${params.albumId}/photos`, params)
  }
}
