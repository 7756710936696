import { motion, useAnimation } from "framer-motion"
import React, { useContext, useEffect } from "react"
import { useInView } from "react-intersection-observer"
import tw from "twin.macro"
import { LanguageContext } from "../../../contexts/Language"
import { Offer } from "../../../global"
import { Link } from "../../atoms/Link"
import { OfferCard } from "../../molecules/OfferCard"
import { ThreeArrowAnimation } from "../../molecules/ThreeArrowAnimation"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"

const FeaturedOffersSection: React.FC<any> = ({
  dealer,
  offers,
  _key,
  sectionSlug,
}) => {
  const { language, _ } = useContext(LanguageContext)

  const featuredOffers = [
    offers?.find((node: Offer) => node?.cardLabel === "APR"),
  ]
    .concat(offers?.find((node: Offer) => node?.cardLabel === "Lease"))
    .concat(offers?.find((node: Offer) => node?.cardLabel === "Cash"))

  const cardVariants = {
    offscreen: {
      y: 300,
    },
    onscreen: (i: number) => ({
      y: 50,
      transition: {
        type: "spring",
        duration: 0.8,
        delay: i * 0.1,
      },
    }),
  }
  const fade = useAnimation()
  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView) {
      fade.start("visible")
    }
  }, [fade, inView])

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  return (
    <section
      key={_key}
      id={sectionSlug?.current}
      css={[
        tw`flex flex-col justify-start gap-2 pt-8 pb-20`,
        tw`lg:(flex-row justify-center items-center gap-14 pt-0 pb-20)`,
      ]}
    >
      <div css={[tw`ml-8`]}>
        {dealer?.Name && (
          <div
            css={[
              tw`w-full text-3xl font-light`,
              tw`lg:(text-3xl max-w-[300px])`,
              "letter-spacing: 6px;",
            ]}
          >
            {dealer?.Name} {_("Offers")}
          </div>
        )}
        <Link
          className="group"
          to="/offers/"
          css={[
            tw`mt-4 font-semibold text-lg leading-6`,
            tw`lg:(inline-flex items-center)`,
            tw`focus-visible:(outline-gray)`,
          ]}
          onClick={() => {
            trackTealEvent({
              tealium_event: "cta_click",
              link_href: "/offers/",
              coupon_module_text: "View All Offers",
            })
          }}
          analytics-id="all offers:more offers:"
        >
          <span>{_("View All Offers")}</span>
          <span css={[tw`inline-block mb-7`, tw`lg:(inline-block mb-10)`]}>
            <ThreeArrowAnimation large />
          </span>
        </Link>
      </div>
      <div css={[tw`overflow-x-hidden`]}>
        <div
          css={[
            tw`grid grid-flow-col gap-6 p-5 pt-0 max-w-max mx-auto overflow-x-auto overflow-y-hidden scrollbar-hide`,
            "background: radial-gradient(farthest-side, #dee2e6, #fff);",
          ]}
        >
          {featuredOffers?.map((node: Offer, i: number) => (
            <motion.div
              initial="offscreen"
              custom={i}
              variants={cardVariants}
              whileInView="onscreen"
              viewport={{ once: true }}
              css={[tw`mb-6`, tw`md:(mb-16)`]}
            >
              <OfferCard
                {...node}
                cardType={node?.cardLabel}
                cardLabel={
                  language === "es" ? node?.cardLabelES : node?.cardLabel
                }
                css={[tw`min-w-[18rem]`]}
                analyticsId={`offer details:more offers:${i + 1}`}
              />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default FeaturedOffersSection
