import React, { useContext } from "react"
import tw from "twin.macro"
import BlockContent from "../../atoms/BlockContent"
import { PartInfoProps } from "./Part.d"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { parseDisclaimerBlocks } from "../../../helpers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const PartInfo: React.FC<PartInfoProps> = ({
  infoBlockHeading,
  infoBlockDescription,
}) => {
  const [state, dispatch] = useContext(DisclaimersContext)

  return (
    <article
      aria-label="Part Description"
      css={[
        tw`relative px-12 max-w-7xl text-center mx-auto py-10 my-10`,
        tw`md:(items-center px-12 h-auto)`,
      ]}
    >
      {infoBlockHeading && infoBlockHeading !== null && (
        <h2
          css={[
            tw`text-2xl tracking-widest font-book mb-8 text-red-300 block`,
            tw`md:(text-5xl)`,
          ]}
        >
          {parseDisclaimerBlocks(infoBlockHeading, selection =>
            dispatch(toggleDisclaimersModal(selection))
          )}
        </h2>
      )}
      {infoBlockDescription && <BlockContent data={infoBlockDescription} />}
    </article>
  )
}

export default PartInfo
