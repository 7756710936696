import { useContext, useEffect, useState } from "react"
import { DealersClient } from "../../clients/DealersClient"
import { LocationContext } from "../../contexts/Location"
import { useTealiumContext } from "../../contexts/Tealium"
import useDealers from "../useDealers"
import useTealiumEvent from "./useTealiumEvent"

/**
 * Provides custom logic for zipcode_go Tealium events. This hook is instantiated for each page from the Layout component.  The optional prop, "tealium" can be passed to Layout in order to customize the behaviour of, and data in Tealium zipcoge_go events for that page.
 *
 * @author Stu & Becca
 * @param {string} location Name (can be any string) of page where the hook is called. this is used for case/switch to provide custom functionality on a page-by-page basis.
 * @param {object} customProperties Optional field for custom values for Tealium calls, provided as {property:value}. The object is spread into Tealium event calls (at this time only for the default logic, but it can be added to other Tealium calls as required)
 * @returns {void}
 */

// Incoming data is state data from the top page level (e.g. dealers)
const useZipCodeGo = (
  location: string,
  customProperties = {},
  pilotDealerCodes: string[] = []
) => {
  const { tealPageData, zipEvent, setZipEvent, setRefineInAction } =
    useTealiumContext()
  const [{ dealers, radius }] = useContext(LocationContext)
  const { trackTealEvent } = useTealiumEvent()
  const { getDealerInfo } = useDealers()

  // This allows the modal events to fire asynchronoously, only once the page level data has loaded (i.e after the tealium page load event)
  const zipcodeGoEvents = async (
    searchInput: string,
    locationValue: string
  ) => {
    // Updated dealers is required only for certain Tealium events (i.e. not for inventory page events)
    switch (locationValue) {
      case "inventoryPage":
        if (zipEvent.prevZip == searchInput) {
          setRefineInAction({
            inProgress: true,
            tealiumEvent: "zipcode_go",
            customer_zip: searchInput,
            confirmZipEvent: true,
          })
        } else {
          setRefineInAction({
            inProgress: true,
            tealiumEvent: "zipcode_go",
            customer_zip: searchInput,
          })
        }
        break
      case "tactPage":
        if (zipEvent.prevZip == searchInput) {
          trackTealEvent({
            tealium_event: "zipcode_go",
            customer_zip: searchInput?.toString(),
            dealer_code: dealers
              ?.filter(dealer => dealer.TactUrl)
              .map(dealer => dealer.DealerCode),
            dealer_name: dealers
              ?.filter(dealer => dealer.TactUrl)
              .map(dealer => dealer.Name),
            search_results: dealers?.filter(dealer => dealer.TactUrl).length,
          })
        } else {
          const dealersInfo = await DealersClient.getForLocation(
            parseInt(searchInput),
            radius
          )
          trackTealEvent({
            tealium_event: "zipcode_go",
            customer_zip: searchInput?.toString(),
            dealer_code: dealersInfo
              ?.filter(dealer => dealer.TactUrl)
              .map(dealer => dealer.DealerCode),
            dealer_name: dealersInfo
              ?.filter(dealer => dealer.TactUrl)
              .map(dealer => dealer.Name),
            search_results: dealersInfo?.filter(dealer => dealer.TactUrl)
              .length,
          })
        }
        break
      case "mobileTirePage":
        if (zipEvent.prevZip == searchInput) {
          // Filtering to pilot dealers
          const pilotDealers = dealers?.filter(dealer =>
            pilotDealerCodes?.includes(dealer.DealerCode)
          )
          trackTealEvent({
            tealium_event: "zipcode_go",
            customer_zip: searchInput?.toString(),
            dealer_code: pilotDealers?.map(dealer => dealer.DealerCode),
            dealer_name: pilotDealers?.map(dealer => dealer.Name),
            search_results: pilotDealers?.length,
          })
        } else {
          let dealersInfo = await DealersClient.getForLocation(
            parseInt(searchInput),
            radius
          )
          // Filtering to pilot dealers
          dealersInfo = dealersInfo?.filter(dealer =>
            pilotDealerCodes?.includes(dealer.DealerCode)
          )

          trackTealEvent({
            tealium_event: "zipcode_go",
            customer_zip: searchInput?.toString(),
            dealer_code: dealersInfo?.map(dealer => dealer.DealerCode),
            dealer_name: dealersInfo?.map(dealer => dealer.Name),
            search_results: dealersInfo?.length,
          })
        }
        break
      case "global":
        trackTealEvent({
          customer_zip: searchInput?.toString(),
        })
        break
      default:
        if (zipEvent.prevZip !== searchInput) {
          const dealersInfo = await DealersClient.getForLocation(
            parseInt(searchInput),
            radius
          )
          trackTealEvent({
            tealium_event: "zipcode_go",
            customer_zip: searchInput?.toString(),
            dealer_code: dealersInfo?.map(dealer => dealer.DealerCode),
            dealer_name: dealersInfo?.map(dealer => dealer.Name),
            search_results: dealersInfo?.length,
            ...customProperties,
          })
        } else {
          const dealerData = getDealerInfo()
          trackTealEvent({
            tealium_event: "zipcode_go",
            customer_zip: searchInput?.toString(),
            dealer_code: dealerData?.dealerCodes,
            dealer_name: dealerData?.dealerNames,
            search_results: dealerData?.searchResults,
            ...customProperties,
          })
        }
        break
    }
  }

  // This useEffect listens to the "zipEvent", "tealPageData", and "dealers" states
  useEffect(() => {
    // "Only fire event when the data (e.g. dealers) is updated and tealPageData is populated"
    if (tealPageData?.page_name && dealers && zipEvent?.inProgress) {
      //Re-set modal event status
      setZipEvent({ inProgress: false })
      if (zipEvent?.isGlobal) {
        // Substitute "global" string value for location if this is triggered by a global use of this event (e.g. Dealers near you, in footer)
        zipcodeGoEvents(zipEvent.searchInput, "global")
      } else {
        zipcodeGoEvents(zipEvent.searchInput, location)
      }
    }
  }, [zipEvent?.inProgress, tealPageData?.page_name, dealers])
}

export default useZipCodeGo
