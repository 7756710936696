import React, { useRef, useState, useEffect, useContext } from "react"
import { ToyotaCareProps } from "./ToyotaCare.d"
import tw from "twin.macro"
import { Image } from "../../../atoms/Image"
import { ButtonLink } from "../../../atoms/Button"
import { motion, useMotionValue } from "framer-motion"
import { LanguageContext } from "../../../../contexts/Language"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { getCTALinkValue, parseDisclaimerBlocks } from "../../../../helpers"
import { useTealiumContext } from "../../../../contexts/Tealium"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import ImageBlock from "../ImageBlock/ImageBlock"

/**
 * @author Tyler
 *
 * @returns <ToyotaCare /> @todo -- the component renders more than just ToyotaCare we should probably look at renaming it
 */

const ToyotaCare: React.FC<ToyotaCareProps> = ({
  headingImage,
  heading,
  body,
  imageWithTextcta,
  image,
  sectionSlug,
  margins,
  imagePosition = "left",
}) => {
  const { _ } = useContext(LanguageContext)
  const [{}, dispatch] = useContext(DisclaimersContext)
  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { vehicleTealData } = useTealiumContext()
  return (
    <section
      css={[
        tw`grid grid-cols-1 overflow-hidden pb-[200px] mb-[-200px]`,
        tw`lg:(grid-cols-2 auto-rows-fr overflow-visible)`,
        `margin: ${margins?.top}px 0 ${margins?.bottom}px !important;`,
      ]}
      id={sectionSlug?.current}
      aria-label="Featured Section"
    >
      <motion.div
        css={[
          tw`bg-white col-span-1 flex gap-8 flex-col justify-end items-start px-8 pt-12 pb-8 z-40`,
          tw`md:(justify-center px-20 py-20 order-2)`,
          tw`lg:(justify-center px-20 py-10 order-2)`,
          imagePosition === "right" && tw`lg:(order-1)`,
        ]}
        initial={{ opacity: 0, x: 0 }}
        whileInView={{
          opacity: 1,
          x: 0,
        }}
        transition={{ duration: 0.25 }}
        viewport={{ once: true, amount: 0.35 }}
      >
        <Image imageData={headingImage?.image} css={tw`mr-1 w-[444px]`} />
        {heading && (
          <h2
            css={[
              tw`font-semibold text-lg md:(text-3xl max-w-[30rem]) 2xl:(max-w-full)`,
            ]}
          >
            {parseDisclaimerBlocks(heading, selection =>
              dispatch(toggleDisclaimersModal(selection))
            )}
          </h2>
        )}
        {body && (
          <div css={[tw`desktop-up:(max-w-[43rem]) desktop-hd:(max-w-full)`]}>
            {parseDisclaimerBlocks(body, selection =>
              dispatch(toggleDisclaimersModal(selection))
            )}
          </div>
        )}
        <ButtonLink
          secondary
          to={getCTALinkValue(imageWithTextcta)}
          css={[tw``]}
          target={
            imageWithTextcta?.linkType === "external" ? "_blank" : "_self"
          }
          onClick={() => {
            const cleanedHeading = heading.replace(/\[.*?\]/g, "")
            trackTealEvent({
              tealium_event: "footer_module",
              link_href: imageWithTextcta?.anchor
                ? `#${imageWithTextcta?.anchor}`
                : imageWithTextcta?.externalUrl ||
                  `/${imageWithTextcta?.internalLink?.slug?.current}`,
              coupon_module_text: cleanedHeading,
            })
          }}
          analytics-id={`image with text::${heading}`}
        >
          {imageWithTextcta?.title}
        </ButtonLink>
      </motion.div>
      <div
        css={[
          tw`relative col-span-1 overflow-hidden`,
          tw` w-full h-full`,
          imagePosition === "right" && tw`md:(order-1)`,
        ]}
      >
        <Image
          imageData={image?.image}
          noPreview
          css={[tw` object-cover object-center`]}
        />

        <motion.div
          initial={{
            width: "100%",
          }}
          whileInView={{
            width: 0,
          }}
          viewport={{ once: true, amount: 0.35 }}
          transition={{ duration: 0.25 }}
          css={[tw`absolute bottom-0 h-full z-20 bg-white`]}
        />

        <motion.div
          initial={{
            width: "100%",
          }}
          whileInView={{
            width: 0,
          }}
          viewport={{ once: true, amount: 0.35 }}
          transition={{ delay: 0.5, duration: 0.25 }}
          css={[tw`absolute bottom-0 h-full z-10 bg-black`]}
        />

        <div
          css={[
            tw`block absolute bottom-0 w-full h-full z-10`,
            tw`md:(hidden)`,
          ]}
        ></div>
      </div>
    </section>
  )
}
export default ToyotaCare

// White container covering initially
// WhileInView triggers white container x transition and black container x transition across
