import React, { useCallback, useContext, useState } from "react"
import {
  FamilySeriesTabsProps,
  SeriesObject,
  TabObject,
  TabProps,
  TabsProps,
  TabTitleProps,
} from "./FamilySeriesTabs.d"
import { Family, Model, Route } from "../../../global"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import tw from "twin.macro"
import { Image } from "../../atoms/Image"
import { Link } from "../../atoms/Link"
import {
  generateInventoryLink,
  normalizeSeriesNameForLink,
  parseDisclaimerBlocks,
  toUsd,
} from "../../../helpers"
import { LanguageContext } from "../../../contexts/Language"
import Icon from "../../atoms/Icon"
import { SlideOver } from "../../atoms/SlideOver"
import { HeaderContext } from "../../../contexts/Header"
import { css } from "@emotion/react"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { ThreeArrowAnimation } from "../ThreeArrowAnimation"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"

const tabAnimation = css`
  &:after {
    ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
    ${tw`h-px`}
      content: "";
    transform: scaleX(0);
  }
  @media (min-width: 1024px) {
    &:hover:after {
      transform: scaleX(1);
      ${tw`origin-bottom-left bg-gray-900 h-px`}
    }
  }
`
const focusAnimation = css`
  &:focus-visible:after {
    ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
    ${tw`h-px`}
    content: "";
    transform: scaleX(0);
  }
  @media (min-width: 1024px) {
    &:focus-visible:after {
      transform: scaleX(1);
      ${tw`origin-bottom-left bg-gray-900 h-px`}
    }
  }
`
const active = css`
  ${tw`font-semibold focus-visible:(border-transparent)`}
  &:after {
    transform: scaleX(1);
    ${tw`h-1 bg-red-400`}
  }
  &:hover:after {
    ${tw`h-1 bg-red-400`}
  }
  &:focus-visible:after {
    transform: scaleX(1);
    ${tw`h-1 bg-red-400`}
  }
`

const FamilySeriesTabs: React.FC<FamilySeriesTabsProps> = ({
  familyRef,
  mobileView,
  comingSoon,
}) => {
  const { _, language } = useContext(LanguageContext)
  const { open, setOpen } = useContext(HeaderContext)
  const [_state, dispatch] = useContext(DisclaimersContext)
  /* Filter for families that are activated */
  const activeFamilies = familyRef?.filter(
    (family: Family) => family?.isActive === true
  )
  // New data structure with only the data we need arranged how we need it
  const data = activeFamilies?.map((family: Family) => {
    return {
      family: family,
      data: family?.routes?.map((node: Route) => {
        let seriesData = null

        node?.route?.page?.vehicle?.models?.map((model: Model) => {
          //TODO dont make this a map so we can break once the featured is found
          if (model._type === "vehicleModelGroup") {
            model.models.map((models: Model) => {
              if (models.featuredModel) {
                if (seriesData === null) {
                  //if we already found the featured model, don't overwrite it
                  seriesData = models
                }
              }
            })
          } else {
            if (seriesData === null) {
              //if we already found the featured model, don't overwrite it
              seriesData = node?.route?.page?.vehicle?.models?.find(
                (model: Model) => model?.featuredModel === true
              )
            }
          }
        })

        // If there is no featured model, use the first model in the list
        // this is a failsafe that if for some reason the featured information was misconfigured we will still get something
        if (seriesData === null || seriesData === undefined) {
          for (let i = 0; i < node.route.page.vehicle.models.length; i++) {
            if (
              node.route.page.vehicle.models[i]._type === "vehicleModelGroup"
            ) {
              seriesData = node.route.page.vehicle.models[i].models[i]
              break
            } else {
              seriesData = node.route.page.vehicle.models[i]
              break
            }
          }
        }

        let imageData: any = node?.seriesImage?.image
        let imageSrc = seriesData?.model?.jellybean?.asset?.gatsbyImageData
        if (imageData) {
          imageSrc = null
        } else {
          imageData = null
        }

        let hybridModelNumbers: string[] = []
        node.route.page.vehicle.models.forEach(model => {
          if (model?.model?.isHybridModel) {
            hybridModelNumbers.push(model?.model?.code)
            return
          }

          if (model?.models) {
            model.models?.forEach(model => {
              if (model?.model?.isHybridModel) {
                hybridModelNumbers.push(model?.model?.code)
              }
            })
            return
          }
        })

        return {
          isHybrid: family.name == "Hybrid & Electric",
          hybridModelNumbers: hybridModelNumbers,
          slug: `/${node?.route?.slug?.current}/`,
          seriesData: seriesData.model?.series,
          imageData: imageData,
          imageSrc: imageSrc,
        }
      }),
    }
  })

  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  const hasGasAndHybridModels = (slug: string) => {
    switch (slug?.toLowerCase()) {
      case "camry":
        return true
      case "corolla":
        return true
      case "avalon":
        return true
      case "rav4":
        return true
      case "highlander":
        return true
      case "corolla cross":
        return true
      case "grand highlander":
        return true
      case "tundra":
        return true
      default:
        return false
    }
  }
  const [slideoverComingSoon, setSlideoverComingSoon] = useState(false)
  // MOBILE VIEW
  if (mobileView)
    return (
      <div css={[tw`col-span-full bg-white w-full h-full`]}>
        {data?.map(tab => {
          const [slideover, setSlideover] = useState(false)
          return (
            <>
              <button
                css={[
                  tw`flex justify-between items-center w-full bg-white font-semibold border-b border-gray-200 pl-4 pt-5 pb-2`,
                ]}
                onClick={() => {
                  setSlideover(!slideover),
                    trackTealEvent({
                      tealium_event: "nav_click",
                      click_text: `top nav:${tab?.family.name}`,
                    })
                }}
                analytics-id={`${tab?.family.name}:top nav:Vehicles`}
              >
                <span>{_(tab?.family.name)}</span>
                <Icon.Chevron
                  color="red-400"
                  direction="right"
                  css={[tw`h-2`]}
                />
              </button>
              <SlideOver
                css={[
                  tw`flex flex-col items-center max-w-full w-full h-full pb-[56px] px-8 overflow-scroll fixed`,
                ]}
                active={slideover}
                noCloseButton
                menuType
              >
                <button
                  css={[
                    tw`flex items-center w-full pb-5 border-b border-gray-200`,
                  ]}
                  onClick={() => setSlideover(!slideover)}
                >
                  <Icon.Chevron
                    color="red-400"
                    direction="left"
                    css={[tw`h-2 mr-3`]}
                  />
                  <span css={[tw`text-xl font-semibold uppercase`]}>
                    {_(tab?.family.name)}
                  </span>
                </button>
                {tab?.data?.map((node: SeriesObject) => {
                  if (!node || !node?.seriesData) return
                  const seriesData = node?.seriesData
                  const imageData = node?.imageData
                  const imageSrc = node?.imageSrc
                  let inventoryLinkOptions = {
                    series: node?.slug.replaceAll("/", "").replaceAll("-", ""),
                  }
                  // TODO: Find all model codes for hybrid
                  if (node?.isHybrid) {
                    inventoryLinkOptions.modelNumber = node?.hybridModelNumbers
                    inventoryLinkOptions.isHybridFamilySelected = true
                  }

                  return (
                    <div css={[tw`flex items-center justify-between py-1`]}>
                      <Link
                        to={`${node?.slug}${
                          node?.isHybrid && hasGasAndHybridModels(node?.slug)
                            ? "?hybrid=true"
                            : ""
                        }`}
                        onClick={() => {
                          trackTealEvent({
                            tealium_event: "nav_click",
                            click_text: `top nav:${seriesData?.name}`,
                            vehicle_segment_vehicle_page: tab?.family.name,
                          })
                        }}
                        css={tw`inline-block py-0`}
                      >
                        {(imageData || imageSrc) && (
                          <Image
                            alt={seriesData?.name}
                            src={imageSrc}
                            css={[tw`w-36`]}
                            imageData={imageData}
                          />
                        )}
                      </Link>
                      <div css={[tw`text-center w-[165px]`]}>
                        {seriesData?.name && (
                          <Link
                            to={`${node?.slug}${
                              node?.isHybrid &&
                              hasGasAndHybridModels(seriesData?.name)
                                ? "?hybrid=true"
                                : ""
                            }`}
                            css={[
                              tw`font-semibold relative mx-2 inline-flex flex-nowrap items-center justify-center text-center py-0`,
                            ]}
                            onClick={() => {
                              trackTealEvent({
                                tealium_event: "nav_click",
                                click_text: `top nav:${seriesData?.name}`,
                                vehicle_segment_vehicle_page: tab?.family.name,
                              })
                            }}
                          >
                            {seriesData?.name}
                            <Icon.Chevron
                              direction="right"
                              color="red-400"
                              className="darkRed"
                              css={[
                                tw`absolute bottom-1 -right-4 w-1.5 translate-x-0 -translate-y-0.5`,
                              ]}
                            />
                          </Link>
                        )}
                        {seriesData?.msrp && (
                          <>
                            <div css={[tw`text-sm`]}>
                              <span>$</span>
                              {parseDisclaimerBlocks(
                                `${toUsd(seriesData?.msrp)} [starting_msrp]`,
                                code => dispatch(toggleDisclaimersModal(code))
                              )}
                            </div>
                            <div css={["font-size: 10px;"]}>
                              {_("Starting MSRP")}
                            </div>
                            <div css={[tw`flex items-center justify-center`]}>
                              <Link
                                animated
                                animatedThin
                                to={
                                  "/offers/" +
                                  normalizeSeriesNameForLink(
                                    node?.seriesData?.name
                                  )
                                }
                                css={[
                                  tw`py-1 my-1 mr-1 text-xs border border-transparent focus-visible:(border-white border-dashed border outline-none p-2 -ml-2 -mb-0 -mt-0 -mr-2)`,
                                ]}
                                target="_self"
                                onClick={() => {
                                  trackTealEvent({
                                    tealium_event: "nav_click",
                                    click_text: `top nav:Offers`,
                                    vehicle_model_truncated: seriesData?.name,
                                    vehicle_segment_vehicle_page:
                                      tab?.family.name,
                                  })
                                }}
                              >
                                {_("Offers")}{" "}
                                <Icon.Chevron
                                  color="red-400"
                                  css={[tw`w-1 ml-0.5 inline-flex`]}
                                />
                              </Link>
                              <Link
                                animated
                                animatedThin
                                to={generateInventoryLink(inventoryLinkOptions)}
                                css={[
                                  tw`py-1 my-1 ml-1 text-xs border border-transparent focus-visible:(border-white border-dashed border outline-none p-2 -ml-2 -mb-0 -mt-0 -mr-2)`,
                                ]}
                                onClick={() => {
                                  trackTealEvent({
                                    tealium_event: "nav_click",
                                    click_text: `top nav:Inventory`,
                                    vehicle_model_truncated: seriesData?.name,
                                    vehicle_segment_vehicle_page:
                                      tab?.family.name,
                                  })
                                }}
                              >
                                {_("Inventory")}{" "}
                                <Icon.Chevron
                                  color="red-400"
                                  css={[tw`w-1 ml-0.5 inline-flex`]}
                                />
                              </Link>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )
                })}
                <div css={[tw`flex justify-center items-center px-12`]}>
                  <Link
                    to={"/" + tab?.family?.linkRoute?.slug.current}
                    className={"group"}
                    css={tw`font-semibold text-center`}
                    target="_self"
                  >
                    {language === "es"
                      ? tab?.family.linkTextES
                      : tab?.family.linkText}{" "}
                    <Icon.Chevron
                      color="red-400"
                      css={[tw`w-2 ml-1 inline-flex`]}
                    />
                  </Link>
                </div>
              </SlideOver>
            </>
          )
        })}
        {comingSoon && (
          <>
            {" "}
            <button
              css={[
                tw`flex justify-between items-center w-full bg-white font-semibold border-b border-gray-200 pl-4 pt-5 pb-2`,
              ]}
              onClick={() => {
                setSlideoverComingSoon(!slideoverComingSoon),
                  trackTealEvent({
                    tealium_event: "nav_click",
                    click_text: `top nav:Coming Soon`,
                  })
              }}
              analytics-id={`Coming Soon:top nav:Vehicles`}
            >
              <span>{_("Coming Soon")}</span>
              <Icon.Chevron color="red-400" direction="right" css={[tw`h-2`]} />
            </button>
            <SlideOver
              css={[
                tw`flex flex-col items-center max-w-full w-full h-full pb-[56px] px-8 overflow-scroll fixed`,
              ]}
              active={slideoverComingSoon}
              noCloseButton
              menuType
            >
              <button
                css={[
                  tw`flex items-center w-full pb-5 border-b border-gray-200`,
                ]}
                onClick={() => setSlideoverComingSoon(!slideoverComingSoon)}
              >
                <Icon.Chevron
                  color="red-400"
                  direction="left"
                  css={[tw`h-2 mr-3`]}
                />
                <span css={[tw`text-xl font-semibold uppercase`]}>
                  {_("Coming Soon")}
                </span>
              </button>
              {comingSoon.comingSoon.map(
                (vehicle: {
                  subtitleEn: string
                  image: any
                  subtitleEs: string
                  route: any
                  title:
                    | boolean
                    | React.ReactChild
                    | React.ReactFragment
                    | React.ReactPortal
                }) => {
                  return (
                    <div
                      css={[
                        tw`flex flex-col items-center pb-2`,
                        tw`md:(focus-visible:(outline-red) pb-10 pt-10)`,
                      ]}
                      tabIndex={open === 0 ? 0 : -1}
                    >
                      <Link
                        to={`/${vehicle.route.slug.current}`}
                        css={[tw`flex flex-col justify-center items-center`]}
                      >
                        <Image
                          imageData={vehicle.image.image}
                          css={[tw`rounded-lg w-48 h-20 object-cover`]}
                        />
                        <p css={[tw`font-semibold pt-5`]}>{vehicle.title}</p>
                        <p css={[tw`text-xs`]}>
                          {language === "es"
                            ? vehicle.subtitleEs
                            : vehicle.subtitleEn}
                        </p>
                      </Link>
                    </div>
                  )
                }
              )}
            </SlideOver>{" "}
          </>
        )}
      </div>
    )

  // DESKTOP VIEW
  return (
    <Tabs>
      {data?.map(tab => (
        <Tab family={tab?.family} title={_(tab?.family.name)}>
          {tab?.data?.map((node: SeriesObject) => {
            if (!node || !node?.seriesData) return
            const seriesData = node?.seriesData
            const imageData = node?.imageData
            const imageSrc = node?.imageSrc
            let inventoryLinkOptions = {
              series: node?.slug.replaceAll("/", "").replaceAll("-", ""),
            }
            if (node?.isHybrid) {
              inventoryLinkOptions.modelNumber = node?.hybridModelNumbers
              inventoryLinkOptions.isHybridFamilySelected = true
            }

            return (
              <div
                css={[
                  tw`flex flex-col items-center pb-2`,
                  tw`md:(focus-visible:(outline-red))`,
                ]}
                tabIndex={open === 0 ? 0 : -1}
              >
                <Link
                  to={`${node?.slug}${
                    node?.isHybrid && hasGasAndHybridModels(seriesData?.name)
                      ? "?hybrid=true"
                      : ""
                  }`}
                  className="group"
                  css={[
                    tw`flex flex-col items-center mx-4 py-0`,
                    tw`md:(focus-visible:(outline-red))`,
                    tw`lg:(mx-0)`,
                  ]}
                  onClick={() => {
                    trackTealEvent({
                      tealium_event: "nav_click",
                      click_text: `top nav:${seriesData?.name}`,
                      vehicle_segment_vehicle_page: tab?.family.name,
                    })
                    setOpen(null)
                  }}
                  analytics-id={`${seriesData?.name}:top nav:Vehicles|${tab?.family.name}`}
                >
                  {(imageData || imageSrc) && (
                    <Image
                      alt={seriesData?.name}
                      src={imageSrc}
                      imageData={imageData}
                      css={[
                        tw`w-52 h-auto mb-0`,
                        tw`lg:w-[11rem]`,
                        tw`2xl:w-52`,
                      ]}
                    />
                  )}
                  {seriesData?.name && (
                    <div css={[tw`font-semibold mx-3 relative`]}>
                      {seriesData?.name}
                      <ThreeArrowAnimation />
                    </div>
                  )}
                </Link>
                {seriesData?.msrp && (
                  <>
                    <div>
                      <span>$</span>
                      {parseDisclaimerBlocks(
                        `${toUsd(seriesData?.msrp)} [starting_msrp]`,
                        code => dispatch(toggleDisclaimersModal(code))
                      )}
                    </div>
                    <div css={[tw`font-semibold`, "font-size: 10px"]}>
                      {_("Starting MSRP")}
                    </div>
                    <div css={[tw`flex justify-between`]}>
                      <Link
                        animated
                        animatedThin
                        to={
                          "/offers/" +
                          normalizeSeriesNameForLink(node?.seriesData?.name)
                        }
                        css={[
                          tw`py-1 my-1 mr-2 text-xs border border-transparent focus-visible:(border-white border-dashed border outline-none p-2 -ml-2 -mb-0 -mt-0 -mr-2)`,
                        ]}
                        onClick={() => {
                          trackTealEvent({
                            tealium_event: "nav_click",
                            click_text: `top nav:Offers`,
                            vehicle_model_truncated: seriesData?.name,
                            vehicle_segment_vehicle_page: tab?.family.name,
                          })
                        }}
                        analytics-id={`Offers:top nav:Vehicles|${tab?.family.name}|${seriesData?.name}`}
                      >
                        {_("Offers")}{" "}
                        <Icon.Chevron
                          color="red-400"
                          css={[tw`ml-0.5 w-1.5 inline-flex`]}
                        />
                      </Link>

                      <Link
                        className="group"
                        animated
                        animatedThin
                        to={generateInventoryLink(inventoryLinkOptions)}
                        css={[
                          tw`py-1 my-1 ml-2 text-xs border border-transparent focus-visible:(border-white border-dashed border outline-none p-2 -ml-2 -mb-0 -mt-0 -mr-2)`,
                        ]}
                        onClick={() => {
                          trackTealEvent({
                            tealium_event: "nav_click",
                            click_text: `top nav:Inventory`,
                            vehicle_model_truncated: seriesData?.name,
                            vehicle_segment_vehicle_page: tab?.family.name,
                          })
                        }}
                        analytics-id={`Inventory:top nav:Vehicles|${tab?.family.name}|${seriesData?.name}`}
                      >
                        {_("Inventory")}{" "}
                        <Icon.Chevron
                          color="red-400"
                          css={[tw`ml-0.5 w-1.5 inline-flex`]}
                        />
                      </Link>
                    </div>
                  </>
                )}
              </div>
            )
          })}
        </Tab>
      ))}
      {comingSoon && (
        <Tab title={_("Coming Soon")}>
          {comingSoon.comingSoon.map(
            (vehicle: {
              subtitleEn: string
              image: any
              subtitleEs: string
              route: any
              title:
                | boolean
                | React.ReactChild
                | React.ReactFragment
                | React.ReactPortal
            }) => {
              return (
                <div
                  css={[
                    tw`flex flex-col items-center pb-2`,
                    tw`md:(focus-visible:(outline-red) pb-10 pt-10)`,
                  ]}
                  tabIndex={open === 0 ? 0 : -1}
                >
                  <Link
                    to={`/${vehicle.route.slug.current}`}
                    css={[tw`flex flex-col justify-center items-center`]}
                    onClick={() => {
                      trackTealEvent({
                        tealium_event: "nav_click",
                        click_text: `top nav:Coming Soon`,
                      })
                    }}
                  >
                    <Image
                      imageData={vehicle.image.image}
                      css={[tw`rounded-lg w-48 h-20 object-cover`]}
                    />
                    <p css={[tw`font-semibold pt-5`]}>{vehicle.title}</p>
                    <p css={[tw`text-xs`]}>
                      {language === "es"
                        ? vehicle.subtitleEs
                        : vehicle.subtitleEn}
                    </p>
                  </Link>
                </div>
              )
            }
          )}
        </Tab>
      )}
    </Tabs>
  )
}

/* PARENT CONTAINER */
const Tabs: React.FC<TabsProps> = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState(0)
  return (
    <article css={[tw`w-full col-span-3 bg-gradient-to-b from-gray-100`]}>
      <ul
        css={[
          tw`flex flex-nowrap justify-between overflow-x-auto w-4/5 py-3`,
          tw`md:(flex justify-center pb-0 pt-3 w-full)`,
        ]}
      >
        {children[0]
          .concat(children[1])
          .map((item: { props: { title: string } }, index: number) => (
            <TabTitle
              key={index}
              title={item?.props?.title}
              index={index}
              setSelectedTab={setSelectedTab}
              selected={selectedTab === index}
              analytics-id={`${item?.props?.title}:top nav:Vehicles`}
            />
          ))}
      </ul>
      {children[0].concat(children[1])[selectedTab]}
    </article>
  )
}

/* TAB HEADING */
const TabTitle: React.FC<TabTitleProps> = ({
  title,
  setSelectedTab,
  selected,
  index,
  ...remainingProps
}) => {
  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  const onClick = useCallback(() => {
    setSelectedTab(index)
    trackTealEvent({
      tealium_event: "nav_click",
      click_text: `top nav:${title}`,
    })
  }, [setSelectedTab, index])

  const { open } = useContext(HeaderContext)
  return (
    <li>
      <button
        onClick={onClick}
        css={[
          tabAnimation,
          focusAnimation,
          tw`flex-nowrap whitespace-nowrap px-3 mx-4 cursor-pointer flex`,
          tw`md:(relative px-0 mx-6 py-2 cursor-pointer flex whitespace-pre-wrap border border-transparent)`,
          tw`focus-visible:(outline-red)`,
          selected && active,
        ]}
        tabIndex={open === 0 ? 0 : -1}
        {...remainingProps}
      >
        {title}
      </button>
    </li>
  )
}

/* TAB CONTENT */
const Tab: React.FC<TabProps> = ({ children, family }) => {
  const { language } = useContext(LanguageContext)

  return (
    <>
      <div
        css={[
          tw`flex justify-center items-end flex-wrap pb-4 pt-2 scrollbar-hide`,
        ]}
      >
        {children}
      </div>
      {family?.linkRoute && (
        <div
          css={[
            tw`flex font-book text-gray-700 mx-3 relative justify-center items-center pb-6`,
          ]}
        >
          <Link
            to={"/" + family?.linkRoute?.slug.current}
            className={"group"}
            css={tw`pt-[1px]`}
            target="_self"
          >
            {language === "es" ? family.linkTextES : family.linkText}
            <ThreeArrowAnimation />
          </Link>
        </div>
      )}
    </>
  )
}

export default FamilySeriesTabs
