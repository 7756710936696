import React, { useContext } from "react"
import tw from "twin.macro"
import { Image } from "../../../atoms/Image"
import { AwardsProps } from "./Awards.d"
import { Card } from "../../../atoms/Card"
import { motion } from "framer-motion"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { parseDisclaimerBlocks } from "../../../../helpers"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"
import { LanguageContext } from "../../../../contexts/Language"
import { css } from "@emotion/react"

const Awards: React.FC<AwardsProps> = ({
  heading,
  awards,
  _key,
  sectionSlug,
  margins,
}) => {
  const cardVariants = {
    offscreen: {
      y: 300,
    },
    onscreen: (i: number) => ({
      y: 0,
      transition: {
        type: "spring",
        duration: 0.8,
        delay: i * 0.1,
      },
    }),
  }
  const [state, dispatch] = useContext(DisclaimersContext)
  const { _ } = useContext(LanguageContext)
  return (
    <section
      css={[
        tw`bg-gray-50 pt-8 pb-6 py-16 relative`,
        tw`lg:(bg-gradient-to-t from-gray-100 to-gray-50)`,
        tw`grid grid-cols-1`,
        `margin: ${margins?.top}px 0 ${margins?.bottom}px !important;`,
      ]}
      key={_key}
      id={sectionSlug?.current}
      aria-label={_("Awards Section")}
    >
      {heading && (
        <h2
          css={[
            tw`text-3xl font-light tracking-widest justify-self-center`,
            tw`md:(text-4xl)`,
          ]}
        >
          {parseDisclaimerBlocks(heading, selection =>
            dispatch(toggleDisclaimersModal(selection))
          )}
        </h2>
      )}
      {awards && (
        <div css={[tw`w-full pt-12`]}>
          <div
            css={[
              tw`flex flex-wrap gap-0 px-6 pb-10`,
              tw`md:(gap-4 flex-nowrap justify-center)`,
              tw`lg:(flex-nowrap gap-6)`,
            ]}
          >
            {awards?.map((award, i) => (
              <motion.div
                initial="offscreen"
                custom={i}
                variants={cardVariants}
                whileInView="onscreen"
                viewport={{ once: true }}
                css={[tw`w-full mb-6`, tw`md:(w-1/2)`, tw`lg:(w-1/4)`]}
              >
                <Card
                  shadowLevel={5}
                  css={[tw`bg-white h-full pb-6 overflow-visible`]}
                >
                  {award?.awardImage && (
                    <div css={[tw`flex justify-center h-48`]}>
                      <Image
                        imageData={award?.awardImage?.image}
                        objectFit="contain"
                        css={[tw`m-8`]}
                      />
                    </div>
                  )}
                  {award?.heading && (
                    <div css={[tw`font-semibold text-lg pt-0`]}>
                      {parseDisclaimerBlocks(award?.heading, selection =>
                        dispatch(toggleDisclaimersModal(selection))
                      )}
                    </div>
                  )}
                  {award?.subHeading && (
                    <div
                      css={[tw`font-book text-gray-700 text-base pt-4 md:h-24`]}
                    >
                      {parseDisclaimerBlocks(award?.subHeading, selection =>
                        dispatch(toggleDisclaimersModal(selection))
                      )}
                    </div>
                  )}
                </Card>
              </motion.div>
            ))}
          </div>
        </div>
      )}
    </section>
  )
}

export default Awards
