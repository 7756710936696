import React, { useContext } from "react"
import { MoreLikeThisProps } from "./MoreLikeThis.d"
import { Image } from "../../atoms/Image"
import tw from "twin.macro"
import { LanguageContext } from "../../../contexts/Language"

const MoreLikeThis: React.FC<MoreLikeThisProps> = ({ upsell, downsell }) => {
  const { _ } = useContext(LanguageContext)
  return (
    <div css={[tw`pt-8 col-start-2 col-end-13`]}>
      <span css={[tw`pl-8 font-light tracking-widest text-lg md:(pl-8)`]}>
        {_("More Like This")}
      </span>
      <div css={[tw`relative mb-48`]}>
        <div css={[tw`absolute -left-32 top-12`]}>
          <Image
            css={[tw`h-24 lg:(h-36)`]}
            imgStyle={{ objectFit: "contain" }}
            src={
              upsell.models[0].model.colors[0].exteriorImages[0].asset
                .gatsbyImageData
            }
            alt={""}
          ></Image>
          <div css={[tw`text-center`]}>{upsell.series.name}</div>
        </div>
        <div css={[tw`absolute left-36 top-12`]}>
          <Image
            css={[tw`h-24 lg:(h-36)`]}
            imgStyle={{ objectFit: "contain" }}
            src={
              downsell?.models[0]?.model?.colors[0]?.exteriorImages[0]?.asset
                ?.gatsbyImageData
            }
            alt={""}
          ></Image>
          <div css={[tw`text-center`]}>{downsell?.series?.name}</div>
        </div>
      </div>
    </div>
  )
}

export default MoreLikeThis
