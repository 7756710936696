import React, { useContext } from "react"
import { Image } from "../../../atoms/Image"
import { PartSaleItemProps } from "./HeroCarousel.d"
import tw from "twin.macro"
import { LanguageContext } from "../../../../contexts/Language"
import { css } from "@emotion/react"
import { ButtonLink } from "../../../atoms/Button"
import { getCTALinkValue, parseDisclaimerBlocks } from "../../../../helpers"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"

const PartSaleItem: React.FC<PartSaleItemProps> = ({ slide, index }) => {
  const { language, _ } = useContext(LanguageContext)
  const {
    desktopImage,
    mobileImage,
    saleTextLine1,
    saleTextLine2,
    saleTextLine3,
    saleTextColor,
    saleLogo,
    saleLogoMobile,
    boxColor,
    ctaArray,
    endDateText,
  } = slide

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const [state, modalDispatch] = useContext(DisclaimersContext)
  return (
    <article
      css={[
        // `background:${`#fffff`}`,
        tw`h-full w-full grid grid-cols-1 grid-rows-2 bg-gray-300 xl:(bg-transparent)`,
      ]}
    >
      {/* BACKGROUND Desktop*/}
      {desktopImage?.image && (
        <div
          css={[
            tw`hidden`,
            tw`lg:(col-start-1 col-end-2 col-span-2 row-start-1 row-end-3 grid grid-cols-1 grid-rows-1)`,
          ]}
        >
          {/* Background image */}
          <Image
            imageData={desktopImage?.image}
            css={[tw`h-full w-full object-cover`]}
            transparentPreview
          />
        </div>
      )}
      {/* BACKGROUND Mobile */}
      {mobileImage?.image && (
        <div
          css={[
            tw`relative col-start-1 col-end-2 col-span-2 row-start-1 row-end-2 grid grid-cols-1 grid-rows-1 -my-24`,
            tw`sm:(-my-16)`,
            tw`lg:(hidden)`,
          ]}
        >
          {/* Background image */}
          <Image
            imageData={mobileImage?.image}
            css={[tw`h-full w-full object-cover`]}
            transparentPreview
          />
        </div>
      )}

      <section
        css={[
          tw`bg-transparent p-6 col-end-2 row-start-1 row-end-3 grid-cols-1 w-full mx-auto relative`,
          tw`lg:(bg-transparent p-16 pt-12 col-end-2 row-start-1 grid w-full mx-auto)`,
        ]}
      >
        {saleLogo?.image && (
          <Image
            imageData={saleLogo?.image}
            css={[
              tw`hidden`,
              tw`lg:(w-[270px] block h-auto object-contain pl-6)`,
            ]}
            transparentPreview
          />
        )}
        {/* Sale Text */}
        <div
          css={[
            tw`w-full absolute bottom-0 right-0 p-10`,
            tw`lg:(max-w-[19rem] absolute bottom-10 right-8 p-10 rounded-lg)`,
            tw`xl:(absolute bottom-20 left-[5.5rem] max-w-[26rem] mt-20 p-10 rounded-lg text-center)`,
            `backdrop-filter: blur(1px);`,
            `background:${boxColor ? `${boxColor.hex}D9` : `#1E262ED9`}`,
            `@media (max-width: 1024px) {
           background:${boxColor ? `${boxColor.hex}` : `#1E262ED9`}`,
            language === "es" && tw`px-6`,
          ]}
        >
          <h1>
            {saleTextLine1 && (
              <span
                css={[
                  tw`font-bold block text-[1.5rem] leading-[.6rem]`,
                  tw`sm:(font-bold block text-[2.25rem] leading-[1rem])`,
                  tw`xl:(text-[3.5rem] leading-[1rem] text-left mt-0 block)`,
                  `color:${
                    saleTextColor ? `${saleTextColor.hex}FF` : `#FFFFFF`
                  }`,
                  css`
                    :after {
                      content: "";
                      display: inline-block;
                      width: 100%;
                    }
                  `,
                  language === "es" &&
                    tw`font-bold block text-[1.38rem] leading-[.6rem]`,
                  tw`sm:(font-bold block text-[2.23rem] leading-[1rem])`,
                  tw`md:(font-bold block text-[1.8rem] leading-[1rem])`,
                  tw`xl:(font-bold block text-[2.65rem] leading-[1rem])`,
                ]}
              >
                {parseDisclaimerBlocks(saleTextLine1, selection =>
                  modalDispatch(toggleDisclaimersModal(selection))
                )}
              </span>
            )}
            {saleTextLine2 && (
              <span
                css={[
                  tw`font-bold block text-[1.40rem] leading-[.6rem]`,
                  tw`sm:(font-bold block text-[2.08rem] leading-[1rem])`,
                  tw`xl:(text-[3.2rem] leading-[1rem] text-left mt-0 block)`,
                  `color:${
                    saleTextColor ? `${saleTextColor.hex}FF` : `#FFFFFF`
                  }`,
                  css`
                    :after {
                      content: "";
                      display: inline-block;
                      width: 100%;
                    }
                  `,
                  language === "es" &&
                    tw`font-bold block text-[1.30rem] leading-[.6rem]`,
                  tw`sm:(font-bold block text-[2.1rem] leading-[1rem])`,
                  tw`md:(font-bold block text-[1.7rem] leading-[1rem])`,
                  tw`xl:(font-bold block text-[2.5rem] leading-[1rem])`,
                ]}
              >
                {parseDisclaimerBlocks(saleTextLine2, selection =>
                  modalDispatch(toggleDisclaimersModal(selection))
                )}
              </span>
            )}
            {saleTextLine3 && (
              <span
                css={[
                  tw`font-bold block text-[2.08rem] leading-[.9rem]`,
                  tw`sm:(font-bold block text-[3.1rem] leading-[1.5rem])`,
                  tw`xl:(text-[4.8rem] leading-[2rem] text-left mt-0 block)`,
                  `color:${
                    saleTextColor ? `${saleTextColor.hex}FF` : `#FFFFFF`
                  }`,
                  css`
                    :after {
                      content: "";
                      display: inline-block;
                      width: 100%;
                    }
                  `,
                  language === "es" &&
                    tw`font-bold block text-[1.6rem] leading-[.9rem]`,
                  tw`sm:(font-bold block text-[2.6rem] leading-[1.5rem])`,
                  tw`md:(font-bold block text-[2.1rem] leading-[1.5rem])`,
                  tw`xl:(font-bold block text-[3.09rem] leading-[1.5rem])`,
                ]}
              >
                {parseDisclaimerBlocks(saleTextLine3, selection =>
                  modalDispatch(toggleDisclaimersModal(selection))
                )}
              </span>
            )}
            <span
              css={[
                tw`text-lg text-gray-50 block text-left`,
                language === "es" && tw`text-base text-gray-50 block text-left`,
                tw`sm:(text-lg text-gray-50 block text-left)`,
              ]}
            >
              {endDateText}
            </span>
            {/* CTAs */}
            {ctaArray && (
              <div
                css={[
                  tw`mt-4 flex grid-cols-2 gap-2`,
                  //tw`md:(max-w-[500px])`,
                ]}
              >
                {ctaArray?.map((cta: any) => (
                  <ButtonLink
                    {...{ [cta?.buttonType]: true }}
                    to={getCTALinkValue(cta)}
                    target={cta?.linkType === "external" ? "_blank" : "_self"}
                    css={[tw`whitespace-nowrap`, tw`lg:w-auto`]}
                    aria-label={cta?.title}
                    key={cta?.title}
                    analytics-id={`${cta?.title}:homepage carousel:${
                      index + 1
                    }`}
                    onClick={() => {
                      trackTealEvent({
                        coupon_module_text: cta?.title,
                      })
                    }}
                  >
                    {cta?.title}
                  </ButtonLink>
                ))}
              </div>
            )}
          </h1>
          {saleLogoMobile?.image && (
            <Image
              imageData={saleLogoMobile?.image}
              css={[
                tw`block absolute top-10 right-8 object-contain h-auto w-[130px]`,
                tw`sm:(block absolute top-10 right-8 object-contain h-auto w-[180px])`,
                tw`lg:(hidden)`,
              ]}
              transparentPreview
            />
          )}
        </div>
      </section>
    </article>
  )
}

export default PartSaleItem
