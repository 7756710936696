import React from "react"
import { GenericBreadcrumbsProps } from "./Breadcrumbs.d"
import { Link } from "../Link"
import { Chevron } from "../Icon"
import tw, { TwStyle } from "twin.macro"

/**
 * Generic breadcrumb navigation
 *
 * Takes a list of Breadcrumb as prop "items".
 *
 * @component
 * @param {GenericBreadcrumb} items - List of breadcrumb elements
 * @param {TwStyle} breadcrumbStyles
 *
 * @returns <ol></ol>
 */

const GenericBreadcrumbs: React.FC<GenericBreadcrumbsProps> = ({
  items,
  breadcrumbStyles = "",
  ...remainingProps
}) => {
  return (
    <nav aria-label="Breadcrumb" {...remainingProps}>
      <ol css={tw`flex gap-1.5`}>
        {items?.map(({ label, to, isCurrentPage }) => {
          if (isCurrentPage) {
            return (
              <li css={[breadcrumbStyles]}>
                <Link to="" disabled aria-current="page">
                  {label}
                </Link>
              </li>
            )
          }

          return (
            <li css={[breadcrumbStyles]}>
              <Link to={to}>{label}</Link>
              <Chevron
                direction="right"
                css={tw`inline-block w-2.5 ml-1.5 mb-1`}
              />
            </li>
          )
        })}
      </ol>
    </nav>
  )
}

export default GenericBreadcrumbs
