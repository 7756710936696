import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { SeriesInfoSectionProps } from "./SeriesInfoSection.d"
import { parseDisclaimerBlocks } from "../../../../helpers"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"
import {
  setActiveDisclaimer,
  toggleDisclaimersModal,
} from "../../../../contexts/Disclaimers/actions"
import { Image } from "../../../atoms/Image"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const SeriesInfoSection: React.FC<SeriesInfoSectionProps> = ({
  features,
  sectionTitle,
  paragraphs,
  sectionSubheading,
  subHeadingParagraphs,
  sectionImage,
}) => {
  const [state, modalDispatch] = useContext(DisclaimersContext)
  return (
    <section
      css={[tw`grid grid-cols-1 xl:(grid grid-cols-2)`]}
      aria-label={`${sectionTitle}` + " Section"}
    >
      <section
        css={[tw`col-span-1 text-left p-8`, tw`md:(p-20)`, tw`2xl:(p-40)`]}
      >
        {sectionTitle && (
          <div
            css={[
              tw`text-3xl font-light tracking-widest mb-10`,
              tw`text-4xl line-height[3rem] `,
              tw`xl:(text-5xl)`,
            ]}
          >
            {parseDisclaimerBlocks(sectionTitle, selection =>
              modalDispatch(toggleDisclaimersModal(selection))
            )}
          </div>
        )}

        {paragraphs &&
          paragraphs.map((paragraph, index) => (
            <div
              key={index}
              css={[
                tw`text-lg font-light tracking-widest mb-10`,
                tw`xl:(text-lg)`,
              ]}
            >
              {parseDisclaimerBlocks(paragraph, selection =>
                modalDispatch(toggleDisclaimersModal(selection))
              )}
            </div>
          ))}

        {sectionSubheading && (
          <div
            css={[
              tw`justify-self-center text-3xl font-light tracking-widest`,
              tw`md:(text-2xl)`,
            ]}
          >
            {parseDisclaimerBlocks(sectionSubheading, selection =>
              modalDispatch(toggleDisclaimersModal(selection))
            )}
          </div>
        )}

        {subHeadingParagraphs &&
          subHeadingParagraphs.map((paragraph, index) => (
            <div
              key={index}
              css={[
                tw`text-lg font-light tracking-widest mb-10`,
                tw`xl:(text-lg)`,
              ]}
            >
              {parseDisclaimerBlocks(paragraph, selection =>
                modalDispatch(toggleDisclaimersModal(selection))
              )}
            </div>
          ))}

        {features && (
          <div css={[tw`grid grid-cols-2 md:(grid-cols-2) gap-8`]}>
            {features.map((feature, index) => (
              <div key={index} css={[tw`flex flex-col`]}>
                <span css={[tw`font-semibold`]}>
                  {parseDisclaimerBlocks(feature.title, selection =>
                    modalDispatch(toggleDisclaimersModal(selection))
                  )}
                </span>
                <span>
                  {parseDisclaimerBlocks(feature.description, selection =>
                    modalDispatch(toggleDisclaimersModal(selection))
                  )}
                </span>
              </div>
            ))}
          </div>
        )}
      </section>
      {sectionImage && (
        <div css={[tw``]}>
          <Image imageData={sectionImage?.image} />
        </div>
      )}
    </section>
  )
}

export default SeriesInfoSection
