import { Link } from "gatsby"
import React, { useContext, useState } from "react"
import tw from "twin.macro"
import { ContentSlider } from "../../organisms/Homepage"
import { ImageBlocksWithDescriptionProps } from "./ImageBlocksWithDescription.d"
import { Image } from "../../atoms/Image"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { getCTALinkValue, parseDisclaimerBlocks } from "../../../helpers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { LanguageContext } from "../../../contexts/Language"

const ImageBlocksWithDescription: React.FC<ImageBlocksWithDescriptionProps> = ({
  section,
  analyticsIdArrows,
  tealCarouselLabel,
  fromAccessory = false,
}) => {
  const [zoomImage, setZoomImage] = useState(false)
  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { language } = useContext(LanguageContext)

  const handleClick = (isLink: boolean, imageLink: any) => {
    if (isLink) {
      trackTealEvent({
        tealium_event: "footer_module",
        footer_module_click:
          imageLink?.externalUrl ||
          `/${imageLink?.internalLink?.slug?.current}/`,
      })
    }
  }
  return (
    <ContentSlider
      sectionSlug={section?.sectionSlug}
      sectionTitle={section?.sectionTitle}
      analyticsId={analyticsIdArrows}
      tealCarouselLabel={tealCarouselLabel}
    >
      {section?.callouts?.map(
        ({ heading, body, image, imageLink, type }, i) => {
          const isLink = type !== "none"
          const BlockComponent = isLink ? Link : "div"
          const [state, modalDispatch] = useContext(DisclaimersContext)
          const to =
            language === "es"
              ? type !== "none" &&
                getCTALinkValue(imageLink).replace("/", "/es/")
              : type !== "none" && getCTALinkValue(imageLink)
          return (
            <BlockComponent
              onClick={() => handleClick(isLink, imageLink)}
              to={to}
              className="group"
              css={[
                tw`relative z-10 flex flex-col justify-start overflow-hidden h-full w-full`,
              ]}
              analytics-id={`image with text::${heading}`}
            >
              {image?.image && (
                <div
                  css={[
                    tw`absolute z-0 transform top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-items-center`,
                  ]}
                >
                  <Image
                    imageData={image?.image}
                    alt={""}
                    css={[
                      tw`scale-105 transition transform ease-in-out object-cover w-full`,
                      tw`group-hover:(transition-all scale-125 duration-500) group-focus:(transition-all scale-125 duration-500)`,
                    ]}
                  />
                </div>
              )}
              <div
                css={[
                  tw`absolute bottom-0 z-0 h-full opacity-80 w-full`,
                  `background: linear-gradient(180deg, transparent 0, transparent , #343a40 calc(100% - 8rem))`,
                ]}
                onMouseEnter={() => setZoomImage(true)}
                onMouseLeave={() => setZoomImage(false)}
              />
              <div
                css={[
                  tw`px-10 pb-0 pt-24 z-10 relative text-white drop-shadow w-full h-full`,
                  tw`md:(py-12 px-14 mt-36)`,
                  tw`xl:(mt-52 px-16)`,
                  fromAccessory && tw`pt-56 md:(mt-40)`,
                ]}
              >
                <h2
                  css={[
                    tw`mb-4 text-3xl font-semibold`,
                    `text-shadow: rgba(0, 0, 0, 0.5) 0px 2px 4px;`,
                  ]}
                  aria-label={heading}
                >
                  {parseDisclaimerBlocks(heading, selection =>
                    modalDispatch(toggleDisclaimersModal(selection))
                  )}
                </h2>
                <p css={[`text-shadow: rgba(0, 0, 0, 0.5) 0px 2px 4px;`]}>
                  {parseDisclaimerBlocks(body, selection =>
                    modalDispatch(toggleDisclaimersModal(selection))
                  )}
                </p>
              </div>
            </BlockComponent>
          )
        }
      )}
    </ContentSlider>
  )
}

export default ImageBlocksWithDescription
