import React, { useContext, useEffect } from "react"
import tw from "twin.macro"
import { ContactContext } from "../../../contexts/Contact"
import { setActiveView, updateField } from "../../../contexts/Contact/actions"
import { LanguageContext } from "../../../contexts/Language"
import { useTealiumContext } from "../../../contexts/Tealium"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { Textarea } from "../../atoms/Textarea"
import { ViewProps } from "./ContactDealer.d"
import FormPage from "./FormPage"
import { tealiumNavClick } from "./TealiumHelpers"
import TextareaField from "./TextareaField"

const OptionalCommentsView: React.FC<ViewProps> = ({
  active,
  showFormHeading,
  maxLength,
}) => {
  const { _ } = useContext(LanguageContext)
  const [{ vehicle, seriesName, comments, leadType }, dispatch] =
    useContext(ContactContext)
  const interestedInTranslation = _("I'm interested in the")
  const vinTranslation = _("VIN")

  useEffect(() => {
    if (active && (!comments || comments === " ") && vehicle) {
      const capitalModelName = vehicle.title?.toUpperCase()
      dispatch(
        updateField(
          "comments",
          `${interestedInTranslation} ${seriesName} ${capitalModelName?.replace(
            seriesName.toUpperCase(),
            ""
          )}, ${vinTranslation} #${vehicle.vin}.`
        )
      )
    }
    if (
      active &&
      (!comments || comments === " ") &&
      leadType === "Schedule Service Appointment"
    ) {
      dispatch(
        updateField(
          "comments",
          `I would like to schedule a service appointment for my vehicle.`
        )
      )
    }
  }, [active])
  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { contactDealerData } = useTealiumContext()

  if (!active) return null

  return (
    <FormPage
      active={active}
      prevOnClick={() => {
        dispatch(setActiveView("NameView")),
          tealiumNavClick(
            "Would you like to give us any additional information?",
            "Back",
            trackTealEvent,
            contactDealerData
          )
      }}
      nextOnClick={() => {
        dispatch(setActiveView("SummaryView")),
          tealiumNavClick(
            "Would you like to give us any additional information?",
            "Next",
            trackTealEvent,
            contactDealerData
          )
      }}
      showFormHeading={showFormHeading}
      heading={_("Would you like to give us any additional information?")}
    >
      <TextareaField
        active={active}
        fieldName="Comments"
        type="text"
        label={_("Your optional comments.")}
        css={[tw`h-48 max-w-[38rem] mt-4`]}
        value={comments}
        customOnChange={(value: string) => {
          dispatch(updateField("comments", value))
        }}
        maxLength={maxLength}
      />
    </FormPage>
  )
}

export default OptionalCommentsView
