import React from "react"
import tw from "twin.macro"
import { PartHighlightProps } from "./Part.d"
import { Image } from "../../atoms/Image"
import { Breadcrumbs } from "../../atoms/Breadcrumbs"
import { Card } from "../../atoms/Card"
import moment from "moment"
import Icon from "../../atoms/Icon"
import appleWalletGraphic from "../../../images/appleWalletGraphic.svg"
import { ButtonLink } from "../../atoms/Button"
import { Accordion } from "../../atoms/Accordion"
/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const PartHighlights: React.FC<PartHighlightProps> = ({
  highlightHeading,
  highlightSubHeading,
  highlightImage,
}) => {
  return (
    <section
      aria-label="Part Highlights Section"
      css={[
        tw`text-center relative max-w-7xl mx-auto mb-20 px-4`,
        tw`md:(items-center px-12 h-auto)`,
      ]}
    >
      <div css={[tw`max-w-7xl`]}>
        <p
          css={[
            tw`text-base tracking-widest font-book mb-8 text-red-300 block`,
            tw`md:(text-3xl)`,
          ]}
        >
          {highlightHeading}
        </p>
        <p css={[tw`text-base font-book mb-8 text-red-300 md:(text-xl)`]}>
          {highlightSubHeading}
        </p>
      </div>
      <Image imageData={highlightImage?.image} />
    </section>
  )
}

export default PartHighlights
