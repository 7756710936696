import React, { useState } from "react"
import { CheckboxProps } from "./Checkbox.d"
import tw from "twin.macro"
import { Bar, Checkmark } from "../Icon"
import useComponentId from "../../../hooks/useIdGenerator"

/**
 * Checkbox input
 *
 * @param {string} props.label - Label text and input value
 * @param {boolean} props.indeterminate - Displays as black checkbox with horizontal line. Prevents checkbox from being checked.
 * @param {"left" | "center" | "right"} props.alignment - Position of checkbox input relative to label text
 * @param {() => void)} props.onChange - Position of checkbox input relative to label text
 *
 */

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  indeterminate = false,
  alignment,
  backgroundColor,
  backgroundImageUrl,
  active,
  onChange,
  analyticsId = null,
  ...remainingProps
}) => {
  // const [isChecked, setIsChecked] = useState(
  //   remainingProps.hasOwnProperty("checked") && remainingProps.checked
  // )

  // const uuid = useComponentId()

  // const handleChange = () => {
  //   if (indeterminate) return
  //   setIsChecked(!isChecked)
  //   onChange && onChange(!isChecked)
  // }

  return (
    <label
      htmlFor={label}
      css={[
        tw`flex items-center gap-3 text-sm relative`,
        alignment === "center" && tw`flex-col gap-0`,
        !remainingProps.disabled && tw`cursor-pointer`,
        "width: fit-content",
      ]}
      tabIndex={-1}
      analytics-id={analyticsId}
    >
      <input
        type="checkbox"
        id={label}
        checked={checked}
        onChange={onChange}
        active={active}
        className="peer"
        css={[
          tw`appearance-none absolute h-5 w-5 rounded pl-2`,
          tw`focus-visible:(outline-gray)`,
          alignment === "right" && tw`right-0`,
        ]}
        {...remainingProps}
      />
      <span
        css={[
          tw`block h-5 w-5 rounded relative border-2 border-gray-500 peer-disabled:opacity-50`,
          !remainingProps.disabled &&
            !indeterminate &&
            tw`peer-checked:bg-red-400 peer-checked:border-red-400 peer-hover:border-red-500 peer-focus:border-red-500 transition-all`,
          indeterminate && tw`bg-black border-black`,
          backgroundColor &&
            backgroundColor.length === 1 &&
            `background-color: ${backgroundColor[0]}`,
          backgroundColor &&
            backgroundColor.length === 2 &&
            `background: linear-gradient(45deg, ${backgroundColor[0]} 0, ${backgroundColor[0]} 50%, ${backgroundColor[1]} 50%, ${backgroundColor[1]} 100%)`,
          backgroundImageUrl &&
            `background: center / cover no-repeat url(${backgroundImageUrl});`,
        ]}
      >
        {indeterminate ? (
          <Bar
            color={indeterminate ? "white" : "gray-500"}
            css={[
              tw`absolute w-3 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2`,
            ]}
          />
        ) : (
          <Checkmark
            color={"white"}
            css={[
              tw`absolute w-3 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 opacity-0`,
              checked && tw`opacity-100`,
            ]}
          />
        )}
      </span>
      <span
        css={[
          tw`order-last peer-disabled:opacity-30 flex-1`,
          alignment === "right" && tw`order-first`,
        ]}
      >
        {label}
      </span>
    </label>
    // <label
    //   htmlFor={`${label}-${uuid}`}
    //   css={[
    //     tw`flex items-center gap-3 text-sm relative`,
    //     alignment === "center" && tw`flex-col gap-0`,
    //     !remainingProps.disabled && tw`cursor-pointer`,
    //     "width: fit-content",
    //   ]}
    //   {...remainingProps}
    //   tabIndex={-1}
    // >
    //   <input
    //     type="checkbox"
    //     name={`${label}-${uuid}`}
    //     id={`${label}-${uuid}`}
    //     value={label}
    //     disabled={
    //       remainingProps.hasOwnProperty("disabled") && remainingProps.disabled
    //     }
    //     tabIndex={
    //       remainingProps.hasOwnProperty("tabIndex")
    //         ? remainingProps.tabIndex
    //         : 0
    //     }
    //     checked={isChecked}
    //     className="peer"
    //     css={[
    //       tw`appearance-none absolute h-5 w-5 rounded pl-2`,
    //       tw`focus-visible:(outline-gray)`,
    //       alignment === "right" && tw`right-0`,
    //     ]}
    //     onChange={handleChange}
    //   />
    //   <span
    //     css={[
    //       tw`block h-5 w-5 rounded relative border-2 border-gray-500 peer-disabled:opacity-50`,
    //       !remainingProps.disabled &&
    //         !indeterminate &&
    //         tw`peer-checked:bg-red-400 peer-checked:border-red-400 peer-hover:border-red-500 peer-focus:border-red-500 transition-all`,
    //       indeterminate && tw`bg-black border-black`,
    //       backgroundColor &&
    //         backgroundColor.length === 1 &&
    //         `background-color: ${backgroundColor[0]}`,
    //       backgroundColor &&
    //         backgroundColor.length === 2 &&
    //         `background: linear-gradient(45deg, ${backgroundColor[0]} 0, ${backgroundColor[0]} 50%, ${backgroundColor[1]} 50%, ${backgroundColor[1]} 100%)`,
    //     ]}
    //   >
    //     {indeterminate ? (
    //       <Bar
    //         color={indeterminate ? "white" : "gray-500"}
    //         css={[
    //           tw`absolute w-3 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2`,
    //         ]}
    //       />
    //     ) : (
    //       <Checkmark
    //         color={"white"}
    //         css={[
    //           tw`absolute w-3 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 opacity-0`,
    //           isChecked && tw`opacity-100`,
    //         ]}
    //       />
    //     )}
    //   </span>
    //   <span
    //     css={[
    //       tw`order-last peer-disabled:opacity-30 flex-1`,
    //       alignment === "right" && tw`order-first`,
    //     ]}
    //   >
    //     {label}
    //   </span>
    // </label>
  )
}

export default Checkbox
