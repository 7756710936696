import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { InventoryClient } from "../../../clients/InventoryClient"
import { ContactContext } from "../../../contexts/Contact"
import { openContactDealerModal } from "../../../contexts/Contact/actions"
import {
  removeFavoritedDealer,
  addFavoritedDealer,
} from "../../../contexts/Favorites/actions"
import { Link } from "../../atoms/Link"
import { FavoritesContext } from "../../../contexts/Favorites/context"
import { LanguageContext } from "../../../contexts/Language"
import { LocationContext } from "../../../contexts/Location"
import { useTealiumContext } from "../../../contexts/Tealium"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import dealer from "../../../pages/dealer"
import { ButtonLink, Button } from "../../atoms/Button"
import Icon from "../../atoms/Icon"
import { PilotDealerProps } from "./Dealer.d"
import { slugify } from "../../../helpers"

const PilotDealer: React.FC<PilotDealerProps> = ({
  i,
  dealer,
  collapsable,
  onClick = null,
  selected = false,
  dealerCtaText,
  dealerCtaLink,
  lmAnalyticsId = null,
  ctcAnalyticsId = null,
  cdAnalyticsId = null,
  ctaAnalyticsId = null,
  offerType = null,
  pilotPhoneNumber = "",
}) => {
  const [hours, setHours] = useState({ sales: null, service: null })
  const [{ zip }] = useContext(LocationContext)
  const [dealerInventory, setDealerInventory] = useState<number>(0)
  const [expanded, setExpanded] = useState<boolean>(false)
  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData } = useTealiumContext()
  const [{ favoritedDealer }, dispatch] = useContext(FavoritesContext)
  const [_contactState, contactDispatch] = useContext(ContactContext)
  const { _ } = useContext(LanguageContext)

  const isFavorite =
    favoritedDealer && favoritedDealer.DealerCode === dealer.DealerCode

  const salesDept =
    dealer.Departments?.filter(department => department.name === "Sales")[0] ||
    dealer.Departments?.length > 0
      ? dealer.Departments[0]
      : null

  const serviceDept = dealer.Departments?.filter(
    department => department.name === "Service"
  )[0]

  let ctaLinkToUse = ""
  if (dealerCtaText) {
    switch (dealerCtaLink) {
      case "tact":
        ctaLinkToUse = dealer.TactUrl
        break
      case "tires":
        ctaLinkToUse = serviceDept?.uris?.filter(
          link => link.type === "Tire Center"
        )[0]?.value
        break
      case "scheduleService":
        ctaLinkToUse = dealer.ServiceUrl
        break
      default:
        ctaLinkToUse = dealer.Url
    }
  }

  /**
   * We need to use useEffect here to get the hours on load. This way it's dynamic based
   * on the current day, not just when the current build was performed.
   */
  useEffect(() => {
    const currentDay = new Date().getDay() - 1 // current day, minus one since 0 indexed array of hours

    const hours = {
      sales: salesDept?.hoursOfOperation[currentDay],
      service: serviceDept?.hoursOfOperation[currentDay],
    }
    setHours(hours)

    const setInventoryCount = async () => {
      const { count } = await InventoryClient.get({
        dealer: dealer.DealerCode,
        countOnly: true,
      })
      setDealerInventory(count)
    }
    setInventoryCount()
  }, [])

  // Parameters:  Name of event and an object with additional properties for tealium event call if required
  const handleTealEvent: (eventName: string, moreData?: {}) => void = (
    eventName = "No event name provided",
    moreData = {}
  ) => {
    trackTealEvent({
      tealium_event: eventName,
      dealer_name: dealer?.Name,
      dealer_code: dealer?.DealerCode,
      page_type: tealPageData.page_type,
      customer_zip: zip,
      ...(offerType && { offer_type: offerType }),
      ...moreData,
    })
  }
  return (
    <div css={[tw`w-full`]}>
      <div css={[tw`flex justify-between items-start`]}>
        <button
          css={[
            tw`flex text-lg font-semibold text-left`,
            tw`focus-visible:outline-gray`,
          ]}
          onClick={onClick}
        >
          <span css={[tw`pr-1.5`]}>{i && `${i}. `}</span>
          <span css={[tw`pr-2`]}>{dealer.Name}</span>
        </button>
        <button
          onClick={() => {
            if (isFavorite) {
              dispatch(removeFavoritedDealer())
            } else {
              dispatch(addFavoritedDealer(dealer))
            }
          }}
          css={[
            tw`flex items-center justify-center`,
            tw`focus-visible:outline-gray`,
          ]}
          aria-label={
            isFavorite ? `Unfavorite ${dealer.Name}` : `Favorite ${dealer.Name}`
          }
        >
          <Icon.Heart
            color={isFavorite ? "red-400" : "gray-900"}
            css={[tw`h-8 -mb-1`]}
            filled={isFavorite}
          />
        </button>
      </div>
      <div
        css={[
          tw`hidden overflow-hidden grid-cols-2 grid-rows-1 gap-2 grid-flow-row w-auto h-auto content-center sm:gap-4`,
          (!collapsable || selected || expanded) && tw`grid`,
        ]}
      >
        <div>
          <Icon.Directions
            color="red-400"
            css={[tw`max-h-8 w-8 mt-1 inline-block`]}
          />
          <span css={tw`ml-0.5 inline-block sm:ml-2`}>
            {dealer.Distance} {_("mi away")}{" "}
          </span>
        </div>
        <div>
          <Icon.Phone
            color="red-400"
            css={[tw`max-h-6 w-6 mt-1 inline-block`]}
          />
          <Link
            to={`tel:${pilotPhoneNumber ? pilotPhoneNumber : dealer.Phone}`}
            css={[
              tw`mt-2 p-0 ml-0.5 inline-block sm:ml-2`,
              tw`focus-visible:outline-gray`,
            ]}
            animated
            animatedThin
            onClick={() => handleTealEvent("click_to_call")}
            analytics-id={ctcAnalyticsId || (i && `call:dealers:${i}`)}
          >
            {pilotPhoneNumber ? pilotPhoneNumber : dealer.Phone}
          </Link>
        </div>
        {dealerCtaLink && dealerCtaLink !== "contact" ? (
          <ButtonLink
            to={ctaLinkToUse}
            target="_blank"
            primary
            css={[tw`text-xs px-2! my-2 flex items-center justify-center`]}
            onClick={() => {
              let eventName = "contact_dealer_rest"
              if (ctaAnalyticsId) {
                if (ctaAnalyticsId == "apply now") {
                  eventName = "tact_apply"
                } else if (ctaAnalyticsId == "shop tires") {
                  eventName = "shop_tires"
                } else if (ctaAnalyticsId == "schedule service") {
                  eventName = "schedule_service"
                }
              }
              handleTealEvent(eventName)
            }}
            analytics-id={
              ctaAnalyticsId && i
                ? `${ctaAnalyticsId}:dealers:${i}`
                : `${dealerCtaText}:top nav:favorite dealer`
            }
          >
            {dealerCtaText}
          </ButtonLink>
        ) : (
          <Button
            primary
            css={[tw`text-xs px-0! m-2 flex items-center justify-center`]}
            onClick={() => {
              handleTealEvent("contact_dealer_rest")
              contactDispatch(openContactDealerModal(dealer))
            }}
            analytics-id={cdAnalyticsId || (i && `contact:dealers:${i}`)}
          >
            {_("Contact Dealer")}
          </Button>
        )}

        <ButtonLink
          to={`/dealers/${slugify(dealer.Name)}`}
          secondary
          css={[
            tw`text-xs px-0! m-2 flex items-center justify-center`,
            tw`focus-visible:outline-gray!`,
          ]}
          aria-label={_("learn more about") + " " + dealer.Name}
          onClick={() => {
            handleTealEvent("dealer_detail_click")
          }}
          analytics-id={lmAnalyticsId || (i && `learn more:dealers:${i}`)}
        >
          {_("Learn More")}
        </ButtonLink>
      </div>
    </div>
  )
}

export default PilotDealer
