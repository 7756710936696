import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { Image } from "../../../atoms/Image"
import { css } from "@emotion/react"
import { RetentionSalesEventItemProps } from "../HeroCarousel/HeroCarousel.d"
import { LanguageContext } from "../../../../contexts/Language"
import useViewportSize from "../../../../hooks/useViewportSize"
import BlockContent from "../../../atoms/BlockContent"
import { ImageWithAlt } from "../../../molecules/Callout/Callout.d"
import { getCTALinkValue } from "../../../../helpers"
import { ButtonLink } from "../../../atoms/Button"
import { AnimatePresence, motion } from "framer-motion"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import LottieJson from "../../../../animations/JulyRentionHomepageDesktop.json"
import LottieJsonMobile from "../../../../animations/JulyRentionHomepageMobile.json"
import Lottie from "react-lottie-player"

const RetentionSalesEventItem: React.FC<RetentionSalesEventItemProps> = ({
  desktopImage,
  mobileImage,
  saleLogo,
  saleLogoMobile,
  promoText,
  promoTextColor,
  endDateText,
  endDateTextColor,
  endDateTextColorDesktop,
  promoTextColorDesktop,
  ctaArray,
  boxColor,
  boxColorDesktop,
  slideIndex,
}) => {
  const { language } = useContext(LanguageContext)
  const [backgroundImageSrc, setBackgroundImageSrc] = useState<ImageWithAlt>()
  const [salesLogoImageSrc, setSalesLogoImageSrc] = useState<ImageWithAlt>()
  const { width } = useViewportSize()

  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  useEffect(() => {
    if (width < 768 && mobileImage) {
      setBackgroundImageSrc(mobileImage)
      setSalesLogoImageSrc(saleLogoMobile)
    } else {
      setBackgroundImageSrc(desktopImage)
      setSalesLogoImageSrc(saleLogo)
    }
  }, [width])

  // const promoTextStyles = css`
  //   ${tw`text-center font-black`}
  //   h1,h2,h3,h4 {
  //     ${tw`text-[4rem] leading-[3.25rem] mb-0 md:(text-8xl) lg:(text-[7rem])`}
  //   }
  //   p {
  //     ${tw`text-[2.25rem] leading-[2.25rem] mb-0 md:(text-5xl) lg:(text-[3.5rem])`}
  //   }
  //   .disclaimer {
  //     ${tw`text-xs`}
  //   }
  //   color: ${promoTextColor?.hex ? promoTextColor.hex : "#FFF"};
  // `

  // const promoTextStylesES = css`
  //   ${tw`text-center font-black`}
  //   h1,h2,h3,h4 {
  //     ${tw`text-[3rem] leading-[3.25rem] mb-0 md:(text-7xl) lg:(text-[6rem])`}
  //   }
  //   p {
  //     ${tw`text-[2rem] leading-[2rem] mb-0 md:(text-4xl) lg:(text-[3rem])`}
  //   }
  //   .disclaimer {
  //     ${tw`text-xs`}
  //   }
  //   color: ${promoTextColor?.hex ? promoTextColor.hex : "#FFF"};
  // `
  console.log(boxColor, boxColorDesktop)
  return (
    <section
      css={[
        tw`flex flex-col h-auto relative`,
        tw`lg:(items-center flex-row h-[600px])`,
      ]}
    >
      <div css={tw`relative md:(static)`}>
        {/* {backgroundImageSrc && (
          <Image
            imageData={backgroundImageSrc?.image}
            css={[
              tw`relative w-full h-[380px] object-cover object-[93% 50%]`,
              `@media screen and (min-width: 390px) {
                object-position: 100% 100%;
                height:400px;
              }`,
              tw`md:(h-[380px] object-[100% 100%])`,
              tw`lg:(h-[600px] absolute object-[66% 50%] top-0)`,
              "z-index: 0;",
            ]}
          />
        )} */}

        {LottieJson && (
          <div
            css={[
              tw`hidden relative w-full h-[380px] object-cover object-[93% 50%]`,
              `@media screen and (min-width: 390px) {
                object-position: 100% 100%;
                height:400px;
              }`,
              tw`md:(h-[380px] object-[100% 100%])`,
              tw`lg:(h-[600px] block absolute object-[66% 50%] top-0)`,
              "z-index: 0;",
            ]}
          >
            <Lottie
              loop
              animationData={LottieJson}
              play
              style={{
                width: "100%",
                height: "100%",
                // backgroundSize: 'cover',
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
            />
          </div>
        )}
        {LottieJsonMobile && (
          <div
            css={[
              tw`relative w-full h-[340px] object-cover`,
              `@media screen and (min-width: 390px) {
                object-position: 100% 100%;
                height:340px;
              }`,
              tw`md:(h-[380px] object-[100% 100%])`,
              tw`lg:(h-[600px] hidden absolute object-[66% 50%] top-0)`,
              "z-index: 0;",
            ]}
          >
            <Lottie
              loop
              animationData={LottieJsonMobile}
              play
              style={{
                width: "100%",
                height: "100%",
                // backgroundSize: 'cover',
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
            />
          </div>
        )}
        {/* <div
          css={[
            tw`flex flex-col z-20 absolute right-[32%] top-1/2 transform -translate-y-1/2 align-middle`,
            tw`sm:(right-32)`,
            tw`md:(right-[18.5rem] top-[13rem])`,
            tw`lg:(right-auto left-[63%] top-[18rem])`,
            tw`xl:(left-[64%])`,
            tw`2xl:(left-[65%])`,
            tw`desktop-hd:(left-[66%])`,
            language === "es" ? promoTextStylesES : promoTextStyles,
            language === "es" &&
            tw`right-[4.5rem] sm:(right-24) md:(right-[15rem]) lg:(right-0 left-[48%] top-[18.5rem]) xl:(left-[47%]) 2xl:(left-[44%]) desktop-hd:(left-[42%])`,
          ]}
        >
          <BlockContent data={promoText} />
        </div> */}
      </div>
      {/* Left box with sales logo */}
      <div
        css={[
          tw`relative z-20 h-full w-full max-w-[1440px] mx-auto`,
          ctaArray?.length === 0 && tw`pb-[72px] md:(pb-0)`,
        ]}
      >
        <div
          css={[
            tw`px-6 pt-4 pb-4 mb-[6.5rem] transition delay-300 duration-300 ease-in-out bg-white w-full z-10`,
            tw`md:(pb-10)`,
            tw`lg:(absolute top-[46%] transform -translate-y-1/2 left-10 w-auto bg-opacity-90 px-8 pb-4 pt-6 my-auto rounded-2xl backdrop-blur-sm)`,
            tw`xl:(pb-8 left-[6rem] top-[43%])`,
            `@media (max-width: 1024px) {
              background:${`${boxColor?.hex}`}`,
            `@media (min-width: 1024px) {
                    background:${`#${boxColorDesktop?.hex}`}`,
          ]}
        >
          <div>
            <div
              css={[
                tw`justify-center w-full mt-4 lg:(flex mt-0 justify-start w-auto)`,
              ]}
            >
              <Image
                imageData={salesLogoImageSrc?.image}
                objectFit="cover"
                transparentPreview
                hideAlt
                aria-label={salesLogoImageSrc?.image?.asset?.altText}
                css={[
                  tw`w-full min-w-[300px] mx-auto md:(w-[400px]) lg:(ml-0 w-[375px])`,
                ]}
              />
            </div>
            {/* <div
              css={[
                tw`flex text-center font-black text-[2rem] leading-[2.5rem] mb-0 md:(text-6xl leading-[4.5rem]) lg:(text-left text-[3.25rem] max-w-[400px] leading-[3.5rem])`,

                `color: ${promoTextColor?.hex ? promoTextColor.hex : "#EEE"};`,
                `@media screen and (min-width: 1024px) {
                  color: ${promoTextColorDesktop?.hex};
                }
                `,
              ]}
            >
              <BlockContent data={promoText} />
            </div> */}
            <div
              css={[
                tw`block text-2xl !normal-case mt-2.5 font-semibold text-center`,
                tw`lg:(text-3xl text-left)`,
                `color: ${
                  endDateTextColor?.hex ? endDateTextColor.hex : "#EEE"
                };`,
                `@media screen and (min-width: 1024px) {
                  color: ${endDateTextColorDesktop?.hex};
                }
                `,
              ]}
            >
              {endDateText}
            </div>
          </div>
          {/* CTAs */}
          {ctaArray && (
            <AnimatePresence>
              <motion.div
                css={[
                  tw`mt-6 flex grid-cols-2 gap-2 justify-center lg:justify-start`,
                ]}
                key={slideIndex}
                animate={{ opacity: 1, x: 0 }}
                initial={{ opacity: 0, x: 0 }}
                transition={{ duration: 0.5, delay: 0.5 }}
              >
                {ctaArray?.map((cta: any) => (
                  <ButtonLink
                    {...{ [cta?.buttonType]: true }}
                    to={getCTALinkValue(cta)}
                    target={cta?.linkType === "external" ? "_blank" : "_self"}
                    css={[tw`whitespace-nowrap`, tw`lg:w-auto`]}
                    aria-label={cta?.title}
                    key={cta?.title}
                    analytics-id={`${cta?.title}:homepage carousel:${slideIndex}`}
                    onClick={() => {
                      trackTealEvent({
                        tealium_event: "cta_click",
                        coupon_module_text: cta?.title,
                        link_href: `${getCTALinkValue(cta)}`,
                      })
                    }}
                  >
                    {cta?.title}
                  </ButtonLink>
                ))}
              </motion.div>
            </AnimatePresence>
          )}
        </div>
      </div>
    </section>
  )
}

export default RetentionSalesEventItem
