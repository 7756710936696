import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import {
  parseDisclaimerBlocks,
  reformatCategory,
  toUsd,
} from "../../../../helpers"
import { OfferInfoProps } from "./Hero.d"
import { LanguageContext } from "../../../../contexts/Language"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"
import { FavoritesContext } from "../../../../contexts/Favorites/context"
import { lang } from "moment"
import Icon from "../../../atoms/Icon"
import { DealerDetailsPageContext } from "../../../../templates/dealerDetails"
import { Link } from "../../../atoms/Link"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../../contexts/Tealium"
import { SeriesPageContext } from "../../../../templates/series"

/**
 * @author Tyler
 * @summary - Conditionally renders based on type of offer.
 *
 * @component
 * @param {string} offerType - Will be either apr or lease
 * @returns <OfferInfo />
 *
 */

const OfferInfo: React.FC<OfferInfoProps> = ({
  offer,
  textColor = "text-blue",
  ...remainingProps
}) => {
  const { language, _ } = useContext(LanguageContext)
  const [{}, dispatch] = useContext(DisclaimersContext)
  //START: logic added for Optimizely test 7
  const { dealer } = useContext(DealerDetailsPageContext)
  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData } = useTealiumContext()
  const { vehicle } = useContext(SeriesPageContext)
  const reformattedCategory = reformatCategory(vehicle?.series?.category)
  //END: logic added for Optimizely test 7

  /* Offer is for APR */
  if (offer?.type === "APR") {
    const sortedTermRate = offer.termRates
      .sort((a: any, b: any) => b.rate - a.rate)
      .sort((a: any, b: any) => a.term - b.term)[0]
    return (
      <div
        css={[
          tw`relative flex w-full justify-evenly items-center px-4 pb-8 pt-8`,
        ]}
        {...remainingProps}
      >
        <div css={[tw`flex flex-col`]}>
          <div
            css={[
              tw`flex items-end font-light text-6xl h-[4rem] sm:(text-7xl h-[4.5rem])`,
              `color: ${textColor};`,
            ]}
          >
            {sortedTermRate.rate}%
          </div>
          <div css={[tw`font-light lowercase text-xl`, `color: ${textColor};`]}>
            {_("APR")}
          </div>
        </div>
        <div
          css={[tw`w-[1px] h-16 sm:(h-20)`, `background-color: ${textColor}`]}
        >
          &nbsp;
        </div>
        <div css={[tw`flex flex-col`]}>
          <div
            css={[
              tw`font-book text-5xl h-[4rem] normal-case flex items-end sm:(text-6xl h-[4.5rem])`,
              `color: ${textColor};`,
            ]}
          >
            {sortedTermRate.term}
            {offer.disclaimer &&
              parseDisclaimerBlocks(`[${offer._id}]`, selection =>
                dispatch(toggleDisclaimersModal(selection))
              )}
          </div>
          <div css={[tw`font-light lowercase text-xl`, `color: ${textColor};`]}>
            {_("months")}
          </div>
        </div>
        {/* Conditionally render extra cash amount if value > 0 */}
        {/* {offer.extraCashAmount !== undefined &&
          offer.extraCashAmount !== null &&
          offer.extraCashAmount !== 0 && (
            <div css={[tw`flex flex-col items-center`]}>
              <div css={[tw`text-blue font-semibold text-5xl`]}>
                ${toUsd(offer.extraCashAmount)}
              </div>
              <div css={[tw`text-blue font-light`]}>
                {_("extra cash amount")}
              </div>
            </div>
          )} */}
        {/* START: logic added for Optimizely test 7 */}

        <Link
          animated
          css={[
            tw`normal-case cursor-pointer w-full absolute bottom-0 text-xs lg:(hidden)`,
            `color: ${textColor};`,
          ]}
          className="optimizelyTest7Variant"
          to={`/offers/${offer?._id}`}
          onClick={() => {
            // Conditionally add values if they are available
            const tealOptionalValues: {
              dealer_name?: string
              dealer_code?: string
              vehicle_model_truncated?: string
              vehicle_model?: string
              vehicle_year?: string | number
            } = {
              ...(dealer?.Name && { dealer_name: dealer.Name }),
              ...(dealer?.DealerCode && {
                dealer_code: dealer.DealerCode,
              }),
            }
            trackTealEvent({
              ...tealOptionalValues,
              offer_type: `${tealPageData.page_type} | ${tealPageData.page_name} | ${offer?.type} | ${offer.cardTitle}`,
              tealium_event: "view_offer",
              vehicle_year: vehicle?.year,
              vehicle_segment_vehicle_page: reformattedCategory,
              vehicle_model_truncated: vehicle?.series?.name,
              vehicle_model: vehicle?.series?.name,
            })
          }}
          analytics-id="offer hero:offers"
        >
          {_("View Offer Details")}{" "}
          <Icon.Chevron
            direction="right"
            color={textColor}
            css={[tw`h-2.5 inline lg:(h-3)`]}
          />
        </Link>
        {/* END: logic added for Optimizely test 7 */}
      </div>
    )
  }

  /* Offer is for Lease */
  if (offer?.type === "Lease") {
    return (
      <div
        css={[
          tw`relative flex w-full justify-evenly px-4 pb-8 pt-8 items-center`,
        ]}
        {...remainingProps}
      >
        <div css={[tw`col-span-1 flex flex-col`]}>
          <div
            css={[
              tw`text-white flex font-light text-6xl normal-case lg:(text-7xl)`,
              `color: ${textColor};`,
            ]}
          >
            ${offer.monthlyPayment && toUsd(offer.monthlyPayment)}
            {parseDisclaimerBlocks(`[${offer._id}]`, selection =>
              dispatch(toggleDisclaimersModal(selection))
            )}
          </div>
          <div
            css={[
              tw`text-white font-light normal-case`,
              `color: ${textColor};`,
            ]}
          >
            {_("per month")}
          </div>
        </div>
        <div
          css={[
            tw`w-[1px] h-16 mx-2 sm:(h-20)`,
            `background-color: ${textColor}`,
          ]}
        >
          &nbsp;
        </div>
        <div css={[tw`col-span-1 col-start-2 items-center`]}>
          {offer.duration && (
            <>
              <div
                css={[
                  tw`text-white font-semibold text-2xl lg:(text-3xl)`,
                  `color: ${textColor};`,
                ]}
              >
                {offer.duration}
              </div>
              <div
                css={[
                  tw`text-white text-sm font-light mb-1 normal-case`,
                  `color: ${textColor};`,
                ]}
              >
                {_("months")}
              </div>
            </>
          )}
          {offer.dueAtSigningAmount && (
            <>
              <div
                css={[
                  tw`text-white font-semibold text-2xl inline-flex justify-center border-t lg:(text-3xl)`,
                  `border-color: ${textColor};`,
                  `color: ${textColor};`,
                ]}
              >
                ${toUsd(offer.dueAtSigningAmount)}
              </div>
              <div
                css={[
                  tw`text-white font-light text-sm lowercase`,
                  `color: ${textColor};`,
                ]}
              >
                {_("due at signing")}
              </div>
            </>
          )}
        </div>
        {/* {offer?.extraCashAmount && (
          <div css={[tw`col-span-1 col-start-2 items-center`]}>
            <div
              css={[
                tw`text-white font-semibold text-3xl`,
                `color: ${textColor};`,
              ]}
            >
              ${toUsd(offer.extraCashAmount)}
            </div>
            <div
              css={[
                tw`text-white font-light whitespace-nowrap`,
                `color: ${textColor};`,
              ]}
            >
              {_("extra cash amount")}
            </div>
          </div>
        )} */}
        {/* START: logic added for Optimizely test 7 */}

        <Link
          animated
          css={[
            tw`normal-case text-white cursor-pointer w-full absolute bottom-0 text-xs lg:(hidden)`,
            `color: ${textColor};`,
          ]}
          to={`/offers/${offer?._id}`}
          onClick={() => {
            // Conditionally add values if they are available
            const tealOptionalValues: {
              dealer_name?: string
              dealer_code?: string
              vehicle_model_truncated?: string
              vehicle_model?: string
              vehicle_year?: string | number
            } = {
              ...(dealer?.Name && { dealer_name: dealer.Name }),
              ...(dealer?.DealerCode && {
                dealer_code: dealer.DealerCode,
              }),
            }
            trackTealEvent({
              ...tealOptionalValues,
              offer_type: `${tealPageData.page_type} | ${tealPageData.page_name} | ${offer?.type} | ${offer.cardTitle}`,
              tealium_event: "view_offer",
              vehicle_year: vehicle?.year,
              vehicle_segment_vehicle_page: reformattedCategory,
              vehicle_model_truncated: vehicle?.series?.name,
              vehicle_model: vehicle?.series?.name,
            })
          }}
          analytics-id="offer hero:offers"
        >
          {_("View Offer Details")}{" "}
          <Icon.Chevron
            direction="right"
            color={textColor}
            css={[tw`h-2.5 inline lg:(h-3)`]}
          />
        </Link>
        {/* END: logic added for Optimizely test 7 */}
      </div>
    )
  }

  return null
}

export default OfferInfo
