import React, { createContext, useContext, useEffect, useState } from "react"
import { TemplateProps, SeriesTemplatePageData } from "./templates.d"
import { graphql } from "gatsby"
import { Layout } from "../components/atoms/Layout"
import {
  Accessories,
  Awards,
  Brochure,
  FeatureBadges,
  Gallery,
  Hero,
  ImageBlocks,
  ModelList,
  Offers,
  ToyotaCare,
  Xseries,
  Features,
} from "../components/organisms/Series"
import { Pixlee } from "../components/molecules/Pixlee"
import { Model } from "../global"
import { SEO } from "../components/atoms/SEO"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import HybridFeatures from "../components/organisms/Series/HybridFeatures/HybridFeatures"
import { reformatCategory, remove4by, sanitizeSeries } from "../helpers"
import UpdatedHero from "../components/organisms/Series/Hero/UpdatedHero"
import HeroFooter from "../components/organisms/Series/Hero/HeroFooter"
import StandardEquipmentSection from "../components/organisms/Series/StandardEquipment/StandardEquipment"
import SeriesInfoSection from "../components/organisms/Series/SeriesInfo/SeriesInfoSection"
import useSalesEventsData from "../hooks/useSalesEventsData"
import { useTealiumContext } from "../contexts/Tealium"
import AugustSeriesSalesEventHero from "../components/organisms/Series/SalesEventHero/AugustSeriesSalesEventHero"
import { updateField } from "../contexts/Contact/actions"
import { ContactContext } from "../contexts/Contact"
import { ImageBlocksWithDescription } from "../components/molecules/ImageBlocksWithDescription"
import TabbedImageModule from "../components/molecules/TabbedImageModule/TabbedImageModule"

export const SeriesPageContext = createContext({
  page: null,
  accessories: null,
  series: null,
  models: null,
  vehicle: null,
  selectedOfferTypes: null,
  setSelectedOfferTypes: null,
  selectedFilter: null,
  setSelectedFilter: null,
})

const SeriesTemplate = ({
  data,
  location,
}: TemplateProps<SeriesTemplatePageData>) => {
  const { page, accessories } = data
  const [{}, dispatch] = useContext(ContactContext)

  const models = page?.vehicle?.models.map(
    (model: Model | Model[]): Model[] => {
      if (model.hasOwnProperty("models")) {
        let values = model.models.map((model: { model: Model }) => model)
        values.title = model.title
        values.disclaimer = model.disclaimer
        return values
      }
      return [model]
    }
  )

  const [selectedOfferTypes, setSelectedOfferTypes] = useState([])
  const salesEvent = useSalesEventsData()[0]
  const [salesEventLoaded, setSalesEventLoaded] = useState(false)
  const [hasActiveSalesEvent, setHasActiveSalesEvent] = useState(false)
  const [selectedModelGroup, setSelectedModelGroup] = useState(models[0])

  useEffect(() => {
    if (salesEvent && salesEvent.salesEventActive) {
      setHasActiveSalesEvent(true)
    }
    setSalesEventLoaded(true)
  }, [salesEvent])

  const hybridsOnly =
    new URLSearchParams(location?.search).get("hybrid") ||
    location?.pathname
      ?.split("/")
      ?.filter(item => item !== "")
      ?.pop()
      ?.includes("hybrid")

  const [selectedFilter, setSelectedFilter] = useState(hybridsOnly ? 1 : 0)

  // Pulls section titles and slugs to pass to StickyFooter jumpTo menu
  // This is necessary for the scroll-spy/anchor link functionality
  // const jumpToMenuData = page?.content?.map((section: templateSection) => {
  //   return {
  //     title: section?.sectionTitle,
  //     slug: section?.sectionSlug?.current,
  //   }
  // })

  // This value is assigned as the first color of the first model in the models list.
  // There may be a safer way to do this by raising the state for these values from ModelList and ColorSelector components
  const defaultExteriorColor = models
    ? models[0][0]?.model.colors[0]?.exterior.title
    : ""
  const defaultInteriorColor = models
    ? models[0][0]?.model.colors[0]?.interior[0]?.interior.title
    : ""
  const defaultConfiguration = models ? models[0][0]?.model?.name : ""
  const { setVehicleTealData } = useTealiumContext()
  const { handleTealView } = useTealiumView()
  // Format requested by 22Squared to match Explore site
  const reformattedCategory = reformatCategory(page?.vehicle?.series?.category)
  // Model name includes 4x designation for trucks, whereas seriesName excludes it
  const modelName = page?.vehicle?.series?.name
  const seriesName = remove4by(page?.vehicle?.series?.name)
  // page_name uses lowercase series name, no spaces
  const seriesNamelc = sanitizeSeries(modelName)
  useEffect(() => {
    // Note:  Tealium view function calls must manually include vehicle data values (i.e. not from context), because context data for vehicles (in TealiumContext) is not readily available at load time.
    handleTealView({
      page_name: `${reformattedCategory}_${seriesNamelc}`,
      page_type: "select a vehicle",
      tealium_event: "model_summary",
      vehicle_model: modelName || "",
      vehicle_model_truncated: seriesName || "",
      vehicle_year: page?.vehicle?.year?.toString() || "",
      vehicle_segment_vehicle_page: reformattedCategory || "",
    })
    setVehicleTealData({
      exterior_color: defaultExteriorColor,
      interior_color: defaultInteriorColor,
      trim: `${models ? models[0][0].model.name : ""}`,
      vehicle_model: modelName || "",
      vehicle_series: seriesName || "",
      vehicle_segment_vehicle_page: reformattedCategory || "",
      vehicle_model_truncated: seriesName || "",
      vehicle_config: defaultConfiguration,
      vehicle_year: page?.vehicle?.year?.toString() || "",
    })
  }, [])
  // set vehicle context for global contact dealer data
  useEffect(() => {
    page?.vehicle && dispatch(updateField("vehicle", page?.vehicle))
  }, [page])
  return (
    <SeriesPageContext.Provider
      value={{
        page,
        accessories: accessories?.nodes,
        vehicle: page?.vehicle,
        series: page?.vehicle?.series,
        models: models,
        selectedOfferTypes: selectedOfferTypes,
        setSelectedOfferTypes: setSelectedOfferTypes,
        selectedFilter: selectedFilter,
        setSelectedFilter: setSelectedFilter,
      }}
    >
      <Layout>
        <SEO
          url={location.href}
          title={page.title}
          keywords={page.seo?.keywords}
          schema={page.seo?.schema}
          description={page.seo?.description}
          canonicalPath={page.seo?.canonicalPath}
        />
        <SanitySections
          data={page}
          salesEventLoaded={salesEventLoaded}
          hasActiveSalesEvent={hasActiveSalesEvent}
          salesEvent={salesEvent}
          onModelGroupSelect={setSelectedModelGroup} // Pass the setter function
          models={models} // Pass the models
        />
      </Layout>
    </SeriesPageContext.Provider>
  )
}
const SanitySections = ({
  data,
  salesEventLoaded,
  hasActiveSalesEvent,
  salesEvent,
  onModelGroupSelect, // Add prop for model group selection
  models, // Add prop for models
}: any): JSX.Element => {
  return (
    <>
      {data?.content?.map((section: any) => {
        switch (section?._type) {
          case "hero":
            if (salesEventLoaded && hasActiveSalesEvent) {
              return (
                <>
                  <AugustSeriesSalesEventHero
                    {...section}
                    relatedSeriesPage={data.relatedSeriesPage}
                    salesEvent={salesEvent}
                  />
                  <HeroFooter {...section} />
                </>
              )
            } else {
              return (
                <>
                  <UpdatedHero
                    {...section}
                    relatedSeriesPage={data.relatedSeriesPage}
                  />
                  <HeroFooter {...section} />
                </>
              )
            }
          case "imageWithText":
            return <ToyotaCare {...section} />

          case "gallery":
            return <Gallery {...section} />

          case "offers":
            return <Offers {...section} />

          case "awards":
            return <Awards {...section} />

          case "accessories":
            return <Accessories {...section} />

          case "tabbedImageModule":
            return <TabbedImageModule {...section} />

          case "imageBlocks":
            return <ImageBlocks {...section} />

          case "featureBadges":
            return <FeatureBadges {...section} />

          case "models":
            return (
              <ModelList
                {...section}
                models={models}
                onModelGroupSelect={onModelGroupSelect}
              />
            )

          case "brochure":
            return <Brochure {...section} />

          case "pixleeAlbum":
            return <Pixlee {...section} />

          case "xseries":
            return <Xseries {...section} />

          case "features":
            return <Features {...section} />

          case "hybridFeatures":
            return <HybridFeatures {...section} />

          case "standardEquipmentSection":
            return <StandardEquipmentSection {...section} />

          case "seriesInfoSection":
            return <SeriesInfoSection {...section} />
          case "imageBlocksWithDescriptionSection":
            return <ImageBlocksWithDescription section={section} />
        }
      })}
    </>
  )
}

export const query = graphql`
  query SeriesPageQuery($id: String) {
    page: sanityPageSeries(id: { eq: $id }) {
      id
      title
      seo {
        _type
        keywords
        schema
        description
        canonicalPath
      }
      vehicle {
        year
        id
        seriesTagline
        seriesTaglineES
        series {
          name
          mpgCity
          mpgHighway
          msrp
          slug
          category
          range
        }
        additionalSeries {
          name
          mpgCity
          mpgHighway
          msrp
          slug
          category
        }
        models {
          ... on SanityVehicleModelGroup {
            title
            _key
            _type
            disclaimer
            models {
              featuredColor {
                code
              }
              ...model
              model {
                ... on SanityCustomModel {
                  accessoryCodes
                  _type
                }
              }
            }
          }
          ... on SanityVehicleModel {
            featuredColor {
              code
            }
            ...model
            model {
              ... on SanityCustomModel {
                accessoryCodes
                _type
              }
            }
          }
        }
        upsell {
          series {
            name
          }
          models {
            ...model
          }
        }
        downsell {
          series {
            name
          }
          models {
            ...model
          }
        }
      }
      relatedSeriesPage {
        slug {
          current
        }
        page {
          ... on SanityPageSeries {
            vehicle {
              year
            }
          }
        }
      }
      content {
        ...SeriesHeroData
        ...ToyotaCareData
        ...GallerySection
        ...AccessoriesData
        ...OffersSectionData
        ...AwardsSectionData
        ...ImageBlocksSection
        ...TabbedImageModuleData
        ...FeatureBadges
        ...ModelDataSection
        ...BrochureData
        ...PixleeSectionData
        ...XseriesSectionData
        ...FeaturesSectionData
        ...HybridFeaturesData
        ...StandardEquipmentSectionData
        ...SeriesInfoSectionData
        ...ImageBlocksWithDescriptionData
      }
      disclaimers {
        code
        disclaimer
      }
    }
  }
`
export default SeriesTemplate
