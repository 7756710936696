import React, { useContext, useState, useRef } from "react"
import tw from "twin.macro"
import { AccessoriesPageContext } from "../../../templates/seriesAccessories"
import Icon, { Chevron, Filter } from "../../atoms/Icon"
import { Checkbox } from "../../atoms/Checkbox"
import { LanguageContext } from "../../../contexts/Language"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"

const AccyFilters: React.FC = () => {
  const {
    page,
    accessories,
    selectedCategories,
    setSelectedCategories,
    setSelectedYears,
    selectedYears,
  } = useContext(AccessoriesPageContext)
  const { _ } = useContext(LanguageContext)

  const vehicles = page.vehicles

  function clearAll() {
    setSelectedCategories([])
    setSelectedYears([])
  }

  function updateSelectedCategories(category: string, isChecked: any) {
    if (isChecked) {
      setSelectedCategories((selectedCategories: any) => [
        ...selectedCategories,
        category,
      ])
    } else {
      setSelectedCategories((selectedCategories: any) =>
        selectedCategories.filter(
          (selectedCategory: string) => selectedCategory !== category
        )
      )
    }
  }

  function updateSelectedYears(year: string, isChecked: any) {
    if (isChecked) {
      setSelectedYears((selectedYears: any) => [...selectedYears, year])
    } else {
      setSelectedYears((selectedYears: any) =>
        selectedYears.filter((selectedYear: string) => selectedYear !== year)
      )
    }
  }

  const subCategories = accessories.reduce(
    (acc: any[], curr: { subCategory: any }) => {
      const { subCategory } = curr.subCategory
        ? curr
        : { subCategory: "Uncategorized" }
      if (subCategory && !acc.includes(subCategory)) {
        acc.push(subCategory)
      }
      return acc
    },
    [] as string[]
  )
  subCategories.sort()

  //iterate over the vehicles object and if any of them have featuredAccessories.length > 0, then we have accessories
  const hasFeaturedAccessories = vehicles.reduce(
    (acc: boolean, curr: { featuredAccessories: any }) => {
      if (curr.featuredAccessories.length > 0) {
        return true
      }
      return acc
    },
    false
  )

  if (hasFeaturedAccessories) {
    subCategories.unshift("Featured")
  }

  const yearFilterOptions = vehicles.map(
    (vehicle: { year: any }) => vehicle.year
  )
  const content = useRef(null)
  const [isExpanded, setisExpanded] = useState(false)

  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData } = useTealiumContext()

  return (
    <>
      <div css={[tw`px-8 mt-4 flex flex-col w-full`]}>
        <div
          css={[tw`flex justify-between items-center border-b border-gray-200`]}
        >
          <div
            css={[
              tw`inline-flex w-full lg:(justify-between) items-center mb-1`,
            ]}
          >
            {/* <button
              type="button"
              css={[
                tw`w-6 mb-2 lg:(w-0)`,
                "-webkit-tap-highlight-color: transparent;",
              ]}
              aria-expanded={isExpanded}
              onClick={() => setisExpanded(!isExpanded)}
            > */}
            <Filter
              color="red-400"
              css={[tw`max-h-4 inline m-2`, tw`lg:(hidden)`]}
            />
            {/* </button> */}
            <h5 css={[tw`mb-1`]}>{_("Filters")}</h5>
            <button
              onClick={() => clearAll()}
              css={[
                tw`p-0 ml-2 text-xs font-book text-red-400 normal-case cursor-pointer`,
              ]}
            >
              {_("Clear All")}
            </button>
          </div>
          <button
            type="button"
            css={[
              tw`w-6 mb-2 lg:hidden`,
              "-webkit-tap-highlight-color: transparent;",
            ]}
            aria-expanded={isExpanded}
            onClick={() => setisExpanded(!isExpanded)}
          >
            <Icon.Chevron
              color="red-400"
              direction="down"
              css={[
                tw`h-3 transition-all duration-300 mt-0.5 ml-1`,
                isExpanded && tw`transform -rotate-180`,
              ]}
            />
          </button>
        </div>
        <div
          ref={content}
          css={[
            tw`overflow-hidden transition-all mb-6 ease-out duration-500 opacity-100 h-0 lg:(overflow-visible)`,
            isExpanded
              ? `height: ${content.current && content.current.scrollHeight}px`
              : tw`h-0 opacity-0 lg:(h-auto opacity-100)`,
          ]}
        >
          <h5 css={[tw`text-left pr-4 font-semibold inline-block w-auto mt-4`]}>
            {_("Year")}
          </h5>
          <ul css={[tw`block mt-4`]}>
            {yearFilterOptions?.map((year: string) => (
              <li css={[tw`min-w-max transition-all last:pr-16 mb-4 ml-1.5`]}>
                <Checkbox
                  label={year}
                  onChange={e => {
                    updateSelectedYears(year, e.target.checked)
                  }}
                  checked={selectedYears.includes(year)}
                  tabIndex={0}
                  aria-selected={selectedYears.includes(year)}
                />
              </li>
            ))}
          </ul>
          <div css={[tw`mt-4 flex flex-col w-full`]}>
            <h5
              css={[tw`text-left mt-0 pr-4 font-semibold inline-block w-auto`]}
            >
              {_("Category")}
            </h5>
            <ul css={[tw`block mt-4`]}>
              {subCategories?.map((category: string, i: number) => (
                <li css={[tw`min-w-max transition-all last:pr-16 mb-4 ml-1.5`]}>
                  <Checkbox
                    label={category}
                    onChange={e => {
                      updateSelectedCategories(category, e.target.checked)
                      trackTealEvent({
                        tealium_event: "refinement",
                        refinement_value: `${tealPageData?.page_type}|${tealPageData?.page_name}|Accessory|${category}`,
                      })
                    }}
                    checked={selectedCategories.includes(category)}
                    tabIndex={0}
                    aria-selected={selectedCategories.includes(category)}
                    analytics-id={`category filter:refinement:${i + 1}`}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default AccyFilters
