import React, { useContext } from "react"
import { HeroCarouselProps } from "./HeroCarousel.d"
import tw from "twin.macro"
import { LanguageContext } from "../../../../contexts/Language"
import NewHeroCarousel from "../../../NewCarousel/NewHeroCarousel"
import HeroCarouselItem from "./HeroCarouselItem"
import PartSaleItem from "./PartSaleItem"
import StaticCarouselItem from "./StaticCarouselItem"
import RetentionSalesEventItem from "../SalesEventHero/RetentionSalesEventItem"

/**
 * An Autoplaying Carousel with Mobile and Desktop Images.
 *
 * @param {HeroCarouselProps} props React Props
 * @param {HeroCarouselItem[]} props.slides indicates whether this component is active
 * @param {number} props.timeout number of milliseconds until the slide advances
 * @return {JSX.Element}
 * @todo Destructure objects, create bindings for image data objects
 */

const HeroCarousel: React.FC<HeroCarouselProps> = ({
  slides,
  sectionSlug,
  margins,
}) => {
  const { _ } = useContext(LanguageContext)

  return (
    <NewHeroCarousel
      slides={slides}
      hero
      continuous
      mobileButtons
      section="hero"
      css={[
        tw`h-[670px]`,
        tw`sm:(h-[550px])`,
        tw`md:(h-[700px])`,
        tw`lg:(h-[600px])`,
        `margin: ${margins?.top}px 0 ${margins?.bottom}px !important;`,
      ]}
      id={sectionSlug?.current}
    >
      {({ slideIndex }) => {
        const { _type } = slides[slideIndex]
        const slideData = slides[slideIndex]
        if (_type === "heroCarouselItem") {
          return (
            <HeroCarouselItem slide={slides[slideIndex]} index={slideIndex} />
          )
        } else if (_type === "partSaleItem") {
          return <PartSaleItem slide={slides[slideIndex]} index={slideIndex} />
        } else if (_type === "staticCarouselItem") {
          return (
            <StaticCarouselItem slide={slides[slideIndex]} index={slideIndex} />
          )
        } else if (_type === "retentionSEHeroItem") {
          return (
            <RetentionSalesEventItem {...slideData} slideIndex={slideIndex} />
          )
        }
      }}
    </NewHeroCarousel>
  )
}

export default HeroCarousel
