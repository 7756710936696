import React, { useContext } from "react"
import tw from "twin.macro"
import { LanguageContext } from "../../../../contexts/Language"
import ImageBlock from "./ImageBlock"
import { ImageBlocksProps } from "./ImageBlocks.d"

const ImageBlocks: React.FC<ImageBlocksProps> = ({
  imageBlocks,
  _key,
  sectionSlug,
  margins,
}) => {
  const { _ } = useContext(LanguageContext)
  return (
    <section
      css={[
        tw`overflow-hidden`,
        `margin: ${margins?.top}px 0 ${margins?.bottom}px !important;`,
      ]}
      key={_key}
      id={sectionSlug?.current}
      aria-label={_("Image Block Section")}
    >
      <div
        css={[
          tw`relative flex flex-row w-full overflow-x-scroll scrollbar-hide`,
        ]}
      >
        {imageBlocks?.map((block, index) => (
          <ImageBlock key={index} block={block} count={ImageBlocks.length} />
        ))}
      </div>
    </section>
  )
}

export default ImageBlocks
