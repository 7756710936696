import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { StandardEquipmentSectionProps } from "./StandardEquipment.d"
import { Model } from "../../../../global"
import { parseDisclaimerBlocks } from "../../../../helpers"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { Accordion } from "../../../atoms/Accordion"
import { SeriesPageContext } from "../../../../templates/series"
import ModelListItem from "../ModelList/ModelListItem"
// import Icon from "../../../atoms/Icon"
import { LanguageContext } from "../../../../contexts/Language"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const StandardEquipmentSection: React.FC<StandardEquipmentSectionProps> = ({
  heading,
}) => {
  const [state, modalDispatch] = useContext(DisclaimersContext)
  const { models } = useContext(SeriesPageContext)
  const { language, _ } = useContext(LanguageContext)
  const [selectedModelGroup, setSelectedModelGroup] = useState(models[0])
  const [standardEquipment, setStandardEquipment] = useState(null)
  // let icon = null

  useEffect(() => {
    if (selectedModelGroup) {
      const newStandardEquipData =
        language === "es"
          ? selectedModelGroup[0].model.standardEquipment.es
          : selectedModelGroup[0].model.standardEquipment.en
      const featuredStandardEquip = newStandardEquipData?.filter(
        (standardEquip: any) => standardEquip?.type === "features"
      )
      setStandardEquipment(featuredStandardEquip)
    }
  }, [selectedModelGroup])

  return (
    <section
      css={[
        tw`px-0 w-full bg-gradient-to-b bg-gray-100 to-white pt-12 mb-6`,
        tw`md:(mx-auto)`,
      ]}
      aria-label="Standard Equipment Section"
    >
      {heading && (
        <div
          css={[
            tw`justify-self-center text-3xl font-light tracking-widest mb-10 text-left px-6`,
            tw`lg:(text-5xl mx-auto px-12)`,
            tw`2xl:(px-20)`,
          ]}
        >
          {parseDisclaimerBlocks(heading, selection =>
            modalDispatch(toggleDisclaimersModal(selection))
          )}
        </div>
      )}

      <div
        css={[
          tw`overflow-auto min-h-[123px] px-6 scrollbar-hide`,
          tw`lg:(max-w-desktop mx-auto px-16 min-h-[155px])`,
        ]}
      >
        <div
          css={[
            tw`flex flex-nowrap w-auto justify-start`,
            tw`2xl:(justify-start)`,
          ]}
        >
          {models.map((modelGroup: Model[], i) => {
            return (
              <ModelListItem
                onSelect={() => {
                  // trackTealEvent({
                  //   features_series_prop: `model_selector|${vehicleTealData.exterior_color}|${modelGroup[0].model.name} `,
                  //   vehicle_model: vehicleTealData.vehicle_model,
                  // })
                  setSelectedModelGroup(
                    models.filter(
                      (e: Model) => e[0].model.id === modelGroup[0].model.id
                    )[0]
                  )
                }}
                active={
                  modelGroup[0].model.id === selectedModelGroup[0].model.id
                }
                modelGroup={modelGroup}
                isElectricVehicle={modelGroup.some(x => x?.isElectricModel)}
                isHybridVehicle={modelGroup.some(x => x?.isHybridModel)}
                key={i}
                showConfigurations={true}
                analytics-id={`model selector:model list:${i + 1}`}
              />
            )
          })}
        </div>
      </div>

      <div css={[tw`bg-white pt-12 overflow-hidden`]}>
        {standardEquipment &&
          standardEquipment.map(
            (se: { items: { name: string; items: any }[] }) => {
              return se.items
                .sort((a, b) => {
                  if (a.name < b.name) {
                    return -1
                  }
                  if (a.name > b.name) {
                    return 1
                  }
                  return 0
                })
                .map((item: { name: string; items: any }) => {
                  return (
                    <div
                      css={[tw`px-6`, tw`lg:(mx-auto px-12)`, tw`2xl:(px-20)`]}
                    >
                      <Accordion
                        label={_(item.name, true)}
                        css={[
                          tw`border-t items-start border-gray-500 px-5 pl-0 py-4 mt-0`,
                          tw`md:(mt-0)`,
                        ]}
                      >
                        <ul
                          css={[
                            tw`text-left mr-7 ml-10 grid grid-cols-1`,
                            tw`lg:(grid-cols-2)`,
                          ]}
                        >
                          {Array.isArray(item.items) &&
                            item.items.map(v => (
                              <li
                                css={[
                                  tw`mb-4 list-disc list-outside md:( mr-12)`,
                                ]}
                              >
                                {parseDisclaimerBlocks(v.title, selection =>
                                  modalDispatch(
                                    toggleDisclaimersModal(selection)
                                  )
                                )}
                              </li>
                            ))}
                        </ul>
                      </Accordion>
                    </div>
                  )
                })
            }
          )}
      </div>
    </section>
  )
}
export default StandardEquipmentSection
