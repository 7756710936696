import { JSX } from "@emotion/react/jsx-runtime"
import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { Accessory, Model } from "."
import { LanguageContext } from "../../../contexts/Language"
import { filterArray, filterPlainArray } from "../../../helpers"
import { AccessoriesPageContext } from "../../../templates/seriesAccessories"
import { AccessoryCard } from "../../molecules/AccessoryCard"
const AccysFiltered: React.FC = () => {
  const {
    accessories,
    selectedCategories,
    selectedYears,
    selectedModelGroup,
    selectedAccyType,
    setSelectedCategories,
  } = useContext(AccessoriesPageContext)

  const [filteredAccessories, setFilteredAccessories] = useState(null)

  const { _ } = useContext(LanguageContext)

  useEffect(() => {
    let accysToFilter = []

    if (selectedAccyType === "port") {
      accysToFilter = accessories.filter(
        (accessory: Accessory) => accessory.type === "port"
      )
    } else {
      accysToFilter = accessories.filter(
        (accessory: Accessory) => accessory.type === "factory"
      )
    }

    const filters = {
      subCategory: selectedCategories,
      year: selectedYears,
    }

    //since checking for model id is 2 levels deep we cant use filterPlainArray
    if (selectedModelGroup && selectedModelGroup.length > 0) {
      let modelsToFilter = selectedModelGroup.map(
        (model: { model: any }) => model.model
      )
      const modelFilter = {
        models: (models: any[]) => {
          return models.find(x =>
            modelsToFilter.some((y: { id: any }) => y.id === x.id)
          )
        },
      }

      accysToFilter = filterArray(accysToFilter, modelFilter)

      // Return XP Package Acc if trim is XP
      if (selectedModelGroup.title == "XP" && selectedAccyType === "port") {
        const xpAcc = accessories.filter(
          (acc: Accessory) => acc.subCategory == "X-Series"
        )
        accysToFilter.push(...xpAcc)
        if (!filters.subCategory.includes("X-Series")) {
          setSelectedCategories([...selectedCategories, "X-Series"])
        }
      }

      accysToFilter = accysToFilter.sort((a, b) => {
        const titleA = a.title.toUpperCase()
        const titleB = b.title.toUpperCase()
        if (titleA < titleB) return -1
        if (titleA > titleB) return 1
        return 0
      })
    }

    setFilteredAccessories(filterPlainArray(accysToFilter, filters))
  }, [selectedCategories, selectedYears, selectedModelGroup, selectedAccyType])

  return (
    <div
      css={[
        tw`py-10 grid grid-cols-1 sm:(grid-cols-1) md:(grid-cols-2 gap-10) lg:(grid-cols-2 gap-10) xl:(grid-cols-3 gap-10)`,
      ]}
    >
      {filteredAccessories && filteredAccessories?.length !== 0 ? (
        filteredAccessories
          .slice() // Create a shallow copy to avoid mutating the original array
          .sort((a: { title: string }, b: { title: string }) => {
            const titleA = a.title.toUpperCase()
            const titleB = b.title.toUpperCase()
            if (titleA < titleB) return -1
            if (titleA > titleB) return 1
            return 0
          })
          .map((accessory: Accessory, index: number) => (
            <AccessoryCard
              key={accessory._id}
              title={accessory.title}
              media={accessory.media}
              subCategory={accessory.subCategory}
              modelsForAccy={accessory.models}
              code={accessory.code}
              partNumber={accessory.partNumber}
              description={accessory.description}
              year={accessory.year}
              type={accessory.type}
              {...accessory}
              index={index}
              css={[tw`mx-0`]}
            />
          ))
      ) : (
        <div
          css={[
            tw`bg-gray-100 rounded-lg p-16 text-center col-start-1 col-end-4 max-w-[325px] mx-auto`,
          ]}
        >
          <p css={[tw`font-bold text-lg`]}>
            {_("Sorry, there are no accessories that match your selection.")}
          </p>
        </div>
      )}
    </div>
  )
}

export default AccysFiltered
