import React from "react"
import { NavigationSubGroupProps, SubGroup } from "./Footer.d"
import tw from "twin.macro"
import { LinkType } from "../../../global"
import { Link } from "../../atoms/Link"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { getCTALinkValue } from "../../../helpers"

/**
 *
 * @author Tyler
 * @summary - Unordered list of links separated by vehicle family
 *
 * @component
 * @param {SubGroup} items - List of links with a heading
 *
 * @returns <NavigationSubGroup />
 *
 */

export const NavigationSubGroup: React.FC<NavigationSubGroupProps> = ({
  items,
}) => {
  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  const hybridSeries = [
    "camry",
    "corolla",
    "corolla-cross",
    "highlander",
    "rav4",
    "grand-highlander",
    "tundra",
  ]

  return (
    <>
      {items?.map((subGroup: SubGroup) => (
        <div key={subGroup?._key}>
          {subGroup?.heading && (
            <h3
              css={[tw`text-sm mb-2`]}
              onClick={() =>
                trackTealEvent({
                  tealium_event: "nav_click",
                  click_text: `footer:${subGroup.heading}`,
                })
              }
              analytics-id={`${subGroup.heading}:footer:`}
            >
              {subGroup.headingLink ? (
                <Link
                  animated
                  animatedThin
                  to={`/${subGroup.headingLink?.slug?.current}/`}
                  css={[
                    tw`py-1 my-1 border border-transparent cursor-pointer`,
                    tw`focus-visible:(border-white border-dashed border outline-none p-2 -ml-2 -mb-0 -mt-0 -mr-2)`,
                  ]}
                >
                  {subGroup.heading}
                </Link>
              ) : (
                subGroup.heading
              )}
            </h3>
          )}

          {subGroup?.items && (
            <ul aria-label={subGroup?.heading}>
              {subGroup?.items?.map((navItem: LinkType, idx: number) => {
                let itemLink = getCTALinkValue(navItem)
                const isHybridGroup =
                  subGroup?.headingLink?.slug?.current === "hybrids"
                const hasHybrid = hybridSeries.includes(
                  navItem?.internalLink?.slug?.current.toLowerCase()
                )
                const hybridQeuryParam =
                  isHybridGroup && hasHybrid ? "?hybrid=true" : ""
                return (
                  <li
                    key={idx}
                    css={[tw`text-xs text-gray-600 flex`]}
                    analytics-id={`${navItem?.displayText}:footer:`}
                  >
                    <Link
                      animated
                      animatedThin
                      to={itemLink + hybridQeuryParam}
                      target={
                        navItem?.linkType === "external" ? "_blank" : "_self"
                      }
                      css={[
                        tw`py-1 my-1 border border-transparent focus-visible:(border-white border-dashed border outline-none p-2 -ml-2 -mb-0 -mt-0 -mr-2)`,
                      ]}
                      onClick={() =>
                        trackTealEvent({
                          tealium_event: "nav_click",
                          click_text: `footer:${navItem?.displayText}`,
                        })
                      }
                    >
                      {navItem?.displayText}
                    </Link>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      ))}
    </>
  )
}
