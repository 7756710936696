import React, { useContext } from "react"
import tw from "twin.macro"
import { Button, ButtonLink } from "../../atoms/Button"
import Icon from "../../atoms/Icon"
import { LocalInventoryLinksProps } from "./LocalInventoryLinks.d"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"
import { navigate } from "gatsby"
import { ShareButton } from "../../atoms/ShareButton"
import { LanguageContext } from "../../../contexts/Language"
import { SeriesPageContext } from "../../../templates/series"
import { vehicleCategory } from "../../../helpers"

/**
 *
 *
 * @param {string} series - Vehicle data used for tealium event call
 * @param {string} year - Vehicle data used for tealium event call
 * @param {string} name - Vehicle name used for share button
 *
 */

const LocalInventoryLinks: React.FC<LocalInventoryLinksProps> = ({
  city,
  dealerCount,
  inventoryCount,
  share,
  light = false,
  secondaryBgLight = true,
  hideLocation,
  series,
  year,
  inventoryLink,
  name,
  model,
  hideInventory,
  analyticsId = null,
  extraTealiumValues,
  additionalLinkText = null,
  ...remainingProps
}) => {
  const { _ } = useContext(LanguageContext)
  const { trackTealEvent } = useTealiumEvent()
  const { vehicleTealData, tealPageData } = useTealiumContext()
  const { vehicle } = useContext(SeriesPageContext)

  // Vehicle data
  const vehicleYear = vehicle?.year

  // Force vehicle year to be a string
  //const vehicleYear = year?.toString() || undefined

  const handleInventoryClick = () => {
    navigate(inventoryLink)
  }

  return (
    <div css={[tw`mb-0 md:(mb-0)`]} {...remainingProps}>
      <div css={[tw`md:(pt-0 pb-0)`]}>
        {!hideLocation && city && (
          <p css={[tw`font-light tracking-wider text-lg pb-5 md:(text-xl)`]}>
            {_("Near")} {city}
          </p>
        )}
        <div css={[tw`flex`]}>
          <ButtonLink
            primary
            to="/dealers"
            css={[
              tw`mr-2 mb-1 text-xs flex items-center justify-center !flex-nowrap !px-5 uppercase min-w-[170px]`,
              tw`focus-visible:(outline-gray)`,
            ]}
            analytics-id={`dealers:${analyticsId || ""}`}
            onClick={() => {
              trackTealEvent({
                tealium_event: "cta_click",
                coupon_module_text: `Dealers ${
                  dealerCount === undefined
                    ? "" // return empty string if dealerCount is undefined (i.e. if user clicks before data loads)
                    : `(${dealerCount.toLocaleString("en-US")})`
                }`,
                link_href: `/dealers`,
                ...(vehicleTealData?.vehicle_model && {
                  vehicle_model: vehicleTealData?.vehicle_model,
                }),
                ...(vehicleTealData?.vehicle_model_truncated && {
                  vehicle_model_truncated:
                    vehicleTealData?.vehicle_model_truncated,
                }),
                ...(tealPageData?.page_type == "select a vehicle" && {
                  vehicle_year: vehicleYear,
                  vehicle_segment_vehicle_page: vehicleCategory(series),
                }),
                ...(extraTealiumValues && { ...extraTealiumValues }),
              })
            }}
          >
            {additionalLinkText && _(additionalLinkText)} {_("Dealers")}
            {dealerCount ? ` (${dealerCount})` : ""}
          </ButtonLink>
          {!hideInventory && (
            <ButtonLink
              to={inventoryLink}
              secondary={!light}
              secondaryLight={light}
              secondaryBgLight={!light}
              css={[
                tw`text-xs flex items-center justify-center !px-5 mb-1 uppercase min-w-[150px]`,
                tw`focus-visible:(outline-gray)`,
              ]}
              onClick={() => {
                trackTealEvent({
                  tealium_event: "view_inventory_click",
                  coupon_module_text: `Inventory ${
                    Number(inventoryCount) > 0
                      ? `(${inventoryCount.toLocaleString("en-US")})`
                      : "" // empty string is returned instead of "false" if lefthand side is falsy. I.e. because user has clicked before data loads, or inventory is 0
                  }`,
                  link_href: inventoryLink,
                  ...(vehicleTealData?.vehicle_model && {
                    vehicle_model: vehicleTealData?.vehicle_model,
                  }),
                  ...(vehicleTealData?.vehicle_model_truncated && {
                    vehicle_model_truncated:
                      vehicleTealData?.vehicle_model_truncated,
                  }),
                  ...(tealPageData.page_type == "select a vehicle" && {
                    vehicle_year: vehicleYear,
                    vehicle_segment_vehicle_page: vehicleCategory(series),
                  }),
                  ...(extraTealiumValues && { ...extraTealiumValues }),
                })
              }}
              analytics-id={`inventory:${analyticsId || ""}`}
            >
              {_("Inventory")}{" "}
              {Number(inventoryCount) > 0 &&
                `(${inventoryCount.toLocaleString("en-US")})`}
            </ButtonLink>
          )}
        </div>
      </div>
      {share && (
        <div css={[tw`flex mt-4`]}>
          {share && (
            <ShareButton
              iconStyles={tw`h-5 w-5 mr-3`}
              text={name}
              css={[
                tw`font-semibold tracking-widest mr-4 capitalize lg:(text-lg mr-8)`,
              ]}
            >
              {_("Share")}
            </ShareButton>
          )}
        </div>
      )}
    </div>
  )
}

export default LocalInventoryLinks
