import React, { useContext } from "react"
import tw from "twin.macro"
import { LanguageContext } from "../../../../contexts/Language"
import { Button } from "../../../atoms/Button"
import { BackAndNextButtonsProps } from "../ContactDealer.d"
import { ContactContext } from "../../../../contexts/Contact"

const BackAndNextButtons: React.FC<BackAndNextButtonsProps> = ({
  type = "button",
  prevOnClick,
  nextOnClick,
  active,
  submitting,
  nextButtonText,
  ...remainingProps
}) => {
  const { _ } = useContext(LanguageContext)
  const [{ contactButtonType }, dispatch] = useContext(ContactContext)
  const { heading } = remainingProps

  // Optimizely Experiment #3 - Measuring form submissions on Inventory Pages and VDPs
  const isCheckAvailabilitySubmit =
    contactButtonType === "CheckAvailability" && type === "submit"
  return (
    <section
      css={[
        tw`flex justify-between mb-6 px-6 h-11 w-full`,
        tw`lg:(mb-4)`,
        active ? tw`flex` : tw`hidden`,
        !prevOnClick && tw`justify-end`,
      ]}
      {...remainingProps}
    >
      {prevOnClick && (
        <Button
          invisible
          type="button"
          disabled={!active}
          onClick={prevOnClick}
          css={tw`flex items-center hover:text-black md:hover:text-white`} // Add hover black for iOS Safari bug where text is white
          analytics-id={`Back:contact form:${heading}`}
        >
          {_("Back")}
        </Button>
      )}
      {nextOnClick && (
        <Button
          primary
          type={type}
          disabled={!active || submitting}
          form={type === "submit" ? "contactDealer" : null}
          name="next"
          onClick={nextOnClick}
          css={tw`flex items-center`}
          analytics-id={`${
            type !== "submit"
              ? nextButtonText || _("Next")
              : submitting
              ? _("Sending...")
              : nextButtonText || _("Send")
          }:contact form:${heading}`}
          id="contactFormNextBtn"
          className={
            isCheckAvailabilitySubmit ? "optimizelySubmitLeadButton" : ""
          }
        >
          {type !== "submit"
            ? nextButtonText || _("Next")
            : submitting
            ? _("Sending...")
            : nextButtonText || _("Send")}
        </Button>
      )}
    </section>
  )
}

export default BackAndNextButtons
